<section>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title style="color:#595c5f;">
                <mat-icon style="margin-right: 10px;">rule</mat-icon>
                {{'taskTemplates.validations' | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>

            <div >
                <div class="list-group mb-4 ">
                    @for( rule of validationAll; track rule; let index = $index){
                        <div class="list-group-item" >
                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <div class=" w-75" (click)="editRule(rule, index)">
                                    <p  >{{'taskTemplates.rules.' + rule.type | translate}}</p>
                                    <!-- <span  >{{rule.type | translate}}</span><br> -->
                                    <div class="d-flex flex-column">
                                        @if(rule.min){
                                            <span><strong>min: </strong>
                                                @if(rule.type == 'dateRange'){
                                                    {{ showDate(rule.min)  }}
                                                }
                                                
                                                @if(rule.type != 'dateRange'){
                                                    {{rule.min}}
                                                }
                                            </span>
                                        }
    
                                        @if(rule.max){
                                            <span><strong>max: </strong>
                                                @if(rule.type == 'dateRange'){
                                                    {{ showDate(rule.max)  }}
                                                }
                                                
                                                @if(rule.type != 'dateRange'){
                                                    {{rule.max}}
                                                }
                                            </span>
                                        }
                                        @if(rule.regex){
                                            <span><strong>regex: </strong>{{rule.regex}}</span>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <button  mat-icon-button matTooltip="{{'delete' | translate}}" style="color:red;" (click)="deleteRule(rule, index);">
                                        <span class="material-symbols-outlined">delete</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    
                </div>
            </div>

            <mat-action-row>
                <button mat-button class="secondary-gray-button my-2" [matMenuTriggerFor]="rulesTypes" [disabled]="!tasktemplateUserRights_update">
                    <mat-icon>add_circle</mat-icon> {{'taskTemplates.addRule' | translate}}
                </button>

                <mat-menu #rulesTypes="matMenu" xPosition="before" yPosition="below">
                    <button mat-menu-item *ngFor="let element of ruleTypes; let elementIndex = index" (click)="addRule(element.type);" [disabled]="!element.allowedFields.includes(fieldType)"><!-- || checkIfRuleIsAddedAlready(element)-->
                        <mat-icon class="material-symbols-outlined">{{element.icon}}</mat-icon> {{element.translation | translate}}
                    </button>
                </mat-menu>
            </mat-action-row>

    </mat-expansion-panel>

</section>