import { Type } from "@angular/core"

export abstract class MediaFolderData {
    abstract className: string
    listeners: Set<any> = new Set()
    abstract layoutComponentRef: Type<any>
    addListener(obj: any) { this.listeners.add(obj) }
    removeListener(obj: any) { this.listeners.delete(obj) }
    
    constructor(
        public route: string,
        public routeUrlSegment: string[],
        public folderName: string,
        public folderItems: any,
        public view:string) {}

    abstract openFilePreview(file) 
    abstract deleteFile(file)
    abstract downloadFile(file)
    abstract setViewStyle(style)
    abstract addFileToFolder(file, num)
}   

export class MediaInsideFolderData extends MediaFolderData {
    public static className = "MediaInsideFolderData"
    public className = "MediaInsideFolderData"

    constructor(
        route: string,
        routeUrlSegment: string[],
        folderName: string, 
        folderItems: any,
        view:string,
        public layoutComponentRef: Type<any>
    ) {
        super(route,routeUrlSegment, folderName, folderItems, view)
    }
    
    downloadFile(file) { this.listeners.forEach((obj: any) => { obj.downloadFile(file) }) }
    deleteFile(file) { this.listeners.forEach((obj: any) => { obj.deleteFile(file) }) }
    openFilePreview(file) { this.listeners.forEach((obj: any) => { obj.openFilePreview(file, this) }) }
    setViewStyle(style) { this.listeners.forEach((obj: any) => { obj.setViewStyle(style) }) }
    addFileToFolder(file, num) { this.listeners.forEach((obj:any) => { obj.addFileToFolder(file, num) }) }
    searchFilter(str: string): boolean { return this.folderItems.searchFilter(str) }
}

export class MediaFilePreviewDialog extends MediaFolderData {

    public static className = "MediaFilePreviewDialog"
    public className = "MediaFilePreviewDialog"

    constructor(
        route: string,
        routeUrlSegment: string[],
        folderName: string, 
        folderItems: any,
        view:string,
        public layoutComponentRef: Type<any>) {
        super(route, routeUrlSegment, folderName, folderItems, view)
    }

    downloadFile(file) { this.listeners.forEach((obj: any) => { obj.downloadFile(file) }) }
    deleteFile(file) { this.listeners.forEach((obj: any) => { obj.deleteFile(file) }) }
    openFilePreview(file) {  }
    setViewStyle(style) {  }
    addFileToFolder(file, index) {  }
}



export abstract class MediaDialogFolderData {
    abstract className: string
    listeners: Set<any> = new Set()
    abstract layoutComponentRef: Type<any>
    addListener(obj: any) { this.listeners.add(obj) }
    removeListener(obj: any) { this.listeners.delete(obj) }
    
    constructor(
        public route: string,
        public routeUrlSegment: string[],
        public folderName: string,
        public folderItems: any,
        public supportedFiles:string[]) {}

    abstract setSelectedFile(file) 
    // abstract deleteFile(file)
    // abstract downloadFile(file)
    // abstract setViewStyle(style)
    // abstract addFileToFolder(file, num)
} 

export class MediaDialogInsideFolderData extends MediaDialogFolderData {
    public static className = "MediaDialogInsideFolderData"
    public className = "MediaDialogInsideFolderData"

    constructor(
        route: string,
        routeUrlSegment: string[],
        folderName: string, 
        folderItems: any,
        supportedFiles:string[],
        public layoutComponentRef: Type<any>
    ) {
        super(route,routeUrlSegment, folderName, folderItems,supportedFiles)
    }
    
    // downloadFile(file) { this.listeners.forEach((obj: any) => { obj.downloadFile(file) }) }
    // deleteFile(file) { this.listeners.forEach((obj: any) => { obj.deleteFile(file) }) }
    setSelectedFile(file) { this.listeners.forEach((obj: any) => { obj.setSelectedFile(file) }) }
    // setViewStyle(style) { this.listeners.forEach((obj: any) => { obj.setViewStyle(style) }) }
    // addFileToFolder(file, num) { this.listeners.forEach((obj:any) => { obj.addFileToFolder(file, num) }) }
    // searchFilter(str: string): boolean { return this.folderItems.searchFilter(str) }
}


export interface ICalledFromData {
    passId?:string
    widgetNumber?:string
    widget?:string
    imageIndex?:number
}
export class DragFileBrowseData {
    calledFrom: any
    calledFromData: ICalledFromData
    enableDragDrop:boolean
    enableBrowse: boolean
    enableMedia:boolean
    enableEnterUrl:boolean
    enableMultipleDragDrop: boolean
    supportedFileTypes:string[]
    supportedFileAccept:string
    maxSizeInBytes:number
    maxAllowedSizeInBytes:number
    returnSupportUrl:string
    returnUrlData:String
    enterUrl:  boolean
    returnUrlType?:string//'media', 'dragDrop', 'browse'
}

