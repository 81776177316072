import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { NetworkConstants } from 'src/network.constants';
import { removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { collection, deleteDoc, doc, getFirestore, setDoc, updateDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class InterfacesService {
  db = getFirestore()

  constructor(private settingsService: SettingsService) { }

  async createInterface(data):Promise<string>{
    return new Promise(async (resolve, reject) => {

      const docRef = doc( collection(this.db,NetworkConstants.COLLECTION_CONTEXTS, this.settingsService.contextId$, NetworkConstants.COLLECTION_INTERFACES) )
      const newId = docRef.id

      let newInterface = removeUndefinedValuesFromObject(data)
      newInterface['creationDate'] = new Date()
      newInterface['timeStamp'] = new Date()
      newInterface['id'] = newId

      try{

        await setDoc( docRef, newInterface)
        resolve(newId)
        
      }catch (error) {
        reject(error)
      }
    })

  }



  updateInterface(data:any):Promise<void>{
    return new Promise(async (resolve, reject) => {
        
      try{
        removeUndefinedValuesFromObject(data)
        data['timeStamp']= new Date()

        // await this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        // .doc(this.settingsService.contextId$)
        // .collection(NetworkConstants.COLLECTION_INTERFACES)
        // .doc(data.id)
        // .update(data)


        const docRef = doc(this.db, NetworkConstants.COLLECTION_CONTEXTS, this.settingsService.contextId$,NetworkConstants.COLLECTION_INTERFACES, data.id )
        await updateDoc( docRef, data)
        
        resolve()
      }catch(error){
        reject(error)
      }
    }) 
  }

  deleteInterface(interfaceId):Promise<void>{
    return new Promise(async (resolve, reject) => {
      try {
        // const d = this.firestore.collection(NetworkConstants.COLLECTION_CONTEXTS)
        // .doc(this.settingsService.contextId$)
        // .collection(NetworkConstants.COLLECTION_INTERFACES)
        // .doc(interfaceId)
        // .delete()

        const docRef = doc(this.db, NetworkConstants.COLLECTION_CONTEXTS, this.settingsService.contextId$, NetworkConstants.COLLECTION_INTERFACES, interfaceId)
        const d  = await deleteDoc( docRef)

        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }
}