import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[app-composed-phone]',
  templateUrl: './composed-phone.component.html',
  styleUrls: ['./composed-phone.component.css']
})
export class ComposedPhoneComponent {
  @Input('app-composed-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any}>()

  idToShow:any
  routerUrl:any
  composedData:any
  editMode = false
  widgetNumber:any
  darkModeActive= false
  titleColor = "var(--title)"
  subtitleColor = "var(--subtitle)"

  constructor(private router: ActivatedRoute,
    private renderer: Renderer2,
    private element: ElementRef){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }

  ngOnInit():void{
    this.composedData=this.data[0]
    this.editMode=this.data[1]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
  }

  ngOnChanges(){
    this.composedData=this.data[0]
    this.editMode=this.data[1]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
    this.darkModeActive = this.data[5]
    
    setTimeout(() => {
      this.setWidgetStyle(this.composedData)     
    }, 200);
  }


  setWidgetStyle(data){
    const elementId = 'phoneSection-'+data.id
    const element = this.element.nativeElement.querySelector('#'+elementId);
    if(data?.widgetStyle){
      if(this.darkModeActive){
        if( data.widgetStyle.dark){
          if(data.widgetStyle.dark.backgroundColor){
            document.getElementById(elementId).style.backgroundColor = data.widgetStyle.dark.backgroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          }
          if( data.widgetStyle.dark.foregroundColor){
            document.getElementById(elementId).style.color = data.widgetStyle.dark.foregroundColor
            if(data.title)
              this.titleColor = data.widgetStyle.dark.foregroundColor
            if(data.subtitle)
              this.subtitleColor = data.widgetStyle.dark.foregroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
          }
        }else{
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');

            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
        }
      }else{
        if( data.widgetStyle.light){
          if( data.widgetStyle.light.backgroundColor){
            document.getElementById(elementId).style.backgroundColor = data.widgetStyle.light.backgroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          }

          if( data.widgetStyle.light.foregroundColor){
            document.getElementById(elementId).style.color = data.widgetStyle.light.foregroundColor
            if(data.title)
              this.titleColor = data.widgetStyle.light.foregroundColor
            if(data.subtitle)
              this.subtitleColor = data.widgetStyle.light.foregroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
          }
        }else{
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');

          if(data.title)
            this.titleColor = "var(--title)"
          if(data.subtitle)
            this.subtitleColor = "var(--subtitle)"
        }
      }

      if(data.widgetStyle.contentAlignment){
        if(data.widgetStyle.contentAlignment=='leading' || data.widgetStyle.contentAlignment=='left')
          document.getElementById(elementId).style.textAlign = 'left'

        if(data.widgetStyle.contentAlignment=='center')
          document.getElementById(elementId).style.textAlign = 'center'

        if(data.widgetStyle.contentAlignment=='trailing' || data.widgetStyle.contentAlignment=='right')
          document.getElementById(elementId).style.textAlign = 'end'

      }else{
        if(document.getElementById(elementId)?.style && document.getElementById(elementId)?.style.textAlign)
          this.renderer.removeStyle(element, 'textAlign');
      }
    }
  }

}
