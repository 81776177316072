import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from '../service/comments.service';
import { AuthService } from '../service/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-info-mesage-dialog',
  templateUrl: './info-mesage-dialog.component.html',
  styleUrls: ['./info-mesage-dialog.component.css']
})
export class InfoMesageDialogComponent {

  message_mail:any
  message_mail_2:any
  email:any
  messageConfirmation=false
  setMailToResetPassword=false
  resetForm!:FormGroup
  USER_REINVITE_OK = false

  constructor(
    public dialogRef: MatDialogRef<InfoMesageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private commentsService: CommentsService,
    private translate:TranslateService,
    private authService:AuthService,
    private fb: FormBuilder,
    ){
      dialogRef.disableClose = true;
    }

    ngOnInit(){
      this.resetForm = this.fb.group({
        email:[]
      })

      if(this.dialogData.values['message'] =="PASSWORD_RESET_MAIL_OK"){
        this.messageConfirmation=true
        this.message_mail = this.translate.instant('reset-password-mail')
        this.message_mail_2 = this.translate.instant('reset-password-mail_2')
        this.email = this.dialogData.values['mail']
      } 

      if(this.dialogData.values['message'] =="SET_MAIL_TO_RESET_PASSWORD"){
        this.setMailToResetPassword=true
      } 

      if(this.dialogData.values['message'] =="USER_REINVITE_OK"){
        this.USER_REINVITE_OK=true
        this.message_mail = this.translate.instant('SnackBarConstants.USER_REINVITE_OK')
        this.message_mail_2 = this.translate.instant('reset-password-mail_2')
        this.email = this.dialogData.values['mail']
      } 
    }

    closeInfoMessage(){
      this.dialogRef.close()
    }

    cancelResetPassword(){
      this.dialogRef.close()
    }

    sendMailToResetPassword(){
      this.commentsService.progressSpin.emit(true)
      this.authService.resetPassword(this.resetForm.value.email).then( 
        () => {
          this.commentsService.progressSpin.emit(false)
          this.message_mail = this.translate.instant('reset-password-mail')
          this.message_mail_2 = this.translate.instant('reset-password-mail_2')
          this.email = this.resetForm.value.email
          this.setMailToResetPassword=false
          this.messageConfirmation=true
          const message = this.translate.instant("SnackBarConstants.RESET_PASSWORD_MAIL_OK")
          this.commentsService.addSnackBar.emit(message)

        },
        (rejectionReason) => {
          console.log(rejectionReason)
          this.commentsService.progressSpin.emit(false)
          const message = this.translate.instant("SnackBarConstants.RESET_PASSWORD_MAIL_FAILED")
          this.commentsService.addSnackBar.emit(message)
        }
      ).catch( e => {
        console.log(e)
        this.commentsService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.RESET_PASSWORD_MAIL_FAILED")
        this.commentsService.addSnackBar.emit(message)
      })
    }
}
