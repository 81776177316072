import { Component, Injectable, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { SettingsService } from '../../service/settings.service';
import { WorkTaskTemplatesService } from '../../service/work-task-templates.service';
import { Router } from '@angular/router';
import { CommentsService } from '../../service/comments.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { TaskTemplateCreateDialogComponent } from '../worktask-template-create-dialog/worktask-template-create-dialog.component';
import { DeleteConfirmationDialogComponent } from '../../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { NavbarAccesService } from '../../service/navbar-acces.service';
import { DuplicateTaskTemplateDialogComponent } from '../../duplicate-task-template-dialog/duplicate-task-template-dialog.component';
import { LocalStorageService } from '../../service/local-storage-service.service';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  constructor(private translate: TranslateService){}

  firstPageLabel = this.translate.instant("first_page");
  itemsPerPageLabel = this.translate.instant("items_page");
  lastPageLabel =this.translate.instant("last_page")
  nextPageLabel = this.translate.instant("next_page");
  previousPageLabel = this.translate.instant("previous_page");

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.instant("page1_1");
    }
    const amountPages = Math.ceil(length / pageSize);
     
    return this.translate.instant("page_of_1") + (page + 1) +this.translate.instant("page_of_2") + amountPages;
  }
}

@Component({
  selector: 'app-worktask-templates',
  templateUrl: './worktask-templates.component.html',
  styleUrls: ['./worktask-templates.component.css'],
  providers: [{provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl}],
})

export class TaskTemplatesComponent implements OnInit, AfterViewInit {
  taskTemplates:any=[]
  emptyList = false
  searchText=''
  displayedColumns: string[] = ['title', 'creationDate', 'timeStamp', 'delete'];
  listdata = new MatTableDataSource()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paginatorSize = this.settingService.paginator$
  tasktemplateUserRights_create:any
  tasktemplateUserRights_delete:any

  constructor(private settingService: SettingsService,
    private wtaskTemplateService: WorkTaskTemplatesService,
    private router: Router,
    private commentService: CommentsService,
    private translate:TranslateService,
    public dialog: MatDialog,
    private navbarAcces: NavbarAccesService,
    private localStorageService:LocalStorageService,
    private changeDetectorRef: ChangeDetectorRef
    ){}
    
  ngOnInit(): void {
    this.getTasksTemplates()
    if( this.settingService.modulesRolesRights$.value['workTaskTemplates']){
      this.tasktemplateUserRights_create = this.settingService.modulesRolesRights$.value['workTaskTemplates']['C']
      this.tasktemplateUserRights_delete = this.settingService.modulesRolesRights$.value['workTaskTemplates']['D']
    }else{
      if(this.navbarAcces.workTasks)
      this.tasktemplateUserRights_create = true
      this.tasktemplateUserRights_delete = true
    }

    this.settingService.modulesRolesRights$.subscribe( value => {
      this.tasktemplateUserRights_create = this.settingService.modulesRolesRights$.value['workTaskTemplates']['C']
      this.tasktemplateUserRights_delete = this.settingService.modulesRolesRights$.value['workTaskTemplates']['D']
    })
  }

  loadTablePreferences(): void {
    const preferences = this.localStorageService.get('taskTemplateTablePreferences');
    if (preferences) {
      this.paginator.pageSize = preferences.pageSize;
      this.sort.active = preferences.sortActive;
      this.sort.direction = preferences.sortDirection;
      this.paginator.pageIndex = preferences.pageIndex;
    } else {
      this.sort.active = 'timeStamp';
      this.sort.direction = 'desc';
    }
    this.changeDetectorRef.detectChanges();
  }

  storeTablePreferences(): void {
    const preferences = {
      pageSize: this.paginator.pageSize,
      sortActive: this.sort.active,
      sortDirection: this.sort.direction,
      pageIndex: this.paginator.pageIndex
    };
    this.localStorageService.set('taskTemplateTablePreferences', preferences);
  }

  getTasksTemplates(){
    try{
      this.commentService.progressSpin.emit(true)
      this.settingService.observeContextsWorkTasksTemplatesFirestoreWithId().subscribe( value => {
        this.taskTemplates = value
        this.listdata = new MatTableDataSource<any>(this.taskTemplates)
        this.shorTableAgain()
      })
      // this.taskTemplates = this.settingService.observeContextsWorkTasksTemplatesFirestoreWithId()

    }catch(error){
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  ngAfterViewInit(): void {
    this.listdata.paginator = this.paginator;
    this.listdata.sort = this.sort;

    this.paginator.page.subscribe(() => this.storeTablePreferences());
    this.sort.sortChange.subscribe(() => this.storeTablePreferences());

    this.loadTablePreferences();

    setTimeout(() => {
      this.listdata.filter = " ";
      this.listdata.paginator = this.paginator;
      const preferences = this.localStorageService.get('taskTemplateTablePreferences');
      if (preferences) {
        this.paginator.pageIndex = preferences.pageIndex;
        this.paginator._changePageSize(this.paginator.pageSize);
      } else {
        if(this.listdata && this.listdata.paginator)
        this.listdata.paginator.firstPage();
      }
      this.commentService.progressSpin.emit(false)
    }, 600);

    setTimeout(() => {
      if(this.taskTemplates?.length === 0){
        this.emptyList = true
      }else{
        this.emptyList=false
      }
    }, 700);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.listdata.filter = filterValue.trim().toLowerCase();

    if (this.listdata.paginator) {
      this.listdata.paginator.firstPage();
    }
  }

  createNewWorkTaskTemplate(){
    let dialogRef= this.dialog.open(TaskTemplateCreateDialogComponent, {
      data: { 
         
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.router.navigate(['home', this.settingService.contextId$,'taskTemplates', result[1]])

      }
    })
  }

  openDuplicateTaskDialog(element){

      let dialogRef = this.dialog.open(DuplicateTaskTemplateDialogComponent, {
        data: {
          workTaskTemplateId: element.id,
          oldTitle: element.title
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          this.getTasksTemplates()
        }
      });
  
  }

  shorTableAgain(){
    this.listdata.paginator = this.paginator;
    this.listdata.sort = this.sort;
    setTimeout(() => {
      this.listdata.filter = " ";
      this.commentService.progressSpin.emit(false)
      if(this.taskTemplates?.length === 0){
        this.emptyList = true
      } else{
        this.emptyList = false
      }
    }, 500);
  }

  deleteTask(task){
    let dialogRef= this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { 
        values:task,
        type:"Task Template"
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.getTasksTemplates()
        this.shorTableAgain()
      }else{
        const message = this.translate.instant("SnackBarConstants.DELETE_DISMISS")
        this.commentService.addSnackBar.emit(message)
      }
    });
  }
}
