import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { UploadService } from 'src/app/service/upload.service';
import { MediaDetailsDialogComponent } from '../media-details-dialog/media-details-dialog.component';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { languagesList, languagesList_ } from '../../../../../../backend/src/languagesList.dto';
import { SettingsService } from 'src/app/service/settings.service';
import { DragFileBrowseData } from 'src/app/shared/media';

@Component({
  selector: 'app-video-edit',
  templateUrl: './video-edit.component.html',
  styleUrls: ['./video-edit.component.css']
})

export class VideoEditComponent implements OnInit{
  videoEditData:any
  oldData:any
  url:any
  noUrl=false
  videoEditForm!:FormGroup
  changes=false
  newVideo:any=[]
  widgetNumber:any
  passId:any
  files: FileHandle[] = []
  drop=false
  urlData:any
  @ViewChild('media') myVideo: ElementRef;
  dynamic = false
  addUrl = false
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>
  languagesList = languagesList
  defaultLanguage:string
  dynamicUrl = false
  userHasBetaWidgetRole = false
  dragFileData: DragFileBrowseData 
  
  constructor(public dialogRef: MatDialogRef<VideoEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private uploadService: UploadService,
    private commentService: CommentsService,
    private fb: FormBuilder,
    private translate:TranslateService,
    private settingsService : SettingsService){
      dialogRef.disableClose=true
      const currentLang = this.translate.currentLang;
      this.languagesList = languagesList_[currentLang]
  }

  ngOnInit():void{
    if(this.settingsService.userRoleBetaWidget || this.settingsService.userRolePerContextBetaWidget[this.settingsService.contextId$])
      this.userHasBetaWidgetRole = true
    this.defaultLanguage = this.data.defaultLanguage
    this.oldData=this.data.values
    this.videoEditData=this.data.values
    this.url=this.videoEditData.url
    this.widgetNumber= this.data['widget']
    this.passId = this.data['passId']

    if(this.data['url']){
      this.urlData = this.data['url']
    }else{
      if(this.videoEditData.url ){
        this.supportUrl(this.videoEditData.url)
      }
    }

    this.videoEditForm = this.fb.group({
      id:[],
      type:[],
      url:[,[Validators.required]],
      previewPicture:[],
      copyright:[],
      title:[],
      subtitle:[],
      autoPlay:[],
      autoRePlay:[],
      muted:[],
      videoControls:[],
      size:[],
      name:[],
      filter:[],
      widgetStyle:[]
    })

    const {id, filter,url, widgetStyle, type, previewPicture, copyright, title, subtitle, autoPlay, autoRePlay, muted, videoControls,size, name} = this.videoEditData

    this.videoEditForm.patchValue({
      id : id ? id : undefined,
      url : url ? url : undefined,
      previewPicture : previewPicture ? previewPicture : undefined,
      copyright : copyright ? copyright : undefined,
      title: title ? title : undefined,
      type : type ? type : undefined,
      subtitle : subtitle ? subtitle : undefined,
      autoPlay: autoPlay ? autoPlay : false,
      autoRePlay: autoRePlay ? autoRePlay : false,
      muted: muted ? muted : false,
      videoControls: videoControls ? videoControls : false,
      size: size ? size : undefined,
      name: name ? name : undefined,
      filter:filter ? filter : undefined,
      widgetStyle: widgetStyle ? widgetStyle : undefined

    })

    this.videoEditForm.get('url').valueChanges.subscribe((value:any)=> {
      // if(this.addUrl){
      //   this.url = value
      //   this.supportUrl(value)
      // }
      if(value?.match(/\${(.+)\}/))
        this.dynamicUrl = true
      else
        this.dynamicUrl = false
    })

    this.dragFileData = {
      calledFrom: VideoEditComponent,
      calledFromData:{
        passId: this.passId,
        widgetNumber:this.widgetNumber,
        widget: "video",
      },
      enableDragDrop: true,
      enableBrowse:true,
      enableMedia:true,
      enableEnterUrl:true,
      enableMultipleDragDrop:false,
      supportedFileTypes:['video/*'],
      supportedFileAccept:"video/*",
      maxSizeInBytes: 2 * 1024 * 1024, // 2 MB size limit for compression,
      maxAllowedSizeInBytes:undefined,
      returnSupportUrl: undefined,
      returnUrlData: undefined,
      enterUrl:false,
      returnUrlType:undefined
    }
  }
  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 25 * 1024 * 1024; // 25 MB size limit
  
      if (file.size > maxSizeInBytes) {
        const message = this.translate.instant("SnackBarConstants.VIDEO_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      var reader = new FileReader();
      this.newVideo.push(file);
      reader.readAsDataURL(file);
  
      reader.onload = async (event) => {
        if (event.target) {
          const urlNew = event.target.result as string;
          if (urlNew) {
            this.url = urlNew;
            this.changes = true;
            this.urlData = "";
            this.noUrl = false;
            this.addUrl = false
  
            try {
              const uploadURL = await this.uploadService.uploadNewVideo(this.passId, this.newVideo, this.widgetNumber);
  
              if (uploadURL) {
                this.url = uploadURL;
                this.urlData = urlNew;
              }
              this.videoEditForm.patchValue({url: this.url});
            } catch (error) {
              console.log(error);
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
              this.commentService.addSnackBar.emit(message);
            }
          }
        }
      };
    }
  }


  async filesDropped(files: FileHandle[]): Promise<void> {
    this.files = files;
    const fileToUpload = files[0].file;
    const maxSizeInBytes = 25 * 1024 * 1024; // 25 MB size limit
  
    if (fileToUpload.size > maxSizeInBytes) {
      const message = this.translate.instant("SnackBarConstants.VIDEO_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    }
  
    this.newVideo.push(fileToUpload);
    const newUrl = files[0].url;
    const type = fileToUpload.type.split("/");
  
    if (type[0] === 'video') {
      if (newUrl) {
        this.drop = true;
        this.url = newUrl;
        this.changes = true;
        this.urlData = "";
        this.addUrl = false
  
        try {
          const uploadURL = await this.uploadService.uploadNewVideo(this.passId, this.newVideo, this.widgetNumber);
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = newUrl;
          }
  
          this.videoEditForm.patchValue({url: this.url});
        } catch (error) {
          console.log(error);
          const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
          this.commentService.addSnackBar.emit(message);
        }
      }
    } else {
      const message = this.translate.instant('SnackBarConstants.VIDEO_TYPE');
      this.commentService.addSnackBar.emit(message);
    }
  }

  delete(){
    this.url= undefined
    this.videoEditForm.patchValue({url:this.url, size:undefined})
    this.urlData=""
  }

  editVideo(url){
    let dialogRef= this.dialog.open(MediaDetailsDialogComponent, {
      data: { 
        values: this.videoEditForm.value,
        type: this.videoEditForm.value.type,
        passId: this.passId

      },
    });

    dialogRef.afterClosed().subscribe(result => {
     
      if(result[1]){
        if(result[1].url != this.url){
          this.urlData=""

        }
        this.url = result[0].url
        this.videoEditForm.patchValue({url: this.url})
        
        if(this.url){
          this.supportUrl(this.url)
        }
      }
    });
  }

  async saveChanges(){
  this.commentService.progressSpin.emit(true)



  if(!this.dynamicUrl){
    if(this.videoEditForm.value.size?.length==0 || !this.videoEditForm.value.size || (this.videoEditForm.value.size && this.videoEditForm.value.size[0]==0 && this.videoEditForm.value.size[1]==0)){
      await this.onVideoMetadataVideo()
    }
  }

  if(this.videoEditForm.value.url){
    this.dialogRef.close([this.videoEditForm.value, true, this.newVideo]);
    this.commentService.progressSpin.emit(false)
  }else{
    this.noUrl=true
    this.commentService.progressSpin.emit(false)
    const message = this.translate.instant("SnackBarConstants.VIDEO_NEEDED")
    this.commentService.addSnackBar.emit(message)
  }

  }

  cancelChanges(){
    this.dialogRef.close([this.oldData, false])
  }

  supportUrl(url){
    this.urlData = url
    if(url){
      try{
        const imgUrl = new URL(url)
        let hostname = environment.urlHostName
        
        if(imgUrl.protocol=="pass:" ){
          const parts = url.match(/pass:\/\/(.+)\/(.+)/)
          if(parts){
            const passTemplateID = parts[1]
            const path = parts[2]
            const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            this.urlData=firebaseUrl
          }else{
            const newparts = url.match(/pass:\/\/(.+)/)
            if(newparts){
              let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
              newparts.forEach( (part,index)=> {
                if(index>0){
                  firebaseUrl += '/' + part 
                }
              })
              this.urlData=firebaseUrl
            }
          }
        }
  
        if(imgUrl.protocol =="tridy:"){
          const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
          let path=""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`
          this.urlData=firebaseUrl
        }
      }catch(error){
      }
    
    }
  }

  onVideoMetadataVideo(event?: Event): void {
    try{
      const videoElement: HTMLVideoElement = this.myVideo.nativeElement;
      const width: number = videoElement.videoWidth;
      const height: number = videoElement.videoHeight;
      this.videoEditForm.patchValue({size : [ width, height]})    
    } catch(error){
      this.commentService.progressSpin.emit(false)
    }
  }

  checkNewUrl(){
    this.url = this.videoEditForm.value.url
    this.supportUrl(this.videoEditForm.value.url)
  }
  
  typeURL(){
    this.addUrl = true
    this.urlData = './../../assets/img/default.jpg';
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  returnChangesFilters($event){
    const key = Object.keys($event.newFilters)

    this.videoEditForm.patchValue({filter: $event.newFilters})
  }


  returnChangesStyle($event){
    this.videoEditForm.patchValue({widgetStyle: $event.newStyle})
  }

  returnChangesDragFileBrowseUI($event){
    console.log($event)
    if($event){
      const {calledFrom, calledFromData, enableDragDrop, enableBrowse, enableMediaenableEnterUrl, enableMultipleDragDrop, 
        supportedFileTypes,supportedFileAccept, maxSizeInBytes,maxAllowedSizeInBytes, 
        returnSupportUrl,returnUrlData, enterUrl} = $event

        this.dragFileData = $event
      if(enterUrl){
        this.typeURL()
      }else{
        this.addUrl = false
        this.urlData = undefined
      }

      if(returnUrlData)
      this.urlData = returnUrlData

      if(returnSupportUrl){
        this.url = returnSupportUrl
        this.videoEditForm.patchValue({ url: returnSupportUrl })
      }
    }
  }
}
