<section class="topNavbar"  >
    <button mat-button style="color:var(--warmOrange);" (click)="backTo();">
        <span class="material-symbols-outlined" style="font-size: 10px;">
            arrow_back_ios
        </span>
        <span>{{navBackTo}}</span>
    </button>

    <div class="d-flex align-items-center">

        <label class="file-button" >
            <span class="material-symbols-outlined">
                photo_library
            </span>
            <input type="file" id="myfile" name="myfile" style="display: none;" (change)="readURL($event);"> 
        </label>
    </div>
    
</section>

<section class="pt-5">
    <div class="shadow-sm containerFolders">
        <p *ngIf="folder.length==0">{{'empty' | translate}}</p>
        <div *ngFor="let value of folder" class="m-3"> 

            <!-- <button *ngIf="value.folder" mat-button style="color:var(--warmOrange);" routerLink="{{value.folder}}">
                <span class="material-symbols-outlined">
                    folder
                </span>
                {{value.folder}}
            </button> -->

            <button *ngIf="value.folder" mat-fab extended style="color:var(--warmOrange); background-color:var(--pureWhite);" routerLink="{{value.folder}}" >
                <mat-icon>folder</mat-icon>
                {{value.folder}}
            </button>
            <div *ngIf="value.item" style="background-position: 0px 0px, 10px 10px;
                                        background-size: 20px 20px;
                            background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);">
                <!-- <div *ngIf="value.item" style="filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)); width: 200px;margin:5px;"> -->

                <img  src="{{value.url}}" alt="{{value.item}}" width="200"  style=" border-radius: 10px;">
            </div>
        </div>
    </div>
</section>