export const NetworkConstants= {
    COLLECTION_ANALYTICS:"Analytics",
    COLLECTION_ADVANCED_EXPERIENCES:"AdvancedExperiences",
    COLLECTION_CONTEXTS:"Contexts",
    COLLECTION_CUSTOMERS:"Customers",
    COLLECTION_TRIDYS:"Tridys",
    COLLECTION_USERS:"Users",
    COLLECTION_APITOKENS:"APITokens",
    COLLECTION_CONTENT:"Content",
    COLLECTION_CONTENT_TYPES:"ContentTypes",
    COLLECTION_ITEMS:"Items",
    COLLECTION_INTERFACES:"Interfaces",
    COLLECTION_IDENTIFIERS:"Identifiers",
    COLLECTION_PASSDEFINITIONS:"PassDefinitions",
    COLLECTION_PURCHASES:"Purchases",
    COLLECTION_WORK_TASK_TEMPLATES:"WorkTaskTemplates",
    COLLECTION_WORK_TASK_TEMPLATES_GROUPS:"WorkTaskTemplateGroups",
    COLLECTION_WORK_TASKS:"WorkTasks",
    COLLECTION_REPORTS:"ReportTemplates",
    STORE_MEDIA:"Media",
}