import { Injectable } from '@angular/core';
import { Subject,BehaviorSubject, Observable } from 'rxjs';
import { deepCopy, removeUndefined } from '../../../../backend/utils/object';
import { deleteField } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TranslationsDPPService {
  passTemplateFormDataById$: Subject<any> = new Subject<any>()
  passTemplateTranslatedValues$: Subject<any> = new Subject<any>()
  OLDpassTemplateTranslatedValues$: Subject<any> = new Subject<any>()
  passLanguagesList$:Subject<string[]> = new Subject<string[]>()
  passLanguagesSelected$:Subject<string> = new Subject<string>()
  firstLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined)
  passAllInfo:Subject<any> = new Subject<any>()
  passDefaultLanguage$:Subject<string> = new Subject<string>()
  constructor() { }

  initializeFormDataForTranslations(data){
    this.passTemplateFormDataById$.next(data)
  }

  setDefaultLanguagePass(lang){
    this.passDefaultLanguage$.next(lang)
  }
  
  initializePassTranslatedValues(translations){
    this.passTemplateTranslatedValues$.next(translations)
    this.OLDpassTemplateTranslatedValues$.next(translations)
  }

  initializePassLanguages(languages){
    this.passLanguagesList$.next(languages)
  }

  initializePassFirstLanguage(language){
    this.firstLanguage$.next(language)
  }

  updateFormDataForTranslations(data){
    this.passTemplateFormDataById$.next(data)
  }

  cleanFormDataForTranslations(data?){
    this.passTemplateFormDataById$.next({})
  }

  updatepassLanguagesList(languages){
    if(languages){
      this.passLanguagesList$.next(languages)
      if(languages.length!=0)
        this.firstLanguage$.next(languages[0])

    }
  }

  setPassFirstLanguage(passId, firstLanguage){
    let info = {}

    if(this.passAllInfo[passId]){
      this.passAllInfo[passId]['firstLanguage'] = firstLanguage
    }else{
      info={
        [passId]: {
          'firstLanguage':firstLanguage
        }
      }
    }
    this.passAllInfo.next(info)
  }

  addTranslations(widget, language, values){

  }

/* 
  viewPassLanguageForm(lang, formData){
    const copiaForm = deepCopy(formData)
    // this.languageSelected = lang//coment to prevent select to chnage language select 12.07
    // let firstLanguage
    // let languagesList
    // if(this.passLanguagesList$.value.length!=0){
    //   languagesList=this.passLanguages
    //   firstLanguage=this.passFirstLanguage
    // }else{
    //   languagesList=this.contextLanguages
    //   firstLanguage=this.contextFirstLanguage
    // }

    // let dialogRef = this.dialog.open(LanguageVisualisationDialogComponent, {
    //   panelClass: 'custom-dialog-container',
    //   position: { left: '0'},
    //   animation: {
    //     to: "right",
    //     incomingOptions: {
    //       keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
    //     },
    //     outgoingOptions: {
    //       keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
    //     }
    //   },
    //   data:{
    //     values:copiaForm,
    //     firstLanguageContexts: firstLanguage,
    //     contextLanguages: languagesList,
    //     languageSelected: lang,
    //     idToShow:this.idToShow
    //   }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if(this.passLanguages.length!=0){
    //     this.languageSelected = this.passFirstLanguage   
    //   }else{
    //     this.languageSelected = this.contextFirstLanguage   
    //   }
    // })
  }
 */
  //delete translations of deleted widget
  //----every time a widget is deleted, it checks if there were translations of that widget
  //----YES --- delete translations 
  //----NO ---> next
  removeDeletedWidgetsTranslationsBeforeSaving(deletedWidget, translations){
    const alltransaltions = Object.entries(translations)

    alltransaltions.forEach( valuesTranslated => {
      const values = Object.keys(valuesTranslated)
      values.forEach( (key, index) => {
        const id = key.split('.')
        if(id == deletedWidget.id){
          delete values[index]
        }
      })


    })
  }

  clearPassInfoTranslations(){
    this.passTemplateFormDataById$.next({})
    this.passTemplateTranslatedValues$.next({})
    this.OLDpassTemplateTranslatedValues$.next({})
    this.passLanguagesList$.next([])
    this.passLanguagesSelected$.next(undefined)
    this.firstLanguage$.next(undefined)
    this.passAllInfo.next({})
  }


  addMoreTranslationsToThisWidgetType(result, type){
    let newtranslations = {}
  
    if(this.passTemplateTranslatedValues$){
      newtranslations = this.passTemplateTranslatedValues$
    }
      if(result[1]){
        const data = result[0]
        removeUndefined(data)
        const trans = Object.entries(data)
        trans.forEach( value => {
          const key = value[0]
          const keyName = key.split(".")
          const lang = keyName[0]
          const id = keyName[1]
          const field = keyName[2]
          const tranlationField = id +"."+field

          //------------------------widgetData on first Language translations
          // if(lang == this.firstLanguageContexts){
          //   if(this.textEditForm.value[field]){
          //     this.textEditForm.patchValue({[field]: value[1]})
          //   }
          // }
          if(value[1]){
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = value[1]
            }else{
              newtranslations[lang]= {}
              newtranslations[lang][tranlationField] = value[1]
            }
          }else{
            if(newtranslations[lang]){
              newtranslations[lang][tranlationField] = undefined

              //to delete old value
              if(this.OLDpassTemplateTranslatedValues$[lang] && this.OLDpassTemplateTranslatedValues$[lang][tranlationField])
                newtranslations[lang][tranlationField] = deleteField()

            }else{
              newtranslations[lang] = {}
              newtranslations[lang][tranlationField] = undefined
            }
          }
        })
        this.passTemplateTranslatedValues$.next(newtranslations)
      }
  }


  ////-------- call this if, after changing the default language, you want to keep previous language values when there no translations
  updateWidgetsWithNewDefaultLanguage( oldFirstLanguage, newFirstLanguage, oldFirstLanguageWidgets, translations, languages){
    // update widgets fields with the translations of the new language
    /// is there translations for the new widget??
        /// YES --> update widget field
        /// NO --> keep previous language field value
        
    
    return new Observable<any> ((observer) => {
      console.log(oldFirstLanguage, newFirstLanguage, oldFirstLanguageWidgets, translations, languages)
      let newWidgets
      let newtranslations = deepCopy(translations)
      newtranslations[oldFirstLanguage]={}
      let oldLanguageTranslations = {}

      const widgets = deepCopy(oldFirstLanguageWidgets)
      const addTranslationsOfOldLanguage = languages.includes(oldFirstLanguage)

      if(translations && translations[newFirstLanguage] ){
        const entries = Object.entries(translations[newFirstLanguage])
        
        entries.forEach( translation => {
          const translationField = translation[0]
          const idField = translation[0].split(".")
          const id = idField[0]
          const field = idField[1]
          const value = translation[1]

          console.log('_____________', translation)
          console.log('translationField',translationField)
          console.log('id', id)
          console.log('field', field)
          console.log('value', value)

          if(oldFirstLanguageWidgets ){//&& value
            // const widgets = deepCopy(oldFirstLanguageWidgets)
            widgets.forEach( (widget, index) => {
              const oldLanguagewidget = deepCopy(widget)
              // if(value ){
                if(id == widget['id']){
                  widget[field] = value
                  oldLanguageTranslations[translationField]=oldLanguagewidget[field]
                }

                if(id.includes(widget['id']+'_')){
                  if(widget['type']=="annotatedMap"){
                    if(widget['annotations']){
                      const annotations = widget['annotations']
                      annotations.forEach((annotation, index) => {
                        if(id == widget['id']+'_'+annotation.id){
                          annotation[field] = value
                          oldLanguageTranslations[translationField]=oldLanguagewidget['annotations'][index][field]
                        }
                      });
                    }
                  }

                  if(widget['type'] == 'buttons'){
                    const buttons = widget['buttons']
                    if(buttons){
                      buttons.forEach((btn, indexBTN) => {
                        if(id == widget['id']+'_'+btn.id){
                          // if(btn[field])
                          btn[field] = value
                          oldLanguageTranslations[translationField]=oldLanguagewidget['buttons'][indexBTN][field]
                        }
                      });
                    }
                  }
                }
              // }else{
              //   // // no newFirstLanguage translation value for that field --> keep old defaultLanguage widget value
              // }
              
            })

          }
        })
        
      }else{


      }

      if(addTranslationsOfOldLanguage)
        translations[oldFirstLanguage] = oldLanguageTranslations

      observer.next({translations, widgets})
      // observer.next({translations:translations, widgets: Promise.all(widgets)})

    })
  }

  ////-------- call this if, after changing the default language, dont want to keep previous language values
  updateWidgetsDefaultWithNewDefaultLanguageTranslationValues( oldFirstLanguage, newFirstLanguage, oldFirstLanguageWidgets, translations, languages){
    // update widgets fields with the translations of the new language
    /// is there translations for the new widget??
        /// YES --> update widget field
        /// NO --> widget field will be empty
    
    return new Observable<any> ((observer) => {

      console.log(oldFirstLanguage, newFirstLanguage, oldFirstLanguageWidgets, translations, languages)
      let newWidgets
      let newtranslations = deepCopy(translations)
      newtranslations[oldFirstLanguage]={}
      let oldLanguageTranslations = {}

      const widgets = deepCopy(oldFirstLanguageWidgets)
      const addTranslationsOfOldLanguage = languages.includes(oldFirstLanguage)

      if(translations && translations[newFirstLanguage] ){
        const newLanguageTranslations = deepCopy(translations[newFirstLanguage])

        widgets.forEach( (widget, index) => {
          const oldLanguagewidget = deepCopy(widget)
          switch(widget.type){
            case 'text':{
              const textTranslateFields = ['title','subtitle', 'text']
  
              textTranslateFields.forEach( field => {
                const translationField = widget.id + '.' + field
                oldLanguageTranslations[translationField] = widget[field]
                if(newLanguageTranslations[translationField]){
                  widget[field] = newLanguageTranslations[translationField]
                }else{
                  widget[field] = undefined
                }
              })
              break;
            }
  
            case 'image':{
              const imageTranslateFields =['title','subtitle', 'url', 'linkURL']
              imageTranslateFields.forEach( field => {
                const translationField = widget.id + '.' + field
                oldLanguageTranslations[translationField] = widget[field]
                if(newLanguageTranslations[translationField]){
                  widget[field] = newLanguageTranslations[translationField]
                }else{
                  widget[field] = undefined
                }
              })
  
              break;
            }
  
            case 'buttons':{
              const buttonsTranslateFields = ['title', 'url']
              if(widget.buttons && widget.buttons.length!=0 ){
                widget.buttons.forEach(button => {
                  buttonsTranslateFields.forEach( field => {
                    const translationField = widget.id + '_' + button.id + '.' + field
                    oldLanguageTranslations[translationField] = button[field]
                    
                    if(newLanguageTranslations[translationField]){
                      button[field] = newLanguageTranslations[translationField]
                    }else{
                      button[field] = undefined
                    }
                  })
                });
              }

              
              break;
            }
  
            case 'annotatedMap':{
              const annotatedMapTranslateFields =['title','subtitle']
              annotatedMapTranslateFields.forEach( field => {
                const translationField = widget.id + '.' + field
                oldLanguageTranslations[translationField] = widget[field]
                if(newLanguageTranslations[translationField]){
                  widget[field] = newLanguageTranslations[translationField]
                }else{
                  widget[field] = undefined
                }
              })

              if(widget.annotations){
                widget.annotations.forEach(annotation => {
                  const annotationTranslateFields =['title','subtitle','linkURL' ]

                  annotationTranslateFields.forEach( field => {
                    const translationField = widget.id + '_' + annotation.id + '.' + field
                    oldLanguageTranslations[translationField] = annotation[field]

                    if(newLanguageTranslations[translationField]){
                      annotation[field] = newLanguageTranslations[translationField]
                    }else{
                      annotation[field] = undefined
                    }
                  })
                });
              }


              break;
            }
          }
        })
      }

      if(addTranslationsOfOldLanguage)
        translations[oldFirstLanguage] = oldLanguageTranslations

      observer.next({translations, widgets})
    })
  }

}
