import { Component, Input } from '@angular/core';
import { supportUrlStructure } from '../../../../../../backend/utils/object';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/service/settings.service';
@Component({
  selector: '[app-composed-content-phone]',
  templateUrl: './composed-content-phone.component.html',
  styleUrls: ['./composed-content-phone.component.css']
})
export class ComposedContentPhoneComponent {
  contentData:any
  @Input('app-composed-content-phone') data:any
  nodeType:string
  hasChildren = false
  fontfamily:string = 'var(--plainFontName)'
  verticalAlignment:string = 'center'
  horizontalAlignment:string = 'center'
  width:string = '100%'
  height:string = '100%'
  routerUrl:any
  idToShow:string

  constructor(private router: ActivatedRoute,
    private settingsService: SettingsService){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }
  ngOnInit(): any{
    this.contentData = this.data[0]
    if(this.contentData?.attributes?.font){
      this.fontfamily = 'var(--' + this.contentData?.attributes?.font + 'FontName)'
    }

    if(this.contentData?.attributes?.verticalAlignment){
      switch(this.contentData?.attributes?.verticalAlignment){
        case 'top':{
          this.verticalAlignment = 'flex-start'
          break;
        }

        case 'center':{
          this.verticalAlignment = 'center'
          break
        }

        case 'bottom':{
          this.verticalAlignment = 'flex-end'
          break
        }
      }
    }

    if(this.contentData?.attributes?.horizontalAlignment){
      switch(this.contentData?.attributes?.horizontalAlignment){
        case 'leading':{
          this.horizontalAlignment = 'flex-start'
          break;
        }

        case 'left':{
          this.horizontalAlignment = 'flex-start'
          break;
        }

        case 'center':{
          this.horizontalAlignment = 'center'
          break
        }

        case 'trailing':{
          this.horizontalAlignment = 'flex-end'
          break
        }

        case 'right':{
          this.horizontalAlignment = 'flex-end'
          break
        }
      }
    }

    if(this.contentData?.attributes?.width){
      this.width = this.contentData?.attributes?.width + 'px'
    }

    if(this.contentData?.attributes?.height){
      this.height = this.contentData?.attributes?.height + 'px'
    }
  }

  supportUrl(url){
    try{
      if(url)
      return supportUrlStructure(url, this.idToShow, this.settingsService.contextId$ )
      else return './../../assets/img/default.jpg'
    }catch(e){
      return './../../assets/img/default.jpg'
    }
    
  }

}
