<section class="container">

    <ng-container *ngIf="emptyList">
        <div class="row justify-content-center my-5">
            <div class="col-8 text-center my-5 py-5">
                <h1 class="mb-1">{{'topNavbar.taskTemplates' | translate}}</h1>
                <p class="lead pb-3" style="font-family:'MyFont-Light';">{{'empty_templates' | translate}}</p>
                <div [matTooltip]="!tasktemplateUserRights_create ? ('home.tooltip' | translate) : null">
                    <button mat-button 
                    class="orange-button" 
                    id="btn-create" 
                    (click)="createNewWorkTaskTemplate();" 
                    [disabled]="!tasktemplateUserRights_create"
                    >
                    <mat-icon>add_circle</mat-icon> {{'add_new' | translate}}
                    </button>
                </div>
            </div>
          </div>
    </ng-container>

    <ng-container *ngIf="!emptyList">
        <div class="row mt-4">

            <div class="mt-3">
                <h1 class="mb-1">{{'topNavbar.taskTemplates' | translate}}</h1>
                <p style="font-family:'MyFont-Light';">{{'headlines.subTracerTemplate' | translate}}</p>
            </div>

            <div class="col-12">
    
                <section class="mt-1 filter-section d-flex flex-row flex-wrap align-items-center" >
    
                    <div [matTooltip]="!tasktemplateUserRights_create ? ('home.tooltip' | translate) : null">
                        <button  mat-button class="orange-button" (click)="createNewWorkTaskTemplate();" [disabled]="!tasktemplateUserRights_create">
                            <mat-icon>add_circle</mat-icon>
                            {{'add_new' | translate}}
                        </button>
                    </div>

                    <mat-form-field class="pt-3 m-2 mb-0 mt-0 search">
                        <mat-label>{{'search_title' | translate}}</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
                        <mat-icon matPrefix>search</mat-icon>
                    </mat-form-field>
                </section>
    
            </div>
        </div>
    
        <div class="row mt-4">
            <div class="col-12">
    
                <div class="shadow-sm mat-elevation-z8 m-auto mb-4" id="table-container" >
                    <table mat-table [dataSource]="listdata" matSort class="rounded">
                
                    <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="rounded"> {{'taskTemplates.table_title' | translate}} </th>
                    <td mat-cell *matCellDef="let element" style="width: 50%;" routerLink="{{element.id}}"> 
                        <span *ngIf="element.title" class="text-wrap text-break d-flex align-items-center">
                            {{element.title}}
                            <span *ngIf="element.hidden" class="material-symbols-outlined ps-2 ">
                                visibility_off
                            </span>
                        </span>
                    </td>
                    </ng-container>
                
                    <ng-container matColumnDef="creationDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'creation_date' | translate}} </th>
                        <td mat-cell *matCellDef="let element" routerLink="{{element.id}}"> 
                            <span *ngIf="element.creationDate" class="text-wrap text-break">{{element.creationDate.toDate() | date: 'dd.MM.yyyy, HH:mm'}}</span> 
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef="timeStamp">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'last_update' | translate}} </th>
                        <td mat-cell *matCellDef="let element" routerLink="{{element.id}}"> 
                            <span *ngIf="element.timeStamp" class="text-wrap text-break">{{element.timeStamp.toDate() | date: 'dd.MM.yyyy, HH:mm'}} </span>      
                        </td>
                    </ng-container>
    
                    <!-- ACTIONS -->
                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element"> 

                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="openDuplicateTaskDialog(element);" [disabled]="!tasktemplateUserRights_create">
                                    <mat-icon>content_copy</mat-icon> {{'duplicate' | translate}}
                                </button>
                                <button mat-menu-item (click)="deleteTask(element);" [disabled]="!tasktemplateUserRights_delete">
                                    <mat-icon class="material-symbols-outlined delete">delete</mat-icon> {{'delete' | translate}}
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break border-bottom" ></tr>
                </table> 
                
                <mat-paginator [pageSizeOptions]="paginatorSize" aria-label="Select page of tasks" showFirstLastButtons class="pb-2"></mat-paginator>
    
                </div>
    
            </div>
        </div>
    </ng-container>

</section>