import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { languagesList, languagesList_ } from '../../../../../../backend/src/languagesList.dto';
import { SettingsService } from 'src/app/service/settings.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-spacer-edit',
  templateUrl: './spacer-edit.component.html',
  styleUrls: ['./spacer-edit.component.css']
})
export class SpacerEditComponent {
  spacerData:any
  oldData:any
  widgetNumber:any
  spacerForm!:FormGroup
  languagesList = languagesList
  defaultLanguage:string
  userHasBetaWidgetRole = false

  constructor(public dialogRef: MatDialogRef<SpacerEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private settingsService: SettingsService,
    private translate: TranslateService
  ){
  dialogRef.disableClose = true;
  const currentLang = this.translate.currentLang;
  this.languagesList = languagesList_[currentLang]
  }

  ngOnInit():void{
    if(this.settingsService.userRoleBetaWidget || this.settingsService.userRolePerContextBetaWidget[this.settingsService.contextId$])
      this.userHasBetaWidgetRole = true
    this.defaultLanguage = this.data.defaultLanguage

    this.spacerData = this.data.values
    this.widgetNumber = this.data['widget']

    this.spacerForm = this.fb.group({
      type:[],
      id:[],
      divider:[],
      spacing: [],
      name:[],
      filter:[],
      widgetStyle:[]
    })

    const {type, filter,id, divider, spacing,name,widgetStyle } = this.spacerData
    this.spacerForm.patchValue({
      type: type ? type : undefined,
      id: id ? id : undefined,
      divider: divider ? divider : false,
      spacing: spacing ? spacing : 8,
      name: name ? name : undefined,
      filter:filter ? filter : undefined,
      widgetStyle: widgetStyle ? widgetStyle : undefined
    })
  }

  dialogCancel(){
    this.dialogRef.close([this.oldData,false]);
  }

  dialogSave(){
    this.commentService.progressSpin.emit(true)

    const formValue = { ...this.spacerForm.value };
    if (formValue.spacing === 8) {
      delete formValue.spacing; // Remove if default 8
    }

    this.dialogRef.close([formValue, true]);
    this.commentService.progressSpin.emit(false);
  }

  returnChangesFilters($event){
    this.spacerForm.patchValue({filter: $event.newFilters})
  }

  returnChangesStyle($event){
    this.spacerForm.patchValue({widgetStyle: $event.newStyle})
  }

  
}
