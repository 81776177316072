<h2 mat-dialog-title>{{'pass.widgets.products' | translate}}</h2>
<!-- <div *ngIf="defaultLanguage" style=" position: absolute;top: 25px;right: 10px;">
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
</div> -->

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="productEditForm" class="m-1">

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>
        
                <div class="input-group" >
                    <mat-form-field  appearance="outline" class="w-100" >
                        <mat-label>{{'pass.layout' | translate}}</mat-label>
                        <mat-select formControlName="layout" class="input-group m-1">
                        <mat-option value="vertical">{{'pass.vertical' | translate}}</mat-option>
                        <mat-option value="horizontal">{{'pass.horizontal' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
        
                <mat-form-field appearance="outline" class="w-100 p-1">
                    <mat-label>{{'pass.title' | translate}}</mat-label>
                    <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.subtitle' | translate}}</mat-label>
                    <input formControlName="subtitle" type="text" matInput placeholder="{{'pass.subtitle' | translate}}">
                </mat-form-field>
        
        
                <mat-form-field appearance="outline" class="w-100 p-1">
                    <mat-label>{{'products.p_group' | translate}}</mat-label>
                    <input formControlName="productGroups" type="text" matInput placeholder="{{'products.p_group' | translate}}">
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'products.p_types' | translate}}</mat-label>
                    <input formControlName="productTypes" type="text" matInput placeholder="{{'products.p_types' | translate}}">
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label> {{'products.p_tags' | translate}}</mat-label>
                    <input formControlName="productTags" type="text" matInput placeholder="{{'products.p_tags' | translate}}">
                </mat-form-field>
            
                <p class="text-muted">{{'products.p_tags_definition' | translate}}</p>
                
            </form>
        </section>
    </div>

    <div [app-widget-edit-filters] = "productEditData" (returnChanges)="returnChangesFilters($event)"></div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="onSaveEdit();" >{{'apply' | translate}}</button>
</mat-dialog-actions>