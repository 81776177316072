<h2 mat-dialog-title>{{'taskTemplates.editImportedField' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section">

            <form *ngIf="data.tridyPayloadKey" [formGroup]="editFieldItemForm">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Tridy Payload Key</mat-label>
                <input matInput formControlName="tridyPayloadKey">
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Target Key</mat-label>
                <input matInput formControlName="key">
              </mat-form-field>
            </form>

            <form *ngIf="data.sourceKey" [formGroup]="editMediaItemForm">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Media Key</mat-label>
                <input matInput formControlName="sourceKey">
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Target Key</mat-label>
                <input matInput formControlName="targetKey">
              </mat-form-field>
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >
        {{'cancel' | translate}}
    </button>
    <button 
      mat-button class="orange-button" 
      [disabled]="data.tridyPayloadKey && !editFieldItemForm.valid || data.sourceKey && !editMediaItemForm.valid"
      (click)="onSave();">{{'save' | translate}}</button>
</mat-dialog-actions>
