<section class="container">
    <ng-container *ngIf="allInterfaces.length == 0">

            <div class="row justify-content-center my-5">
                <div class="col-8 text-center my-5 py-5">
                    <h2 class="mb-1">{{'interfaces.title' | translate}}</h2>
                    <p class="lead pb-3" style="font-family:'MyFont-Light';">{{'empty_interfaces' | translate}}</p>
                    <button mat-button 
                            style="background-color:var(--warmOrange); color:white;" 
                            id="btn-create" 
                            (click)="openDialogNewInterface();" 
                            [disabled]="!interfacesUserRights_create"
                    >
                    <mat-icon>add_circle</mat-icon> {{'add_new' | translate}}
                    </button>
                </div>
            </div>
    </ng-container>

    <ng-container *ngIf="allInterfaces.length > 0">

        <div class="row mt-4">
            <div class="mt-3 mb-3">
                <h1 class="mb-1">{{'interfaces.title' | translate}}</h1>
                <p style="font-family:'MyFont-Light';">{{'headlines.subInterfaces' | translate}}</p>
            </div>

            <div class="col-12">
                <section class="mt-1 filter-section d-flex flex-row flex-wrap align-items-center">    
                    <div>
                        <button mat-button class="orange-button mb-2" (click)="openDialogNewInterface();" [disabled]="!interfacesUserRights_create">
                            <mat-icon>add_circle</mat-icon>
                            {{'add_new' | translate}}
                        </button>
                    </div>

                    <mat-form-field class="pt-3 m-2 my-0 search">
                        <mat-label>{{'search_title' | translate}}</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
                        <mat-icon matPrefix>search</mat-icon>
                    </mat-form-field>
                </section>
    
            </div>
        </div>
    
        <div class="row mt-4">
            <div class="col-12">

                <div class="rounded shadow-sm mb-4" id="table-container">
                    <table mat-table [dataSource]="listdata" matSort > 
                    
                        <!-- displayName Column -->
                        <ng-container matColumnDef="displayName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:var(--accentGlobal);"> {{'contentTypes.displayName' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> 
                            <span *ngIf="row.displayName" class="text-wrap text-break">{{row.displayName}}</span> 
                        </td>
                        </ng-container>
                    
                        <!-- name Column -->
                        <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:var(--accentGlobal);"> {{'contentTypes.name' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> 
                            <span *ngIf="row.name" class="text-wrap text-break">{{row.name}}</span>    
                        </td>
                        </ng-container>
                    
                        <!-- description Column -->
                        <ng-container matColumnDef="info">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="color:var(--accentGlobal);"> {{'contentTypes.description' | translate}} </th>
                        <td mat-cell *matCellDef="let row"> 
                            <span *ngIf="row.info" class="text-wrap text-break"> {{row.info}}</span>    
                        </td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;" class="p-1"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break" (click)="openEditInterface(row);"></tr>
                    
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">{{'no_matching_data' | translate}} "{{input.value}}"</td>
                        </tr>
                    </table>
                    
                    <mat-paginator [pageSizeOptions]="paginatorSize" aria-label="Select page of users" showFirstLastButtons class="pb-2"></mat-paginator>
                </div>


            </div>
        </div>

    </ng-container>
</section>