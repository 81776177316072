import { Component } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { SettingsDialogComponent } from '../settings-dialog/settings-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-context-id-selector',
  templateUrl: './context-id-selector.component.html',
  styleUrls: ['./context-id-selector.component.css']
})
export class ContextIdSelectorComponent {
  contextIds:any
  settingData:any
  userData:any
  accountName:any


  constructor(private settingsService: SettingsService,
    public dialog: MatDialog,
    private router: Router,
    private translate:TranslateService
    ){

  }

  ngOnInit():void{
    // const a = this.settingsService.setContextsAccount(this.settingsService.contextId$)  
    this.openDialogSettings()  
  }
  openDialogSettings():void{

    let dialogRef= this.dialog.open(SettingsDialogComponent, {
      data: { 
        values: this.userData,
        title:this.translate.instant('selectAccount')

      },
    });

    dialogRef.afterClosed().subscribe(async (result:any) => {
      try{
        // this.router.navigate([result[0][0]])
        this.router.navigate(['home',result[0][0]])
        await this.settingsService.updateContextId(result[0][0])
        this.accountName=result[1].id
        this.observeData()
      }catch(error){
        console.log(error)
      }
      
    });
    this.settingsService.changeAccount=false
  }


  async observeData(){
    this.settingsService.resetSettingsValues()
    // const a = await this.settingsService.observeContextsFirestore()
    // const cu = await this.settingsService.observeContextsIdLoggedFirestore(this.settingsService.contextId$)
    const usersWithcontextId = this.settingsService.observeUsersWithcontextId(this.settingsService.contextId$)
    // const pstpmId = this.settingsService.observeContextsPassDefinitionsFirestoreWithId(this.settingsService.contextId$)
    // this.settingsService.observeContextsContentTypesFirestoreWithId(this.settingsService.contextId$)
  }
}
