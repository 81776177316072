<section class="container">
    <div class="row mt-4">
        <div class="col-12">
            <section class=" mt-1 filter-section d-flex flex-row flex-wrap align-items-center m-auto" >
                <div>
                    <button  mat-fab extended style="background-color:var(--accentGlobal); color:white;" class="mb-2" (click) = "createNewProduct();" [disabled]="!productsUserRights_create">
                        <mat-icon>add_circle</mat-icon>
                        {{'add_new' | translate}}
                    </button>
                </div>
                <mat-form-field appearance="outline" class="pt-3 my-0 search">
                    <mat-label>{{'filter' | translate}}</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
                </mat-form-field>
            </section>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <div class="shadow-sm mat-elevation-z8 m-auto mb-4" id="table-container" >
                <table mat-table [dataSource]="listdata" matSort class=" rounded">

                    <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="rounded"> {{'products.title' | translate}} </th>
                    <td mat-cell *matCellDef="let element" style="width: 60%;"> 
                        <span *ngIf="element.title" class="text-wrap text-break">{{element.title}}</span>
                    </td>
                    </ng-container>
                
                    <ng-container matColumnDef="creationDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'creation_date' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> 
                            <span *ngIf="element.creationDate" class="text-wrap text-break">{{element.creationDate.toDate() | date}}</span> 
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="timeStamp">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'last_update' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> 
                            <span *ngIf="element.timeStamp" class="text-wrap text-break">{{element.timeStamp.toDate() | date}} </span>        
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break border-bottom" routerLink="{{row.id}}"></tr>
                </table> 

                <mat-paginator [pageSizeOptions]="paginatorSize" aria-label="Select page of products" showFirstLastButtons class="pb-2"></mat-paginator>

            </div>
        </div>
    </div>
</section>