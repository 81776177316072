<h2 mat-dialog-title>{{'pass.widgets.buttons' | translate}}</h2>

<mat-dialog-content>
    <div class="py-3">
        <form [formGroup]="buttonsForm">

            <mat-form-field appearance="outline" class="w-100">
                <mat-label>{{'pass.name' | translate}}</mat-label>
                <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
            </mat-form-field>
            
            <div>
                <button mat-button 
                        class="secondary-gray-button my-3"
                        (click)="addButton();"
                >
                    <mat-icon>add_circle</mat-icon> {{'pass.buttons.add_button' | translate}}
                </button>
                <form [formGroup]="allbuttonsForm">
                    <mat-accordion multi>
        
                        <mat-expansion-panel  class="mt-1 mb-1" *ngFor=" let button of allButtons; let index= index;" 
                                            (opened)="panelOpenState = true"
                                            (closed)="panelOpenState = false"
                                            [expanded]="index === 0">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Button {{index + 1}}</mat-panel-title>

                            <button mat-icon-button style="color: red;" (click)="deleteBtn(index, button.id);">
                                <span class="material-symbols-outlined">delete</span>
                            </button>
            
                        </mat-expansion-panel-header>
            
                            <div class="w-100">

                                <mat-form-field appearance="outline" class="w-100 mb-1">
                                    <mat-label>{{'pass.title' | translate}}</mat-label>
                                    <input formControlName="{{button.id}}.title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                                </mat-form-field>

                                <div class="w-100 d-flex flex-row mb-3 pb-3">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-icon matIconPrefix class="material-symbols-outlined">link</mat-icon>
                                        <mat-label>{{'pass.url' | translate}}</mat-label>
                                        <input formControlName="{{button.id}}.url" type="text" matInput placeholder="{{'pass.url' | translate}}"><!--*ngIf="!allbuttonsForm.get(button.id+'.url')?.hasError('pattern')"-->
                                        <mat-error *ngIf="allbuttonsForm?.controls[button.id+'.url']?.invalid">{{'url_pattern_invalid' | translate}} </mat-error>
                                        <mat-hint >{{'url_pattern_detailed' | translate}} </mat-hint>
                                    </mat-form-field> 
                                    <button mat-icon-button matTooltip=" {{'pick' | translate}}" (click)="fileInput.click()">
                                        <mat-icon class="material-symbols-outlined">folder_open</mat-icon>
                                    </button>
                                    <input type="file" id="myfile" name="myfile" style="display: none;" accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx, .txt, .csv, .vcf, .rtf, .webp" (change)="readDocument($event, button)" #fileInput>
                                </div>

                                <div class="form-control p-3 mb-3 d-flex justify-content-between w-100">
                                    <label for="value">{{'pass.buttons.button.customize' | translate}}</label>
                                    <mat-slide-toggle class="ms-5 example-margin" formControlName="{{button.id}}.customize"></mat-slide-toggle>  
                                </div>
            
                                <div *ngIf="getCustomizeOptionOfButton(index, button.id);">
                                    <div class="list-group mb-4 ">
                                        <div class="list-group-item">
                                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                <span class=" w-50 bg-white" >{{'pass.buttons.backgroundColor' | translate}}</span>
                                                <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="{{button.id}}.backgroundColor" style="margin-left:10px;"></ngx-colors>
                                                    <span *ngIf="allbuttonsForm.controls[button.id + '.backgroundColor'].hasError('invalidColor')" id="invalid_accent" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                                </label>
                                            </div>
                                        </div>
                
                                        <div class="list-group-item">
                                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                <span class="w-50 bg-white" >{{'pass.buttons.borderColor' | translate}}</span>
                                                <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="{{button.id}}.borderColor" style="margin-left:10px;"></ngx-colors>
                                                    <span *ngIf="allbuttonsForm.controls[button.id + '.borderColor'].hasError('invalidColor')" id="invalid_title" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                                </label>
                                            </div>
                                        </div>
                
                                        <div class="list-group-item">
                                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                <span class="w-50 bg-white" >{{'pass.buttons.foregroundColor' | translate}}</span>
                                                <div class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="{{button.id}}.foregroundColor" style="margin-left:10px;"></ngx-colors>
                                                    <span *ngIf="allbuttonsForm.controls[button.id + '.foregroundColor'].hasError('invalidColor')" id="invalid_subtitle" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
            
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>{{'pass.buttons.borderWidth' | translate}}</mat-label>
                                        <input formControlName="{{button.id}}.borderWidth" type="number" matInput placeholder="{{'pass.buttons.borderWidth' | translate}}">
                                        <span matSuffix class="p-3"> px</span>
                                    </mat-form-field>
                                </div>
                            </div>
                        
                        </mat-expansion-panel>
                    </mat-accordion> 
                </form>
            </div>

            <div class="text-muted text-uppercase my-4">{{'pass.buttons.defaultStyle' | translate }}</div>
            <section class="mt-1 mb-3 rounded m-auto w-100" id="section">

                <form [formGroup]="buttonsStyleForm">
                    <div class="list-group mb-4">
                        <div class="list-group-item">
                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <span class=" w-50 bg-white" >{{'pass.buttons.backgroundColor' | translate}}</span>
                                <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="backgroundColor" style="margin-left:10px;"></ngx-colors>
                                    <span *ngIf="buttonsStyleForm.controls['backgroundColor'].hasError('invalidColor')" id="invalid_accent" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                </label>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <span class="w-50 bg-white" >{{'pass.buttons.borderColor' | translate}}</span>
                                <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="borderColor" style="margin-left:10px;"></ngx-colors>
                                    <span *ngIf="buttonsStyleForm.controls['borderColor'].hasError('invalidColor')" id="invalid_title" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                </label>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <span class="w-50 bg-white" >{{'pass.buttons.foregroundColor' | translate}}</span>
                                <div class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="foregroundColor" style="margin-left:10px;"></ngx-colors>
                                    <span *ngIf="buttonsStyleForm.controls['foregroundColor'].hasError('invalidColor')" id="invalid_subtitle" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                </div>
                            </div>
                        </div>  
                    </div>
                
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'pass.buttons.borderWidth' | translate}}</mat-label>
                        <input formControlName="borderWidth" type="number" matInput min="0" placeholder="{{'pass.buttons.borderWidth' | translate}}">
                        <span matSuffix class="p-3"> px</span>
                    </mat-form-field>
                </form>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.buttons.cornerRadius' | translate}}</mat-label>
                    <input formControlName="cornerRadius" type="number"  min="0" matInput placeholder="{{'pass.buttons.cornerRadius' | translate}}">
                    <span matSuffix class="p-3"> px</span>
                </mat-form-field>

            </section>
        </form>

    </div>


    <div [app-widget-edit-filters] = "buttonsData" (returnChanges)="returnChangesFilters($event)"></div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();" >{{'cancel' | translate}}</button>
    <button mat-button 
            class="orange-button" 
            [disabled]="invalid_backgroundColor || invalid_borderColor || invalid_foregroundColor || !buttonsStyleForm.valid"
            (click)="onSaveEdit();" >{{'apply' | translate}}</button>
</mat-dialog-actions>