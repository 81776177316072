

<section id="callToAction">
    <div class="container">
        <img *ngIf="url" src="{{url}}" alt="callToaAction-background" style="width:100%;" onerror="this.src='./../../assets/img/default.jpg'">
        <img *ngIf="!url" src="{{callToActionData.imageUrl}}" alt="callToaAction-background" style="width:100%;" onerror="this.src='./../../assets/img/default.jpg'">

        <span *ngIf="dynamic" class="material-symbols-outlined" style="right: 10px; position: absolute; filter: drop-shadow(2px 4px 6px black);">
            finance_chip
        </span>

        <div class="btnSocialNet d-flex" >
            <div *ngFor="let sn of callToActionData.socialNetworks">
                <a *ngIf="sn.url" href="{{sn.url}}" target="_blank"><i class="bi bi-{{sn.type}}" style="font-size:1.5em; margin-right:8px; color:var(--warmOrange);"></i></a>
            </div>
        </div>
    </div>
</section>



