<h2 mat-dialog-title>{{'contentTypes.duplicate_pass_template' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-1 rounded m-auto w-100" id="section" >
             <form [formGroup]="duplicateContentForm">
                <div class="d-flex flex-column">
                   
                    <div class="mb-4">{{'duplicationInfo_p1' | translate}} "<strong>{{selectedPassName}}</strong>" {{'duplicationInfo_p2' | translate}}</div>

                    <br>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{'contentTypes.newDisplayName' | translate}}</mat-label>
                        <input type="text" matInput formControlName="displayName" placeholder="{{'contentTypes.newDisplayName' | translate}}">
                    </mat-form-field>
                </div>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelDuplication();">
        {{'cancel' | translate}}
    </button>
     <button mat-button class="orange-button" (click)="duplicatePassTemplate()">
        {{'duplicate' | translate}}
    </button>
</mat-dialog-actions>
