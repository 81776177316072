<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section">

            <div >
                <div class="p-2 pb-4 mb-3 d-flex flex-column justify-content-center align-items-center">
            
                    <span class="p-1 fw-bolder fs-5 text-wrap text-break">{{data.message | translate}}</span>
            
                </div>
            </div>
        
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button   mat-button style="color:var(--warmOrange);" [mat-dialog-close]="false"  >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" style="color:var(--warmOrange);" [mat-dialog-close]="true"  >
        {{'confirm' | translate}}
    </button>
</mat-dialog-actions>
