import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentTypeService } from '../service/content-type.service';
import { Router } from '@angular/router';
import { CommentsService } from '../service/comments.service';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { Functions } from "@angular/fire/functions";
import { SettingsService } from '../service/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { FunctionsService } from '../service/functions.service';

@Component({
  selector: 'app-duplicate-pass-template',
  templateUrl: './duplicate-pass-template-dialog.component.html',
  styleUrls: ['./duplicate-pass-template-dialog.component.css']
})
export class DuplicatePassTemplateDialogComponent implements OnInit{

  duplicateContentForm!:FormGroup
  selectedPassId:any
  selectedPassName:any
  contextid:any
  newName: any
  contentTypeId: any
  functions = this.functionsService.getFunctionsRegion()

  constructor(
    public dialogRef: MatDialogRef<DuplicatePassTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private contentTypeService: ContentTypeService,
    private functionsService: FunctionsService,
    // private functions: Functions,
    private settingsService: SettingsService,
    private commentService: CommentsService,
    private router: Router,
    private translate:TranslateService
  ){
    dialogRef.disableClose = true;

  }

  ngOnInit(): void {

    this.selectedPassId = this.data.passTemplateId
    this.selectedPassName = this.data.oldDisplayName
    this.contextid = this.data.contextid
    this.contentTypeId = this.data.contentTypeId

    this.duplicateContentForm = this.fb.group({
      displayName:[,Validators.required]
    })
  }

  cancelDuplication(){
    this.dialogRef.close([false]);
  }

  duplicatePassTemplate(){
    if(this.duplicateContentForm.value.displayName){
      try{

        this.newName = this.duplicateContentForm.value.displayName

        this.commentService.progressSpin.emit(true)

        const data = {
          contextId: this.contextid,
          passTemplateId: this.selectedPassId,
          name: this.newName,
          duplicateContentType: true,
          contentTypeDisplayName: this.newName
        }

        const duplicatePassTemplate = httpsCallable(this.functions, 'admin-duplicatePassTemplate' );
        duplicatePassTemplate(data).then((result) => {
          const data = result.data;
          const message = this.translate.instant("SnackBarConstants.DUPLICATION_OK")
          this.commentService.addSnackBar.emit(message)
          this.commentService.progressSpin.emit(false)
          this.dialogRef.close([true])
        })
        .catch((error) => {
          this.commentService.progressSpin.emit(false)
          console.log(error)
          this.dialogRef.close([false])

          const message = this.translate.instant("SnackBarConstants.DUPLICATION_FAILED")
          this.commentService.addSnackBar.emit(message)
        });

      }
      catch(error){
        console.log(error)
      }

    } 
  } 
}
