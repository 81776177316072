import { Injectable } from '@angular/core';
import { getFunctions, Functions } from "@angular/fire/functions";
import { getFirestore, Firestore } from '@angular/fire/firestore';
import { CommentsService } from './comments.service';
import { getApp } from '@angular/fire/app';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
  // private readonly functions: Functions;
  // private readonly db: Firestore;
  db = getFirestore()
  app = getApp(); // Initialize Firebase app
  private readonly functions: Functions;


  constructor(  ) { 
      const functions = getFunctions(this.app, 'europe-west1');
      this.functions = functions;
  }


  getFunctionsRegion(){
    return this.functions
  }


}
