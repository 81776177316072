import { Component, ComponentFactoryResolver, ComponentRef, Input, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { StorageService } from '../service/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { deepCopy, getSizeBytes } from '../../../../backend/utils/object';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FileHandle } from '../directive/drag-and-drop.directive';
import { ImageCompressionService } from '../service/image-compression.service';
import { UploadService } from '../service/upload.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MediaInsideFolderData } from '../shared/media';

@Component({
  selector: 'app-media-folder-content',
  templateUrl: './media-folder-content.component.html',
  styleUrls: ['./media-folder-content.component.css']
})

export class MediaFolderContentComponent {
  storage:any=[]
  storageMedia:any={
    items:[],
    folder: []
  }
  storageItems:any={
    items:[],
    folder: []
  }

  storagePassDefinitions:any=[]
  storageWorkTasks:any=[]
  storagePasses:any=[]
  panelOpenState = false;
  routerUrl:any
  idToShow:any
  currentRoute:any
  folder:any = []
  folderRoute:any
  newImage:any
  gridView = true
  gridViewOption = 'item'
  @Input() data: MediaInsideFolderData
  displayedColumns: string[] = ['icon','name','type', 'updated', 'size', 'actions'];
  listdata= new MatTableDataSource()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paginatorSize = this.settingsService.paginator$
  filteredFolder:any = []
  emptyMedia = false

  constructor(private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private uploadService: UploadService,
    private commentService: CommentsService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private http: HttpClient,
    private imageCompressionService: ImageCompressionService,
    ){  
      this.routerUrl=this.route.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
      this.currentRoute=this.routerUrl.value[0].path
    }

  ngOnInit():void{
    this.folderRoute=""
    this.storage = []
    this.storageItems.items = []
    this.storageMedia.items = []

    if(this.data.folderItems){
      this.commentService.progressSpin.emit(true)

        if(this.data.folderItems.length ==  0)
          this.emptyMedia = true
        else
          this.emptyMedia = false
        this.folder = this.data.folderItems
        this.filteredFolder = deepCopy(this.data.folderItems)
        const items = deepCopy(this.data.folderItems)
        items.forEach(file => {
          if(!file.folder){
            if(file.metadata){
              file.size = file.metadata?.size
              file.updated = file.metadata?.updated
              file.type = file.metadata?.contentType
            }
          }
        });
        this.listdata = new MatTableDataSource(items);
        this.shorTableAgain()

      this.commentService.progressSpin.emit(false)
    }

    if(this.data.view){
      const view = this.data.view
      this.gridViewOption = view
      switch(view){
        case 'list': {
          this.gridView = false
          // this.data.setViewStyle('list')
          break;
        }
  
        case 'item': {
          this.gridView = true
          // this.data.setViewStyle('item')
          break;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.listdata.paginator = this.paginator;
    this.listdata.sort = this.sort;
  }

  shorTableAgain(){
    this.listdata.paginator = this.paginator;
    this.listdata.sort = this.sort;
    setTimeout(() => {
      this.listdata.filter = " ";
    this.commentService.progressSpin.emit(false)
      
    }, 500);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    if(!filterValue.trim().toLocaleLowerCase())
      this.filteredFolder =  deepCopy(this.folder)
    else
      this.filteredFolder = this.filteredFolder.filter( file => file.name.toLocaleLowerCase().includes(filterValue.trim().toLocaleLowerCase()))
      
    this.listdata.filter = filterValue.trim().toLowerCase();

    if (this.listdata.paginator) {
      this.listdata.paginator.firstPage();
    }
  }

  triggerFileInput() {
    document.getElementById('myfile-upload-media')?.click();
  }

  readURL(event:any){
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.data.addFileToFolder(file.file, this.folder.length)
    }
  }

  changeView( view){
    switch(view){
      case 'list': {
        this.gridView = false
        this.data.setViewStyle('list')
        break;
      }

      case 'item': {
        this.gridView = true
        this.data.setViewStyle('item')
        break;
      }
    }
  }

  checkFileType(file){
    let type 

    if(file.folder)
      type = 'folder'
    if(file.metadata){
      if(file.metadata?.contentType === 'application/pdf')
        type = 'file'

      if(file.metadata?.contentType.startsWith('image'))
        type = 'image'

        
      if(file.metadata?.contentType.startsWith('video') )
        type = 'video'

      if(!file.metadata?.contentType.startsWith('image') && !file.metadata?.contentType.startsWith('video'))
        type='file'
        
    }else{

    }

    return type
  }


  getFileSize(size){
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    if(size)
    return getSizeBytes(size,units ,2)
    else
    return undefined
  }

  openFilePreview(file){
    this.data.openFilePreview(file)
  }

  downloadFile(file){
    this.data.downloadFile(file)
  }

  deleteFile(file){
    this.data.deleteFile(file)
  }

  async filesDropped(files: FileHandle[]): Promise<void> {
    files.forEach( async (file, index) => {
      this.data.addFileToFolder(file.file, index)
    })
  }

}
