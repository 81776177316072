import { Injectable } from '@angular/core';
import { collection, deleteDoc, doc, getFirestore, setDoc, updateDoc } from '@angular/fire/firestore';
import { NetworkConstants } from 'src/network.constants';

@Injectable({
  providedIn: 'root'
})
export class WorkTaskTemplateGroupsService {
  db = getFirestore()
  constructor() { }

  async createWorkTaskTemplateGroup(data, contextId,sortIndex):Promise<string>{
    return new Promise(async (resolve, reject) => {

      const workTTRef = doc( collection(this.db,NetworkConstants.COLLECTION_CONTEXTS, contextId, NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES_GROUPS) )
      const newId = workTTRef.id

      let group = data
      group['creationDate'] = new Date()
      group['timeStamp'] = new Date()
      group['id'] = newId
      group['sortIndex']=sortIndex

      try{
        await setDoc( workTTRef, group)
        resolve(newId)
      }catch (error) {
        reject(error)
      }
    })
  }

  deleteworkTaskTemplateGroup(workTaskTemplateGroupId: any, contextId):Promise<void>{
    return new Promise(async (resolve, reject) => {
      try {
        const docRef = doc(this.db,NetworkConstants.COLLECTION_CONTEXTS,contextId,NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES_GROUPS,workTaskTemplateGroupId)
        const d  = await deleteDoc( docRef)

        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }

  updateWorkTaskTemplateGroup(value,groupId,contextId){
    return new Promise<void>(async (resolve, reject) => {
      try {
        value['timeStamp'] = new Date()
        const docRef = doc(this.db, NetworkConstants.COLLECTION_CONTEXTS, contextId,NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES_GROUPS, groupId )
        await updateDoc( docRef, value)

        resolve()
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }
}