import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageEditComponent } from '../../editWidgets/image-edit/image-edit.component';
import { ActivatedRoute } from '@angular/router';
import { checkDynamicContent, deepCopy, supportUrlStructure } from '../../../../../../backend/utils/object';
import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-image-phone]',
  templateUrl: './image-phone.component.html',
  styleUrls: ['./image-phone.component.css']
})
export class ImagePhoneComponent implements OnInit {
  @Input('app-image-phone') data:any;
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetId: any, widgetType:any, images:any, translations: any}>()
  darkModeActive= false
  imageData:any
  imageDataSupportUrl:any
  editMode:any
  widgetNumber:any
  imageToStorage:any=[]
  routerUrl:any
  idToShow:any
  translationsPass:any
  url:any
  linkURL:any
  dynamic=false
  titleColor = "var(--title)"
  subtitleColor = "var(--subtitle)"

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    private settingsService:SettingsService,
    private renderer: Renderer2,
    private element: ElementRef
    ){
      this.routerUrl=this.router.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }
  ngOnInit(): void {
    this.imageData=this.data[0]
    this.editMode=this.data[1]
    this.translationsPass = this.data[3]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
    this.imageDataSupportUrl = deepCopy (this.data[0])
      if(this.imageData.url){
        try{
          const partsd = this.imageData.url.includes("${")
          if(partsd){
            //this.imageData.url = checkDynamicContent(this.imageData.url)
            this.dynamic = true
          }
          let url = supportUrlStructure(this.imageData.url, this.idToShow, this.settingsService.contextId$)
          this.url=url
            
          // const imgUrl = new URL(this.imageData.url)
          // let hostname = environment.urlHostName
         
          // if(imgUrl.protocol=="pass:" ){
          //   const parts = this.imageData.url.match(/pass:\/\/(.+)\/(.+)/)
          //   if(parts){
          //     const passTemplateID = parts[1]
          //     const path = parts[2]
          //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          //     this.url=firebaseUrl
          //   }else{
          //     const newparts = this.imageData.url.match(/pass:\/\/(.+)/)
          //     if(newparts){
          //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
          //       newparts.forEach( (part,index)=> {
          //         if(index>0){
          //           firebaseUrl += '/' + part 
          //         }
          //       })
          //       this.url=firebaseUrl
          //     }
          //   }
          // }

          // if(imgUrl.protocol =="tridy:"){
          //   const parts = this.imageData.url.match(/tridy:\/\/(.+)\/(.+)/)
          //   let path =""
          //   parts.forEach((parte, index) =>{
          //     if(index>0 && index<parts.length){
          //       path += "/" + parte
          //     }
          //   })
            
          //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
          //   this.url=firebaseUrl
          // }
        }catch(error){
        }
      }

      if(this.imageData.linkURL){
        try{
          //this.imageData.linkURL = checkDynamicContent(this.imageData.linkURL)
          this.linkURL = this.imageData.linkURL
          let url = supportUrlStructure(this.imageData.linkURL, this.idToShow, this.settingsService.contextId$)
          if(url)
          this.linkURL=url
          // const imgUrl = new URL(this.imageData.linkURL)
          // let hostname = environment.urlHostName
          // if(imgUrl.protocol=="pass:" ){
          //   const parts = this.imageData.linkURL.match(/pass:\/\/(.+)\/(.+)/)
          //   if(parts){
          //     const passTemplateID = parts[1]
          //     const path = parts[2]
          //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          //     this.linkURL=firebaseUrl
          //   }else{
          //     const newparts = this.imageData.linkURL.match(/pass:\/\/(.+)/)
          //     if(newparts){
          //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
          //       newparts.forEach( (part,index)=> {
          //         if(index>0){
          //           firebaseUrl += '/' + part 
          //         }
          //       })
          //       this.linkURL=firebaseUrl
          //     }
          //   }
          // }
          // if(imgUrl.protocol =="tridy:"){
          //   const parts = this.imageData.linkURL.match(/tridy:\/\/(.+)\/(.+)/)
          //   let path =""
          //   parts.forEach((parte, index) =>{
          //     if(index>0 && index<parts.length){
          //       path += "/" + parte
          //     }
          //   })
            
          //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
          //   this.linkURL=firebaseUrl
          // }
        }catch(error){}
      }
/*       if(this.imageData.title)
        this.imageData.title = checkDynamicContent(this.imageData.title)
  
      if(this.imageData.subtitle)
        this.imageData.subtitle = checkDynamicContent(this.imageData.subtitle) */
    
    if(this.data[5]){
      this.darkModeActive = this.data[5]
      // this.setWidgetStyle(this.imageData)  
    }
  }

  ngOnChanges(){
    this.imageData=this.data[0]
    this.editMode=this.data[1]
    this.translationsPass = this.data[3]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
    this.darkModeActive = this.data[5]
    
    setTimeout(() => {
      this.setWidgetStyle(this.imageData)     
    }, 200);
  }

  openDialogEdit():void{
    let dialogRef = this.dialog.open(ImageEditComponent, {
      data:{
        values: this.imageData,
        widget: this.widgetNumber,
        passId: this.idToShow,
        translations: this.translationsPass,
        url :this.url
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.imageData=result[0]
        this.imageToStorage.push( result[2])
        this.returnChanges.emit({newdata: this.imageData, widget: this.widgetNumber, widgetId: result[0].id, widgetType: this.imageData.type,images: this.imageToStorage, translations: result[3]})
      }

    })
  }

  setWidgetStyle(data){
    const elementId = 'phoneSection-'+data.id
    const element = this.element.nativeElement.querySelector('#'+elementId);
    if(data?.widgetStyle){
      if(this.darkModeActive){
        if( data.widgetStyle.dark){
          if(data.widgetStyle.dark.backgroundColor){
            document.getElementById(elementId).style.backgroundColor = data.widgetStyle.dark.backgroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          }
          if( data.widgetStyle.dark.foregroundColor){
            if(data.title)
              this.titleColor = data.widgetStyle.dark.foregroundColor
            if(data.subtitle)
              this.subtitleColor = data.widgetStyle.dark.foregroundColor
            document.getElementById(elementId).style.color = data.widgetStyle.dark.foregroundColor
          }else{
            if(data.title)
            this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
          }
        }else{
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');

            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
        }
      }else{
        if( data.widgetStyle.light){
          if( data.widgetStyle.light.backgroundColor){
            document.getElementById(elementId).style.backgroundColor = data.widgetStyle.light.backgroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          }

          if( data.widgetStyle.light.foregroundColor){
            document.getElementById(elementId).style.color = data.widgetStyle.light.foregroundColor
            if(data.title)
              this.titleColor = data.widgetStyle.light.foregroundColor
            if(data.subtitle)
              this.subtitleColor = data.widgetStyle.light.foregroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
          }
        }else{
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');

            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
        }
      }

      if(data.widgetStyle.contentAlignment){
        if(data.widgetStyle.contentAlignment=='leading')
          document.getElementById(elementId).style.textAlign = 'left'

        if(data.widgetStyle.contentAlignment=='center')
          document.getElementById(elementId).style.textAlign = 'center'

        if(data.widgetStyle.contentAlignment=='trailing')
          document.getElementById(elementId).style.textAlign = 'end'

      }else{
        if(document.getElementById(elementId)?.style && document.getElementById(elementId)?.style.textAlign)
          this.renderer.removeStyle(element, 'textAlign');
      }
    }
  }
}
