<div appDragAndDrop (files)="filesDropped($event)">
    <mat-card class="upload-section text-center justify-content-center">
        <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">
            <div class="p-2 mb-4 text-center">
                <mat-icon class="decoration-icon material-symbols-outlined">add_photo_alternate</mat-icon>

                <button mat-button class="my-3 orange-button" [matMenuTriggerFor]="menu">
                    {{'pass.pick' | translate}}
                 </button>
                 <mat-menu #menu="matMenu">
                     @if(data.enableBrowse){
                         <button mat-menu-item type="button" (click)="triggerFileInput()">
                             <mat-icon class="material-symbols-outlined">folder</mat-icon>{{'pass.browse' | translate}}
                         </button>
                     }
                     @if(data.enableEnterUrl){
                         <button mat-menu-item type="button"(click)="typeURL()">
                             <mat-icon class="material-symbols-outlined">link</mat-icon>{{'enterURL' | translate}}
                         </button>
                     }
                     @if(data.enableMedia){
                         <button mat-menu-item type="button" (click)="browserFromMediaFolder()">
                             <mat-icon class="material-symbols-outlined">perm_media</mat-icon>{{'pass.pick_media' | translate}}
                         </button>
                     }
                 </mat-menu>

                <input #fileInput type="file" id="imageFile" name="imageFile" style="display: none;" accept={{data.supportedFileAccept}} (change)="readURL($event)">
            </div>
            <p class="py-2 mt-2">{{'pass.supportedFiles' | translate}}: {{data.supportedFileAccept}}</p>
        </div>
    </mat-card>
</div>