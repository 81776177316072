import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountLanguagesDialogComponent } from './account-languages-dialog/account-languages-dialog.component';
import { PassTemplatesComponent } from './pass-templates/pass-templates.component';
import { TridysComponent } from './tridys/tridys.component';
import { MediaComponent } from './media/media.component';
import { ProductsComponent } from './products/products.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { TaskTemplatesComponent } from './worktask/worktask-templates/worktask-templates.component';
import { FinishedTasksComponent } from './finished-tasks/finished-tasks.component';
import { UsersComponent } from './users/users.component';
import { LogInComponent } from './log-in/log-in.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ContentTypesListComponent } from './content-type/content-types-list/content-types-list.component';
import { ContentComponent } from './content/content.component';
import { MediaFolderComponent } from './media-folder/media-folder.component';
import { FinishedTasksItemComponent } from './finished-tasks-item/finished-tasks-item.component';
import { TaskTemplateItemComponent } from './worktask/worktask-template-item/worktask-template-item.component';
import { TridysItemComponent } from './tridys-item/tridys-item.component';
import { ProductsItemComponent } from './products-item/products-item.component';
import { ContextIdSelectorComponent } from './context-id-selector/context-id-selector.component';
import { AdvancedExperiencesComponent } from './advanced-experience/advanced-experiences/advanced-experiences.component';
import { AdvancedExperiencesItemComponent } from './advanced-experience/advanced-experiences-item/advanced-experiences-item.component';
import { AuthGuard } from './shared/auth.guard';
import { PagesGuard } from './shared/pages.guard';
import { ReportsListComponent } from './reports/reports-list/reports-list.component';
import { ReportsItemTridysListComponent } from './reports/reports-item-tridys-list/reports-item-tridys-list.component';
import { InterfacesListComponent } from './interfaces-list/interfaces-list.component';
import { TaskTemplatesGroupsComponent } from './worktask/worktask-templates-groups/worktask-templates-groups.component';
import { RegisterComponent } from './register/register.component';
import { LogGuard } from './shared/log.guard';
import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard';
import { CdSettingsComponent } from './cd-settings/cd-settings.component';
import { AuthPageGuard } from './shared/auth-page.guard';
import { HomeComponent } from './home/home.component';
import { MediaHandlerComponent } from './media-handler/media-handler.component';

const routes1: Routes = [
  {path:'home/:contextId', component: DashboardComponent,  canActivate:[AuthGuard]},
  {path:'home/:contextId/dashboard', component: DashboardComponent},

  {path:'home/:contextId/account/:accountId', component: AccountLanguagesDialogComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/interfaces', component: InterfacesListComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/passTemplates/:passTemplateId', component: PassTemplatesComponent},
  {path:'home/:contextId/passTemplates/:passTemplateId', component: PassTemplatesComponent},

  {path:'home/:contextId/content', component: ContentComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/media', component: MediaHandlerComponent, canActivate:[PagesGuard] },
  // {path:'home/:contextId/media/:folder', component: MediaFolderComponent , canActivate:[PagesGuard]},
  // {path:'home/:contextId/media/:folder/:insideFolder', component: MediaFolderComponent , canActivate:[PagesGuard]},
  // {path:'home/:contextId/media/:folder/:insideFolder/:folder', component: MediaFolderComponent , canActivate:[PagesGuard]},
  // {path:'home/:contextId/media/:folder/:insideFolder/:folder/:folder1', component: MediaFolderComponent, canActivate:[PagesGuard] },

  {path:'home/:contextId/contentTypes', component: ContentTypesListComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/contentTypes/:contentTypeId/passTemplate/:passTemplateId', component: PassTemplatesComponent, canActivate:[PagesGuard], canDeactivate: [CanDeactivateGuard]},
  {path:'home/:contextId/contentTypes/:contentTypeId/passTemplate/:passTemplateId/coverImage/:coverImageId', component: AdvancedExperiencesItemComponent, canActivate:[PagesGuard]}, 
  {path:'home/:contextId/contentTypes/:contentTypeId/passTemplate/:passTemplateId', component: PassTemplatesComponent, canActivate:[PagesGuard], canDeactivate: [CanDeactivateGuard]},
  {path:'home/:contextId/contentTypes/:contentTypeId/passTemplate/:passTemplateId/coverImage/:coverImageId', component: AdvancedExperiencesItemComponent, canActivate:[PagesGuard]}, 

  {path:'home/:contextId/reports', component: ReportsListComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:reportId', component: ReportsItemTridysListComponent, canActivate:[PagesGuard]},
  // {path:'home/:contextId/reports/:id/filter', component: ReportsItemTridysListComponent, canActivate:[PagesGuard]},
  // {path:'home/:contextId/reports/:id/filter/:value', component: ReportsItemTridysListComponent, canActivate:[PagesGuard]},
  // {path:'home/:contextId/reports/:id/filter/:value/tridys/:tridyId', component: TridysItemComponent, canActivate:[PagesGuard]},
  // {path:'home/:contextId/reports/:id/filter/tridys/:tridyId', component: TridysItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:reportId/tridys/:tridyId', component: TridysItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/reports/:reportId/tridys/:tridyId/finishedTasks/:finishedTaskId', component: FinishedTasksItemComponent, canActivate:[PagesGuard]},
  // {path:'home/:contextId/reports/:id/filter/:value/tridys/:tridyId/finishedTasks/:id', component: FinishedTasksItemComponent, canActivate:[PagesGuard]},
  // {path:'home/:contextId/reports/:id/filter/tridys/:tridyId/finishedTasks/:id', component: FinishedTasksItemComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/tridys', component: TridysComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/tridys/:tridyId', component: TridysItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/tridys/:tridyId/finishedTasks/:finishedTaskId', component: FinishedTasksItemComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/coverImages', component: AdvancedExperiencesComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/coverImages/:coverImageId', component: AdvancedExperiencesItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/coverImages/:coverImageId', component: AdvancedExperiencesItemComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/products', component: ProductsComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/products/:coverImageId', component: ProductsItemComponent, canActivate:[PagesGuard]},

  {path:'home/:contextId/taskTemplatesGroups', component: TaskTemplatesGroupsComponent , canActivate:[PagesGuard]},

  {path:'home/:contextId/taskTemplates', component: TaskTemplatesComponent , canActivate:[PagesGuard]},
  {path:'home/:contextId/taskTemplates/:taskTemplateId', component: TaskTemplateItemComponent , canActivate:[PagesGuard], canDeactivate: [CanDeactivateGuard]},

  {path:'home/:contextId/finishedTasks', component: FinishedTasksComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/finishedTasks/:finishedTaskId', component: FinishedTasksItemComponent, canActivate:[PagesGuard]},
  {path:'home/:contextId/finishedTasks/:finishedTaskId/tridys/:tridyId', component: TridysItemComponent, canActivate:[PagesGuard]},
  
  {path:'home/:contextId/users', component: UsersComponent, canActivate:[PagesGuard]},
  
  {path:'home/:contextId/cdSettings', component: CdSettingsComponent, canActivate:[PagesGuard]},
  // ----------------------------------------------------------------------------------------------------
  {path:'home', component: DashboardComponent,  canActivate:[AuthGuard]},
 
  {path:'logIn', component: LogInComponent, canActivate:[LogGuard]},
  {path:'register', component: RegisterComponent},
  {path:'selectAccount', component: ContextIdSelectorComponent, canActivate:[PagesGuard]},
  {path:'selectAccount/:contextId', component: ContextIdSelectorComponent, canActivate:[PagesGuard]},
  //{path:'setPassword/:id', component: RegisterSetPasswordComponent},
  
  // { path: "", component: RedirectComponent, canActivate:[PagesGuard] },
  {path:'**', component:RedirectComponent, canActivate:[PagesGuard]}
  // {path:'**', component:PAgeNotFound}
];

const routes: Routes = [
  { path: "", component: RedirectComponent },
  { path: "logIn", loadChildren: () => import('./log-in/log-in.module').then(m => m.LogInModule) },
  { path: "register", loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)},
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  // { path: '**' , redirectTo: "/logIn", pathMatch: 'full' }
  {path:'selectAccount', component: ContextIdSelectorComponent, canActivate:[PagesGuard]},
  {path:'selectAccount/:contextId', component: ContextIdSelectorComponent, canActivate:[PagesGuard]},

]

@NgModule({
  imports: [RouterModule.forRoot(routes)],//, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
