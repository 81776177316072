<section class="topNavbar py-2 px-3 sticky-top" id="editNavbar">
    <div class="row align-items-center">
        <div class="col-md-3 col-lg-3 d-flex my-2 my-md-auto flex-nowrap align-content-center align-items-center">
            <button mat-icon-button style="color:var(--warmOrange);" (click)="backTo();"> 
                <span class="material-symbols-outlined">
                    arrow_back_ios_new
                </span>
            </button>
            <span class="component-headline text-white fw-light">{{'edit' | translate}} "{{titleTask}}" </span>
        </div>
        <!-- desktop -->
        <div class="col-9 d-none d-lg-flex justify-content-end align-items-center">
            <div class="button-group">
                <button mat-button style="color:white;" (click)="openWorkTaskTemplateDialog();">
                    <mat-icon>settings_applications</mat-icon><span class="d-none d-md-block">{{'workTask.template_settings'  | translate}}</span>
                </button>
                <button mat-button style="color:white;" (click)="openWorkTaskTemplateAdvancedDialog();">
                    <mat-icon>tune</mat-icon><span class="d-none d-md-block">{{'workTask.advanced_settings'  | translate}}</span>
                </button>
                <div class="vr custom-vr d-none d-md-inline-block"></div>

                <button mat-button class="cancel-editing" (click)="backTo();" id="btn-cancel" [disabled]="!tasktemplateUserRights_update">
                    {{'cancel' | translate}}
                </button>
                <button mat-button class="orange-button" id="btn-save" (click)="saveTaskTemplate();" [disabled]="!changesToSave">
                    {{'workTask.publishWorkTask' | translate }}
                </button>
            </div>
        </div>
        <!-- mobile -->
        <div class="col-md-9 d-lg-none">
            <div class="button-group d-flex align-items-center justify-content-end">
                <button mat-icon-button style="color:white;" (click)="openWorkTaskTemplateDialog();">
                    <mat-icon>settings_applications</mat-icon>
                </button>
                <button mat-icon-button style="color:white;" (click)="openWorkTaskTemplateAdvancedDialog();">
                    <mat-icon>tune</mat-icon>
                </button>
                <div class="vr custom-vr d-none me-3 d-md-inline-block"></div>
                <button mat-button class="cancel-editing me-2" (click)="backTo();">
                    <span class="d-none d-md-inline-block">{{'cancel' | translate}}</span>
                    <mat-icon class="d-md-none mx-0">cancel</mat-icon>
                </button>
                <button mat-button class="orange-button" id="btn-save" (click)="saveTaskTemplate();" [disabled]="!changesToSave">
                    <span class="d-none d-md-inline-block">{{'workTask.publishWorkTask' | translate }}</span>
                    <mat-icon class="d-md-none mx-0">check_circle</mat-icon>
                </button>
            </div>
        </div>
    </div>
</section>

<!-- mobile-message -->
<div class="row p-3 d-md-none" style="background-color: #dd2f2f;color:white">
    <div class="col-1 d-flex align-items-center align-content-center"><span class="material-symbols-outlined">error</span></div>
    <div class="col-11">{{'mobile_info' | translate}}</div>
</div>

<div class="container">
    <!-- <main class="container"> -->
    <div class="row mt-4">
        <div class="col-xs-6 col-md-6">
            <div class="d-flex mobile-wrapper justify-content-center align-self-center" [class.full-view]="phoneFullHeightView">
                <section class="mobile">
                    <!--  *ngIf="!editTemp" -->
                        <div class="d-flex mb-3 justify-content-center align-items-center">
                            <h2 class="text-center mb-0 px-2">{{'content.preview' | translate}}</h2>
                            <mat-icon class="text-orange" matTooltipPosition="before" matTooltip="{{'workTask.previewTooltip' | translate}}">info</mat-icon>
                        </div>

                    <div class="mobile-frame-container"></div>
                    <div class="mobile-frame">  <!-- .disable-interaction -->   
                        <div class="group-title p-3">
                            <div class="d-flex justify-content-between align-items-center" style="padding-top: 5px;">
                                <div class="d-flex">
                                    <span class="material-symbols-outlined">
                                        chevron_left
                                    </span>
                                    <span *ngIf="taskTemplateGroupName; else ungrouped">{{ taskTemplateGroupName }}</span>
                                    <ng-template #ungrouped>{{'taskTemplates.ungrouped' | translate}}</ng-template>
                                </div>

                                <span class="material-symbols-outlined" *ngIf="taskTemplateForm.value.hidden">
                                    visibility_off
                                </span>
                            </div>
                        </div>
                        <h2 class="p-3 pb-0 mb-0"><strong>{{titleTask}}</strong></h2>

                        <div *ngIf="taskTemplate && taskTemplate.incomingTridys" class="me-2 mt-1">
                            <div *ngFor="let incoming of taskTemplate.incomingTridys; let index = index;">
                                <div class="p-1 ps-2 pe-2 d-flex m-1 w-100 align-items-center justify-content-between disable-interaction border rounded" >
                                    <span> {{getContentTypeIdName(incoming.contentTypeId)}}</span>
                                    <div class="d-flex flex-row align-items-center">
                                        <span class="material-symbols-outlined" *ngIf="includesSource(incoming.sources, 'scanQR')"> qr_code_scanner</span>
                                        <span class="material-symbols-outlined" *ngIf="includesSource(incoming.sources, 'scanNFC')">phonelink_ring </span>
                                        <span class="material-symbols-outlined" *ngIf="includesSource(incoming.sources, 'select')"> list </span>
                                    
                                    </div>
                                </div>
                                <span class="text-muted p-1 m-1">0</span>
                            </div>
                        </div>

                        <div *ngFor="let task of sections; let index = index;" class="p-3 pt-1">
                            <form>
                                <div class="section-title">{{task.title}}</div>
                                <div *ngFor="let item of task.fields; let fieldNumber = index;">
                                    <div class="d-flex flex-row flex-wrap">
        
                                        <!-- upload / action fields-->
                                        <mat-form-field appearance="outline" *ngIf="item.type=='barcode'" class="w-100 disable-interaction">
                                            <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                            <input type="tel" matInput placeholder="...">
                                            <mat-icon class="text-orange" matSuffix>qr_code_scanner</mat-icon>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" *ngIf="item.type=='tridy'" class="w-100 disable-interaction">
                                            <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                            <input type="tel" matInput placeholder="...">
                                            <mat-icon class="text-orange" matSuffix>contactless</mat-icon>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" *ngIf="item.type=='signature'" class="w-100 disable-interaction">
                                            <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                            <input type="tel" matInput placeholder="...">
                                            <span matSuffix class="material-symbols-outlined px-2 mx-1 text-orange">signature</span>
                                         <!--    <mat-icon class="text-orange" matSuffix>signature</mat-icon> -->
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" *ngIf="item.type=='nfc'" class="w-100 disable-interaction">
                                            <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                            <input type="tel" matInput placeholder="...">
                                            <mat-icon class="text-orange" matSuffix>nfc</mat-icon>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" *ngIf="item.type=='document'" class="w-100 disable-interaction">
                                            <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                            <input type="tel" matInput placeholder="...">
                                            <mat-icon class="text-orange" matSuffix>attach_file</mat-icon>
                                        </mat-form-field>
                                        <!-- standard fields -->
                                        <!-- stays hidden :) <span *ngIf="item.type=='hidden'">hidden</span> -->
                                        <div class="pb-4 d-flex w-100 justify-content-between disable-interaction" *ngIf="item.type=='boolean'">
                                            <div>{{item.title}}<span *ngIf="item.mandatory">*</span></div>
                                            <div class="text-align-right px-1">
                                                <mat-slide-toggle></mat-slide-toggle>
                                            </div>
                                        </div>
                                        
                                        <div *ngIf="item.type=='dateTime'" class="disable-interaction w-100">
                                            <mat-form-field appearance="outline" *ngIf="item.style=='dateTime'" class="w-100">
                                                <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                                <input matInput type="datetime-local" value ="" [readonly]="!tasktemplateUserRights_update">
                                            </mat-form-field>
                    
                                            <mat-form-field appearance="outline" *ngIf="item.style=='date'" class=" w-100">
                                                <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                                <input matInput [matDatepicker]="picker">
                                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>

                                            <mat-form-field appearance="outline" *ngIf="item.style=='time'" class="w-100">
                                                <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                                <input matInput type="time" *ngIf="item.defaultValue" value ="{{isTime(item.defaultValue)}}" [readonly]="!tasktemplateUserRights_update">
                                                <input matInput type="time" *ngIf="!item.defaultValue" value ="--:--" [readonly]="!tasktemplateUserRights_update">
                                            </mat-form-field>
                                        </div>

                                        <div class="pb-3 d-flex w-100 justify-content-between disable-interaction" *ngIf="item.type=='display'">
                                            <div>{{item.title}}</div>
                                            <div class="text-align-right px-1">
                                                <!-- <small>Value</small> -->
                                            </div>
                                        </div>

                                        <mat-form-field appearance="outline" *ngIf="item.type=='longText'" class="w-100 disable-interaction">
                                            <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                            <textarea matInput></textarea>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" *ngIf="item.type=='decimal' || item.type=='weight' || item.type=='number' || item.type=='currency'" class="disable-interaction w-100">
                                            <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                            <input type="number" name="{{item.title}}" value="{{item.title}}" matInput>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline" *ngIf="item.type=='text'" class="w-100 disable-interaction">
                                            <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                            <input type="text" name="{{item.title}}" value="{{item.title}}" matInput>
                                        </mat-form-field>

                                        <mat-form-field *ngIf="item.type=='options'" appearance="outline" class="w-100 disable-interaction">
                                            <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                            <mat-select><!-- <mat-option value="-">-</mat-option> --></mat-select>
                                        </mat-form-field>
                                        
                                        <div *ngIf="item.type=='showText'" class="pt-2">
                                            <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                            <div [innerHTML]="transformMarkDownText(item)" class="markdwn">  </div>
                                        </div>

                                        <div *ngIf="item.type=='showImage'" class="w-100 pt-2">
                                            <mat-label>{{item.title}}<span *ngIf="item.mandatory">*</span></mat-label>
                                            <div class="m-auto d-flex justify-content-center">
                                                <img src="{{loadShowImage(item.defaultValue)}}" style=" max-height:200px; max-width: 100%;">
                                            </div>                                        
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>

                        <div *ngIf="taskTemplateForm.value.showImages" class="p-3 pt-0 pb-0">
                            <span class="text-muted text-uppercase">{{'taskTemplates.images' | translate}}</span>
                            <div class="insertImgContainer mt-1" >
                                <label for="myfile" class="labelFile"> +  <input type="file" id="myfile" name="myfile" style="display: none;" accept=".jpg, .jpeg" disabled> </label>
                            </div>
                        </div>

                        <div *ngIf="taskTemplateForm.value.showComment" class="p-3 pt-0 pb-0">
                            <span class="text-muted text-uppercase">{{'pass.comment' | translate}}</span>
                            <div class="insertImgContainer mt-1"></div>
                        </div>
                        <div class="p-3 mb-4">
                            <button mat-button class="w-100" style="background-color:var(--warmOrange); color:white;">{{ 'submit' | translate }}</button>
                        </div>
                    </div>
                </section> 
            </div>
        </div>
        <div class="col-xs-6 col-md-6 mb-5">
            <div class="d-flex flex-row bd-highlight mb-4 align-items-center justify-content-end">
                <mat-slide-toggle class="screen-switcher" [(ngModel)]="phoneFullHeightView">{{ 'fullView' | translate }}</mat-slide-toggle>
                <div [matTooltip]="!tasktemplateUserRights_update ? ('home.tooltip' | translate) : null">
                    <button mat-button 
                    style="margin-left: 8px!important;" 
                    class="m-1 shadow-sm button-task orange-button" 
                    (click)="addSection();" 
                    [disabled]="!tasktemplateUserRights_update"
                    >
                        {{'taskTemplates.wt_section'  | translate}}
                    </button>
                </div>
            </div>

            <div class="p-4 mt-5 text-center" style="color: #a2a2a2;" *ngIf="sections.length==0">{{'workTask.noFields' | translate}}</div>

            <mat-accordion class="form-field-section" multi cdkDropList (cdkDropListDropped)="dropPanel($event)">
                <mat-expansion-panel cdkDrag *ngFor="let section of sections; let index = index;" [expanded]="index === 0">

                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span id="truncate-text">{{section.title}}</span>
                            </mat-panel-title>
                            <mat-panel-description>
                                <button mat-icon-button [matMenuTriggerFor]="editDelete" style="color:var(--warmOrange);" (click)="$event.stopPropagation()">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #editDelete="matMenu">
                                    <button mat-menu-item (click)="editTitleSection(section.title, index)" [disabled]="!tasktemplateUserRights_update"><mat-icon>edit</mat-icon> {{"taskTemplates.editSectionTitle" | translate}}</button>
                                    <button mat-menu-item (click)="duplicateSection(section, index);" [disabled]="!tasktemplateUserRights_update"><mat-icon>content_copy</mat-icon> {{"taskTemplates.duplicateSection" | translate}}</button>
                                    <button mat-menu-item (click)="deleteSection(section.title, index);" [disabled]="!tasktemplateUserRights_update"><mat-icon class="material-symbols-outlined delete">delete</mat-icon> {{"taskTemplates.deleteSection" | translate}}</button>
                                </mat-menu>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
    
                        <!-- CONTENT -->
                        <div class="expansion-panel-content">
                            <div cdkDropList class="shadow-sm rounded" (cdkDropListDropped)="drop($event,index)">
    
                                <div cdkDrag *ngFor="let item of section.fields; let fieldNumber = index;" id="fields-list" (click)="openFieldDialog(item, fieldNumber, index);" class="d-flex"> <!-- cdkDrag -->
                                    
                                    <div class="d-flex flex-row flex-nowrap">
    
                                        <span *ngIf="item.type=='barcode'" class="material-symbols-outlined p-2 text-orange">qr_code_scanner</span>
                                        <span *ngIf="item.type=='signature'" class="material-symbols-outlined p-2 text-orange">signature</span>
                                        <span *ngIf="item.type=='boolean'" class="material-symbols-outlined p-2 text-orange">toggle_on</span>
                                        <span *ngIf="item.type=='currency'" class="material-symbols-outlined p-2 text-orange">euro</span>
                                        <span *ngIf="item.type=='dateTime'" class="material-symbols-outlined p-2 text-orange">calendar_month</span>
                                        <span *ngIf="item.type=='decimal'" class="material-symbols-outlined p-2 text-orange">numbers</span>
                                        <span *ngIf="item.type=='display'" class="material-symbols-outlined p-2 text-orange">visibility</span>
                                        <span *ngIf="item.type=='document'" class="material-symbols-outlined p-2 text-orange">attach_file</span>
                                        <span *ngIf="item.type=='hidden'" class="material-symbols-outlined p-2 text-orange">visibility_off</span>
                                        <span *ngIf="item.type=='longText'" class="material-symbols-outlined p-2 text-orange">subject</span>
                                        <span *ngIf="item.type=='number'" class="material-symbols-outlined p-2 text-orange">pin</span>
                                        <span *ngIf="item.type=='text'" class="material-symbols-outlined p-2 text-orange">text_format</span>
                                        <span *ngIf="item.type=='tridy'" class="material-symbols-outlined p-2 text-orange">contactless</span>
                                        <span *ngIf="item.type=='options'" class="material-symbols-outlined p-2 text-orange">list</span>
                                        <span *ngIf="item.type=='weight'" class="material-symbols-outlined p-2 text-orange">weight</span>
                                        <span *ngIf="item.type=='nfc'" class="material-symbols-outlined p-2 text-orange">nfc</span>
                                        <span *ngIf="item.type=='showText'" class="material-symbols-outlined p-2 text-orange">edit_note</span>
                                        <span *ngIf="item.type=='showImage'" class="material-symbols-outlined p-2 text-orange">image</span>
                        
                                        <div class="d-flex flex-column justify-content-center">
                                            <span class="text-break text-wrap">{{item.title}}</span>
                                            <span class="text-muted text-break text-wrap" style="font-size: 11px;">{{item.key}}</span>
                                        </div>
                                    </div>
                        
                                    <button mat-icon-button [disabled]="!tasktemplateUserRights_update" (click)="deleteFieldOfSection(fieldNumber, index);" id="btn-delete-field" class="delete-icon">
                                        <span class="material-symbols-outlined">
                                            delete
                                        </span>
                                    </button>
                                    <!-- </div> -->
    
                                </div>
                            </div>
    
                            <mat-action-row>
                                <div [matTooltip]="!tasktemplateUserRights_update ? ('home.tooltip' | translate) : null">
                                    <button mat-button class="m-1 shadow-sm button-task orange-button" [matMenuTriggerFor]="fieldTypes" [disabled]="!tasktemplateUserRights_update">
                                        {{'workTask.addNewElement' | translate}}
                                    </button>
                                    <mat-menu #fieldTypes="matMenu" xPosition="before" yPosition="below">

                                        <button mat-menu-item *ngFor="let element of elementTypes; let elementIndex = index" (click)="addFieldToSection(element.type, index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">{{element.icon}}</mat-icon> {{element.translation | translate}}
                                        </button>
                                        <!-- <button mat-menu-item (click)="addFieldToSection('display', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">visibility</mat-icon> {{'taskTemplates.add_display' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('hidden', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">visibility_off</mat-icon> {{'taskTemplates.add_hidden' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('text', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">text_format</mat-icon> {{'taskTemplates.add_text' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('longText', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">subject</mat-icon> {{'taskTemplates.add_longText' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('number', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">pin</mat-icon> {{'taskTemplates.add_number' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('decimal', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">numbers</mat-icon> {{'taskTemplates.add_decimal' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('currency', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">euro</mat-icon> {{'taskTemplates.add_currency' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('dateTime', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">calendar_month</mat-icon> {{'taskTemplates.add_date' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('boolean', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">toggle_on</mat-icon> {{'taskTemplates.add_switch' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('options', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">list</mat-icon> {{'taskTemplates.add_list' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('weight', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">weight</mat-icon>{{'taskTemplates.add_weight' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('tridy', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">contactless</mat-icon> {{'taskTemplates.add_tridy' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('barcode', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">qr_code_scanner</mat-icon> {{'taskTemplates.add_barcode' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('signature', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">signature</mat-icon> {{'taskTemplates.add_signature' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('document', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">attach_file</mat-icon> {{'taskTemplates.add_doc' | translate}}
                                        </button>
                                        <button mat-menu-item (click)="addFieldToSection('nfc', index);" [disabled]="!tasktemplateUserRights_update">
                                            <mat-icon class="material-symbols-outlined">nfc</mat-icon> {{'taskTemplates.add_nfc' | translate}}
                                        </button> -->
                                    </mat-menu>
                                </div>
                            </mat-action-row>
    
                        </div>

              </mat-expansion-panel>
            </mat-accordion>

        </div>
    </div>
</div>