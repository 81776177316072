import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../../service/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { TaskTemplate } from '../../../../../backend/src/tasktemplate.dto';
import { WorkTaskTemplateEditValueDialogComponent } from '../../worktask/work-task-template-edit-value-dialog/work-task-template-edit-value-dialog.component';
import { FilterUIDialogComponent } from '../../filter-ui-dialog/filter-ui-dialog.component';

@Component({
  selector: 'app-work-task-template-advanced-dialog',
  templateUrl: './work-task-template-advanced-dialog.component.html',
  styleUrls: ['./work-task-template-advanced-dialog.component.css']
})
export class WorkTaskTemplateAdvancedDialogComponent {
  workTaskTemplateForm!:FormGroup
  workTaskTemplateData: TaskTemplate
  tasktemplateUserRights_update:any
  contentTypesList:any=[]
  titleKeys:any=[]
  predicates:any=[]

  constructor(
    public dialogRef: MatDialogRef<WorkTaskTemplateAdvancedDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialog: MatDialog,
    private settingsService : SettingsService,
    private translate:TranslateService
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.tasktemplateUserRights_update = this.settingsService.modulesRolesRights$.value['workTaskTemplates']['U']
    this.settingsService.modulesRolesRights$.subscribe( value => {
      this.tasktemplateUserRights_update = this.settingsService.modulesRolesRights$.value['workTaskTemplates']['U']
    })

    this.contentTypesList = this.settingsService.settingsContentTypesWithId
    this.workTaskTemplateData = new TaskTemplate()
    this.workTaskTemplateData.setFromAny(this.data.values)

    this.workTaskTemplateForm = this.fb.group({
      tridySource:[],
      contentType:[],
      visibleInPass:[],
      actions:[],
      titleKeys:[],
      predicates:[],
      createContent:[],
      incomingTridys:[],
      outgoingTridys:[],
      taskType:[],
      showImages:[],
      showComment:[],
      hidden:[],
      gpsMandatory:[],
    })

    const {tridySource, contentType, visibleInPass, actions, titleKeys, predicates,incomingTridys,outgoingTridys,taskType,groupId,showImages,showComment, hidden, gpsMandatory} = this.workTaskTemplateData

    this.workTaskTemplateForm.patchValue({
      tridySource:tridySource ? tridySource : undefined,
      contentType: contentType ? contentType : undefined,
      visibleInPass:visibleInPass ? visibleInPass : false,
      actions:actions ? actions : undefined,
      titleKeys: titleKeys ? titleKeys : undefined,
      predicates: predicates ? predicates : undefined,
      createContent:undefined,
      incomingTridys : incomingTridys ? incomingTridys : undefined,
      outgoingTridys: outgoingTridys ? outgoingTridys :undefined,
      taskType: taskType ? taskType : undefined,
      groupId: groupId ? groupId : undefined,
      showImages:showImages,
      showComment:showComment,
      hidden: hidden ? hidden : undefined,
      gpsMandatory: gpsMandatory ? gpsMandatory : undefined
    })

      this.titleKeys = titleKeys 
      if(!titleKeys){
        this.titleKeys = []
      }
      this.predicates = predicates
      if(!predicates){
        this.predicates = []
      }
  }

  deleteTitleKey(titleKey, index){
    if(this.tasktemplateUserRights_update){
      this.titleKeys.splice(index,1)
      this.workTaskTemplateForm.patchValue({titleKeys:this.titleKeys})
    }
  }

  addTitleKey(){
    const value = "new"
    this.titleKeys.push(value)
    this.workTaskTemplateForm.patchValue({titleKeys:this.titleKeys})
  }

  updateTitleKey(s, index){
    if(this.tasktemplateUserRights_update){
      let dialogRef = this.dialog.open(WorkTaskTemplateEditValueDialogComponent, {
        data: { 
          value: s,
          type:'Title Key'
        },
        width: '600px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[1]){
          this.titleKeys[index] = result[0].value

        }
      });
    }
  }

  addPredicate(){
    const value = "field = value"
    this.predicates.push(value)
    this.workTaskTemplateForm.patchValue({predicates:this.predicates})
  }

  updatePredicate(s, index){
    if(this.tasktemplateUserRights_update){
      let dialogRef = this.dialog.open(FilterUIDialogComponent, {
        data: { 
          value: s,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          this.predicates[index] = result[1]
          this.workTaskTemplateForm.patchValue({predicates:this.predicates})
        }
      });
    }
    
  }

  deletePredicate(predicate, index){
    if(this.tasktemplateUserRights_update){
      this.predicates.splice(index,1)
      this.workTaskTemplateForm.patchValue({predicates:this.predicates})
    }
  }

  onDone(){
    const newValues = this.workTaskTemplateForm.value
    this.dialogRef.close([newValues, true])
  }

  onCancel(){
    this.dialogRef.close([this.data, false])
  }
}
