import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { SettingsService } from 'src/app/service/settings.service';
import { Product } from '../../../../../../backend/src/products.dto';
import { removeUndefinedValuesFromObject } from '../../../../../../backend/utils/object';
import { languagesList, languagesList_ } from '../../../../../../backend/src/languagesList.dto';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products-edit',
  templateUrl: './products-edit.component.html',
  styleUrls: ['./products-edit.component.css']
})
export class ProductsEditComponent {
  productEditForm!:FormGroup
  productEditData:any
  languagesList = languagesList
  defaultLanguage:string

  constructor(
    public dialogRef: MatDialogRef<ProductsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private settingsService: SettingsService,
    private translate: TranslateService)
  {
    dialogRef.disableClose = true;
    const currentLang = this.translate.currentLang;
    this.languagesList = languagesList_[currentLang]

  }

  ngOnInit():void{
    this.defaultLanguage = this.data.defaultLanguage

    this.productEditData = this.data.values
    this.productEditForm = this.fb.group({
      id:[],
      layout:[],
      productGroups:[],
      productTypes:[],
      productTags:[],
      title:[],
      subtitle:[],
      type:[],
      name:[],
      filter:[]
    })

    const {id,filter, productGroups, layout, productTypes, subtitle, productTags, title, type,name} = this.productEditData
    this.productEditForm.patchValue({
      id:id ? id : undefined,
      layout: layout ? layout : "vertical",
      productGroups: productGroups ? productGroups : undefined,
      productTypes: productTypes ? productTypes : undefined,
      productTags:productTags ? productTags : undefined,
      title:title ? title : undefined,
      subtitle:subtitle ? subtitle : undefined,
      type: type ? type : undefined,
      name: name ? name : undefined,
      filter: filter ? filter: undefined
    })

    this.productEditForm.valueChanges.subscribe(value =>{
    })

  }

  onCancelEdit(){
    this.dialogRef.close([this.productEditData, false])
  }

  onSaveEdit(){
    const data = this.productEditForm.value
    removeUndefinedValuesFromObject(data)
    this.dialogRef.close([data, true])
  }

  returnChangesFilters($event){
    this.productEditForm.patchValue({filter: $event.newFilters})
  }
}
