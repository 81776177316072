import { Injectable } from '@angular/core';
import { NetworkConstants } from 'src/network.constants';
import { collection, deleteDoc, doc, getFirestore, setDoc } from '@angular/fire/firestore';
import {Functions, getFunctions, httpsCallable} from "@angular/fire/functions";
import { CommentsService } from './comments.service';
import { TranslateService } from '@ngx-translate/core';
import { getApp } from '@angular/fire/app';


@Injectable({
  providedIn: 'root'
})
export class AdvancedExperiencesService {
  db = getFirestore()
  existingUrlPrefixes:any=[]
  existingCoverImages:any
  private readonly functions: Functions;
  constructor(
    // private functions: Functions,
    private commentService:CommentsService,
    private translate:TranslateService
    ) {
      const functions = getFunctions(getApp(), 'europe-west1');
      this.functions = functions;
     }

    deleteAdvancedExperience(advancedId):Promise<void>{
      return new Promise(async (resolve, reject) => {
        try {
          const docRef = doc(this.db, NetworkConstants.COLLECTION_ADVANCED_EXPERIENCES, advancedId)
          const d  = await deleteDoc( docRef)
          resolve(d)
        } catch (error) {
          reject(error)  
          console.log(error)      
        } 
      })
    }


    createAdvancedExperienceFirestore(data){
      return new Promise(async (resolve, reject) => {
        try{
          const docRef = doc( collection(this.db,NetworkConstants.COLLECTION_ADVANCED_EXPERIENCES) )
          const newId = docRef.id
          console.log(newId)

          data['creationDate'] = new Date()
          data['timeStamp'] = new Date()
         
          await setDoc( docRef, data)

          resolve(newId)
        }catch(error){
          console.log(error)
        }
      }) 
    }

    getActualUrlPrefixes(){
      this.commentService.progressSpin.emit(true)
      const createExperience = httpsCallable(this.functions, 'appStoreConnect-requestExperiences');
      createExperience( ).then((result) => {
          this.existingCoverImages = result.data['data']
          const data = result.data;
          const sanitizedMessage = data;

          result.data['data'].forEach(element => {
            this.existingUrlPrefixes.push(element.link)
          });

          this.commentService.progressSpin.emit(false)
        })
        .catch((error) => {
          this.commentService.progressSpin.emit(false)
          console.log(error)
          const message = this.translate.instant("SnackBarConstants.CREATE_FAILED")
          this.commentService.addSnackBar.emit(message)
          this.commentService.addSnackBar.emit(error)
        });
  }

}