<mat-dialog-content>
    <div class="py-2">
        <section class="my-2 rounded m-auto w-100" id="section">
            <form [formGroup]="schemaPropertyForm" class="m-1">
                <div class="input-group my-1">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>{{'content.type' | translate}} </mat-label>
                      <mat-select formControlName="type" class="input-group" required>
                        <mat-option *ngFor="let option of typesOptions; let index=index" value="{{option.type}}">
                            <mat-icon class="material-symbols-outlined" style="color:#646669;">{{option.mat_icon}}</mat-icon>
                            <div class="d-flex align-items-center">
                                <!-- <span class="material-symbols-outlined" style="color: var(--warmOrange);">
                                    {{option.icon}}
                                </span> -->
                                <span class="ps-2"> {{option.name}}</span>
                            </div>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
        
                <div class="input-group my-1" *ngIf="schemaPropertyForm.value.type=='array'">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>{{'content.listItemsType' | translate}} </mat-label>
                      <mat-select formControlName="listItemsType" class="input-group">
                        <mat-option *ngFor="let option of listTypesOptions; let index=index" value="{{option.type}}">
                            <mat-icon class="material-symbols-outlined" style="color:#646669;">{{option.mat_icon}}</mat-icon>
                            <div class="d-flex align-items-center">
                                <span class="ps-2"> {{option.name}}</span>
                            </div>
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="schemaPropertyForm.get('type').errors && schemaPropertyForm.get('type').errors['required']">{{'taskTemplates.mandatory' | translate}}</mat-error>
                    </mat-form-field>
                </div>
        
                <mat-form-field appearance="outline" class="w-100 my-1">
                    <mat-label>{{'content.key' | translate}}</mat-label>
                    <input formControlName="key" type="text" matInput placeholder="Key" required>
                    <mat-error *ngIf="schemaPropertyForm.get('key').errors && schemaPropertyForm.get('key').errors['required']">{{'taskTemplates.mandatory' | translate}}</mat-error>
                    <mat-error *ngIf="schemaPropertyForm.get('key').hasError('pattern')">{{'taskTemplates.valid-key' | translate}}</mat-error>
                </mat-form-field>
        
                <mat-form-field  appearance="outline" class="w-100">
                    <mat-label>{{'contentTypes.description' | translate}}</mat-label>
                    <input matInput formControlName="description"  placeholder="{{'contentTypes.description' | translate}}" type="text">
                </mat-form-field>
        
                <div class="p-3 form-control d-flex justify-content-between">
                    <label for="value">{{'contentTypes.mandatory' | translate}}</label>
                    <mat-slide-toggle class="example-margin" formControlName="mandatory"></mat-slide-toggle>  
                </div>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();">{{'cancel' | translate}}</button>
    <button mat-button 
            class="orange-button" 
            [disabled]="!schemaPropertyForm.valid"
            (click)="onSaveEdit();">{{'save' | translate}}</button>
</mat-dialog-actions>