
<section class="px-3">

    <!-- <div class="w-100"> -->
        <div *ngFor="let button of buttonsData.buttons" id ="{{button.id}}" class="d-flex my-3 w-100 justify-content-center align-items-center text-center"
                 style = "min-height: 30px;">
            <a *ngIf="button.url && !buttonUrls[button.id]" target="_blank" href="{{button.url}}" style="cursor:pointer;color:unset;text-decoration:none;font-size: 12px;">
                <span class="text-break">{{button.title}}</span>
            </a>

            <a *ngIf="button.url && buttonUrls[button.id]" target="_blank" href="{{buttonUrls[button.id]}}" style="cursor:pointer;color:unset;text-decoration:none;font-size: 12px;">
                <span class="text-break">{{button.title}}</span>
            </a>

            <span class="text-break" style="font-size: 12px;" *ngIf="!button.url">{{button.title}}</span>
        </div>
    <!-- </div> -->
</section>