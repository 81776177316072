import { Component, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../service/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { UploadService } from '../service/upload.service';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { NetworkConstants } from 'src/network.constants';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { deepCopy, getSizeBytes } from '../../../../backend/utils/object';
import { MatDialog } from '@angular/material/dialog';
import { MediaPreviewDialogComponent } from '../media-preview-dialog/media-preview-dialog.component';
import { isDateTime } from '../../../../backend/src/filter';
import { HttpClient } from '@angular/common/http';
import { ImageCompressionService } from '../service/image-compression.service';
import { FileHandle } from '../directive/drag-and-drop.directive';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit{
  storage:any=[]
  storageMedia:any={
    items:[],
    folder: []

  }
  storageItems:any={
    items:[],
    folder: []

  }

  storagePassDefinitions:any=[]
  storageWorkTasks:any=[]
  storagePasses:any=[]
  panelOpenState = false;
  routerUrl:any
  idToShow:any
  currentRoute:any
  folder:any = []
  folderRoute:any
  newImage:any
  // gridViewList = true
  // gridViewIcons = true
  gridView = true
  gridViewOption = 'item'

  displayedColumns: string[] = ['icon','name','type', 'last_update', 'size', 'actions'];
  listdata= new MatTableDataSource()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paginatorSize = this.settingsService.paginator$
  filteredFolder:any = []
  emptyMedia = false

  constructor(private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private uploadService: UploadService,
    private commentService: CommentsService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private http: HttpClient,
    private imageCompressionService: ImageCompressionService,

    ){  

      this.routerUrl=this.route.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
      this.currentRoute=this.routerUrl.value[0].path
    }

  ngOnInit():void{
    this.folderRoute=""
    this.storage = []
    this.storageItems.items = []
    this.storageMedia.items = []
    this.getDataStorage()
  }

  getDataStorage(){
    try{
      this.commentService.progressSpin.emit(true)
      // this.folderRoute='Contexts/' + this.settingsService.contextId$ + '/'
      // this.folder = this.storageService.getStorageContentOfContextId("")

      this.folderRoute= `/${NetworkConstants.STORE_MEDIA}/`
      // this.folder = this.storageService.getStorageMediaOfContextId(this.folderRoute)
      this.commentService.progressSpin.emit(true)
      this.storageService.getStorageMediaOfContextId(this.folderRoute).subscribe( value => {

        if(value.length ==  0)
          this.emptyMedia = true
        else
          this.emptyMedia = false
        this.folder = value
        this.filteredFolder = deepCopy(value)
        this.listdata = new MatTableDataSource<any>(value);

        console.log(value)
      this.commentService.progressSpin.emit(false)
        
      })

      console.log(this.folder)


      // this.storageService.getFilesMetadata( this.folderRoute).subscribe( value => console.log(value))
      this.commentService.progressSpin.emit(false)
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  backTo(){
    // this.router.navigate(['home',this.settingsService.contextId$,'content'])
  }


  shorTableAgain(){
    this.listdata.paginator = this.paginator;
    this.listdata.sort = this.sort;
    setTimeout(() => {
      this.listdata.filter = " ";
    this.commentService.progressSpin.emit(false)
      
    }, 500);
  }

  
  openFolder(folder:string){
    // switch(folder){
    //   case 'Items':{
    //     break;
    //   }

    //   case 'Media':{
    //     const route = 'home/'+ this.settingsService.contextId$ +'/content/media/Media'
    //     this.router.navigateByUrl( route, {state: this.storageMedia})
    //     break;
    //   }

    //   case 'WorkTasks':{
    //     break;
        
    //   }

    //   case 'PassDefinitions':{
    //     break;
    //   }

    //   case 'passes':{
    //     break;
    //   }
    // }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    console.log(filterValue.trim().toLocaleLowerCase())
    // if(this.gridView){
      if(!filterValue.trim().toLocaleLowerCase())
      this.filteredFolder =  deepCopy(this.folder)
      else
      this.filteredFolder = this.filteredFolder.filter( file => file.name.toLocaleLowerCase().includes(filterValue.trim().toLocaleLowerCase()))
    // }else{
      this.listdata.filter = filterValue.trim().toLowerCase();

      if (this.listdata.paginator) {
        this.listdata.paginator.firstPage();
      }
    // }
    
  }

  triggerFileInput() {
    document.getElementById('myfile-upload-media')?.click();
  }

  readURL(event:any){
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.compressFileAndUpload(file, this.folder.length)
    }
  }


  async uploadFile(file, index){
    try{
      this.commentService.progressSpin.emit(true)
    
      const a = await  this.uploadService.addToStorage(file,this.folderRoute, index)
      setTimeout(() =>{
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.UPLOAD_FILE_OK")
        this.commentService.addSnackBar.emit(message)
        this.getDataStorage()
      }, 800);

    }catch(error){
      console.log(error)
      const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  createFolderStorage(){
    const a = this.uploadService.createFolderStorage(this.folderRoute, 'newFolder')
    this.getDataStorage()
  }

  changeView( view){
    switch(view){
      case 'list': {
        this.gridView = false
        break;
      }

      case 'item': {
        this.gridView = true
        break;
      }
    }
  }

  checkFileType(file){
    let type 

    if(file.folder)
      type = 'folder'
    if(file.metadata){
      if(file.metadata?.contentType === 'application/pdf')
        type = 'file'

      if(file.metadata?.contentType.startsWith('image'))
        type = 'image'

        
      if(file.metadata?.contentType.startsWith('video') )
        type = 'video'
        
    }else{

    }

    return type
  }


  getFileSize(size){
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    return getSizeBytes(size,units ,2)
  }

  openFilePreview(file){
    if(file.url){
      let dialogRef = this.dialog.open(MediaPreviewDialogComponent, {
        data: {
          file: file,
          name:file.name,
          type:this.checkFileType(file),
          contextId: this.settingsService.contextId$
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          this.getDataStorage()
        }
      });
    }
    else{
      const message = this.translate.instant("SnackBarConstants.PREVIEW_NOT_POSSIBLE")
      this.commentService.addSnackBar.emit(message)
    }
  }

  downloadFile(file){
    this.commentService.progressSpin.emit(true)
    const date = new Date()    
    this.http.get(file.url, { responseType: 'blob' }).subscribe((response: Blob) => {
      const link = document.createElement('a');
      const blob = new Blob([response], { type: response.type });
      link.href = URL.createObjectURL(blob);
      link.download = `${ isDateTime(date)}_${file.name.replace(' ', '')}`;
      link.click();
      this.commentService.progressSpin.emit(false)

    });

  }

  deleteFile(file){

    this.storageService.deleteFile(file.reference).then( value => {
      this.getDataStorage()
      
    })
  }

  async filesDropped(files: FileHandle[]): Promise<void> {
    console.log(files)
    files.forEach( async (file, index) => {
      console.log(file)
      this.compressFileAndUpload(file.file, index)
    })
  }

  async compressFileAndUpload(file, index){
    const fileToUpload = file;
    console.log(fileToUpload)
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
    const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
  
    console.log(fileToUpload.size > maxAllowedSizeInBytes)
    if (fileToUpload.size > maxAllowedSizeInBytes) {// File is too large for both upload and compression
      const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    }
  
    console.log(fileToUpload.size > maxSizeInBytes)
    if (fileToUpload.size > maxSizeInBytes) {// File is between 2 MB and 20 MB, attempt compression
      try {
        const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
        if (!compressedImage) {
          const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }else{
          console.log(compressedImage)
          await this.uploadFile(compressedImage, index)
        }
      } catch (error) {
        console.error("Compression error:", error);
        const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
        this.commentService.addSnackBar.emit(message);
        return;
      }
    } else {// File is less than or equal to 2 MB, no compression needed
      await this.uploadFile(fileToUpload, index)

    }
  }

}