export const defaultRoles = {
    admin: {
        context:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        users:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        },
        interfaces:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        },
        contentTypes:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        passTemplates:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        },
        content:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        media:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        workTaskTemplates:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        workTask:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        products:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        tridys:{ //'RUD'
            R:true,
            U:true,
            D:true
        },
        reports:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        }
    },
    accountManager:{
        context:{ //'RU'
            R:true,
            U:true
        },
        users:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        },
        interfaces:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        },
        reports:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        },
    },
    contentManager:{
        contentTypes:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        passTemplates:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        content:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        media:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        products:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        }
    },
    workTaskManager: {
        workTaskTemplates:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        workTask:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        }
    },
    tracingSupervisor:{
        workTaskTemplates:{ //'R'
            R:true
        },
        workTask:{ //'CRU'
            C: true,
            R:true,
            U:true
        },
        tridys:{ //'R'
            R:true
        }
    },
    tracer:{
        workTaskTemplates:{ //'R'
            R:true
        },
        workTask:{ //'CRU'
            C: true,
            R:true,
            U:true
        },
        tridys:{ //'R'
            R:true
        }
    },
    betaWidgets: {
        context:{ //'CRUD'
            // C: true,
            // R:true,
            // U:true,
            // D:true
        },
        users:{ //'CRUD'
            // C:true,
            // R:true,
            // U:true,
            // D:true
        },
        interfaces:{ //'CRUD'
            // C:true,
            // R:true,
            // U:true,
            // D:true
        },
        contentTypes:{ //'CRUD'
            // C: true,
            // R:true,
            // U:true,
            // D:true
        },
        passTemplates:{ //'CRUD'
            // C:true,
            // R:true,
            // U:true,
            // D:true
        },
        content:{ //'CRUD'
            // C: true,
            // R:true,
            // U:true,
            // D:true
        },
        media:{ //'CRUD'
            // C: true,
            // R:true,
            // U:true,
            // D:true
        },
        workTaskTemplates:{ //'CRUD'
            // C: true,
            // R:true,
            // U:true,
            // D:true
        },
        workTask:{ //'CRUD'
            // C: true,
            // R:true,
            // U:true,
            // D:true
        },
        products:{ //'CRUD'
            // C: true,
            // R:true,
            // U:true,
            // D:true
        },
        tridys:{ //'RUD'
            // R:true,
            // U:true,
            // D:true
        },
        reports:{ //'CRUD'
            // C:true,
            // R:true,
            // U:true,
            // D:true
        }
    }

}

export const defaultRolesRights = {//....defaulrroles-settinsService
    admin: {
        context:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        users:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        },
        interfaces:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        },
        contentTypes:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        passTemplates:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        },
        content:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        media:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        workTaskTemplates:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        workTask:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        products:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        tridys:{ //'RUD'
            C: false,
            R:true,
            U:true,
            D:true
        },
        reports:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        }
    },
    accountManager:{
        context:{ //'RU'
            C: false,
            R:true,
            U:true,
            D:false
        },
        users:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        },
        interfaces:{ //'CRUD'
            C:true,
            R:true,
            U:true,
            D:true
        },
        contentTypes:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        passTemplates:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        content:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        media:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        workTaskTemplates:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        workTask:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        products:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        tridys:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        reports:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
    },
    contentManager:{
        context:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        users:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        interfaces:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        contentTypes:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        passTemplates:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        content:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        media:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        workTaskTemplates:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        workTask:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        products:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        tridys:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        reports:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
    },
    workTaskManager: {
        context:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        users:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        interfaces:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        contentTypes:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        passTemplates:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        content:{ //''
            C:false,
            R:false,
            U:false,
            D:false
        },
        media:{ //''
            C:false,
            R:false,
            U:false,
            D:false
        },
        workTaskTemplates:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        workTask:{ //'CRUD'
            C: true,
            R:true,
            U:true,
            D:true
        },
        products:{ //''
            C:false,
            R:false,
            U:false,
            D:false
        },
        tridys:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        reports:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
    },
    tracingSupervisor:{
        context:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        users:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        interfaces:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        contentTypes:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        passTemplates:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        content:{ //''
            C:false,
            R:false,
            U:false,
            D:false
        },
        media:{ //''
            C:false,
            R:false,
            U:false,
            D:false
        },
        workTaskTemplates:{ //'R'
            C:false,
            R:true,
            U:false,
            D:false
        },
        workTask:{ //'CRU'
            C: true,
            R:true,
            U:true,
            D:false
        },
        products:{ //''
            C:false,
            R:false,
            U:false,
            D:false
        },
        tridys:{ //'R'
            C:false,
            R:true,
            U:false,
            D:false
        },
        reports:{ //''
            C:false,
            R:false,
            U:false,
            D:false
        },
    },
    tracer:{
        context:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        users:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        interfaces:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        contentTypes:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        passTemplates:{ //''
            C: false,
            R:false,
            U:false,
            D:false
        },
        content:{ //''
            C:false,
            R:false,
            U:false,
            D:false
        },
        media:{ //''
            C:false,
            R:false,
            U:false,
            D:false
        },
        workTaskTemplates:{ //'R'
            C:false,
            R:true,
            U:false,
            D:false
        },
        workTask:{ //'CRU'
            C: true,
            R:true,
            U:true,
            D:false
        },
        products:{ //''
            C:false,
            R:false,
            U:false,
            D:false
        },
        tridys:{ //'R'
            C:false,
            R:true,
            U:false,
            D:false
        },
        reports:{ //''
            C:false,
            R:false,
            U:false,
            D:false
        },
    }

}

export const userRolesRights = {

    context:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    users:{ //'CRUD'
        C:true,
        R:true,
        U:true,
        D:true
    },
    interfaces:{ //'CRUD'
        C:true,
        R:true,
        U:true,
        D:true
    },
    contentTypes:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    passTemplates:{ //'CRUD'
        C:true,
        R:true,
        U:true,
        D:true
    },
    content:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    media:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    workTaskTemplates:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    workTask:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    products:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    tridys:{ //'RUD'
        C: false,
        R:true,
        U:true,
        D:true
    },
    reports:{ //'CRUD'
        C:true,
        R:true,
        U:true,
        D:true
    }
}

export const userRolesNoRights = {
    context:{ //'CRUD'
        C:false,
        R:false,
        U:false,
        D:false
    },
    users:{ //'CRUD'
        C:false,
        R:false,
        U:false,
        D:false
    },
    interfaces:{ //'CRUD'
        C:false,
        R:false,
        U:false,
        D:false
    },
    contentTypes:{ //'CRUD'
        C:false,
        R:false,
        U:false,
        D:false
    },
    passTemplates:{ //'CRUD'
        C:false,
        R:false,
        U:false,
        D:false
    },
    content:{ //'CRUD'
        C:false,
        R:false,
        U:false,
        D:false
    },
    media:{ //'CRUD'
        C:false,
        R:false,
        U:false,
        D:false
    },
    workTaskTemplates:{ //'CRUD'
        C:false,
        R:false,
        U:false,
        D:false
    },
    workTask:{ //'CRUD'
        C:false,
        R:false,
        U:false,
        D:false
    },
    products:{ //'CRUD'
        C:false,
        R:false,
        U:false,
        D:false
    },
    tridys:{ //'RUD'
        C:false,
        R:false,
        U:false,
        D:false
    },
    reports:{ //'CRUD'
        C:false,
        R:false,
        U:false,
        D:false
    }
}

export const userRolesRightsAll = {
    context:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    users:{ //'CRUD'
        C:true,
        R:true,
        U:true,
        D:true
    },
    interfaces:{ //'CRUD'
        C:true,
        R:true,
        U:true,
        D:true
    },
    contentTypes:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    passTemplates:{ //'CRUD'
        C:true,
        R:true,
        U:true,
        D:true
    },
    content:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    media:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    workTaskTemplates:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    workTask:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    products:{ //'CRUD'
        C: true,
        R:true,
        U:true,
        D:true
    },
    tridys:{ //'RUD'
        C: false,
        R:true,
        U:true,
        D:true
    },
    reports:{ //'CRUD'
        C:true,
        R:true,
        U:true,
        D:true
    }
}

// export const userRolesRightsAll = {

//     contentTypes:{ //'CRUD'
//         C: true,
//         R:true,
//         U:true,
//         D:true
//     },
//     content:{ //'CRUD'
//         C: true,
//         R:true,
//         U:true,
//         D:true
//     },
//     media:{ //'CRUD'
//         C: true,
//         R:true,
//         U:true,
//         D:true
//     },
//     workTaskTemplates:{ //'CRUD'
//         C: true,
//         R:true,
//         U:true,
//         D:true
//     },
//     workTask:{ //'CRUD'
//         C: true,
//         R:true,
//         U:true,
//         D:true
//     },
//     products:{ //'CRUD'
//         C: true,
//         R:true,
//         U:true,
//         D:true
//     },
//     tridys:{ //'CRUD'
//         C: true,
//         R:true,
//         U:true,
//         D:true
//     },
//     users:{ //'CRUD'
//         C:true,
//         R:true,
//         U:true,
//         D:true
//     },
//     customers:{ //'CRUD'
//         C: true,
//         R:true,
//         U:true,
//         D:true
//     },
// }


// export const defaultRoles = {
//     admin: {
//         contentTypes:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         content:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         media:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         workTaskTemplates:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         workTask:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         products:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         tridys:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         users:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         customers:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//     },
//     contentManager:{
//         contentTypes:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         content:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         media:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         products:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },

//     },
//     workTaskManager: {
       
//         workTaskTemplates:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         workTask:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//     },
//     logger:{
//         workTaskTemplates:{ //'R'
//             R:true
//         },
//         workTask:{ //'CRU'
//             C: true,
//             R:true,
//             U:true
//         },
//         tridys:{ //'R'
//             R:true
//         }
//     }

// }

// export const defaultRolesRights = {
//     admin: {
//         contentTypes:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         content:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         media:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         workTaskTemplates:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         workTask:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         products:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         tridys:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         users:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         customers:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//     },
//     contentManager:{
//         contentTypes:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         content:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         media:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         workTaskTemplates:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         workTask:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         products:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         tridys:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         users:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         customers:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         }
//     },
//     workTaskManager: {
//         contentTypes:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         content:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         media:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         workTaskTemplates:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         workTask:{ //'CRUD'
//             C: true,
//             R:true,
//             U:true,
//             D:true
//         },
//         products:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         tridys:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         users:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         customers:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         }
//     },
//     logger:{
//         contentTypes:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         content:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         media:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         workTaskTemplates:{ //'R'
//             C:false,
//             R:true,
//             U:false,
//             D:false
//         },
//         workTask:{ //'CRU'
//             C: true,
//             R:true,
//             U:true,
//             D:false
//         },
//         products:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         tridys:{ //'R'
//             C: false,
//             R:true,
//             U:false,
//             D:false
//         },
//         users:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//         customers:{ //''
//             C: false,
//             R:false,
//             U:false,
//             D:false
//         },
//     }

// }