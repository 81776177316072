import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/app/service/comments.service';
import { UploadService } from 'src/app/service/upload.service';

@Component({
  selector: 'app-widget-edit-filters-edit-dialog',
  templateUrl: './widget-edit-filters-edit-dialog.component.html',
  styleUrls: ['./widget-edit-filters-edit-dialog.component.css']
})
export class WidgetEditFiltersEditDialogComponent {
  filterForm:FormGroup
  filterOperatorTypes = [
    {
      type:'==',
      translate: "pass.widgets.filter.operators.equal",
      name:'equal'
    },
    {
      type:'!=',
      translate: "pass.widgets.filter.operators.not_equal",
      name:"not_equal"
    },
    {
      type:'<',
      translate: "pass.widgets.filter.operators.less_than",
      name:"less_than"
    },
    {
      type:'>',
      translate: "pass.widgets.filter.operators.more_than",
      name:"more_than"
    },
    {
      type:'<=',
      translate: "pass.widgets.filter.operators.at_least",
      name:"at_least"
    },
    {
      type:'>=',
      translate: "pass.widgets.filter.operators.at_most",
      name:"at_most"
    }
  ]
  constructor(public dialogRef: MatDialogRef<WidgetEditFiltersEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private uploadService: UploadService,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private translate:TranslateService){
      dialogRef.disableClose = true;
  }

  ngOnInit():void {
    console.log(this.data)

    this.filterForm = this.fb.group({
      field:[,[ Validators.required]],
      operator:[,[ Validators.required]],
      value:[,[ Validators.required]]
    })

    if(this.data.type == 'edit'){
      const { field, operator, value } = this.data.values

      this.filterForm.patchValue({
        field:field,
        operator:operator,
        value:value
      })
    }
  }

  onSave(){
    this.dialogRef.close([true, this.filterForm.value])
  }
  onCancel(){
    this.dialogRef.close([false])

  }


}
