import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/app/service/comments.service';
import { ImageCompressionService } from 'src/app/service/image-compression.service';
import { deepCopy } from '../../../../../../backend/utils/object';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UploadService } from 'src/app/service/upload.service';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { environment } from 'src/environments/environment';
import { Template } from '../../../../../../backend/src/template.dto';
import { LanguageEditorDialogComponent } from 'src/app/language-editor-dialog/language-editor-dialog.component';
import { SettingsService } from 'src/app/service/settings.service';
import { LocalStorageService } from 'src/app/service/local-storage-service.service';
import { languagesList, languagesList_ } from '../../../../../../backend/src/languagesList.dto';
import { validColorValidator } from 'ngx-colors';
import { CallToActionEditComponent } from '../call-to-action-edit/call-to-action-edit.component';
import { DragFileBrowseData } from 'src/app/shared/media';

@Component({
  selector: 'app-product-registration-edit',
  templateUrl: './product-registration-edit.component.html',
  styleUrls: ['./product-registration-edit.component.css']
})
export class ProductRegistrationEditComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  registerProductDataEdit:any
  oldData:Template
  registerProductForm: FormGroup;
  newImage:any
  widgetNumber:any
  url:any
  urlData:any
  imageUrl:string
  changes: boolean = false;
  noUrl: boolean = true;
  cardImg: boolean = false;
  passId:any
  invalid_backgroundColor=false
  invalid_foregroundColor=false
  invalid_borderColor=false
  colorPalette: any[] = [];
  files: FileHandle[] = [];
  drop=false
  urlRegex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  addUrl = false
  languagesList = languagesList
  defaultLanguage:string
  dynamicUrl = false
  dragFileData: DragFileBrowseData 

  constructor(
    public dialogRef: MatDialogRef<ProductRegistrationEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private imageCompressionService: ImageCompressionService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private settingsService: SettingsService,
    private uploadService: UploadService
    
  ) {
    dialogRef.disableClose = true;
    this.registerProductForm = this.fb.group({
      imageUrl: ['']
    });
    this.imageUrl = './../../assets/img/default.jpg';
    const currentLang = this.translate.currentLang;
    this.languagesList = languagesList_[currentLang]
  }
  ngOnInit(): void {
    this.defaultLanguage = this.data.defaultLanguage

    this.registerProductDataEdit = this.data.values
    this.oldData= new Template
    this.oldData.setFromAny(this.data)
    this.passId = this.data['passId']
    if(this.data['url']){
      this.urlData = deepCopy(this.data['url'])
    }else{
      if(this.registerProductDataEdit.image && this.registerProductDataEdit.image.url){        
        this.supportUrl(this.registerProductDataEdit.image.url)
      }
    }

 
    if(this.registerProductDataEdit.image.url){
      this.cardImg = true
    } 
    this.widgetNumber= this.data['widget']

    // Generate the key for localStorage
    const registeredViewKey = `registeredView_${this.passId}_${this.registerProductDataEdit.id}`;
    const storedRegisteredView = this.localStorageService.get(registeredViewKey);


    this.registerProductForm = this.fb.group({
      id:[],
      type:[],
      name:[],
      registeredView: [storedRegisteredView],
      registeredShowImage:[],
      registeredText:[],
      registeredTextAlign:[],
      unregisteredShowImage:[],
      unregisteredText:[],
      imageUrl:[],
      imageDescription:[],
      imageSize:[],
      backgroundColor:[, [validColorValidator()]],
      foregroundColor:[, [validColorValidator()]],
      borderColor:[, [validColorValidator()]],
      borderWidth:[],
      filter:[]
    })

    const {id, filter,type, name, registeredView, registered, unregistered, image , style } = this.registerProductDataEdit;

    this.registerProductForm.patchValue({
      id: id ? id : undefined,
      name: name ? name : undefined,
      type: type ? type :undefined,
      registeredView: registeredView ? registeredView : storedRegisteredView,
      registeredShowImage: registered?.showImage ? registered?.showImage : undefined,
      registeredText: registered?.text ? registered?.text : undefined,
      registeredTextAlign: registered?.textAlign ? registered?.textAlign : undefined,
      unregisteredShowImage: unregistered?.showImage ? unregistered?.showImage : undefined,
      unregisteredText: unregistered?.text ? unregistered?.text : undefined,
      imageUrl: image?.url ? image?.url : undefined,
      imageDescription: image?.description ? image?.description : undefined,
      imageSize: image?.size ? image?.size : undefined,
      backgroundColor: style?.backgroundColor ? style?.backgroundColor : undefined,
      foregroundColor: style?.foregroundColor ? style?.foregroundColor : undefined,
      borderColor: style?.borderColor ? style?.borderColor : undefined,
      borderWidth: style?.borderWidth ? style?.borderWidth : undefined,
      filter: filter ? filter : undefined
    })

    this.registerProductForm.get('imageUrl').valueChanges.subscribe( value => {
      if(value?.match(/\${(.+)\}/))
        this.dynamicUrl = true
      else
        this.dynamicUrl = false
    })


    this.initializePaletteColors();

    this.registerProductForm.valueChanges.subscribe( value => {
      this.checkInvalidColors()
    })
    this.checkInvalidColors()

    this.dragFileData = {
      calledFrom: CallToActionEditComponent,
      calledFromData:{
        passId: this.passId,
        widgetNumber:this.widgetNumber,
        widget: "callToAction",
      },
      enableDragDrop: true,
      enableBrowse:true,
      enableMedia:true,
      enableEnterUrl:true,
      enableMultipleDragDrop:false,
      supportedFileTypes:['image/jpeg', 'image/png', 'image/webp'],
      supportedFileAccept:".jpg, .jpeg, .png, .webp",
      maxSizeInBytes: 2 * 1024 * 1024, // 2 MB size limit for compression,
      maxAllowedSizeInBytes:20 * 1024 * 1024, // 20 MB size limit for direct upload,
      returnSupportUrl: undefined,
      returnUrlData: undefined,
      enterUrl:false,
      returnUrlType:undefined
    }

  }

  checkInvalidColors(){
    if(this.registerProductForm.controls['backgroundColor'].hasError('invalidColor')){
      this.invalid_backgroundColor=true
    }else{
      this.invalid_backgroundColor=false
    }
    if(this.registerProductForm.controls['foregroundColor'].hasError('invalidColor')){
      this.invalid_foregroundColor=true
    }else{
      this.invalid_foregroundColor=false
    }
    if(this.registerProductForm.controls['borderColor'].hasError('invalidColor')){
      this.invalid_borderColor=true
    }else{
      this.invalid_borderColor=false
    }
  }

  private initializePaletteColors(): void {
    const colors = [];
    const warmOrange = getComputedStyle(document.documentElement).getPropertyValue('--warmOrange');
    const darkBlue = getComputedStyle(document.documentElement).getPropertyValue('--darkBlue');
    const pureWhite = getComputedStyle(document.documentElement).getPropertyValue('--pureWhite');
    const neutral = getComputedStyle(document.documentElement).getPropertyValue('--neutral');
    colors.push(warmOrange, darkBlue, pureWhite, neutral);
    this.colorPalette = colors;
  }


  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  onImageError() {
    this.imageUrl = './../../assets/img/default.jpg';
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
      const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload

      // Check for supported file types
      const supportedTypes = ['image/jpeg', 'image/png', 'image/webp'];
      if (!supportedTypes.includes(file.type)) {
        // Unsupported file type
        const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_IMG_TYPE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      if (file.size > maxAllowedSizeInBytes) {
        // File is too large for both upload and compression
        const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      if (file.size > maxSizeInBytes) {
        // File is between 2 MB and 20 MB, attempt compression
        this.imageCompressionService.compressImage(file, maxSizeInBytes).then(compressedImage => {
          if (!compressedImage) {
            // Compression failed
            const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          this.newImage = compressedImage;
          this.processFile(compressedImage);
        }).catch(error => {
          // Error during compression
          console.error("Compression error:", error);
          const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
          this.commentService.addSnackBar.emit(message);
        });
      } else {
        // File is less than or equal to 2 MB, no compression needed
        this.newImage = file;
        this.processFile(file);
      }
    }
  }  

  private async processFile(file: File): Promise<void> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
  
    reader.onload = async (event) => { 
      if(event.target) {
        const urlNew = event.target.result as string;
        if(urlNew) {
          this.url = urlNew;
          this.changes = true;
          this.urlData = "";
          this.registerProductForm.patchValue({
            imageUrl: this.url,
            imageDescription: ""
          });
          
          try {
            const uploadURL = await this.uploadService.uploadNewProductRegistrationImage(this.passId, this.newImage, this.widgetNumber);
  
            if (uploadURL) {
              this.url = uploadURL;
              this.urlData = urlNew;
              this.registerProductForm.patchValue({
                imageUrl: this.url
              });
              this.imageUrl = this.url;
              this.cardImg = true;
            }
          } catch (error) {
            console.log(error);
            const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
            this.commentService.addSnackBar.emit(message);
          }
        }
      }
    }
  }
  async filesDropped(files: FileHandle[]): Promise<void> {
    this.files = files;
    const fileToUpload = files[0].file;
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
    const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
  
    if (fileToUpload.size > maxAllowedSizeInBytes) {
      // File is too large for both upload and compression
      const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    }
  
    if (fileToUpload.size > maxSizeInBytes) {
      // File is between 2 MB and 20 MB, attempt compression
      try {
        const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
        if (!compressedImage) {
          const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }
        this.newImage = compressedImage;
      } catch (error) {
        console.error("Compression error:", error);
        const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
        this.commentService.addSnackBar.emit(message);
        return;
      }
    } else {
      // File is less than or equal to 2 MB, no compression needed
      this.newImage = fileToUpload;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.newImage);
  
    reader.onload = async (event) => {
      if (event.target) {
        const urlNew = event.target.result as string;
        if (urlNew) {
          this.url = urlNew;
          this.changes = true;
          this.urlData = "";
  
          this.registerProductForm.patchValue({
            imageUrl: this.url,
            imageDescription: ""
          });
  
          try {
            const uploadURL = await this.uploadService.uploadNewProductRegistrationImage(this.passId, this.newImage, this.widgetNumber);
  
            if (uploadURL) {
              this.url = uploadURL;
              this.urlData = urlNew;
              this.registerProductForm.patchValue({
                imageUrl: this.url
              });
              this.imageUrl = this.url;
              this.cardImg = true;
            }
          } catch (error) {
            console.log(error);
            const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
            this.commentService.addSnackBar.emit(message);
          }
        }
      }
    };
  }

  onCancelEdit(){
    this.dialogRef.close([this.oldData, false])
  }

  supportUrl(url){
    this.urlData = url
    if(url){
      try{
        const imgUrl = new URL(url)
        let hostname = environment.urlHostName

        if(imgUrl.protocol=="pass:" ){
          const parts = url.match(/pass:\/\/(.+)\/(.+)/)
          if(parts){
            const passTemplateID = parts[1]
            const path = parts[2]
            const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            this.urlData=firebaseUrl
          }else{
            const newparts = url.match(/pass:\/\/(.+)/)
            if(newparts){
              let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
              newparts.forEach( (part,index)=> {
                if(index>0){
                  firebaseUrl += '/' + part 
                }
              })
              this.urlData=firebaseUrl
            }
          }
        }

        if(imgUrl.protocol =="tridy:"){
          const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`
          this.urlData=firebaseUrl
        }

        if(imgUrl.protocol == "context:"){
          const parts = url.match(/context:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
              if(index>0 && index<parts.length){
                  path += "/" + parte
              }
          })
          const firebaseUrl = `https://${hostname}/v1/context/${this.settingsService.contextId$}${path}`
          this.urlData=firebaseUrl
        }  
      }catch(error){}
    }
  }

  deleteImg(x: any) {
    this.url = "";
    this.registerProductForm.patchValue({
      imageUrl: this.url,
      imageDescription: "",
      imageSize:undefined
    });
    this.urlData = "";
    this.cardImg = false;
  }  

  async onSaveEdit() {
    this.commentService.progressSpin.emit(true);
   
    if (this.registerProductForm.value.imageUrl) {
      const newUrl = this.url;
  
      if (newUrl) {
        this.registerProductForm.patchValue({ imageUrl: newUrl });
      }
    }

    if(this.registerProductForm.value.imageUrl && !this.dynamicUrl){
      if(this.registerProductForm.value.imageSize?.length == 0 || (this.registerProductForm.value.imageSize && this.registerProductForm.value.imageSize[0] == 0 && this.registerProductForm.value.imageSize[1] == 0) || !this.registerProductForm.value.imageSize ){
        await this.uploadService.calculateImgFileSize(this.urlData).then(value => {
          this.registerProductForm.patchValue({imageSize: value})
        })
      }
    }
    
    // Transform the flat form values to nested structure
    const transformedData = this.transformToNestedStructure(this.registerProductForm.value);

    // Generate a unique key for registeredView 
    const registeredViewKey = `registeredView_${this.passId}_${this.registerProductForm.value.id}`;
    const registeredView = this.registerProductForm.value.registeredView;
    // Save local storage
    if (registeredView !== undefined) {
      this.localStorageService.set(registeredViewKey, registeredView);
    }

    if(this.registerProductForm.value.registeredShowImage || this.registerProductForm.value.unregisteredShowImage){
      if(this.registerProductForm.value.imageUrl ) {
        this.commentService.progressSpin.emit(false);
        this.dialogRef.close([transformedData, true, this.newImage]);
      }else{
        const message = this.translate.instant("SnackBarConstants.COVER_IMAGE_NEEDED")
        this.commentService.addSnackBar.emit(message)
      }
    }else{
      this.commentService.progressSpin.emit(false);
      this.dialogRef.close([transformedData, true, this.newImage]);
    }
  }
  
  // Transform flat form values to nested structure
  private transformToNestedStructure(formValues: any): any {
    return {
      id: formValues.id,
      type: formValues.type,
      name: formValues.name,
      filter:formValues.filter,
      //registeredView: formValues.registeredView,
      registered: {
        text: formValues.registeredText,
        showImage: formValues.registeredShowImage,
        textAlign: formValues.registeredTextAlign
      },
      unregistered: {
        text: formValues.unregisteredText,
        showImage: formValues.unregisteredShowImage
      },
      image: {
        url: formValues.imageUrl,
        description: formValues.imageDescription,
        size: formValues.imageSize
      },
      style: {
        backgroundColor: formValues.backgroundColor,
        foregroundColor: formValues.foregroundColor,
        borderColor: formValues.borderColor,
        borderWidth: formValues.borderWidth
      }
    };
  }
      
  checkNewUrl(){
    this.url = this.registerProductForm.value.imageUrl
    this.supportUrl(this.registerProductForm.value.imageUrl)
  }

  typeURL(){
    this.addUrl = true
    this.urlData = './../../assets/img/default.jpg';
  }


  returnChangesFilters($event){
    this.registerProductForm.patchValue({filter: $event.newFilters})
  }

  returnChangesDragFileBrowseUI($event){
    if($event){
      const {calledFrom, calledFromData, enableDragDrop, enableBrowse, enableMedia,enableEnterUrl, enableMultipleDragDrop, 
        supportedFileTypes,supportedFileAccept, maxSizeInBytes,maxAllowedSizeInBytes, 
        returnSupportUrl,returnUrlData, enterUrl} = $event

        this.dragFileData = $event
      if(enterUrl){
        this.addUrl = true
        this.urlData = './../../assets/img/default.jpg'
      }else{
        this.addUrl = false
        this.urlData = undefined
      }

      if(returnUrlData)
      this.urlData = returnUrlData

      if(returnSupportUrl){
        this.imageUrl = returnSupportUrl
        this.url = returnSupportUrl
        this.cardImg = true;
        this.registerProductForm.patchValue({ imageUrl: returnSupportUrl })
      }

    }
  }
}
