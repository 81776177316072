import { Component, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportService } from '../service/report.service';
import { deepCopy, removeUndefined } from '../../../../backend/utils/object';
import { ReportFilterService } from '../service/report-filter.service';
import { Filter , FilterEqual, filterObjects} from '../../../../backend/src/filter';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-reports-item-tridys-list-filters',
  templateUrl: './reports-item-tridys-list-filters.component.html',
  styleUrls: ['./reports-item-tridys-list-filters.component.css']
})
export class ReportsItemTridysListFiltersComponent {
  @ViewChildren(MatMenuTrigger) menuTriggers: QueryList<MatMenuTrigger>;
  reportId:any
  routerUrl:any
  idToShow:string
  reportsList:any
  reportData:any
  reportDisplayName:any
  tridysList:any
  contentTypeId:any
  contentTypeName:any
  displayName:any
  displayedColumns: string[] = [ 'timeStamp', "creationDate"];
  breakpoint: number;
  paginatorSize = this.settingService.paginator$
  searchText = ''; 
  reportsDataList:any
  showFiller = false;
  filterForm!:FormGroup
  reportTemplate:any
  panelOpenState = false;
  lastRouteValue:any
  resultFields:any = []
  filtersValues:any = []

  allFiltersWithValues$ = new BehaviorSubject<Filter[]>([])

  filterTypeInput= {
    'string': 'text',
    'number':'number',
    'decimal':'decimal',
    'boolean':'boolean',
    'date':'date',
    'time':'time',
    'dateTime':'datetime-local',
    'media':'text'
  }

  operatorType = {
    '=':'filters.operators.equal',
    '!=':'filters.operators.not_equal',
    '>':'filters.operators.greather_than',
    '<':'filters.operators.less_than',
    '>=':'filters.operators.greather_than_equal_to',
    '<=':'filters.operators.less_than_equal_to',
    'in':'filters.operators.in',
    '!in':'filters.operators.not_in',
    'contains':'filters.operators.contains',
    '!contains':'filters.operators.not_contains',
    'startsWith':'filters.operators.startsWith',
    '!startsWith': 'filters.operators.not_startsWith'
  }
  
  constructor(private router: Router,
    private route: ActivatedRoute,
    private settingService: SettingsService,
    private commentService: CommentsService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private reportService:ReportService,
    private reportFilterService:ReportFilterService,
    private translate:TranslateService
    ){
    this.routerUrl=this.route.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    this.reportId = this.routerUrl.value[3].path
    this.lastRouteValue=this.routerUrl.value[this.routerUrl.value.length-2].path  
  }

  ngOnInit(){
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 6;
    const last = this.reportService.getReportIdLastOpened()//last==reportId
    this.reportTemplate = this.reportService.getTemplate(last)//this.idToShow

    this.reportService.lastReportTemplate$.subscribe( value => {
      this.reportTemplate = value

      this.initializeFilterForm()

      if(this.reportTemplate['resultFields']){
        if(this.reportTemplate.length!=0){
          this.displayedColumns = [ ]
        }
        this.reportTemplate['resultFields'].forEach( field => {
          this.resultFields.push(field)
          this.displayedColumns.push(field.key)
        })

      }else{

        this.displayedColumns = [ ]
        if(this.reportTemplate[last]){
          this.reportsList = this.reportsList[last]
          if(this.reportTemplate[last]['resultFields']){
            this.reportTemplate[last]['resultFields'].forEach( field => {
              this.resultFields.push(field)
              this.displayedColumns.push(field.key)
            })
          }
        }
      }
    })

    this.reportService.lastReportResults$.subscribe(value=> {
      this.reportsList = value
    })

    this.reportFilterService.selectedFilterValue$.subscribe (value => {
      this.allFiltersWithValues$.next(value)
    })

    if(this.lastRouteValue=="filter"){
      // this.filtersValues = this.reportService.getFilterValues()
    }else{
      if(this.reportTemplate.filters){
        this.filtersValues = this.reportTemplate.filters
      }
    }

    this.filterForm= this.fb.group({})
    this.loadReportTemplate()

    this.reportsDataList = this.settingService.allReports$
    if(this.reportsDataList){
      this.reportsDataList.forEach(element => {
        if(element.id == this.reportId){
          this.reportData = element
          this.reportDisplayName = element.displayName
          this.contentTypeId = element.contentTypeId
          this.displayName = element.displayName
          // this.getTridysList()
          // this.getTridysContentTypeName()
        }
      });
    }

  }

  get availableFilters() {
    this.reportTemplate = this.reportService.lastReportTemplateCahnges$.value
    this.filtersValues = this.reportTemplate.filters
    return this.reportService.lastReportTemplateCahnges$.value
  }

  initializeFilterForm(){
    if(this.filterForm.value.length!=0){
      this.clearFilters()
    }
    //initialize the form of filter to be able to put values for each
    if(this.reportTemplate){
      if(this.reportTemplate.filters){
        const filters = deepCopy(this.reportTemplate.filters)

        filters.forEach(filter => {
          if(filter.key){
            if(this.filtersValues && this.filtersValues[filter.key]){
              if(filter.operator =='inrange' ){
                this.filterForm.addControl(filter.key+'_range1',new FormControl(this.filtersValues[filter.key]))
                this.filterForm.addControl(filter.key+'_range1',new FormControl(this.filtersValues[filter.key]))
              }else{
              this.filterForm.addControl(filter.key,new FormControl(this.filtersValues[filter.key]))
              }
            }else{
              if(filter.type=='boolean')
              this.filterForm.addControl(filter.key,new FormControl(false))
              else{
                if(filter.operator =='inrange' ){
                  let range1 = filter.key+'_range1'
                  let range2 = filter.key+'_range2'
                  this.filterForm.addControl(range1,new FormControl({value:undefined,disabled: false}))
                  this.filterForm.addControl(range2,new FormControl())
                }else{
                  let key = filter.key
                  this.filterForm.addControl(key,new FormControl({value:undefined, disabled:false}))
                }
              }
            }
            const name = filter.key + '_operator'
            this.filterForm.addControl(name,new FormControl(filter.operator))
            const nametype = filter.key + '_type'
            this.filterForm.addControl(nametype,new FormControl(filter.type))
          }
        });
        //update filters on the service
        this.reportFilterService.initializeFilters( filters)
      }
    }
  }

  closeOtherMenus(currentTrigger: MatMenuTrigger) {
    this.menuTriggers.forEach(trigger => {
      if (trigger !== currentTrigger) {
        trigger.closeMenu();
      }
    });
  }

  createNewFilters(){
    const firstFilters = deepCopy(this.reportTemplate.filters)

    let newFiltersTemplate = []
    firstFilters.forEach((filter, index) => {
      const key = filter.key ? filter.key : undefined
      const description = filter.description ? filter.description : undefined
      const name = filter.name ? filter.name : undefined
      const operator = this.filterForm.value[filter.key+'_operator'] ? this.filterForm.value[filter.key+'_operator'] : undefined
      const type = this.filterForm.value[filter.key+'_type'] ?  this.filterForm.value[filter.key+'_type'] :  undefined
      const value = this.filterForm.value[filter.key] ?  this.filterForm.value[filter.key] :  undefined
      const newFilter = {
        key, 
        value
      }

      removeUndefined(newFilter)
      if(newFilter.value)
        newFiltersTemplate.push(newFilter)
    })

    const data = { 
      contentTypeId: this.reportTemplate.contentTypeId,
      displayName:this.reportTemplate.displayName,
      id: this.reportId,
      filters: newFiltersTemplate
     }

    // try{
    //   this.commentService.progressSpin.emit(true)
    //   const response = this.reportService.reports_createReport(this.settingService.contextId$, data, 'applyFilter')
    //   setTimeout(() => {
    //     this.reportsList = this.reportService.getReports(this.reportId)
    //     this.dataSource = new MatTableDataSource<any>(this.reportsList);
    //     this.updateTable()
    //     this.reportTemplate = this.reportService.getTemplate(this.reportId)
    //     if(this.lastRouteValue=="filter"){
    //       this.filtersValues = this.reportService.getFilterValues()
    //     }
    //   }, 500);
    // }catch(error){
    //   console.log(error)
    //   this.commentService.progressSpin.emit(false)
    // }
  }


  filterTable(){
    const filterBy = this.getAllFiltersValues()
  }

  getAllFiltersValues(){
    const firstFilters = deepCopy(this.reportTemplate.filters)

    let newFiltersTemplate = []
    firstFilters.forEach((filter, index) => {
      const key = filter.key ? filter.key : undefined
      const description = filter.description ? filter.description : undefined
      const name = filter.name ? filter.name : undefined
      const displayName = filter.displayName ? filter.displayName : undefined
      const operator = this.filterForm.value[filter.key+'_operator'] ? this.filterForm.value[filter.key+'_operator'] : undefined
      const type = this.filterForm.value[filter.key+'_type'] ?  this.filterForm.value[filter.key+'_type'] :  undefined
      const value = this.filterForm.value[filter.key] ?  this.filterForm.value[filter.key] :  undefined
      const newFilter = {
        key, 
        value
      }

      removeUndefined(newFilter)
      if(newFilter.value)
        newFiltersTemplate.push(newFilter)
    })

    return newFiltersTemplate
  }

  loadReportTemplate(){
    const last = this.reportService.getReportIdLastOpened()//last==reportId

    ////////////////////////this is the same as initializeFilterForm()
    // if(this.reportTemplate){
    //   if(this.reportTemplate.filters){
    //     const filters = deepCopy(this.reportTemplate.filters)
    //     this.reportFilterService.initializeFilters( filters)
    //     filters.forEach(filter => {
    //       if(filter.key){
    //         if(this.filtersValues && this.filtersValues[filter.key]){
    //           this.filterForm.addControl(filter.key,new FormControl(this.filtersValues[filter.key]))
    //         }else{
    //           this.filterForm.addControl(filter.key,new FormControl(undefined))
    //         }
    //         const name = filter.key + '_operator'
    //         this.filterForm.addControl(name,new FormControl(filter.operator))
    //         const nametype = filter.key + '_type'
    //         this.filterForm.addControl(nametype,new FormControl(filter.type))
    //         // this.reportFilterService.selectedFilterValue$()
    //       }
    //     });
    //   }
    // }
    ////////////////////////
      this.initializeFilterForm()

    if(this.reportTemplate['resultFields']){
      if(this.reportTemplate.length!=0){
        this.displayedColumns = [ ]
      }
      this.reportTemplate['resultFields'].forEach( field => {
        this.resultFields.push(field)
        this.displayedColumns.push(field.key)
      })

    }else{

      this.displayedColumns = [ ]
      if(this.reportTemplate[last]){
        this.reportsList = this.reportsList[last]
        if(this.reportTemplate[last]['resultFields']){
          this.reportTemplate[last]['resultFields'].forEach( field => {
            this.resultFields.push(field)
            this.displayedColumns.push(field.key)
          })
        }
      }
    }
  }

////////////////////////////////

  clearFilters(){
    const filters = deepCopy(this.reportTemplate.filters)

      filters.forEach(filter => {
        if(filter.key){
          if(this.filterForm.value[filter.key])
          this.clearFilterValue(filter.key, filter)

          if(filter.type=='boolean')
          this.filterForm.setControl(filter.key,new FormControl(false))
          else
          this.filterForm.setControl(filter.key,new FormControl(undefined))
        }
      });

      // this.createNewFilters()
  }

  filterValueExists(key, filter){
    return this.allFiltersWithValues$.value.find( filter => filter.filterKey == key)

    // return this.reportFilterService.reportFiltersValues$.observed.valueOf..find( filt => filt.filterKey == key)
    if(this.allFiltersWithValues$.value.find( filter => filter.filterKey == key))
      return true
    return false

  }


  clearFilterValue(key, filter){
    if(this.filterForm.value[key]!=undefined){
      this.filterForm.patchValue({[key]: undefined})
      if(this.allFiltersWithValues$.value && this.allFiltersWithValues$.value.length!=0 && this.allFiltersWithValues$.value.find( filter => filter.filterKey == key))
      this.reportFilterService.clearFilter( filter, key, this.filterForm.value[key])
    }

    if(filter.operator == 'inrange'){
      if(this.filterForm.value[key+'_range1']!=undefined || this.filterForm.value[key+'_range2']!=undefined){
        this.filterForm.patchValue({[key+'_range1']: undefined, [key+'_range2']: undefined})
        if(this.allFiltersWithValues$.value && this.allFiltersWithValues$.value.length!=0 && this.allFiltersWithValues$.value.find( filter => filter.filterKey == key))
        this.reportFilterService.clearFilter( filter, key, this.filterForm.value[key])
      }
    }

  }

  activeFilterValue(key, filter){
    let value = this.filterForm.value[key]
    if(filter.type =='date')
      value = new Date(this.filterForm.value[key])

    if(filter.operator == 'inrange')
      value = this.filterForm.value[key+'_range1'] + '/' +this.filterForm.value[key+'_range2']
    this.reportFilterService.activeFilter( filter, key, value)
  }

  resetResultsReportOpen(){
    this.clearFilters()
    const element = deepCopy(this.reportTemplate)

    if(element.contentTypeId && element.resultFields && element.resultFields.length!=0){
      let firstFilters
      
      if(element.filters)
      firstFilters=deepCopy(element.filters)
      else
      firstFilters=[]
    
      let newFiltersTemplate = []
      firstFilters.forEach((filter, index) => {
        const key = filter.key ? filter.key : undefined
        const value = filter.value ?  filter.value :  undefined
        const newFilter = {
          key, 
          value
        }
    
        removeUndefined(newFilter)
        if(newFilter.value)
          newFiltersTemplate.push(newFilter)
      })
    
      let data = { 
        contentTypeId: element.contentTypeId,
        displayName:element.displayName,
        id: element.id,
        filters: newFiltersTemplate,
        resultFields: element.resultFields
      }
    
      if(element.fixedfilters && element.fixedFilters.length!=0){
        data['fixedFilters']= element.fixedFilters
      }
    
      try{
        const list = this.reportService.reports_createReport(this.settingService.contextId$, data)
      }catch(error){
        console.log(error)
        const message = this.translate.instant("SnackBarConstants.REPORT_LOAD_FAILED")
        this.commentService.addSnackBar.emit(message)
    
      }
    }else{
      const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
      this.commentService.addSnackBar.emit(message)
    }
  }

  filterActive(key){
    if(this.filterForm.value[key]!=undefined){
      if(this.allFiltersWithValues$.value && this.allFiltersWithValues$.value.length!=0 && this.allFiltersWithValues$.value.find( filter => filter.filterKey == key))
        return true
      else 
        return false
    }else{
      return false
    }
  }
}
