import { NgModule } from '@angular/core';
import { getApp, initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideFunctions, getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { provideStorage, getStorage, connectStorageEmulator } from '@angular/fire/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountLanguagesDialogComponent } from './account-languages-dialog/account-languages-dialog.component';
import { PassTemplatesComponent } from './pass-templates/pass-templates.component';
import { TridysComponent } from './tridys/tridys.component';
import { TridyDetailsDialogComponent } from './tridy-details-dialog/tridy-details-dialog.component';
import { MediaComponent } from './media/media.component';
import { ProductsComponent } from './products/products.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { TaskTemplatesComponent } from './worktask/worktask-templates/worktask-templates.component';
import { FinishedTasksComponent } from './finished-tasks/finished-tasks.component';
import { UsersComponent } from './users/users.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InjectComponentDirective } from './inject-component.directive';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { GoogleMapsModule } from '@angular/google-maps';
import { GooglemapComponent } from './googlemap/googlemap.component';
import { MapMarkerComponent } from './map-marker/map-marker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PassTemplatesThemeEditComponent } from './pass-templates-theme-edit/pass-templates-theme-edit.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxColorsModule } from 'ngx-colors';
import { environment } from '../environments/environment';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LogInComponent } from './log-in/log-in.component';
import { RedirectComponent } from './redirect/redirect.component';
import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';
import { NavbarMenuComponent } from './navbar-menu/navbar-menu.component';
import { ToolbarMenuComponent } from './toolbar-menu/toolbar-menu.component';
import { CreateUserDialogComponent } from './create-user-dialog/create-user-dialog.component';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { ContentTypesListComponent } from './content-type/content-types-list/content-types-list.component';
import { CreateContentTypeDialogComponent } from './content-type/create-content-type-dialog/create-content-type-dialog.component';
import { ContentComponent } from './content/content.component';
import { ContentEditDialogComponent } from './content-edit-dialog/content-edit-dialog.component';
import { EditValueContentDialogComponent } from './edit-value-content-dialog/edit-value-content-dialog.component';
import { MediaFolderComponent } from './media-folder/media-folder.component';
import { FinishedTasksItemComponent } from './finished-tasks-item/finished-tasks-item.component';
import { FinishedTasksMapComponent } from './finished-tasks-map/finished-tasks-map.component';
import { TaskTemplateItemComponent } from './worktask/worktask-template-item/worktask-template-item.component';
import { TaskTemplateEditSectionDialogComponent } from './worktask/worktask-template-edit-section-dialog/worktask-template-edit-section-dialog.component';
import { WorkTaskTemplateDialogComponent } from './worktask/work-task-template-dialog/work-task-template-dialog.component';
import { WorkTaskTemplateEditValueDialogComponent } from './worktask/work-task-template-edit-value-dialog/work-task-template-edit-value-dialog.component';
import { TaskTemplateFieldDialogComponent } from './worktask/worktask-template-field-dialog/worktask-template-field-dialog.component';
import { TridysItemComponent } from './tridys-item/tridys-item.component';
import { ProductsItemComponent } from './products-item/products-item.component';
import { ContextIdSelectorComponent } from './context-id-selector/context-id-selector.component';
import { AdvancedExperiencesComponent } from './advanced-experience/advanced-experiences/advanced-experiences.component';
import { AdvancedExperiencesItemComponent } from './advanced-experience/advanced-experiences-item/advanced-experiences-item.component';
import { FilterContentsPipe} from './shared/filter.pipe';
import { FilterTridyPipe } from './shared/filter-tridy.pipe';
import { FilterExperiencesPipe } from './shared/filterExperiences.pipe';
import { FilterProductsPipe } from './shared/filter-product.pipe';
import { FilterTasksPipe } from './shared/filter-tasks.pipe';
import { FilterFinishedPipe } from './shared/filter-finished.pipe';
import { DeleteContentTypesDialogComponent } from './delete-content-types-dialog/delete-content-types-dialog.component';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { DatePipe, CommonModule } from '@angular/common';
import { QrCodeDialogComponent } from './qr-code-dialog/qr-code-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragAndDropDirective } from './directive/drag-and-drop.directive';
import { AdvancedExperiencesDialogComponent } from './advanced-experience/advanced-experiences-dialog/advanced-experiences-dialog.component';
import { LanguageEditorDialogComponent } from './language-editor-dialog/language-editor-dialog.component';
import { LanguageVisualisationDialogComponent } from './language-visualisation-dialog/language-visualisation-dialog.component';
import { AngularFireDatabaseModule, USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { AngularFirestoreModule, USE_EMULATOR as USE_FIRESTORE_EMULATOR, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule, USE_DEVICE_LANGUAGE, USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule, REGION, USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { InfoMesageDialogComponent } from './info-mesage-dialog/info-mesage-dialog.component';
import { ReportsListComponent } from './reports/reports-list/reports-list.component';
import { ReportsItemTridysListComponent } from './reports/reports-item-tridys-list/reports-item-tridys-list.component';
import { ReportCreateDialogComponent } from './reports/report-create-dialog/report-create-dialog.component';
import { ReportAddFilterFieldDialogComponent } from './reports/report-add-filter-field-dialog/report-add-filter-field-dialog.component';
import { DuplicateReportTemplateDialogComponent } from './duplicate-report-template-dialog/duplicate-report-template-dialog.component';
import { InterfacesListComponent } from './interfaces-list/interfaces-list.component';
import { InterfacesDataDialogComponent } from './interfaces-data-dialog/interfaces-data-dialog.component';
import { InterfacesDataMappingDialogComponent } from './interfaces-data-mapping-dialog/interfaces-data-mapping-dialog.component';
import { TaskTemplateFieldNewOptionDialogComponent } from './worktask/worktask-template-field-new-option-dialog/worktask-template-field-new-option-dialog.component';
import { AnnotatedMapAnnotationDialogComponent } from './widgets/editWidgets/annotated-map-annotation-dialog/annotated-map-annotation-dialog.component';
import { TaskTemplatesGroupsComponent } from './worktask/worktask-templates-groups/worktask-templates-groups.component';
import { TaskTemplatesGroupsDialogComponent } from './worktask/worktask-templates-groups-dialog/worktask-templates-groups-dialog.component';
import { ContentTypesSchemaPropertiesDialogComponent } from './content-type/content-types-schema-properties-dialog/content-types-schema-properties-dialog.component';
import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard';
import { RegisterComponent } from './register/register.component';
import { ContentTypeSettingsDialogComponent } from './content-type/content-type-settings-dialog/content-type-settings-dialog.component';
import { ContentTypesSchemaViewDialogComponent } from './content-type/content-types-schema-view-dialog/content-types-schema-view-dialog.component';
import { AdvancedExperienceViewDialogComponent } from './advanced-experience/advanced-experience-view-dialog/advanced-experience-view-dialog.component';
import { CancelChangesDialogComponent } from './cancel-changes-dialog/cancel-changes-dialog.component';
import { TaskTemplateCreateDialogComponent } from './worktask/worktask-template-create-dialog/worktask-template-create-dialog.component';
import { WorkTaskTemplateAdvancedDialogComponent } from './worktask/work-task-template-advanced-dialog/work-task-template-advanced-dialog.component';
import { UpdateConfirmationDialogComponent } from './update-confirmation-dialog/update-confirmation-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { MapMarkerHorizontalComponent } from './map-marker-horizontal/map-marker-horizontal.component';
import { FilterUIDialogComponent } from './filter-ui-dialog/filter-ui-dialog.component';
import { DuplicatePassTemplateDialogComponent } from './duplicate-pass-template-dialog/duplicate-pass-template-dialog.component';
import { DuplicateTaskTemplateDialogComponent } from './duplicate-task-template-dialog/duplicate-task-template-dialog.component';
import { ReportsItemTridysListFiltersComponent } from './reports/reports-item-tridys-list-filters/reports-item-tridys-list-filters.component';
import { WorkTaskTemplateDialogIncomingOutgoingComponent } from './worktask/work-task-template-dialog-incoming-outgoing/work-task-template-dialog-incoming-outgoing.component';
import { AnnotatedMapAnnotationDynamicDialogComponent } from './widgets/editWidgets/annotated-map-annotation-dynamic-dialog/annotated-map-annotation-dynamic-dialog.component';
import { ContentTypeDisplayTemplateDialogComponent } from './content-type/content-type-display-template-dialog/content-type-display-template-dialog.component';
import { ContentTypeDisplayTemplateFieldDialogComponent } from './content-type/content-type-display-template-field-dialog/content-type-display-template-field-dialog.component';
import { CdSettingsComponent } from './cd-settings/cd-settings.component';
import { PassTemplatesSettingsEditComponent } from './pass-templates-settings-edit/pass-templates-settings-edit.component';
import { MapAdvancedMarkersComponent } from './map-advanced-markers/map-advanced-markers.component';
import { LocationPickerComponent } from './location-picker/location-picker.component';
import { MapAdvancedMarkersHorizontalComponent } from './map-advanced-markers-horizontal/map-advanced-markers-horizontal.component';
import { PassTemplatesTranslationsComponent } from './pass-templates-translations/pass-templates-translations.component';
import { PassTemplatesTranslationsEditorDialogComponent } from './pass-templates-translations-editor-dialog/pass-templates-translations-editor-dialog.component';
import { TaskTemplateImportFieldEditDialogComponent } from './worktask/worktask-template-import-field-edit-dialog/worktask-template-import-field-edit-dialog.component';
import { PlaceholderListComponent } from './placeholder-list/placeholder-list.component';
// MATERIAL MODULES
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs'
import { MatDividerModule } from '@angular/material/divider'
import { MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTreeModule} from '@angular/material/tree';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from  '@angular/material/slide-toggle';
// TRANSLATION WIDGETS
import { TextTranslationEditComponent } from './widgets-translationMode/text-translation-edit/text-translation-edit.component';
import { ImageTranslationEditComponent } from './widgets-translationMode/image-translation-edit/image-translation-edit.component';
import { AnnotatedMapTranslationEditComponent } from './widgets-translationMode/annotated-map-translation-edit/annotated-map-translation-edit.component';
import { AnnotatedMapAnnotationTranslationDialogComponent } from './widgets-translationMode/annotated-map-annotation-translation-dialog/annotated-map-annotation-translation-dialog.component';
import { ButtonsTranslationEditComponent } from './widgets-translationMode/buttons-translation-edit/buttons-translation-edit.component';
import { AnnotatedMapViewComponent } from './widgets-translationMode/annotated-map-view/annotated-map-view.component';
// EDIT WIDGETS
import { ListEditComponent } from './widgets/editWidgets/list-edit/list-edit.component';
import { ImagesHorizontalEditComponent } from './widgets/editWidgets/images-horizontal-edit/images-horizontal-edit.component';
import { WidgetStylingComponent } from './widgets/editWidgets/widget-styling/widget-styling.component';
import { CountdownEditComponent } from './widgets/editWidgets/countdown-edit/countdown-edit.component';
import { ComposedEditComponent } from './widgets/editWidgets/composed-edit/composed-edit.component';
import { ComposedEditContentDialogComponent } from './widgets/editWidgets/composed-edit-content-dialog/composed-edit-content-dialog.component';
import { WidgetEditFiltersComponent } from './widgets/editWidgets/widget-edit-filters/widget-edit-filters.component';
import { WidgetEditFiltersEditDialogComponent } from './widgets/editWidgets/widget-edit-filters-edit-dialog/widget-edit-filters-edit-dialog.component';
import { FormEditComponent } from './widgets/editWidgets/form-edit/form-edit.component';
import { FormEditFieldDialogComponent } from './widgets/editWidgets/form-edit-field-dialog/form-edit-field-dialog.component';
import { SpacerEditComponent } from './widgets/editWidgets/spacer-edit/spacer-edit.component';
import { ButtonsEditComponent } from './widgets/editWidgets/buttons-edit/buttons-edit.component';
import { ProductRegistrationEditComponent } from './widgets/editWidgets/product-registration-edit/product-registration-edit.component';
import { Model3dEditComponent } from './widgets/editWidgets/model3d-edit/model3d-edit.component';
import { ImageBlocksEditComponent } from './widgets/editWidgets/image-blocks-edit/image-blocks-edit.component';
import { TextEditComponent } from './widgets/editWidgets/text-edit/text-edit.component';
import { ImageEditComponent } from './widgets/editWidgets/image-edit/image-edit.component';
import { BillboardVideoEditComponent } from './widgets/editWidgets/billboard-video-edit/billboard-video-edit.component';
import { VideoEditComponent } from './widgets/editWidgets/video-edit/video-edit.component';
import { AnnotatedMapEditComponent } from './widgets/editWidgets/annotated-map-edit/annotated-map-edit.component';
import { MapMarkerEditComponent } from './map-marker-edit/map-marker-edit.component';
import { CallToActionEditComponent } from './widgets/editWidgets/call-to-action-edit/call-to-action-edit.component';
import { BillboardImageEditComponent } from './widgets/editWidgets/billboard-image-edit/billboard-image-edit.component';
import { MediaDetailsDialogComponent } from './widgets/editWidgets/media-details-dialog/media-details-dialog.component';
import { SplashVideoEditComponent } from './widgets/editWidgets/splash-video-edit/splash-video-edit.component';
import { ImagesEditComponent } from './widgets/editWidgets/images-edit/images-edit.component';
import { ProductsEditComponent } from './widgets/editWidgets/products-edit/products-edit.component';
// PHONE WIDGETS
import { BillboardImagePhoneComponent } from './widgets/phoneWidgets/billboard-image-phone/billboard-image-phone.component';
import { ImagesHorizontalPhoneComponent } from './widgets/phoneWidgets/images-horizontal-phone/images-horizontal-phone.component';
import { ImagePhoneComponent } from './widgets/phoneWidgets/image-phone/image-phone.component';
import { BillboardVideoPhoneComponent } from './widgets/phoneWidgets/billboard-video-phone/billboard-video-phone.component';
import { TextPhoneComponent } from './widgets/phoneWidgets/text-phone/text-phone.component';
import { VideoPhoneComponent } from './widgets/phoneWidgets/video-phone/video-phone.component';
import { AnnotatedMapPhoneComponent } from './widgets/phoneWidgets/annotated-map-phone/annotated-map-phone.component';
import { CallToActionPhoneComponent } from './widgets/phoneWidgets/call-to-action-phone/call-to-action-phone.component';
import { Model3dTestComponent } from './widgets/phoneWidgets/model3d-Test/model3d-test.component'
import { Model3dPhoneComponent } from './widgets/phoneWidgets/model3d-phone/model3d-phone.component';
import { Model3dViewComponent } from './widgets/phoneWidgets/model3d-view/model3d-view.component';
import { ImageBlocksPhoneComponent } from './widgets/phoneWidgets/image-blocks-phone/image-blocks-phone.component';
import { ProductsPhoneComponent } from './widgets/phoneWidgets/products-phone/products-phone.component';
import { ProductRegistrationPhoneComponent } from './widgets/phoneWidgets/product-registration-phone/product-registration-phone.component';
import { ShopifyPhoneComponent } from './widgets/phoneWidgets/shopify-phone/shopify-phone.component';
import { InstagramPhoneComponent } from './widgets/phoneWidgets/instagram-phone/instagram-phone.component';
import { ImagesPhoneComponent } from './widgets/phoneWidgets/images-phone/images-phone.component';
import { SplashVideoPhoneComponent } from './widgets/phoneWidgets/splash-video-phone/splash-video-phone.component';
import { ListPhoneComponent } from './widgets/phoneWidgets/list-phone/list-phone.component';
import { SpacerPhoneComponent } from './widgets/phoneWidgets/spacer-phone/spacer-phone.component';
import { ButtonsPhoneComponent } from './widgets/phoneWidgets/buttons-phone/buttons-phone.component';
import { FormPhoneComponent } from './widgets/phoneWidgets/form-phone/form-phone.component';
import { ComposedPhoneComponent } from './widgets/phoneWidgets/composed-phone/composed-phone.component';
import { CountdownPhoneComponent } from './widgets/phoneWidgets/countdown-phone/countdown-phone.component';
import { ComposedContentPhoneComponent } from './widgets/phoneWidgets/composed-content-phone/composed-content-phone.component';
// WYSWIG QUILL
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';
import QuillMarkdown from 'quilljs-markdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MediaPreviewDialogComponent } from './media-preview-dialog/media-preview-dialog.component';
import { MediaHandlerComponent } from './media-handler/media-handler.component';
import { MediaFolderContentComponent } from './media-folder-content/media-folder-content.component';
import { MediaDialogHandlerComponent } from './media-dialog-handler/media-dialog-handler.component';
import { MediaDialogContentComponent } from './media-dialog-content/media-dialog-content.component';
import { DragFileBrowseUIComponent } from './drag-file-browse-ui/drag-file-browse-ui.component';
import { WorkTaskTemplateFieldRulesComponent } from './worktask/work-task-template-field-rules/work-task-template-field-rules.component';
import { WorkTaskTemplateFieldRuleDialogComponent } from './worktask/work-task-template-field-rule-dialog/work-task-template-field-rule-dialog.component';
import { ConfirmChangesDialogComponent } from './confirm-changes-dialog/confirm-changes-dialog.component';

Quill.register('modules/markdown', QuillMarkdown);

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
  
@NgModule({ 
    declarations: [
        AppComponent,
        AccountLanguagesDialogComponent,
        PassTemplatesComponent,
        TridysComponent,
        MediaComponent,
        ProductsComponent,
        PurchasesComponent,
        TaskTemplatesComponent,
        FinishedTasksComponent,
        UsersComponent,
        InjectComponentDirective,
        BillboardImagePhoneComponent,
        ImagesHorizontalPhoneComponent,
        TextPhoneComponent,
        ImagePhoneComponent,
        BillboardVideoPhoneComponent,
        VideoPhoneComponent,
        AnnotatedMapPhoneComponent,
        GooglemapComponent,
        MapMarkerComponent,
        ImagesHorizontalEditComponent,
        BillboardImageEditComponent,
        TextEditComponent,
        ImageEditComponent,
        BillboardVideoEditComponent,
        VideoEditComponent,
        AnnotatedMapEditComponent,
        MapMarkerEditComponent,
        CallToActionEditComponent,
        CallToActionPhoneComponent,
        Model3dTestComponent,
        Model3dPhoneComponent,
        Model3dViewComponent,
        Model3dEditComponent,
        ImageBlocksPhoneComponent,
        ImageBlocksEditComponent,
        PassTemplatesThemeEditComponent,
        // DashboardComponent,
        // LogInComponent,
        RedirectComponent,
        SettingsDialogComponent,
        // NavbarMenuComponent,
        // ToolbarMenuComponent,
        CreateUserDialogComponent,
        EditUserDialogComponent,
        ContentTypesListComponent,
        CreateContentTypeDialogComponent,
        ProductRegistrationPhoneComponent,
        ProductRegistrationEditComponent,
        ProductsPhoneComponent,
        ProductsEditComponent,
        ShopifyPhoneComponent,
        InstagramPhoneComponent,
        ContentComponent,
        ContentEditDialogComponent,
        EditValueContentDialogComponent,
        MediaFolderComponent,
        FinishedTasksItemComponent,
        FinishedTasksMapComponent,
        TaskTemplateItemComponent,
        TaskTemplateEditSectionDialogComponent,
        WorkTaskTemplateDialogComponent,
        WorkTaskTemplateEditValueDialogComponent,
        TaskTemplateFieldDialogComponent,
        TridysItemComponent,
        TridyDetailsDialogComponent,
        ProductsItemComponent,
        ContextIdSelectorComponent,
        SplashVideoPhoneComponent,
        SplashVideoEditComponent,
        AdvancedExperiencesComponent,
        AdvancedExperiencesItemComponent,
        ImagesPhoneComponent,
        ImagesEditComponent,
        MediaDetailsDialogComponent,
        FilterContentsPipe,
        FilterTridyPipe,
        FilterExperiencesPipe,
        FilterProductsPipe,
        FilterTasksPipe,
        FilterFinishedPipe,
        DeleteContentTypesDialogComponent,
        DeleteConfirmationDialogComponent,
        QrCodeDialogComponent,
        DragAndDropDirective,
        AdvancedExperiencesDialogComponent,
        LanguageEditorDialogComponent,
        LanguageVisualisationDialogComponent,
        InfoMesageDialogComponent,
        ReportsListComponent,
        ReportsItemTridysListComponent,
        ReportCreateDialogComponent,
        ReportAddFilterFieldDialogComponent,
        DuplicateReportTemplateDialogComponent,
        InterfacesListComponent,
        InterfacesDataDialogComponent,
        InterfacesDataMappingDialogComponent,
        TaskTemplateFieldNewOptionDialogComponent,
        AnnotatedMapAnnotationDialogComponent,
        TaskTemplatesGroupsComponent,
        TaskTemplatesGroupsDialogComponent,
        ContentTypesSchemaPropertiesDialogComponent,
        // RegisterComponent,
        ContentTypeSettingsDialogComponent,
        ContentTypesSchemaViewDialogComponent,
        AdvancedExperienceViewDialogComponent,
        CancelChangesDialogComponent,
        TaskTemplateCreateDialogComponent,
        WorkTaskTemplateAdvancedDialogComponent,
        SpacerPhoneComponent,
        ButtonsPhoneComponent,
        SpacerEditComponent,
        ButtonsEditComponent,
        UpdateConfirmationDialogComponent,
        ListEditComponent,
        ListPhoneComponent,
        ErrorDialogComponent,
        MapMarkerHorizontalComponent,
        FormPhoneComponent,
        FormEditComponent,
        FormEditFieldDialogComponent,
        FilterUIDialogComponent,
        DuplicatePassTemplateDialogComponent,
        DuplicateTaskTemplateDialogComponent,
        ReportsItemTridysListFiltersComponent,
        WorkTaskTemplateDialogIncomingOutgoingComponent,
        AnnotatedMapAnnotationDynamicDialogComponent,
        ContentTypeDisplayTemplateDialogComponent,
        ContentTypeDisplayTemplateFieldDialogComponent,
        CdSettingsComponent,
        PassTemplatesSettingsEditComponent,
        MapAdvancedMarkersComponent,
        LocationPickerComponent,
        MapAdvancedMarkersHorizontalComponent,
        PassTemplatesTranslationsComponent,
        PassTemplatesTranslationsEditorDialogComponent,
        TaskTemplateImportFieldEditDialogComponent,
        TextTranslationEditComponent,
        ImageTranslationEditComponent,
        AnnotatedMapTranslationEditComponent,
        AnnotatedMapAnnotationTranslationDialogComponent,
        ButtonsTranslationEditComponent,
        PlaceholderListComponent,
        WidgetEditFiltersComponent,
        WidgetEditFiltersEditDialogComponent,
        CountdownPhoneComponent,
        CountdownEditComponent,
        AnnotatedMapViewComponent,
        ComposedPhoneComponent,
        ComposedEditComponent,
        ComposedEditContentDialogComponent,
        ComposedContentPhoneComponent,
        WidgetStylingComponent,
        MediaFolderComponent, 
        MediaDetailsDialogComponent,
        MediaPreviewDialogComponent,
        MediaHandlerComponent,
        MediaFolderContentComponent,
        MediaDialogHandlerComponent,
        MediaDialogContentComponent,
        DragFileBrowseUIComponent,
        WorkTaskTemplateFieldRulesComponent,
        WorkTaskTemplateFieldRuleDialogComponent,
        ConfirmChangesDialogComponent
    ],
    exports: [
        InjectComponentDirective,
    ],
    bootstrap: [AppComponent], 
    
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        BrowserModule,
        AngularFireStorageModule,
        AppRoutingModule,
        MatSidenavModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        DragDropModule,
        MdbCarouselModule,
        VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
        CommonModule,
        GoogleMapsModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatDividerModule,
        MatOptionModule,
        MatSelectModule,
        MatTabsModule,
        MatListModule,
        MatExpansionModule,
        MatSlideToggleModule,
        ColorPickerModule,
        NgxColorsModule,
        MatMenuModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatCardModule,
        MatGridListModule,
        MatChipsModule,
        QRCodeModule,
        ClipboardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTreeModule,
        MatTooltipModule,
        QuillModule.forRoot({
            modules: {
                markdown: {}
            }
        }),
        MatBadgeModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: (createTranslateLoader),//createTranslateLoader,
              deps: [HttpClient]
            },
            // extend: true,
            // defaultLanguage: navigator.language
          }),
        NgbModule],
    providers: [
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFunctions(() => {
            const functions = getFunctions(getApp(), 'europe-west1');
            if (environment.useEmulators) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
                console.log("Using emulator for functions");
            }
            return functions;
        }),
        //provideFirestore(() => getFirestore()),
        provideStorage(() => {
            const storage = getStorage();
            if (environment.useEmulators) {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            return storage;
        }),
        provideHttpClient(withInterceptorsFromDi()),
        provideFunctions(() => getFunctions(undefined, 'europe-west1')),
        CanDeactivateGuard,
        DatePipe,
        { provide: REGION, useValue: 'europe-west1' },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: FIRESTORE_SETTINGS, useValue: { ignoreUndefinedProperties: true } },
        // { provide: ANALYTICS_DEBUG_MODE, useValue: true },
        { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined },
        { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9000] : undefined },
        { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    ] })
export class AppModule { }

// // required for AOT compilation
// export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
//   return new TranslateHttpLoader(http);
// }
