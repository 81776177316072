import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SettingsDialogComponent } from '../settings-dialog/settings-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../service/settings.service';
import { TemplatesService } from '../service/templates.service';
import { AuthService } from '../service/auth.service';
import {build, version} from './../../buildVersion';
import {project} from './../../buildVersion';
import { environment } from '../../environments/environment';
import { getAuth } from '@angular/fire/auth';
import { NavbarAccesService } from '../service/navbar-acces.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-dashboard-component',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit{
  
  userData:any
  accountName:any
  environmentName=environment.firebase
  version=version
  buildEnvironment:string
  buildNumber=build
  navigationSubscription: any;
  contextId: any;
  contentTypes:boolean=true
  content:boolean=true
  taskTemplates:boolean=true
  workTasks:boolean=true
  reports:boolean=true
  interfaces:boolean=true
  tridys:boolean=true
  context:boolean = true
  dashboard :boolean = false
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private settingsService: SettingsService,
    private auth: AuthService,
    private templateService: TemplatesService,
    private navbarAcces: NavbarAccesService,
    private activeRoute: ActivatedRoute){

      // console.log(this.router.url)
      // this.router.events.pipe(
      //   filter(event=> event instanceof NavigationEnd)
      // ).subscribe( event=> {
      //   console.log(this.router.url)
      //   const url = this.router.url
      //   const entries =url.split('/')
      //   console.log(entries)
      //   if(entries?.length==2 || entries?.length==3)
      //   this.dashboard =true
      //   else
      //   this.dashboard =false

      // })


      this.activeRoute.params.subscribe(params => {
        // console.log('Route parameters changed:', params);
      });
  }

  ngOnInit():void{
    this.contextId = this.settingsService.contextId$

    this.settingsService.contextIdChange$.subscribe(value=> {
      this.contextId = value
    })

    this.settingsService.userNameChange$.subscribe(value=>{
      this.accountName = value
    })

    this.userData={
      'account':'',
      'user':''
    }  
    if(this.environmentName.projectId=="narravero-eu-dev"){
      this.buildEnvironment="Narravero DEV "
    }
    if(this.environmentName.projectId=="narravero-eu-prod"){
      this.buildEnvironment="Narravero "
    }
    if(this.environmentName.projectId=="narravero-eu-test"){
      this.buildEnvironment="Narravero TEST"
    }
    
    if(this.settingsService.userAccount$ && this.settingsService.userAccount$.displayName){
      this.accountName = this.settingsService.userAccount$.displayName
    }else{
      this.accountName=""
      this.accountName = this.settingsService.currentUser$.displayName
    }

    if(this.settingsService.currentUser$.displayName){
      this.accountName = this.settingsService.currentUser$.displayName
    }

    this.navbarAcces.contentTypesChange.subscribe( value => {
      this.contentTypes=value
    })

    this.navbarAcces.contentChange.subscribe( value => {
      this.content=value

    })
    this.navbarAcces.taskTemplatesChange.subscribe( value => {
      this.taskTemplates=value
    })
    this.navbarAcces.workTasksChange.subscribe( value => {
      this.workTasks=value
    })

    this.navbarAcces.reportsChange.subscribe( value => {
      this.reports = value
    })
    this.navbarAcces.interfacesChange.subscribe( value => {
      this.interfaces = value
    })

    this.navbarAcces.tridysChange.subscribe( value => {
      this.tridys = value
    })

    this.navbarAcces.contextChange.subscribe( value => {
      this.context = value
    })

    if(this.navbarAcces.actualRights){
      if(this.navbarAcces.actualRights.contentTypes && this.navbarAcces.actualRights.contentTypes.R)
        this.contentTypes=this.navbarAcces.actualRights.contentTypes.R
      else
        this.contentTypes=false

      if(this.navbarAcces.actualRights.workTaskTemplates && this.navbarAcces.actualRights.workTaskTemplates.R)
        this.taskTemplates=this.navbarAcces.actualRights.workTaskTemplates.R
      else
        this.taskTemplates=false

      if(this.navbarAcces.actualRights.workTask && this.navbarAcces.actualRights.workTask.R)
        this.workTasks=this.navbarAcces.actualRights.workTask.R
      else
        this.workTasks=false

      if(this.navbarAcces.actualRights.content && this.navbarAcces.actualRights.content.R)
        this.content=this.navbarAcces.actualRights.content.R
      else
        this.content=false


      if(this.navbarAcces.actualRights.reports && this.navbarAcces.actualRights.reports.R)
        this.reports=this.navbarAcces.actualRights.reports.R
      else
        this.reports=false

      if(this.navbarAcces.actualRights.context && this.navbarAcces.actualRights.context.R)
        this.context=this.navbarAcces.actualRights.context.R
      else
        this.context=false

      if(this.navbarAcces.actualRights.tridys && this.navbarAcces.actualRights.tridys.R)
        this.tridys=this.navbarAcces.actualRights.tridys.R
      else
        this.tridys=false
    }
  }

  openRoute(menuItem:string){
    this.router.navigateByUrl('/home/' + this.contextId + '/' + menuItem);
  }

}
