import { Component, EventEmitter, Input, Output } from '@angular/core';
import { deepCopy } from '../../../../../backend/utils/object';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingsService } from 'src/app/service/settings.service';
import { ValidationData } from '../../../../../backend/src/tasktemplate.dto';
import { MatDialog } from '@angular/material/dialog';
import { WorkTaskTemplateFieldRuleDialogComponent } from 'src/app/worktask/work-task-template-field-rule-dialog/work-task-template-field-rule-dialog.component';
import { isDateTime } from '../../../../../backend/src/filter';

@Component({
  selector: '[app-work-task-template-field-rules]',
  templateUrl: './work-task-template-field-rules.component.html',
  styleUrl: './work-task-template-field-rules.component.css'
})
export class WorkTaskTemplateFieldRulesComponent {
  @Input('app-work-task-template-field-rules') data:any;
  @Output() returnChanges = new EventEmitter<{newRules: any, field: any, fieldId:string}>()
  validationAll:any
  validationForm!:FormGroup
  fieldType:string
  ruleTypes:any = [
    {//textLength
      type: 'textLength',
      inputType:'number',
      icon:'text_format',
      translation:"taskTemplates.rules.textLength",
      active:true,
      allowedFields:['text', 'longText'],
      parameters:[
        {
          key:'min',
          type:'number',
          description:"taskTemplates.rules.textLength_min_description",
          inputType:'number',
        },
        {
          key:'max',
          type:'number',
          description:"taskTemplates.rules.textLength_max_description",
          inputType:'number'
        }
      ],
      description:"taskTemplates.rules.textLength_description"
    },
    {//textRegex
      type: 'textRegex',
      inputType:'text',
      icon:'checklist',
      translation:"taskTemplates.rules.textRegex",
      active:true,
      allowedFields:['text', 'longText','weight'],
      parameters:[
        {
          key:'regex',
          type:'string',
          description:"taskTemplates.rules.textRegex_regex_description",
          inputType:'text',
        }
      ],
      description:"taskTemplates.rules.textRegex_description"
    },
    {//numericRange
      type: 'numericRange',
      inputType:'number',
      icon:'pin',
      translation:"taskTemplates.rules.numericRange",
      active:true,
      allowedFields:['number', 'decimal'],
      parameters:[
        {
          key:'min',
          type:'number',
          description:"taskTemplates.rules.numericRange_min_description",
          inputType:'number',
        },
        {
          key:'max',
          type:'number',
          description:"taskTemplates.rules.numericRange_max_description",
          inputType:'number'
        },
        {
          key:'decimal',
          type:'boolean',
          description:"taskTemplates.rules.numericRange_decimal_description",
          inputType:'number'
        }
      ],
      description:"taskTemplates.rules.numericRange_description"
    },
    {//dateRange
      type: 'dateRange',
      icon:'calendar_month',
      translation:"taskTemplates.rules.dateRange",
      active:true,
      allowedFields:['date', 'dateTime', 'time'],
      parameters:[
        {
          key:'min',
          type:'date',
          description:"taskTemplates.rules.dateRange_min_description",
          inputType:'datetime-local',

        },
        {
          key:'max',
          type:'date',
          description:"taskTemplates.rules.dateRange_max_description",
          inputType:'datetime-local',

        },
        {
          key:'minDaysAgo',
          type:'number',
          description:"taskTemplates.rules.dateRange_minDaysAgo_description",
          inputType:'number',

        }
      ],
      description:"taskTemplates.rules.dateRange_description"
    }
  ]

  tasktemplateUserRights_update:boolean
  tasktemplateUserRights_delete:boolean
  constructor(private fb: FormBuilder,
    private settingService: SettingsService,
    public dialog: MatDialog,
    ){
    this.validationForm = this.fb.group({
      validations:[]
    })
  }

  ngOnInit() : void{
    this.tasktemplateUserRights_update = this.settingService.modulesRolesRights$.value['workTaskTemplates']['U']
    this.tasktemplateUserRights_delete = this.settingService.modulesRolesRights$.value['workTaskTemplates']['D']
    if(this.data.type)
    this.fieldType = this.data.type

    this.validationForm.valueChanges.subscribe(value =>{
      this.returnChanges.emit({newRules: this.validationAll, field: this.data, fieldId: this.data.id})
    })

  }

  ngOnChanges(){
    this.validationAll = deepCopy( this.data.validations)
    if(JSON.stringify(this.validationAll) != JSON.stringify(this.validationForm.value.validations))
    this.validationForm.patchValue({validations: this.validationAll})
  }

  editRule(rule, index){
    const ruleStructure = this.ruleTypes.find(ruleType => ruleType.type == rule.type)

    const dialogRef = this.dialog.open(WorkTaskTemplateFieldRuleDialogComponent, {
      // width: '400px',
      data: { rule, ruleStructure }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.validationAll[index]= result[1]
        this.validationForm.patchValue({validations: this.validationAll})
      }
    });
  }

  deleteRule(rule, index){
    this.validationAll.splice(index,1)
    this.validationForm.patchValue({validations: this.validationAll})
  }

  addRule(type){
    const newRule = ValidationData[type]
    this.validationAll.push(newRule)
    this.validationForm.patchValue({formSections: this.validationAll})
  }

  checkIfRuleIsAddedAlready(rule){
    return this.validationAll.find(ruleAdded => ruleAdded.type == rule.type)
  }


  showDate(date){
    return isDateTime(date)
  }
}
