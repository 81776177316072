import { HttpClient } from '@angular/common/http';
import { Component, ComponentFactoryResolver, ComponentRef, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NetworkConstants } from 'src/network.constants';
import { InjectComponentDirective } from '../inject-component.directive';
import { CommentsService } from '../service/comments.service';
import { ImageCompressionService } from '../service/image-compression.service';
import { SettingsService } from '../service/settings.service';
import { StorageService } from '../service/storage.service';
import { UploadService } from '../service/upload.service';
import { deepCopy, getSizeBytes } from '../../../../backend/utils/object';
import { MediaDialogContentComponent } from '../media-dialog-content/media-dialog-content.component';
import { MediaDialogInsideFolderData } from '../shared/media';

@Component({
  selector: 'app-media-dialog-handler',
  templateUrl: './media-dialog-handler.component.html',
  styleUrls: ['./media-dialog-handler.component.css']
})
export class MediaDialogHandlerComponent {
  folderRouteBase= `/${NetworkConstants.STORE_MEDIA}/`
  currentlyActiveFolder = `/${NetworkConstants.STORE_MEDIA}/`//`${NetworkConstants.COLLECTION_CONTEXTS}/${this.settingsService.contextId$}`
  currentlyActiveFolderRoute = [NetworkConstants.STORE_MEDIA]//`${NetworkConstants.COLLECTION_CONTEXTS}/${this.settingsService.contextId$}`
  currentlyActiveFolderName = `${NetworkConstants.STORE_MEDIA}`
  componentRef: ComponentRef<any>|undefined = undefined
  @ViewChild(InjectComponentDirective, {static: true}) view: InjectComponentDirective;
  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  folder:any = []
  emptyMedia = false
  navigationPath: any[] = []
  showBack = false
  supportedFiles = []
  selectedFile:any
  dataStorageFolder = {}
  constructor( 
    public dialogRef: MatDialogRef<MediaDialogHandlerComponent>,
    private settingsService: SettingsService,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private commentService: CommentsService,
    private storageService: StorageService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uploadService: UploadService,
    private translate: TranslateService,
    private imageCompressionService: ImageCompressionService,
    private http: HttpClient
    ){
      dialogRef.disableClose = true;
    }

  ngOnInit():void{
    console.log(this.data)
    if(this.data.supportedFiles)
      console.log(this.data.supportedFiles)
      this.supportedFiles = this.data.supportedFiles
    this.getDataStorageFolder(this.currentlyActiveFolder, this.currentlyActiveFolderRoute)
  }

  getDataStorageFolder(route, routeUrlSegment){
    try{
      // this.folderRoute = route
      this.commentService.progressSpin.emit(true)
      this.storageService.getStorageMediaOfContextId(route).subscribe( value => {

        if(value){
          if(value.length ==  0)
            this.emptyMedia = true
          else
            this.emptyMedia = false
          this.folder = value
          // this.filteredFolder = deepCopy(value)
          const currentlyActiveFolderRoute = deepCopy(this.currentlyActiveFolderRoute)

          let values:  any
          const listItem = new MediaDialogInsideFolderData(
            this.currentlyActiveFolder,
            currentlyActiveFolderRoute,
            this.currentlyActiveFolderName,
            this.folder,
            this.supportedFiles,
            MediaDialogContentComponent
          )
          listItem.addListener(this)
          values=listItem
          // this.commentService.progressSpin.emit(false)
          this.dataStorageFolder[this.currentlyActiveFolder] = {currentlyActiveFolder:this.currentlyActiveFolder,currentlyActiveFolderRoute, currentlyActiveFolderName: this.currentlyActiveFolderName,values, form: MediaDialogContentComponent }
          this.navigationPath.push({currentlyActiveFolder:this.currentlyActiveFolder,currentlyActiveFolderRoute, currentlyActiveFolderName: this.currentlyActiveFolderName,values, form: MediaDialogContentComponent })
          this.injectView(this.currentlyActiveFolder, values, MediaDialogContentComponent)
        }
      })
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }


  injectView(route: string, values, form) {
    console.log('--inject view handler---', route, values)
    // this.commentService.progressSpin.emit(true)
    if( route == `/${NetworkConstants.STORE_MEDIA}/`){
      this.showBack = false
    }else{
      this.showBack = true
    }

    console.log('currentlyActiveFolder',this.currentlyActiveFolder)
    console.log('currentlyActiveFolderRoute',this.currentlyActiveFolderRoute)
    console.log('currentlyActiveFolderName',this.currentlyActiveFolderName)

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(MediaDialogContentComponent);// Resolving the component factory
    this.container.clear();
    this.viewContainerRef.clear();// Clearing the container before adding the new component
    // this.container.createComponent(componentFactory)// Creating the component dynamically inside the view container
    // const componentRef = this.viewContainerRef.createComponent(componentFactory)// Creating the component dynamically inside the view container
    // componentRef.instance.data = values
    const componentRef: ComponentRef<MediaDialogContentComponent> = this.container.createComponent(componentFactory);

    componentRef.instance.data = values

    console.log('navigationPath',this.navigationPath)
    this.commentService.progressSpin.emit(false)

  }

  setSelectedFile(file){
    if(file.folder){  
      this.showBack = true
      this.currentlyActiveFolder += `${file.folder}/`
      this.currentlyActiveFolderRoute.push(file.folder)
      this.currentlyActiveFolderName = file.name

      if(!this.dataStorageFolder[this.currentlyActiveFolder])
        this.getDataStorageFolder(this.currentlyActiveFolder, this.currentlyActiveFolderRoute)
      else{////--- to avoid having multiple calls to get each folder data again
        console.log(this.dataStorageFolder)
        this.navigationPath.push(this.dataStorageFolder[this.currentlyActiveFolder])
        this.injectView(this.currentlyActiveFolder, this.dataStorageFolder[this.currentlyActiveFolder].values, MediaDialogContentComponent)
      }
    }else{
      const type = this.checkFileType(file)
      //console.log(file)
      if(type == 'video'){
        if(this.data.supportedFiles.find( support => support == 'video/*'))//this.data.supportedFiles.includes('video/*')
          this.selectedFile = file
        else{
          const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_FILE")
          this.commentService.addSnackBar.emit(message)
        }
      }

      if(type == 'image'){
        if(this.data.supportedFiles.find( support => support.includes('image')))
          this.selectedFile = file
        else{
          const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_FILE")
          this.commentService.addSnackBar.emit(message)
        }
      }

      if(type == 'file'){
        if(this.data.supportedFiles.find( support => support.includes('application')))
          this.selectedFile = file
        else{
          const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_FILE")
          this.commentService.addSnackBar.emit(message)
        }
      }
      // this.selectedFile = file
    }
  }

  navBack(){
    if( this.currentlyActiveFolderRoute.length>1) { //this.navigationPath.length>1 ||
      this.navigationPath.pop()
      const {currentlyActiveFolder,currentlyActiveFolderRoute, currentlyActiveFolderName,values, form } = this.navigationPath[this.navigationPath.length -1]
      this.currentlyActiveFolder = currentlyActiveFolder
      this.currentlyActiveFolderRoute = currentlyActiveFolderRoute
      this.currentlyActiveFolderName = currentlyActiveFolderName
      this.injectView(currentlyActiveFolder, values, form)
    }
  }

  checkFileType(file){
    let type 

    if(file.folder)
      type = 'folder'
    if(file.metadata){
      // if(file.metadata?.contentType === 'application/pdf')
      if(file.metadata?.contentType.includes('application'))
        type = 'file'

      if(file.metadata?.contentType.startsWith('image'))
        type = 'image'
        
      if(file.metadata?.contentType.startsWith('video') )
        type = 'video'
        
    }else{

    }

    return type
  }

  getFileSize(size){
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    return getSizeBytes(size,units ,2)
  }
  
  onCancelEdit(){
    this.dialogRef.close([false])

  }
  onSaveEdit(){
    this.dialogRef.close([true, this.selectedFile.supportUrl, this.selectedFile.url])
  }
}
