import { Component, ComponentFactoryResolver, ComponentRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { NetworkConstants } from 'src/network.constants';
import { InjectComponentDirective } from '../inject-component.directive';
import { MatDialog } from '@angular/material/dialog';
import { CommentsService } from '../service/comments.service';
import { SettingsService } from '../service/settings.service';
import { StorageService } from '../service/storage.service';
import { UploadService } from '../service/upload.service';
import { TranslateService } from '@ngx-translate/core';
import { deepCopy } from '../../../../backend/utils/object';
import { ImageCompressionService } from '../service/image-compression.service';
import { MediaInsideFolderData, MediaFilePreviewDialog } from '../shared/media';
import { MediaFolderContentComponent } from '../media-folder-content/media-folder-content.component';
import { MediaPreviewDialogComponent } from '../media-preview-dialog/media-preview-dialog.component';
import { isDateTime } from '../../../../backend/src/filter';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-media-handler',
  templateUrl: './media-handler.component.html',
  styleUrls: ['./media-handler.component.css']
})
export class MediaHandlerComponent {
  @Input() data: any

  folderRouteBase= `/${NetworkConstants.STORE_MEDIA}/`
  currentlyActiveFolder = `/${NetworkConstants.STORE_MEDIA}/`//`${NetworkConstants.COLLECTION_CONTEXTS}/${this.settingsService.contextId$}`
  currentlyActiveFolderRoute = [NetworkConstants.STORE_MEDIA]//`${NetworkConstants.COLLECTION_CONTEXTS}/${this.settingsService.contextId$}`
  currentlyActiveFolderName = `${NetworkConstants.STORE_MEDIA}`
  componentRef: ComponentRef<any>|undefined = undefined
  @ViewChild(InjectComponentDirective, {static: true}) view: InjectComponentDirective;
  folder:any = []
  folderRoute:any
  emptyMedia = false
  navigationPath: any[] = []
  showBack = false
  filteredFolder:any = []
  viewStyle:string = 'item'
  maxSizeInBytes = 25 * 1024 * 1024;

  constructor( private settingsService: SettingsService,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private commentService: CommentsService,
    private storageService: StorageService,
    public dialog: MatDialog,
    private uploadService: UploadService,
    private translate: TranslateService,
    private imageCompressionService: ImageCompressionService,
    private http: HttpClient
    ){
    }

  ngOnInit():void{
    this.getDataStorage()
  }
  
  injectView(route: string, values, form, view) {
    this.commentService.progressSpin.emit(true)
    if( route == `/${NetworkConstants.STORE_MEDIA}/`){
      this.showBack = false
    }else{
      this.showBack = true
    }
    values.view = this.viewStyle

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(form);// Resolving the component factory
    this.viewContainerRef.clear();// Clearing the container before adding the new component
    const componentRef = this.viewContainerRef.createComponent(MediaFolderContentComponent)// Creating the component dynamically inside the view container
    componentRef.instance.data = values

    // console.log('navigationPath',this.navigationPath)
    this.commentService.progressSpin.emit(false)
  }

  injectBaseMediaView(){
    this.commentService.progressSpin.emit(true)
    const currentlyActiveFolderRoute = deepCopy(this.currentlyActiveFolderRoute)

    this.currentlyActiveFolder = `/${NetworkConstants.STORE_MEDIA}/`
    let values:  any
    const listItem = new MediaInsideFolderData(
      this.currentlyActiveFolder,
      currentlyActiveFolderRoute,
      this.currentlyActiveFolderName,
      this.folder,
      this.viewStyle,
      MediaFolderContentComponent
    )
    listItem.addListener(this)
    values=listItem
    this.navigationPath.push({currentlyActiveFolder:this.currentlyActiveFolder,currentlyActiveFolderRoute, currentlyActiveFolderName: this.currentlyActiveFolderName,values, form: MediaFolderContentComponent })
    this.injectView(this.currentlyActiveFolder, values, MediaFolderContentComponent, this.viewStyle)
  }

  navBack(){
    if( this.currentlyActiveFolderRoute.length>1) { //this.navigationPath.length>1 ||
      this.navigationPath.pop()
      const {currentlyActiveFolder,currentlyActiveFolderRoute, currentlyActiveFolderName,values, form } = this.navigationPath[this.navigationPath.length -1]
      this.currentlyActiveFolder = currentlyActiveFolder
      this.currentlyActiveFolderRoute = currentlyActiveFolderRoute
      this.currentlyActiveFolderName = currentlyActiveFolderName
      this.injectView(currentlyActiveFolder, values, form, this.viewStyle)
    }
  }


  triggerFileInput() {
    document.getElementById('myfile-upload-media')?.click();
  }

  readURL(event:any){
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
        if( file.type.startsWith('image'))  {
          console.log('--image')
          this.compressImageFileAndUpload(file, this.folder.length)
        }else{
          console.log('--file')
          this.checkFileSizeAndUpload(file, this.folder.length)
        }
    }
  }

  addFileToFolder(file, index){
    if( file?.type.startsWith('image'))  {
      console.log('--image')
      this.compressImageFileAndUpload(file, index)
    }else{
      console.log('--file')
      this.checkFileSizeAndUpload(file, index)
    }  
  }

  async compressImageFileAndUpload(file, index){
    const fileToUpload = file;
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
    const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
  
    if (fileToUpload.size > maxAllowedSizeInBytes) {// File is too large for both upload and compression
      const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    }
  
    if (fileToUpload.size > maxSizeInBytes) {// File is between 2 MB and 20 MB, attempt compression
      try {
        const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
        if (!compressedImage) {
          const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }else{
          await this.uploadFile(compressedImage, index)
        }
      } catch (error) {
        console.error("Compression error:", error);
        const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
        this.commentService.addSnackBar.emit(message);
        return;
      }
    } else {// File is less than or equal to 2 MB, no compression needed
      await this.uploadFile(fileToUpload, index)

    }
  }

  async checkFileSizeAndUpload(file, index){
    const fileToUpload = file;
    const maxSizeInBytes = this.maxSizeInBytes
  
    if (file.size > maxSizeInBytes) {
      const message = this.translate.instant("SnackBarConstants.VIDEO_TOO_LARGE", " max. 25MB");
      this.commentService.addSnackBar.emit(message);
      return;
    } else {// File is less than or equal to 25 MB
      await this.uploadFile(fileToUpload, index)
    }
  }

  async uploadFile(file, index){
    try{
      this.commentService.progressSpin.emit(true)
      const a = await  this.uploadService.addToStorage(file,this.folderRoute, index).then((value)=> {
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.UPLOAD_FILE_OK")
        this.commentService.addSnackBar.emit(message)

        this.navigationPath.pop()
        this.getDataStorageFolder(this.currentlyActiveFolder, this.currentlyActiveFolderRoute)
      })

    }catch(error){
      console.log(error)
      const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  //--------get strorage base folder
  getDataStorage(){
    try{
      this.folderRoute= `/${NetworkConstants.STORE_MEDIA}/`
      this.commentService.progressSpin.emit(true)
      this.storageService.getStorageMediaOfContextId(this.folderRoute).subscribe( value => {

        if(value.length ==  0)
          this.emptyMedia = true
        else
          this.emptyMedia = false
        this.folder = value
        this.filteredFolder = deepCopy(value)
        this.commentService.progressSpin.emit(false)
        this.injectBaseMediaView()
      })
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  //--------get strorage folder
  getDataStorageFolder(route, routeUrlSegment){
    try{
      this.folderRoute = route
      this.commentService.progressSpin.emit(true)
      this.storageService.getStorageMediaOfContextId(this.folderRoute).subscribe( value => {

        if(value.length ==  0)
          this.emptyMedia = true
        else
          this.emptyMedia = false
        this.folder = value
        this.filteredFolder = deepCopy(value)
        const currentlyActiveFolderRoute = deepCopy(this.currentlyActiveFolderRoute)

        let values:  any
        const listItem = new MediaInsideFolderData(
          this.currentlyActiveFolder,
          currentlyActiveFolderRoute,
          this.currentlyActiveFolderName,
          this.folder,
          this.viewStyle,
          MediaFolderContentComponent
        )
        listItem.addListener(this)
        values=listItem
        this.commentService.progressSpin.emit(false)

        this.navigationPath.push({currentlyActiveFolder:this.currentlyActiveFolder,currentlyActiveFolderRoute, currentlyActiveFolderName: this.currentlyActiveFolderName,values, form: MediaFolderContentComponent })
        this.injectView(this.currentlyActiveFolder, values, MediaFolderContentComponent, this.viewStyle)
      })
      this.commentService.progressSpin.emit(false)
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  openFilePreview( file){
    // console.log('openFilePreview handkler...',file)

    if(!file.folder){
      const currentlyActiveFolderRoute = deepCopy(this.currentlyActiveFolderRoute)
      let values:  any//[] = []
      const listItem = new MediaFilePreviewDialog(
        this.currentlyActiveFolder,
        currentlyActiveFolderRoute,
        this.currentlyActiveFolderName,
        file,
        this.viewStyle,
        MediaPreviewDialogComponent
      )
      listItem.addListener(this)
      values=listItem

      let dialogRef = this.dialog.open(MediaPreviewDialogComponent, {
        data: {
          data: values,
          file: file,
          name:file.name,
          type:this.checkFileType(file),
          contextId: this.settingsService.contextId$
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[0]){
          this.navigationPath.pop()
          this.getDataStorageFolder(this.currentlyActiveFolder, this.currentlyActiveFolderRoute)
        }
      });
    }
    else{
      this.currentlyActiveFolder += `${file.folder}/`
      this.currentlyActiveFolderRoute.push(file.folder)
      this.currentlyActiveFolderName = file.name
      this.getDataStorageFolder(this.currentlyActiveFolder, this.currentlyActiveFolderRoute)
    }
  }

  checkFileType(file){
    let type 

    if(file.folder)
      type = 'folder'
    if(file.metadata){
      if(file.metadata?.contentType === 'application/pdf')
        type = 'file'

      if(file.metadata?.contentType.startsWith('image'))
        type = 'image'
        
      if(file.metadata?.contentType.startsWith('video') )
        type = 'video'

      if(!file.metadata?.contentType.startsWith('image') && !file.metadata?.contentType.startsWith('video'))
        type='file'
        
    }else{

    }

    return type
  }


  deleteFile(file){
    try{
      this.commentService.progressSpin.emit(true)

      this.storageService.deleteFile(file.reference).then( value => {
        this.navigationPath.pop()
        this.getDataStorageFolder(this.currentlyActiveFolder, this.currentlyActiveFolderRoute)
        this.commentService.progressSpin.emit(false)
      })
    }catch(e){
      console.log(e)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.ZIP_EXPORT_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  downloadFile(file){
    try{
      this.commentService.progressSpin.emit(true)
      const date = new Date()    
      this.http.get(file.url, { responseType: 'blob' }).subscribe((response: Blob) => {
      const link = document.createElement('a');
      const blob = new Blob([response], { type: response.type });
      link.href = URL.createObjectURL(blob);
      link.download = `${ isDateTime(date)}_${file.name.replace(' ', '')}`;
      link.click();
      this.commentService.progressSpin.emit(false)

    });
    }catch(e){
      console.log(e)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.ZIP_EXPORT_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  setViewStyle(style){
    this.viewStyle = style
  }

}
