import { Injectable } from '@angular/core';
import { NetworkConstants } from 'src/network.constants';
import { SettingsService } from './settings.service';
import { newTaskTemplate } from '../../../../backend/src/defaultFieldsTaskTemplates.dto';
import { collection, deleteDoc, doc, getFirestore, setDoc, updateDoc, deleteField } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class WorkTaskTemplatesService {
  db = getFirestore()

  constructor(private settingsService: SettingsService) { }

  async createWorkTaskTemplateGroup(data):Promise<string>{
    return new Promise(async (resolve, reject) => {
      const docRef = doc( collection(this.db,NetworkConstants.COLLECTION_CONTEXTS, this.settingsService.contextId$, NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES) )
      const newId = docRef.id
      let newWorktt = newTaskTemplate
      newWorktt['creationDate'] = new Date()
      newWorktt['timeStamp'] = new Date()
      newWorktt['id'] = newId
      newWorktt['taskType'] = 'create'
      if(data['groupId'])
        newWorktt['groupId'] = data['groupId']
      if(data['title'])
        newWorktt['title'] = data['title']

      try{
        await setDoc( docRef, newWorktt)
        resolve(newId)
      }catch (error) {
        reject(error)
      }
    })

  }

  updateWorkTaskTemplate(workTaskTemplate:any, id:any):Promise<void>{
    return new Promise(async (resolve, reject) => {
       if(!workTaskTemplate['id']){
          workTaskTemplate['id'] = id
       }
      try{
        workTaskTemplate['timeStamp']= new Date()
        const docRef = doc(this.db, NetworkConstants.COLLECTION_CONTEXTS, this.settingsService.contextId$,NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES, workTaskTemplate.id )
        await updateDoc( docRef, workTaskTemplate)
        
        resolve()
      }catch(error){
        reject(error)
      }
    }) 
  }

  deleteworkTaskTemplate(workTaskTemplateId: any):Promise<void>{
    return new Promise(async (resolve, reject) => {
      try {
        const docRef = doc(this.db, NetworkConstants.COLLECTION_CONTEXTS, this.settingsService.contextId$,NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES, workTaskTemplateId)
        const d  = await deleteDoc( docRef)
        resolve(d)
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }

  removeTaskFromGroup(task,contextId){
    return new Promise<void>(async (resolve, reject) => {
      try {
        task['timeStamp'] = new Date()
        task.groupId = deleteField()
        const docRef = doc(this.db, NetworkConstants.COLLECTION_CONTEXTS, this.settingsService.contextId$,NetworkConstants.COLLECTION_WORK_TASK_TEMPLATES, task.id )
        await updateDoc( docRef, task)
        console.log('removeTaskFromGroup', task.id)

        resolve()
      } catch (error) {
        reject(error)  
        console.log(error)      
      } 
    })
  }

}