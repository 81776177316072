import { Component, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { SettingsService } from '../../service/settings.service';
import { CommentsService } from '../../service/comments.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportService } from '../../service/report.service';
import { deepCopy, removeUndefined } from '../../../../../backend/utils/object';
import { ReportFilterService } from '../../service/report-filter.service';
import { Filter , FilterEqual, filterObjects, isDate, isDateForSorting, isDateGetTimeSeconds, isDateTimeGetTimeSeconds} from '../../../../../backend/src/filter';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-reports-item-tridys-list-filters',
  templateUrl: './reports-item-tridys-list-filters.component.html',
  styleUrls: ['./reports-item-tridys-list-filters.component.css']
})
export class ReportsItemTridysListFiltersComponent {
  @ViewChildren(MatMenuTrigger) menuTriggers: QueryList<MatMenuTrigger>;
  reportId:any
  routerUrl:any
  idToShow:string
  reportsList:any
  reportData:any
  reportDisplayName:any
  tridysList:any
  contentTypeId:any
  contentTypeName:any
  displayName:any
  displayedColumns: string[] = [ 'timeStamp', "creationDate"];
  breakpoint: number;
  paginatorSize = this.settingService.paginator$
  searchText = ''; 
  reportsDataList:any
  showFiller = false;
  filterForm!:FormGroup
  reportTemplate:any
  panelOpenState = false;
  lastRouteValue:any
  resultFields:any = []
  filtersValues:any = []

  allFiltersWithValues$ = new BehaviorSubject<Filter[]>([])

  filterTypeInput= {
    'string': 'text',
    'number':'number',
    'decimal':'decimal',
    'boolean':'boolean',
    'date':'date',
    'time':'time',
    'dateTime':'datetime-local',
    'media':'text'
  }

  operatorType = {
    '=':'filters.operators.equal',
    '!=':'filters.operators.not_equal',
    '>':'filters.operators.greather_than',
    '<':'filters.operators.less_than',
    '>=':'filters.operators.greather_than_equal_to',
    '<=':'filters.operators.less_than_equal_to',
    'in':'filters.operators.in',
    '!in':'filters.operators.not_in',
    'contains':'filters.operators.contains',
    '!contains':'filters.operators.not_contains',
    'startsWith':'filters.operators.startsWith',
    '!startsWith': 'filters.operators.not_startsWith'
  }

  routeQueryParams:any
  reportQueryParams:any
  constructor(private router: Router,
    private route: ActivatedRoute,
    private settingService: SettingsService,
    private commentService: CommentsService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private reportService:ReportService,
    private reportFilterService:ReportFilterService,
    private translate:TranslateService
    ){
    this.routerUrl=this.route.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    this.reportId = this.routerUrl.value[this.routerUrl.value.length-1].path
    this.lastRouteValue=this.routerUrl.value[this.routerUrl.value.length-2].path  
  }

  ngOnInit(){
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 6;
    const last = this.reportService.getReportIdLastOpened()//last==reportId
    this.reportTemplate = this.reportService.getTemplate(last)//this.idToShow

    this.reportService.lastReportTemplate$.subscribe( value => {
      this.reportTemplate = value

      this.initializeFilterForm()

      if(this.reportTemplate['resultFields']){
        if(this.reportTemplate.length!=0){
          this.displayedColumns = [ ]
        }
        this.reportTemplate['resultFields'].forEach( field => {
          this.resultFields.push(field)
          this.displayedColumns.push(field.key)
        })

      }else{

        this.displayedColumns = [ ]
        if(this.reportTemplate[last]){
          this.reportsList = this.reportsList[last]
          if(this.reportTemplate[last]['resultFields']){
            this.reportTemplate[last]['resultFields'].forEach( field => {
              this.resultFields.push(field)
              this.displayedColumns.push(field.key)
            })
          }
        }
      }
    })

    this.reportService.lastReportResults$.subscribe(value=> {
      this.reportsList = value
    })

    this.reportFilterService.lastReportQueryParams$.subscribe( value => {
      this.reportQueryParams= value

      this.router.navigate([], {
        relativeTo: this.route, 
        queryParams: this.reportQueryParams,
        // queryParamsHandling: 'merge' 
      });
    })

    this.reportFilterService.selectedFilterValue$.subscribe (value => {
      this.allFiltersWithValues$.next(value)
    })

    this.routeQueryParams = this.route.snapshot.queryParams
    this.route.queryParams.pipe( ).subscribe(value => {
      this.routeQueryParams = value
      // console.log('routeQueryParams', value)
    })

    if(this.lastRouteValue=="filter"){
      // this.filtersValues = this.reportService.getFilterValues()
    }else{
      if(this.reportTemplate.filters){
        this.filtersValues = this.reportTemplate.filters
      }
    }

    this.filterForm= this.fb.group({})
    this.loadReportTemplate()

    this.reportsDataList = this.settingService.allReports$
    if(this.reportsDataList){
      this.reportsDataList.forEach(element => {
        if(element.id == this.reportId){
          this.reportData = element
          this.reportDisplayName = element.displayName
          this.contentTypeId = element.contentTypeId
          this.displayName = element.displayName
          // this.getTridysList()
          // this.getTridysContentTypeName()
        }
      });
    }


  }

  get availableFilters() {
    this.reportTemplate = this.reportService.lastReportTemplateCahnges$.value
    this.filtersValues = this.reportTemplate.filters
    return this.reportService.lastReportTemplateCahnges$.value
  }

  async initializeFilterForm(){
    if(this.filterForm.value.length!=0){
      this.clearFilters()
    }
    this.filterForm= this.fb.group({}) //initialize the form of filter to be able to put values for each

    if(this.reportTemplate){
      if(this.reportTemplate.filters){
        const filters = deepCopy(this.reportTemplate.filters)

        await filters.forEach(filter => {
          if(filter.key){
            if(this.filtersValues && this.filtersValues[filter.key]){
              if(filter.operator =='inrange' ){
                this.filterForm.addControl(filter.key+'_range1',new FormControl(this.filtersValues[filter.key+'_range1']))
                this.filterForm.addControl(filter.key+'_range2',new FormControl(this.filtersValues[filter.key +'_range2']))
              }else{
              this.filterForm.addControl(filter.key,new FormControl(this.filtersValues[filter.key]))
              }
            }else{
              if(filter.type=='boolean')
              this.filterForm.addControl(filter.key,new FormControl(false))
              else{
                if(filter.operator =='inrange' ){
                  let range1 = filter.key+'_range1'
                  let range2 = filter.key+'_range2'
                  if(filter.type == 'dateTime'){
                    this.filterForm.addControl(range1,new FormControl({value:undefined, disabled:false}))
                    this.filterForm?.get(range1)?.valueChanges.subscribe( (value) => {
                      this.setDateTimeYearFormat(value, range1)
                    })

                    this.filterForm.addControl(range2,new FormControl({value:undefined, disabled:false}))
                    this.filterForm?.get(range2)?.valueChanges.subscribe( (value) => {
                      this.setDateTimeYearFormat(value, range2)
                    })
                  }else{
                  this.filterForm.addControl(range1,new FormControl({value:undefined,disabled: false}))
                  this.filterForm.addControl(range2,new FormControl())
                  }
                }else{
                  let key = filter.key
                  if(filter.type == 'dateTime'){
                    this.filterForm.addControl(key,new FormControl({value:undefined, disabled:false}))
                    this.filterForm?.get(key)?.valueChanges.subscribe( (value) => {
                      this.setDateTimeYearFormat(value, key)
                    })
                  }
                  else
                  this.filterForm.addControl(key,new FormControl({value:undefined, disabled:false}))
                }
              }
            }
            const nameOptions = filter.key + '_options'
            this.filterForm.addControl(nameOptions,new FormControl(filter.options))
            const name = filter.key + '_operator'
            this.filterForm.addControl(name,new FormControl(filter.operator))
            const nametype = filter.key + '_type'
            this.filterForm.addControl(nametype,new FormControl(filter.type))
          }
        });
        
        this.reportFilterService.initializeFilters( filters)//update filters on the service
        this.loadQueryParams()
      }
    }

  }

  loadQueryParams(){
    if(Object.keys(this.reportFilterService.lastReportQueryParams$.value)?.length!=0/*this.allFiltersWithValues$.value?.length==0*/){

      let keysValues:[string, any][] = Object.entries(this.reportFilterService.lastReportQueryParams$.value)
      keysValues.forEach( keyValue => {
        const key = keyValue[0]
        const value = keyValue[1]
        const decodedValue = decodeURIComponent(value)
        const decodedKey = decodeURIComponent(key)
        const filter = this.reportTemplate?.filters?.find(filter => filter.key == decodedKey)

        let valueForForm:any

        switch( filter.type){
          case 'string':{
            if(filter.options){
              const options = decodedValue.split(',')
              valueForForm = options
            }else
              valueForForm = decodedValue
            // console.log('/////////String', filter.operator, filter.key,valueForForm)
            this.filterForm.patchValue({[filter.key]:valueForForm})
            
            break;}
          case 'number':{
            if(filter.operator == 'inrange'){
              const ranges = decodedValue.split('/')
              const range1 = parseInt(ranges[0])
              const range2 = parseInt(ranges[1])
              this.filterForm.patchValue({[filter.key+'_range1']:range1})
              this.filterForm.patchValue({[filter.key+'_range2']:range2})
              // console.log('/////////number', filter.operator, filter.key,range1, range2)

            }else{
              if(filter.options){
                const options = decodedValue.split(',')
                valueForForm = options
              }else
                valueForForm = parseInt(decodedValue)

            // console.log('/////////number', filter.operator, filter.key,valueForForm)
                this.filterForm.patchValue({[filter.key]:valueForForm})
            }

            
            break;}
          case 'decimal':{
            if(filter.operator == 'inrange'){
              const ranges = decodedValue.split('/')
              const range1 = parseFloat(ranges[0])
              const range2 = parseFloat(ranges[1])
              this.filterForm.patchValue({[filter.key+'_range1']:range1})
              this.filterForm.patchValue({[filter.key+'_range2']:range2})
              // console.log('/////////decimal', filter.operator, filter.key,range1, range2)

            }else{
              if(filter.options){
                const options = decodedValue.split(',')
                valueForForm = options
              }else
                valueForForm = parseFloat(decodedValue)
              
              this.filterForm.patchValue({[filter.key]:valueForForm})
              // console.log('/////////decimal', filter.operator, filter.key,valueForForm)
            }
            
            break;
          }
          case 'boolean':{
            valueForForm = (decodedValue === '1')
            this.filterForm.patchValue({[filter.key]:valueForForm})
            // console.log('/////////boolean', filter.operator, filter.key,valueForForm)
            
            break;}
          case 'date':{
            if(filter.operator == 'inrange'){
              const ranges = decodedValue.split('/')
              const range1 = isDateForSorting(parseInt(ranges[0]),'dateFilter')
              const range2 = isDateForSorting(parseInt(ranges[1]),'dateFilter')
              this.filterForm.patchValue({[filter.key+'_range1']:range1})
              this.filterForm.patchValue({[filter.key+'_range2']:range2})
              // console.log('/////////date', filter.operator, filter.key,range1, range2)

            }else{
              const seconds = parseInt(decodedValue)
              const date = isDateForSorting(seconds,'dateFilter')
              valueForForm = date
              this.filterForm.patchValue({[filter.key]:valueForForm})
              // console.log('/////////date', filter.operator, filter.key,valueForForm)

            }
            
            break;
          }
          case 'dateTime':{
            if(filter.operator == 'inrange'){
              const ranges = decodedValue.split('/')
              const range1 = isDateForSorting(parseInt(ranges[0]))
              const range2 = isDateForSorting(parseInt(ranges[1]))

              let date = new Date(parseInt(ranges[0])).toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'}).split('.')
              let valuerange1 = date[2] +'-'+ date[1] +'-' + date[0] + 'T' + new Date(ranges[0]).toLocaleTimeString('de-DE',{ hour: "2-digit", minute: "2-digit" })

              let date2 = new Date(parseInt(ranges[1])).toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'}).split('.')
              let valuerange2 = date2[2] +'-'+ date2[1] +'-' + date2[0] + 'T' + new Date(ranges[1]).toLocaleTimeString('de-DE',{ hour: "2-digit", minute: "2-digit" })
              // console.log('/////////dateTime', filter.operator, filter.key,valuerange1, valuerange2)

              this.filterForm.patchValue({[filter.key+'_range1']:valuerange1})
              this.filterForm.patchValue({[filter.key+'_range2']:valuerange2})


            }else{
              const seconds = parseInt(decodedValue)
              // const date = isDateForSorting(seconds)
              let date = new Date(seconds).toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'}).split('.')
              let valuedate = date[2] +'-'+ date[1] +'-' + date[0] + 'T' + new Date(seconds).toLocaleTimeString('de-DE',{ hour: "2-digit", minute: "2-digit" })

              this.filterForm.patchValue({[filter.key]:valuedate})
              // console.log('/////////dateTime', filter.operator, filter.key,valuedate)

            }
            
            break;
          }
          case 'time':{
            if(filter.operator == 'inrange'){
              const ranges = decodedValue.split('/')
              const range1 = ranges[0]
              const range2 = ranges[1]

              this.filterForm.patchValue({[filter.key+'_range1']:range1})
              this.filterForm.patchValue({[filter.key+'_range2']:range2})
              // console.log('/////////time', filter.operator, filter.key,range1, range2)

            }else{
              valueForForm = decodedValue
              this.filterForm.patchValue({[filter.key]:valueForForm})
              // console.log('/////////time', filter.operator, filter.key,valueForForm)

            }
            break;
          }
          case 'media':{
            valueForForm = decodedValue
            this.filterForm.patchValue({[filter.key]:valueForForm})
            // console.log('/////////media', filter.operator, filter.key,valueForForm)

            break;
          }
        }

        if(!this.routeQueryParams[encodeURIComponent(filter.key)]){
          const newones =deepCopy( this.routeQueryParams)
          newones[encodeURIComponent(filter.key)] = encodeURIComponent(valueForForm)
          // console.log('comprae to lastReportQueryParams',this.reportFilterService.lastReportQueryParams$.value, this.reportQueryParams, newones)
          this.router.navigate([], {
            relativeTo: this.route, 
            queryParams: this.reportQueryParams,
            // queryParamsHandling: 'merge' 
          });
        }
        this.activeFilterValue(filter.key, filter)
      })
    }else{
      if(this.routeQueryParams?.length!=0){
        this.router.navigate([], {
          relativeTo: this.route, 
          queryParams: {},
        });
      }
    }
  }

  removeQueryParam(removedFilter){
    // const urlQueryParams = { ...this.route.snapshot.queryParams };//this is for the initial parameters
    let urlQueryParams = deepCopy(this.routeQueryParams)

    if(urlQueryParams[removedFilter]){
      delete urlQueryParams[removedFilter];// Remove the query parameter
      
      // this.router.navigate([], {// Update the URL with the new query params
      //   relativeTo: this.route,
      //   queryParams: urlQueryParams,
      // });

      this.reportFilterService.clearQueryParam(removedFilter)
      // this.reportFilterService.setQueryParams(urlQueryParams)
    }
  }

  setDateTimeYearFormat(value, key){
    try{
      const controlDate = new Date(value);
      const year = controlDate.getFullYear()
      if(year.toString()?.length > 4 ){
        const shortyear = year.toString().substring(0, 4);
        controlDate.setFullYear(Number(year))
        this.filterForm.patchValue({[key]: value.replace(year, shortyear)})
      }
    }catch(erro){}
  }

  closeOtherMenus(currentTrigger: MatMenuTrigger) {
    this.menuTriggers.forEach(trigger => {
      if (trigger !== currentTrigger) {
        trigger.closeMenu();
      }
    });
  }

  createNewFilters(){//with backend call
    const firstFilters = deepCopy(this.reportTemplate.filters)

    let newFiltersTemplate = []
    firstFilters.forEach((filter, index) => {
      const key = filter.key ? filter.key : undefined
      const description = filter.description ? filter.description : undefined
      const name = filter.name ? filter.name : undefined
      const operator = this.filterForm.value[filter.key+'_operator'] ? this.filterForm.value[filter.key+'_operator'] : undefined
      const type = this.filterForm.value[filter.key+'_type'] ?  this.filterForm.value[filter.key+'_type'] :  undefined
      const value = this.filterForm.value[filter.key] ?  this.filterForm.value[filter.key] :  undefined
      const newFilter = {
        key, 
        value
      }

      removeUndefined(newFilter)
      if(newFilter.value)
        newFiltersTemplate.push(newFilter)
    })

    const data = { 
      contentTypeId: this.reportTemplate.contentTypeId,
      displayName:this.reportTemplate.displayName,
      id: this.reportId,
      filters: newFiltersTemplate
     }

    // try{
    //   this.commentService.progressSpin.emit(true)
    //   const response = this.reportService.reports_createReport(this.settingService.contextId$, data, 'applyFilter')
    //   setTimeout(() => {
    //     this.reportsList = this.reportService.getReports(this.reportId)
    //     this.dataSource = new MatTableDataSource<any>(this.reportsList);
    //     this.updateTable()
    //     this.reportTemplate = this.reportService.getTemplate(this.reportId)
    //     if(this.lastRouteValue=="filter"){
    //       this.filtersValues = this.reportService.getFilterValues()
    //     }
    //   }, 500);
    // }catch(error){
    //   console.log(error)
    //   this.commentService.progressSpin.emit(false)
    // }
  }


  filterTable(){
    const filterBy = this.getAllFiltersValues()
  }

  getAllFiltersValues(){
    const firstFilters = deepCopy(this.reportTemplate.filters)

    let newFiltersTemplate = []
    firstFilters.forEach((filter, index) => {
      const key = filter.key ? filter.key : undefined
      const description = filter.description ? filter.description : undefined
      const name = filter.name ? filter.name : undefined
      const displayName = filter.displayName ? filter.displayName : undefined
      const operator = this.filterForm.value[filter.key+'_operator'] ? this.filterForm.value[filter.key+'_operator'] : undefined
      const type = this.filterForm.value[filter.key+'_type'] ?  this.filterForm.value[filter.key+'_type'] :  undefined
      const value = this.filterForm.value[filter.key] ?  this.filterForm.value[filter.key] :  undefined
      const newFilter = {
        key, 
        value
      }

      removeUndefined(newFilter)
      if(newFilter.value)
        newFiltersTemplate.push(newFilter)
    })

    return newFiltersTemplate
  }

  loadReportTemplate(){
    const last = this.reportService.getReportIdLastOpened()//last==reportId

    ////////////////////////this is the same as initializeFilterForm()
    // if(this.reportTemplate){
    //   if(this.reportTemplate.filters){
    //     const filters = deepCopy(this.reportTemplate.filters)
    //     this.reportFilterService.initializeFilters( filters)
    //     filters.forEach(filter => {
    //       if(filter.key){
    //         if(this.filtersValues && this.filtersValues[filter.key]){
    //           this.filterForm.addControl(filter.key,new FormControl(this.filtersValues[filter.key]))
    //         }else{
    //           this.filterForm.addControl(filter.key,new FormControl(undefined))
    //         }
    //         const name = filter.key + '_operator'
    //         this.filterForm.addControl(name,new FormControl(filter.operator))
    //         const nametype = filter.key + '_type'
    //         this.filterForm.addControl(nametype,new FormControl(filter.type))
    //         // this.reportFilterService.selectedFilterValue$()
    //       }
    //     });
    //   }
    // }
    ////////////////////////
      this.initializeFilterForm()

    if(this.reportTemplate['resultFields']){
      if(this.reportTemplate.length!=0){
        this.displayedColumns = [ ]
      }
      this.reportTemplate['resultFields'].forEach( field => {
        this.resultFields.push(field)
        this.displayedColumns.push(field.key)
      })

    }else{

      this.displayedColumns = [ ]
      if(this.reportTemplate[last]){
        this.reportsList = this.reportsList[last]
        if(this.reportTemplate[last]['resultFields']){
          this.reportTemplate[last]['resultFields'].forEach( field => {
            this.resultFields.push(field)
            this.displayedColumns.push(field.key)
          })
        }
      }
    }
  }

////////////////////////////////

  clearFilters(){
    const filters = deepCopy(this.reportTemplate.filters)

    filters.forEach(filter => {
      if(filter.key){

        this.clearFilterValue(filter.key, filter)
        // this.removeQueryParam(encodeURIComponent(filter.key))

        if(filter.type=='boolean')
          this.filterForm.setControl(filter.key,new FormControl(false))
        else{
          if(filter.operator == 'inrange'){
            this.filterForm.setControl(filter.key+'_range1',new FormControl(undefined))
            this.filterForm.setControl(filter.key+'_range2',new FormControl(undefined))
          }else
          this.filterForm.setControl(filter.key,new FormControl(undefined))
        }
      }
    });
      // this.createNewFilters()
  }

  filterValueExists(key, filter){
    return this.allFiltersWithValues$.value.find( filter => filter.filterKey == key)
  }


  clearFilterValue(key, filter){
    if(this.filterForm.value[key]!=undefined){
      this.filterForm.patchValue({[key]: undefined})
      if(this.allFiltersWithValues$.value && this.allFiltersWithValues$.value.length!=0 && this.allFiltersWithValues$.value.find( filter => filter.filterKey == key))
      this.reportFilterService.clearFilter( filter, key, this.filterForm.value[key])
    }

    if(filter.operator == 'inrange'){
      if(this.filterForm.value[key+'_range1']!=undefined || this.filterForm.value[key+'_range2']!=undefined){
        this.filterForm.patchValue({[key+'_range1']: undefined, [key+'_range2']: undefined})
        if(this.allFiltersWithValues$.value && this.allFiltersWithValues$.value.length!=0 && this.allFiltersWithValues$.value.find( filter => filter.filterKey == key))
        this.reportFilterService.clearFilter( filter, key, this.filterForm.value[key])

      }
    }


  }

  clearFilterValueQuery(key, filter){
    this.reportFilterService.clearQueryParam(encodeURIComponent(key))
  }

  handleEnter(event: Event, key, filter, trigger) {
    const keyboardEvent = event as KeyboardEvent;
    keyboardEvent.preventDefault();
    keyboardEvent.stopPropagation();
    this.activeFilterValue(key, filter);
    trigger.closeMenu();
  }

  activeFilterValue(key, filter){
    let value = this.filterForm.value[key]
    if(filter.type =='date')
      value = new Date(this.filterForm.value[key])

    if(filter.operator == 'inrange')
      value = this.filterForm.value[key+'_range1'] + '/' +this.filterForm.value[key+'_range2']

    this.reportFilterService.activeFilter( filter, key, value)

    let filterValue = deepCopy(value)
    let valueToFilter = deepCopy(value)

      if(filter.operator == 'inrange'){
        switch(filter.type){
          case 'number':{break;}
          case 'decimal':{break;}
          case 'time':{break;}
          case 'dateTime':{
            const range1 = isDateTimeGetTimeSeconds(this.filterForm.value[key+'_range1'])
            const range2 = isDateTimeGetTimeSeconds(this.filterForm.value[key+'_range2'])
            valueToFilter = range1 + '/' + range2
            break;
          }
          case 'date':{
            const range1 = isDateGetTimeSeconds(this.filterForm.value[key+'_range1'])
            const range2 = isDateGetTimeSeconds(this.filterForm.value[key+'_range2'])
            valueToFilter = range1 + '/' + range2
            break;
          }

        }
      }else{
        if (filter.options)
          valueToFilter = filterValue.join();

        if (filter.type == 'date')
          valueToFilter = isDateGetTimeSeconds(filterValue);

        if (filter.type == 'dateTime')
          valueToFilter = isDateTimeGetTimeSeconds(filterValue);

          if(filter.type == 'boolean')
           valueToFilter = valueToFilter ? '1' : '0';

      }

      const filterForUrl = encodeURIComponent(filter.key) + '=' + encodeURIComponent(valueToFilter) ;

      this.router.navigate([], {
        relativeTo: this.route, 
        queryParams: { [encodeURIComponent(filter.key)]: encodeURIComponent(valueToFilter) },
        queryParamsHandling: 'merge' // This keeps the other query parameters intact (e.g., `filter` will remain)
      });

    this.reportFilterService.addQueryParam([encodeURIComponent(filter.key)], encodeURIComponent(valueToFilter))

  }

  resetResultsReportOpen(){
    this.clearFilters()
    const element = deepCopy(this.reportTemplate)

    if(element.contentTypeId && element.resultFields && element.resultFields.length!=0){
      let firstFilters
      
      if(element.filters)
      firstFilters=deepCopy(element.filters)
      else
      firstFilters=[]
    
      let newFiltersTemplate = []
      firstFilters.forEach((filter, index) => {
        const key = filter.key ? filter.key : undefined
        const value = filter.value ?  filter.value :  undefined
        const newFilter = {
          key, 
          value
        }
    
        removeUndefined(newFilter)
        if(newFilter.value)
          newFiltersTemplate.push(newFilter)
      })
    
      let data = { 
        contentTypeId: element.contentTypeId,
        displayName:element.displayName,
        id: element.id,
        filters: newFiltersTemplate,
        resultFields: element.resultFields
      }
    
      if(element.fixedfilters && element.fixedFilters.length!=0){
        data['fixedFilters']= element.fixedFilters
      }
    
      try{
        const list = this.reportService.reports_createReport(this.settingService.contextId$, data)

        this.reportFilterService.setQueryParams({})
      }catch(error){
        console.log(error)
        const message = this.translate.instant("SnackBarConstants.REPORT_LOAD_FAILED")
        this.commentService.addSnackBar.emit(message)
    
      }
    }else{
      const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
      this.commentService.addSnackBar.emit(message)
    }
  }

  filterActive(key){
    // if(this.filterForm.get(key)){
      if(this.allFiltersWithValues$.value?.find( filter => filter.filterKey == key))
        return true
      else 
        return false
    // }else{
    //   return false
    // }
  }

}
