<h2 mat-dialog-title>{{'workTask.template_settings' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 rounded m-auto"  id="section" >

            <form [formGroup]="tridyTypeForm">
                <mat-form-field appearance="outline" class="w-50 pe-1">
                    <mat-label>{{'workTask.optional_title' | translate}}</mat-label>
                    <input matInput type="text" formControlName="displayTitle" name="title" class="form-control" [readonly]="!tasktemplateUserRights_update">
                </mat-form-field>
                
                <mat-form-field appearance="outline" class="w-50 ps-1">
                    <mat-label>{{'workTask.tridyType' | translate}}</mat-label>
                    <mat-select formControlName="contentTypeId">
                        <mat-option value="" [disabled]="!tasktemplateUserRights_update"> - </mat-option>
                        <mat-option *ngFor="let content of contentTypesList" value="{{content.id}}" [disabled]="!tasktemplateUserRights_update">{{content.displayName}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <mat-form-field appearance="outline" class="w-100" *ngIf="type!='Outgoing'">
                    <mat-label>{{'workTask.tridy_select' | translate}}</mat-label>
                    <mat-select formControlName="source">
                        <mat-option value="select" [disabled]="!tasktemplateUserRights_update">{{'workTask.select_list' | translate}}</mat-option>
                        <mat-option value="scan" [disabled]="!tasktemplateUserRights_update">{{'workTask.select_scan' | translate}} </mat-option>
                        <mat-option value="opticalScan" disabled>{{'workTask.select_opticalScan' | translate}} </mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field appearance="outline" class="w-50 pe-1" *ngIf="type!='Outgoing'">
                    <mat-label>{{'workTask.tridy_select' | translate}}</mat-label>
                    <mat-select formControlName="sources" multiple>
                        <mat-option value="select" [disabled]="!tasktemplateUserRights_update" >{{'workTask.select_list' | translate}}</mat-option>
                        <mat-option value="scanQR" [disabled]="!tasktemplateUserRights_update">{{'workTask.select_scanQR' | translate}} </mat-option>
                        <mat-option value="scanNFC" [disabled]="!tasktemplateUserRights_update">{{'workTask.select_scanNFC' | translate}} </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-50 ps-1" *ngIf="type!='Outgoing'">
                    <mat-label>{{'workTask.multiplicity.title' | translate}}</mat-label>
                    <mat-select formControlName="multiplicity">
                        <mat-option value="" [disabled]="!tasktemplateUserRights_update"> - </mat-option>
                        <mat-option *ngFor="let multiplicity of multiplicityList" value="{{multiplicity.value}}" [disabled]="!tasktemplateUserRights_update">{{multiplicity.translation | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                
                <div *ngIf="tridyTypeForm.value.multiplicity == 'custom'" class="d-flex flex-row">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'workTask.min' | translate}}</mat-label>
                        <input matInput type="number" formControlName="min" class="form-control" [readonly]="!tasktemplateUserRights_update">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'workTask.max' | translate}}</mat-label>
                        <input matInput type="number" formControlName="max" class="form-control" [readonly]="!tasktemplateUserRights_update">
                    </mat-form-field>
                </div>
                
                <label for="amount" class="text-uppercase mb-4">{{'taskTemplates.wt_amount' | translate}}</label>

                <div>
                    <mat-form-field appearance="outline" class="w-50 pe-1" >
                        <mat-label>{{'taskTemplates.wt_amount' | translate}}</mat-label>
                        <mat-select formControlName="amount">
                            <mat-option value='none' [disabled]="!tasktemplateUserRights_update"> {{'taskTemplates.wt_amount_none' | translate}} </mat-option>
                            <mat-option value="weight" [disabled]="!tasktemplateUserRights_update">{{'taskTemplates.wt_amount_weight' | translate}} </mat-option>
                            <mat-option value="quantity" [disabled]="!tasktemplateUserRights_update"> {{'taskTemplates.wt_amount_quantity' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-50 ps-1">
                        <mat-label>{{'taskTemplates.wt-unit' | translate}}</mat-label>
                        <input matInput type="string" formControlName="amountUnit" name="title" class="form-control" [readonly]="!tasktemplateUserRights_update " >
                    </mat-form-field>

                    <div class="form-control d-flex justify-content-between align-items-center p-3">
                        {{'taskTemplates.wt_amountInteger' | translate}}  
                        <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [checked]="tridyTypeForm.value.amountInteger" [disabled]="!tasktemplateUserRights_update"></mat-slide-toggle>
                        <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="amountInteger" ></mat-slide-toggle>
                    </div>
                </div>
        
                <div *ngIf="type=='Outgoing'">
                    <label class="text-uppercase my-4 text-muted">{{'workTask.onSubmit' | translate}}</label>
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'workTask.action' | translate}}</mat-label>
                        <mat-select 
                            formControlName="tridyAction" 
                            (selectionChange)="onTridyActionChange($event.value)"
                        >
                            <mat-option value=""> - </mat-option>
                            <mat-option value="writeTag">{{'workTask.action_new_tag' | translate}} </mat-option>
                            <mat-option value="readTag">{{'workTask.action_scan_existing_tag' | translate}}</mat-option>
                            <mat-option value="readTagQR">{{'workTask.action_scan_existing_QR' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                </div>

                <div class="mt-3" *ngIf="type=='Incoming'"> 
                    <label for="filter">{{'workTask.filter' | translate}}</label>   
                    <div class="list-group mt-1 mb-2">
                        <div class="list-group-item" *ngFor="let s of tridyTypeForm.value.predicates; let index= index;">
                            <div class="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                                <span id="{{s}}" class="w-75 text-wrap text-break" (click)="updatePredicate(s, index);" style="cursor:pointer;">{{s}}</span> 
                                <div (click)="deletePredicate(s, index);">
                                    <button mat-icon-button [disabled]="!tasktemplateUserRights_update">
                                        <span class="material-symbols-outlined" style="color: var(--accentGlobal);">delete</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button mat-button  class="secondary-gray-button my-2" (click)="addPredicate();" [disabled]="!tasktemplateUserRights_update">
                        <mat-icon>add_circle</mat-icon> {{'workTask.f_add' | translate}}
                    </button>
                </div> 
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onDone();" [disabled]="!tasktemplateUserRights_update || tridyTypeForm.invalid">{{'apply' | translate}}</button>
</mat-dialog-actions>
