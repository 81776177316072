<section>
    <div *ngIf="splashVideoData.title" id="title" class="titleVideo">{{splashVideoData.title}}</div>
    <div *ngIf="splashVideoData.subtitle" id="subtitle" class="subtitleVideo">{{splashVideoData.subtitle}}</div>
    <span *ngIf="dynamic" class="material-symbols-outlined" style="right: 10px; position: absolute;color:white;filter: drop-shadow(2px 4px 6px black);z-index: 1;">
        finance_chip
    </span>

    <vg-player *ngIf="!noUrl" style="position: relative; display: inline-block; width: 100%; height: 100%;" >
        <video *ngIf="!url" [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" controls style="height: 550px;
        object-fit: cover;">
            <source *ngIf="!urlData" src="{{splashVideoData.url}}" >
            <source *ngIf="urlData" src="{{urlData}}" >
        </video>

        <video *ngIf="url" [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" controls style="height: 550px;
        object-fit: cover;" >
            <source *ngIf="!urlData" src="{{url}}" type="video/mp4" onerror="this.src=''">
            <source *ngIf="urlData" src="{{urlData}}" type="video/mp4" onerror="this.src=''">
        </video>
        <div *ngIf="splashVideoData.showMoreIndicator" class="icon-swipe-up" style="padding-top: 200px;">
            <!-- <mat-icon>swipe_up</mat-icon> -->
            <img src="./../../../assets/img/narravero-swipe-up.svg" width="30" height="30" alt="swipe_up icon">
        </div>
  
    </vg-player>

    <div *ngIf="noUrl" class="d-flex justify-content-center align-items-center" style="height: 200px;">
        <p>{{'pass.preview_no_video_url' | translate}}</p>
    </div>
</section>