import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { Tridys } from './../../../../backend/src/tridys.dto';
import { CommentsService } from '../service/comments.service';
import { QrCodeDialogComponent } from '../qr-code-dialog/qr-code-dialog.component';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { isDateTime } from '../../../../backend/src/filter';
import { TridyDetailsDialogComponent } from '../tridy-details-dialog/tridy-details-dialog.component';
import { NgDialogAnimationService } from 'ng-dialog-animation';

@Component({
  selector: 'app-tridys-item',
  templateUrl: './tridys-item.component.html',
  styleUrls: ['./tridys-item.component.css']
})
export class TridysItemComponent {
  tridyData:any
  routerUrl:any
  idToShow:any
  finishedTasks:any=[]
  allContentTypes:any=[]
  payload:any
  tridyValues: Tridys
  contentType:string
  panelOpenState=false
  tridyArray:any
  tridyMap:any
  finishedTasksData:any
  resize:number;
  backToPath:any
  backToPathName:any
  allpayload:any = []
  media:any={}
  mediaData:any
  urlData:any = {}
  outgoing:any
  incoming:any
  contentTypesList:any = []
  allTridysList:any
  contentTypeData:any
  payloadCard:any
  showAll:boolean

  titleKey:any
  subtitleKeys:string[]

  incomingTitleKey:any
  outgoingTitleKey:any

  titleValue:any
  incomingTitleValue:any
  outgoingTitleValue:any

  subtitleValues:any
  incomingSubtitleValues:any
  outgoingSubtitleValues:any

  constructor(private router: Router,
    private route: ActivatedRoute,
    private settingService: SettingsService,
    public dialog: NgDialogAnimationService,
    private commentService: CommentsService,
    private location:Location,
    private translate:TranslateService
    ){
    this.routerUrl=this.route.url
    const length = this.routerUrl.value?.length
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    if(length>2 ){
      this.backToPath = this.routerUrl.value[this.routerUrl.value.length-3].path
    }else{
      this.backToPath = this.routerUrl.value[this.routerUrl.value.length-2].path
    }
  }

  ngOnInit(): void {
    if(this.settingService.settingsContentTypesWithId.length==0){
      this.getContentTypes()
    }else{
      this.contentTypesList = this.settingService.settingsContentTypesWithId
    }

    if(this.settingService.allTridys$.length!=0){
      this.allTridysList = this.settingService.allTridys$
    }else{
      this.settingService.observeTridysFirestore()
      this.allTridysList = this.settingService.allTridys$
    }
    // this.getContentTypes()

    this.resize = (window.innerWidth <= 760) ? 1 : 2;

    if(this.backToPath=="tridy"){
      this.settingService.allReports$.forEach(element => {
        if(element.id == this.routerUrl.value[2].path){
          this.backToPathName = element.displayName
        }
      })
    
    }else{
      this.backToPathName = "Tridys"
    }
   try{
    this.settingService.observeTridysIdFirestore(this.idToShow).then(tridy => {
      this.tridyData = tridy

      this.contentTypeData = this.getContentTypeDataById(this.tridyData.contentType)

      this.titleKey = this.getContentTypeFieldById(this.tridyData.contentType, 'titleKey') || false;
      this.subtitleKeys = this.getContentTypeFieldById(this.tridyData.contentType, 'subtitleKeys') || false;

      if(this.tridyData.outgoing){
        this.outgoing = this.tridyData.outgoing
      }

      if(this.tridyData.incoming){
        this.incoming = this.tridyData.incoming
      }

      this.tridyValues = new Tridys()
      this.tridyValues.setFromAny(this.tridyData)
      this.payload = this.tridyValues.getPayload()

      if(this.payload){

        const foundTitleKey = Object.keys(this.payload).find(key => key == this.titleKey)
        if(foundTitleKey){
          this.titleValue = this.payload[foundTitleKey]
        }

        const foundSubtitleKeys = Array.isArray(this.subtitleKeys) ? this.subtitleKeys : Object.keys(this.payload);
        this.subtitleValues = foundSubtitleKeys.map(key => this.payload[key]);

        this.allpayload = Object.entries(this.payload)

        if(this.payload.array){
          this.tridyArray=this.payload.array
        }
  
        if(this.payload.map){
          this.tridyMap=this.payload.map
        }
      }

      if(this.tridyData && this.tridyData.media){
        this.mediaData = this.tridyData.media
        if(this.payload){
          const payloadKeys = Object.keys(this.payload)
        }

        this.tridyData.media.forEach( med=> {
          this.media[med.key] = med.files
          if(med.files){
            med.files.forEach(file => {
              this.supportUrl(file.url, med.key)
            });
          }
        })
      }
      this.contentType = this.tridyValues.contentType
    })
   }catch(error){
    
   }
    
    this.getFinishedTasks()
    this.finishedTasks.forEach(task => {
      const data ={
        
      }
    });

  }

  getTitleKeyOfTridy(data, titleKey){
    const foundTitleKey = Object.keys(data).find(key => key == titleKey)
    if(foundTitleKey){
      return data[foundTitleKey]
    }
  }
  getSubtitleKeyOfTridy(data, subtitleKeys){
    const foundSubtitleKeys = Array.isArray(subtitleKeys) ? subtitleKeys : Object.keys(data);
    return foundSubtitleKeys.map(key => data[key]);
  } 

    openTridyDetailsDialog(tridyId){
  
      let dialogRef = this.dialog.open(TridyDetailsDialogComponent, {
        data: {
          idToShow: tridyId,
          allTridysList: this.allTridysList,
          contentTypesList: this.contentTypesList
        },
        disableClose: true,
        panelClass: 'custom-dialog-container',
        position: { right: '0'},
        animation: {
          to: "left",
          incomingOptions: {
            keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
          },
          outgoingOptions: {
            keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
          }
        }
      });
      dialogRef.afterClosed().subscribe(result => {});
    }

  onResize(event) {
    this.resize = (event.target.innerWidth <= 760) ? 1 : 2;
  }

  backTo(){
    // this.location.back()
    this.route.params.subscribe( (route_:ActivatedRoute) => {
      const contextId = this.settingService.contextId$
      if(route_ && route_['finishedTaskId'] && route_['tridyId'] && !route_['reportId']){
        this.router.navigate(['home', contextId, 'finishedTasks', route_['finishedTaskId'] ])
      }
  
      if(route_ && route_['tridyId'] && !route_['finishedTaskId'] && !route_['reportId']){
        this.router.navigate(['home', contextId, 'tridys' ])
      }

      if(route_ && route_['tridyId'] && !route_['finishedTaskId'] && route_['reportId']){
        this.router.navigate(['home', contextId, 'reports', route_['reportId']])
      }
    })
  }

  redirectTo(task){
    const contextId = this.settingService.contextId$
    this.route.params.subscribe( (route_:ActivatedRoute) => {
      if(route_ && route_['finishedTaskId'] && route_['tridyId'] && !route_['reportId'])
        this.router.navigate(['home', contextId, 'finishedTasks', task.id ])

      if(route_ && route_['tridyId'] && !route_['finishedTaskId'] && !route_['reportId'])
        this.router.navigate(['home', contextId, 'tridys', this.idToShow, 'finishedTasks', task.id  ])

      if(route_ && route_['tridyId'] && !route_['finishedTaskId'] && route_['reportId'])
        this.router.navigate(['home', contextId, 'reports',  route_['reportId'], 'tridys', this.idToShow, 'finishedTasks', task.id  ])

    })
  }


  getObjectData(payload){
    const values = Object.keys(payload)
    return values
  }

  getFinishedTasks(): void {
    this.settingService.observeTridysIdFinishedTasksFirestore(this.idToShow)
      .then(sortedTasks => {
        this.finishedTasks = sortedTasks;
      })
      .catch(error => {
        console.error('Error fetching tasks:', error);
      });
  }

  async getContentTypes(){
    try{
      await this.settingService.observeContextsContentTypesFirestoreWithId(this.settingService.contextId$).subscribe ( value => {
        this.allContentTypes = value
      })
    }catch(error){
      console.log(error)
    }
  }
  isObject(obj){
    return typeof obj === 'object'; 
  }

  QRoptions(){
    let contentype = this.allContentTypes?.find( ct => ct.id == this.contentType)
    let nfcPrefix = this.getContentTypeFieldById(this.tridyData.contentType, 'nfcURLPrefix')
    let dialogRef= this.dialog.open(QrCodeDialogComponent, {
      data: {
        tridy:this.tridyData,
        id: this.idToShow,
        nfcPrefix: nfcPrefix ?? undefined,
        name:  contentype?.displayName ?? contentype?.name ?? this.idToShow
      }
    });
  
  }

  showAllPayload(){
    this.showAll = !this.showAll
    if(this.payloadCard){
      this.payloadCard.classList.toggle('d-none', !(this.contentTypeData?.displaySections && this.showAll))
    }
  }
  getPayloadDataByFieldKey(key){
    if(this.allpayload){
      const foundKey = this.allpayload.find(payload => payload[0] == key);
      if (foundKey) {
        const value = foundKey[1];
        if (value?.nanoseconds || value?.seconds) {
          return value.toDate().toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' }) +
                 ', ' + value.toDate().toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
        }
        return value;
      }
    }
  }

  supportUrl(url, key){
    if(url){
      this.urlData[key]=url

     try{
      const imgUrl = new URL(url)
      let hostname = environment.urlHostName
      
      if(imgUrl.protocol=="pass:" ){
        const parts = url.match(/pass:\/\/(.+)\/(.+)/)
        if(parts){
          const passTemplateID = parts[1]
          const path = parts[2]
          const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          this.urlData[key]=firebaseUrl
        }else{
          const newparts = url.match(/pass:\/\/(.+)/)
          if(newparts){
            let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
            newparts.forEach( (part,index)=> {
              if(index>0){
                firebaseUrl += '/' + part 
              }
            })
            this.urlData=firebaseUrl
          }
        }
      } 

      if(imgUrl.protocol =="tridy:"){
        const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
        if(parts){
          let path =""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`

          this.urlData[key]=firebaseUrl
        }else{
          let path = "/"+this.idToShow
        const partsx = url.match(/tridy:\/\/(.+)/)

        partsx.forEach((parte, index) =>{
          if(index>0 && index<partsx.length){
            path += "/" + parte
          }
        })
        const firebaseUrl = `https://${hostname}/v1/pass${path}`

        this.urlData[key]=firebaseUrl
        }
      }
     }catch(erro){}
    }
  }

  getContentTypeFieldById(id, field){
    const content =  this.contentTypesList.find( ct => ct.id == id)
    if(content && content[field])
    return content[field]
  }

  getContentTypeDataById(id){
    const content =  this.contentTypesList.find( ct => ct.id == id)
    // if(content)
    // console.log(content)
    return content
  }


  isDateTime(element){
    return isDateTime(element)
  }

  getTRIDYIdData(tridyId, field){
    if(this.allTridysList){
      const tridy = this.allTridysList.find( id => tridyId == id.id)
      if(tridy && tridy[field]){
        return tridy[field]
      }
    }
  }

}
