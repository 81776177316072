<h2 mat-dialog-title>{{'browse_from_media' | translate}}</h2>

<mat-dialog-content style="max-width:800px;">
    <mat-divider></mat-divider>
    <div class="row mt-4">
        <div class="d-flex justify-content-between mb-3">

            <div style="min-width:50%;" >
                <button [hidden]="!showBack" mat-icon-button (click)="navBack()">
                    <mat-icon>keyboard_backspace</mat-icon>
                </button>
            </div>

        </div>
    </div>

    <div>
        <ng-template #container injectComp></ng-template>
    </div>

    <section *ngIf="selectedFile" class="w-100 p-3 d-flex justify-content-start align-items-center" style="border-radius:12px; background-color: #ababab21;">
        <img *ngIf="checkFileType(selectedFile) == 'image'" src="{{selectedFile.url}}" width="200" class="imgSelected">

        <mat-card *ngIf="checkFileType(selectedFile) == 'file'" class="h-100 custom-card" >
            <mat-card-content class=" p-1 cursor-pointer">
            <div > <span class="card-icon material-symbols-outlined">draft</span></div>
            </mat-card-content>
        </mat-card>

        <mat-card *ngIf="checkFileType(selectedFile) == 'video'" class=" h-100" >
            <mat-card-content class="custom-card p-1 cursor-pointer">
            <div > <span class="card-icon material-symbols-outlined">movie</span> </div>
            </mat-card-content>
        </mat-card>

        <div class="ps-2 ms-2 d-flex flex-column text-muted text-start">
            <span><strong>Media-URL: </strong> <span>{{selectedFile?.supportUrl}}</span></span>
            <span><strong>Name: </strong> <span>{{selectedFile?.name}}</span></span>
            <span><strong> Modified: </strong> <span>{{selectedFile?.metadata.updated | date: 'dd.MM.yyyy, HH:mm'}}</span></span>
            <span><strong>Size: </strong> <span> {{getFileSize(selectedFile?.metadata?.size)}}</span></span>
            <span><strong>Type: </strong> <span> {{selectedFile?.metadata?.contentType}}</span></span>
        </div>
    </section>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" [disabled]="!selectedFile" (click)="onSaveEdit();"  >{{'register.confirm' | translate}}</button>
</mat-dialog-actions>


