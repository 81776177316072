import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductsEditComponent } from '../../editWidgets/products-edit/products-edit.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[app-products-phone]',
  templateUrl: './products-phone.component.html',
  styleUrls: ['./products-phone.component.css']
})
export class ProductsPhoneComponent implements OnInit{
  @Input('app-products-phone') data:any;
  productsData:any
  editMode:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any}>()
  widgetNumber: any;
  routerUrl:any
  idToShow:any

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    ){
    this.routerUrl=this.router.url

    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    
   }

  ngOnInit(): void {
    this.productsData= this.data[0]
    this.editMode = this.data[1]

    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
  }


  openDialogEdit(){
    let dialogRef = this.dialog.open(ProductsEditComponent, {
      data:{
        values: this.productsData,
        widget: this.widgetNumber,
        passId: this.idToShow
      },
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result[1]){
        this.productsData=result[0]

        this.returnChanges.emit({newdata: this.productsData, widget: this.widgetNumber, widgetType: this.productsData.type})
      }
    })
  }
}
