
<ng-container >
    <section *ngIf="!emptyMedia">
        <div class="containerFolders" >
            <div *ngFor="let value of folder" class="m-3 mb-4 grid-item" (click)="selectFilePreview(value);"> 

                <mat-card *ngIf="value?.folder" class="w-100 h-100">
                    <mat-card-content class="custom-card p-1 cursor-pointer">
                    <div><span class="card-icon material-symbols-outlined">folder</span></div>
                    {{value.folder}}
                    </mat-card-content>
                </mat-card>
    
                <div *ngIf="value?.item" class="w-100 h-100" [matTooltip]="value.item"><!--class="background-img"-->

                    <img *ngIf="checkFileType(value) == 'image'" src="{{value.url}}" alt="{{value.item}}" width="100" height="70" style="border-radius:10px; object-fit: cover;">
                    
                    <mat-card *ngIf="checkFileType(value) == 'file'" class="w-100 h-100" >
                        <mat-card-content class="custom-card p-1 cursor-pointer">
                        <div > <span class="card-icon material-symbols-outlined">draft</span></div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card *ngIf="checkFileType(value) == 'video'" class="w-100 h-100" >
                        <mat-card-content class="custom-card p-1 cursor-pointer">
                        <div > <span class="card-icon material-symbols-outlined">movie</span> </div>
                        </mat-card-content>
                    </mat-card>
    
                </div>
            </div>
        </div>
    </section>
    
    <section *ngIf="emptyMedia"> 
        <p >{{'empty' | translate}}</p>
    </section> 

</ng-container>



