<section>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title style="color:#595c5f;">
                <mat-icon style="margin-right: 10px;">format_color_fill</mat-icon>
                {{'pass.widgetStyle' | translate}}
            </mat-panel-title>
        </mat-expansion-panel-header>

        <form [formGroup]="widgetStyleForm" class="m-1">

            <mat-card class="mb-2">
                <mat-tab-group animationDuration="300ms">
                    <mat-tab label="{{'pass.light' | translate}}">
                        <div [hidden]="lightBackground && lightTitle && lightSubtitle && lightPrimary && lightAccent">
                            <mat-list>
                                <mat-list-item role="listitem">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>{{'pass.buttons.backgroundColor' | translate}}</div>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightBackground" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_lightBackground" id="invalid_lightBackground" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item role="listitem">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>{{'pass.title' | translate}}</div>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightTitle" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_lightTitle" id="invalid_lightTitle" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item role="listitem">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>{{'pass.subtitle' | translate}}</div>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightSubtitle" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_lightSubtitle" id="invalid_lightTitle" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item role="listitem">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>{{'pass.primary' | translate}}</div>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightPrimary" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_lightPrimary" id="invalid_lightTitle" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item role="listitem">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>{{'pass.accent' | translate}}</div>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="lightAccent" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_lightAccent" id="invalid_lightAccent" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{'pass.dark' | translate}}">
                        <div [hidden]="darkBackground && darkTitle && darkSubtitle && darkPrimary && darkAccent">
                            <mat-list>
                                <mat-list-item role="listitem">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>{{'pass.buttons.backgroundColor' | translate}}</div>
                                        <label class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkBackground" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_darkBackground" id="invalid_darkBackground" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item role="listitem">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>{{'pass.title' | translate}}</div>
                                        <label class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkTitle" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_darkTitle" id="invalid_darkTitle" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item role="listitem">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>{{'pass.subtitle' | translate}}</div>
                                        <label class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkSubtitle" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_darkSubtitle" id="invalid_darkSubtitle" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item role="listitem">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>{{'pass.primary' | translate}}</div>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkPrimary" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_darkPrimary" id="invalid_darkPrimary" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item *ngIf="darkAccent" role="listitem">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>{{'pass.accent' | translate}}</div>
                                        <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                            <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="darkAccent" style="margin-left:10px;"></ngx-colors>
                                            <span *ngIf="invalid_darkAccent" id="invalid_darkAccent" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                        </label>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card>


             @if(widgetType != 'spacer') {
            <mat-form-field appearance="outline" class="mt-3 w-100" [hidden]="contentAlignment">
                <mat-label>{{'pass.contentAlignment' | translate}}</mat-label>
                <mat-select formControlName="contentAlignment" class="input-group">
                    <mat-option value="leading"><mat-icon>format_align_left</mat-icon>{{'pass.leading' | translate}}</mat-option>
                    <mat-option value="center"><mat-icon>format_align_center</mat-icon>{{'pass.center' | translate}}</mat-option>
                    <mat-option value="trailing"><mat-icon>format_align_right</mat-icon>{{'pass.trailing' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            }  
        </form>
    </mat-expansion-panel>
</section>