
<div style="padding:20px 24px 20px 24px;"><!--mat-dialog-title--> 
    
    <div class="p-1 d-flex align-items-center justify-content-between ">
        <span style="font-size: 18pt;" class="text-muted">{{mediaFileName}}</span>
        <button mat-icon-button [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>



<mat-dialog-content style="max-width:800px;">
    
    <div *ngIf="imagePreview" style="min-width: 500px;">
        <img src="{{imagePreview}}" class="w-100 image-preview" alt="{{mediaFileData.file.item}}">
    </div>

    <div *ngIf="pdfPreview" >
        <embed [src]="pdfPreview " width="600" height="500" type="application/pdf" />
    </div>


    <vg-player *ngIf="videoPreview">
        <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" controls width="400" >
            <source src="{{videoPreview}}" type="video/mp4" onerror="this.src='./../../assets/img/default.jpg'">
        </video>

    </vg-player>
</mat-dialog-content>

<mat-dialog-actions align="end">

    

    <section class="w-100 p-3 d-flex justify-content-between align-items-center">
        <div class="d-flex flex-column text-muted text-start details">
            <div><strong>Media-URL: </strong><span>{{mediaFileData.file.supportUrl}}</span></div>
            <div><strong> Modified: </strong><span>{{mediaFileData.file?.metadata.updated | date: 'dd.MM.yyyy, HH:mm'}}</span></div>
            <div><strong>Size: </strong><span>{{getFileSize(mediaFileData.file?.metadata?.size)}}</span></div>
        </div>
        <div>
            <button mat-icon-button (click)="openFile()" style="margin-left: 5px;"> 
                <mat-icon>open_in_new</mat-icon>
            </button>
            <button mat-icon-button (click)="downloadFile();" ><!--[disabled]="!contentTypeUserRights_create"-->
                <mat-icon class="material-symbols-outlined">download</mat-icon> 
            </button>
            <button mat-icon-button (click)="deleteFile();">
                <mat-icon class="material-symbols-outlined delete">delete</mat-icon> 
            </button>
        </div>
    </section>
    
</mat-dialog-actions>
