import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommentsService } from '../service/comments.service';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { Functions } from "@angular/fire/functions";
import { SettingsService } from '../service/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkTaskTemplatesService } from '../service/work-task-templates.service';
import { getApp } from '@angular/fire/app';

@Component({
  selector: 'app-duplicate-task-template',
  templateUrl: './duplicate-task-template-dialog.component.html',
  styleUrls: ['./duplicate-task-template-dialog.component.css']
})
export class DuplicateTaskTemplateDialogComponent implements OnInit{

  duplicateContentForm!:FormGroup
  selectedTaskId:any
  taskToDuplicateTitle:any
  private readonly functions: Functions;

  constructor(
    public dialogRef: MatDialogRef<DuplicateTaskTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingService: SettingsService,
    private fb: FormBuilder,
    // private functions: Functions,
    private translate:TranslateService,
    private commentService: CommentsService,
    private translateService: TranslateService,
    private wtaskTemplateService: WorkTaskTemplatesService
  ){
    dialogRef.disableClose = true;
    const functions = getFunctions(getApp(), 'europe-west1');
    this.functions = functions;
  }

  ngOnInit(): void {
    this.selectedTaskId = this.data.workTaskTemplateId
    this.taskToDuplicateTitle = this.data.oldTitle

    this.duplicateContentForm = this.fb.group({
      title:[,Validators.required]
    })
  }


  cancelDuplication(){
    this.dialogRef.close([false]);
  }

  duplicateTaskTemplate(){
    if(this.duplicateContentForm.value.title && this.selectedTaskId && this.duplicateContentForm.value.title){
      this.commentService.progressSpin.emit(true)
      
      const data = {
        contextId: this.settingService.contextId$,
        workTaskTemplateId: this.selectedTaskId,
        title: this.duplicateContentForm.value.title
      }


      try{
        const createExperience = httpsCallable(this.functions, 'admin-duplicateWorkTaskTemplate');

        createExperience(data ).then((result) => {
          const data = result.data;
          const id = data['workTaskTemplateId']
          this.commentService.progressSpin.emit(false)
          this.dialogRef.close([true,id]);
        })
        .catch((error) => {
          this.commentService.progressSpin.emit(false)
          console.log(error)
          const message = this.translateService.instant("SnackBarConstants.DUPLICATION_FAILED")
          this.commentService.addSnackBar.emit(message)
          this.commentService.addSnackBar.emit(error)
        });
      }catch(error){

      this.commentService.progressSpin.emit(false)
      console.log(error)
      const message = this.translate.instant("SnackBarConstants.DUPLICATION_FAILED")
    }

    } 
  } 
}
