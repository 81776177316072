<h2 mat-dialog-title>{{'contentTypes.contentType' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="contentForm">
                <div id="form-display">
                    <div class="p-2">
                        <mat-form-field appearance="outline" class="w-100" >
                            <mat-label>{{'contentTypes.name' | translate}}</mat-label>
                            <input matInput type="text" formControlName="displayName" id="inputDisplayName" name="displayName" placeholder="{{'contentTypes.name' | translate}}">
                        </mat-form-field>

                        <div (click)="openSchemaView();" class="mt-0 mb-4 shadow-sm w-100 rounded passTemplate">
                            {{'contentTypes.schema' | translate}}
                            <span class="material-symbols-outlined text-muted">
                                keyboard_arrow_right
                            </span>
                        </div>
                        
                    </div>
    
                    <div class="p-2 w-100">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'contentTypes.title_key' | translate}}</mat-label>
                            <input matInput type="text" formControlName="titleKey" id="titleKey" placeholder="{{'contentTypes.title_key' | translate}}">
                        </mat-form-field>

                        <mat-divider></mat-divider>
    
                        <div class="w-100">
                            <button mat-button 
                                    class="secondary-gray-button my-3"
                                    id="btn-add"
                                    (click)="addSubtitleKey();"
                            >
                                <mat-icon>add_circle</mat-icon> {{'contentTypes.add_subtitle' | translate}}
                            </button>
                        </div>

                        <div class="w-100 p-2 ps-0 mt-2" style="overflow-y: auto;">

                        <div class="w-100 p-2 ps-0 mt-2" style="overflow-y: auto;">
                            <mat-form-field appearance="outline" *ngFor="let subtitle of subtitleKeys; let index = index;" class="w-100">
                                <mat-label>{{ 'contentTypes.subtitle_key' | translate }} {{ index + 1 }}</mat-label>
                                <input matInput type="text" [formControl]="getSubtitleControl(index)">
                                <button mat-icon-button matSuffix (click)="deleteSubtitleKey(index)">
                                <mat-icon class="material-symbols-outlined delete">delete</mat-icon>
                                </button>
                            </mat-form-field>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelEditContentType();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" (click)="saveEditContentType();"  >{{'save' | translate}}</button>
</mat-dialog-actions>
