<div style=" padding: 0 24px 9px;">
    <div class="d-flex flex-row align-items-center justify-content-between pt-2 mt-1">
        <h2 class="mb-0" >{{'coverImages.title' | translate}}</h2>
        <button mat-icon-button style="color:var(--warmOrange);"  (click)="onCancel();"><mat-icon>close</mat-icon></button>
    </div>
</div>
<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <div *ngIf="!coverImagesAll">
                <p><small>{{"coverImages.noCoverImageFound" | translate}}</small></p>
            </div>
            <div *ngIf="coverImagesAll">
                <div *ngFor="let cover of coverImagesAll; let index = index" class="shadow-sm w-100 rounded passTemplate">
                    <!-- <div *ngFor="let cover of coverImagesAll; let index = index" routerLink="/coverImage/{{cover.id}}" class="shadow-sm w-100 rounded passTemplate"> -->
                        <div class="d-flex flex-row w-100 flex-nowrap align-items-center ">
                            <img width="60" src="{{cover.imageUrl}}"  onerror="this.src='./../../assets/img/default.jpg'">
                            <div class="w-100 d-flex flex-column p-2">
                                <span class="fs-6 text-break text-wrap">{{cover.title}}</span>
                                <span class="text-wrap text-break ">{{cover.subtitle}}</span>
                                <span class="text-wrap text-break text-muted">{{environment.tagHostName}}{{cover.urlPrefix}}/...</span>
                            </div>
                        </div>
                   <!--      <span class="material-symbols-outlined text-muted">
                            keyboard_arrow_right
                        </span> -->
                </div>
            </div>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button *ngIf="!coverImagesAll" style="color:var(--warmOrange);" (click)="onCancel();">{{'cancel' | translate}}</button>
    <!-- <button mat-button class="orange-button" (click)="addAdvancedExperience();">
       <span *ngIf="coverImagesAll">{{"contentTypes.replace_advanced" | translate}}</span>
       <span *ngIf="!coverImagesAll">{{"contentTypes.add_advanced" | translate}}</span>
   </button> -->

   <button mat-button *ngIf="!coverImagesAll" class="orange-button" (click)="addAdvancedExperience();">
        <span >{{"contentTypes.add_advanced" | translate}}</span>
    </button>
</mat-dialog-actions>
