import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContentTypeService } from '../../service/content-type.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommentsService } from '../../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../service/settings.service';
import { AdvancedExperiencesDialogComponent } from '../advanced-experiences-dialog/advanced-experiences-dialog.component';
import { Route, Router } from '@angular/router';
import { deepCopy, removeUndefined, removeUndefinedValuesFromObject } from '../../../../../backend/utils/object';
import { ContentType } from '../../../../../backend/src/contentType.dto';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-advanced-experience-view-dialog',
  templateUrl: './advanced-experience-view-dialog.component.html',
  styleUrls: ['./advanced-experience-view-dialog.component.css']
})
export class AdvancedExperienceViewDialogComponent {
  contentTypeId:any
  contentType:any
  nfcURLPrefix:any
  coverImagesAll:any
  contentForm!:FormGroup
  advancedExperienceId:any
  coverImageData:any
  oldCoverImage:any
  contenTypeForm!:FormGroup
  contextId: any
  newCover=false
  environment = environment
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private settingsService: SettingsService,
    private contentTypeService: ContentTypeService,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private translate:TranslateService,
    public dialogRef: MatDialogRef<AdvancedExperienceViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[]){
  }

  ngOnInit(){
    

    this.contentForm = this.fb.group({
      contextId:[],
      id:[],
      passTemplateId:[],
      passId:[],
      displayName:[],
      displaySections:[],
      schema:[],
      name:[],
      titleKey: [],
      subtitleKeys:[],
      nfcURLPrefix: [],
      advancedExperienceId: []
    })

    if(this.data['contentType']){
      this.contentType = deepCopy(this.data['contentType'])
      this.initializeContentTypeForm()
      if(this.contentType.nfcURLPrefix){
        this.nfcURLPrefix = this.contentType.nfcURLPrefix

        this.getCoverImagesOfThisNFC()
      }else{
        if(this.contentType.advancedExperienceId){
          this.advancedExperienceId = this.contentType.advancedExperienceId
          this.getCoverImagesWithId(this.advancedExperienceId)
        }
      }
    }

    if(this.data['contentTypeId']){
      this.contentTypeId = this.data['contentTypeId']
    }

    if(this.data['contextId']){
      this.contextId = this.data['contextId']
    }

  }


  initializeContentTypeForm(){
    const { contextId, id, passTemplateId, displayName, name,  titleKey, subtitleKeys, nfcURLPrefix,schema,advancedExperienceId} = this.contentType
    this.contentForm.patchValue({
      contextId: contextId ? contextId : undefined,
      id: id ? id : this.contentTypeId,
      passTemplateId:  passTemplateId ? passTemplateId : undefined,
      displayName : displayName ? displayName : undefined,
      name : name ? name : undefined,
      titleKey : titleKey ? titleKey : undefined,
      subtitleKeys : subtitleKeys ? subtitleKeys : undefined,
      nfcURLPrefix: nfcURLPrefix ? nfcURLPrefix : this.nfcURLPrefix,
      schema: schema ? schema : undefined,
      advancedExperienceId: advancedExperienceId ? advancedExperienceId : advancedExperienceId
    })

  }

  getCoverImagesOfThisNFC(){
    try{
      this.commentService.progressSpin.emit(true)
      this.coverImagesAll = this.settingsService.getCoverImagesWithNFCPrefix(this.nfcURLPrefix)
      
      this.oldCoverImage = deepCopy(this.coverImagesAll[0])
      this.commentService.progressSpin.emit(false)
      
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
    }
  }

  getCoverImagesOfThisAppStoreId(appStoreId){
    try{
      this.commentService.progressSpin.emit(true)

      this.coverImagesAll = this.settingsService.getCoverImagesWithAppStoreId(appStoreId)
      
      if(this.coverImagesAll[0] ){
        if(this.coverImagesAll[0].urlPrefix){
          this.nfcURLPrefix = this.coverImagesAll[0].urlPrefix
          this.advancedExperienceId = this.coverImagesAll[0].id
          this.contentForm.patchValue({nfcURLPrefix: this.nfcURLPrefix})
          this.contentForm.patchValue({advancedExperienceId: this.advancedExperienceId})
        }
      }

      this.commentService.progressSpin.emit(false)
      
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)

    }
  }

  getCoverImagesWithId(id){
    try{
      this.commentService.progressSpin.emit(true)

      this.settingsService.observeAdvancedExperiencesIdFirestore(id).then( value => {
        
        if(value){
          this.coverImagesAll = []
        
          this.coverImagesAll.push(value)
          
        }else{
          this.commentService.addSnackBar.emit("cover image not founded")
        
        }

      })
      
      this.commentService.progressSpin.emit(false)
      
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)

    }
  }


  addAdvancedExperience(){
    const data = {
      contextId: this.settingsService.contextId$,
      contentTypeId: this.contentTypeId
    }

    let dialogRef= this.dialog.open(AdvancedExperiencesDialogComponent, {
      data: { 
        values: data
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {

      if(result[0]){
        this.newCover = true
        this.contentType['nfcURLPrefix']=result[2]
        this.getCoverImagesOfThisAppStoreId(result[1])
      }
    });
  }

  redirectToCoverImage(cover){
    this.router.navigate([window.location.pathname, 'coverImages', cover.id])
  }

  async onSave(){
    this.commentService.progressSpin.emit(true)
    const data = this.contentForm.value
    removeUndefinedValuesFromObject(data)

    const contenttype = new ContentType()
    contenttype.setFromAny(data)

    removeUndefinedValuesFromObject(contenttype)

    this.dialogRef.close([true, this.contentType])
    this.commentService.progressSpin.emit(false)
  }

  onCancel(){

    if(this.newCover){
      this.dialogRef.close([true])

    }else{
      this.dialogRef.close([false])

    }
  }

}
