import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { injectGlobal } from '@emotion/css';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/app/service/comments.service';

@Component({
  selector: '[app-form-phone]',
  templateUrl: './form-phone.component.html',
  styleUrls: ['./form-phone.component.css']
})
export class FormPhoneComponent {
  @Input('app-form-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any, images:any}>()
  
  formData:any
  editMode:any
  widgetNumber:any
  routerUrl:any
  idToShow:any
  sections:any = []
  formPhoneForm!:FormGroup
  sectionsForm!:FormGroup
  fields:any = {}
  submitForm=false
  requiredFields:any=[]
  buttonStyleDefault:any = {
    "backgroundColor": "#ff5d38",
    "borderColor": "#ff5d38",
    "borderWidth": 0,
    "foregroundColor": "#fefefe",
    "cornerRadius": 0
  }
  darkModeActive = false
  titleColor = "var(--title)"
  subtitleColor = "var(--subtitle)"

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private commentService: CommentsService,
    private renderer: Renderer2,
    private element: ElementRef

    ){
      this.routerUrl=this.router.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    }

  ngOnInit():void{
    this.formData = this.data[0]
    this.widgetNumber = this.data[2]
    this.sectionsForm= this.fb.group({})
    this.darkModeActive = this.data[5]
    if(this.formData.sections){
      this.sections = this.formData.sections
      if(this.sections){
        this.sections.forEach((section, sectionIndex) => {
          this.fields[section.id] = section.fields
          section.fields.forEach((field, fieldIndex)=>{
            if(field.mandatory){
              this.sectionsForm.addControl(sectionIndex+'.'+section.id+'-'+fieldIndex+'.'+field.id+'_'+field.type,new FormControl(undefined, Validators.required))
              this.requiredFields.push(sectionIndex+'.'+section.id+'-'+fieldIndex+'.'+field.id+'_'+field.type)
            }else{
              this.sectionsForm.addControl(sectionIndex+'.'+section.id+'-'+fieldIndex+'.'+field.id+'_'+field.type,new FormControl(undefined) )
            }
          })
          
        });

      }
    }

    if(this.formData.submitButtonStyle){
      const submitButtonStyle = this.formData.submitButtonStyle
      let {backgroundColor, borderColor,borderWidth,foregroundColor,cornerRadius } = submitButtonStyle

      if(!backgroundColor)
        backgroundColor = "var(--accent)"//this.buttonStyleDefault.backgroundColor

      if(!borderColor)
        borderColor = "var(--accent)"//this.buttonStyleDefault.borderColor

      if(!borderWidth && cornerRadius!=0)
        borderWidth = this.buttonStyleDefault.borderWidth

      if(!foregroundColor)
        foregroundColor = "var(--pureWhite)"//this.buttonStyleDefault.foregroundColor

      if(!cornerRadius && cornerRadius!=0)
        cornerRadius = "var(--cornerRadius)"//this.buttonStyleDefault.cornerRadius
      else
        cornerRadius = cornerRadius+'px'

      injectGlobal`
        #formPhoneBtn-${this.formData.id} {
          background-color: ${backgroundColor};
          border: ${borderWidth}px solid ${borderColor};
          color: ${foregroundColor};
          border-radius: ${cornerRadius};
          font-family: var(--plainFontName);
          font-size: 1.1em;
        }
      `
    }else{
      injectGlobal`
        #formPhoneBtn-${this.formData.id} {
          background-color: var(--accent);
          border: ${this.buttonStyleDefault.borderWidth}px solid var(--accent);
          color: white;
          border-radius: var(--cornerRadius);
          font-family: var(--plainFontName);
          font-size: 1.1em;
        }
      `
    }
  }

  ngOnChanges(){
    this.formData=this.data[0] 
    this.editMode=this.data[1]
    this.widgetNumber = this.data[2]
    this.darkModeActive = this.data[5]
    
    setTimeout(() => {
      this.setWidgetStyle(this.formData)     
    }, 200);
  }


  submitDataForm(){
    let requiredFieldsEmpty=false
    const allControls = Object.entries(this.sectionsForm.value)
    let submission ={
      widgetId:this.idToShow,
      customerID:"",//if logged in
      data:{

      }
    }
    let data = []

    allControls.forEach((control, index)=> {
      const controlName = control[0]
      const controlValue = control[1]

      const info = controlName.split('_')
      const sectionField = info[0].split('-')
      const section  = sectionField[0].split('.')
        const sectionIndex= section[0]
        const sectionId= section[1]
      const field  = sectionField[1].split('.')
        const fieldIndex  = field[0]  
        const fieldId  = field[1]  
      const type = info[1]
      const fieldData = this.sections[sectionIndex].fields[fieldIndex]

      if(this.requiredFields.includes(controlName)){
        if(!controlValue){
          requiredFieldsEmpty = true
          if(controlValue===false){
            requiredFieldsEmpty = false
            let value = controlValue
            const newData = {
              [fieldData.key]: value
            }

            data.push(newData)
          }
        }else{
          let value = controlValue
          if(type=='date'){
            value+='Z'
          }
          const newData = {
            [fieldData.key]: value
          }

          data.push(newData)
        }          
      }else{
        if(controlValue){
          let value = controlValue
          if(type=='date'){
            value+='Z'
          }
          const newData = {
            [fieldData.key]: value
          }
          data.push(newData)
        }else{
          if(controlValue===false){
            let value = controlValue
            const newData = {
              [fieldData.key]: value
            }

            data.push(newData)
          }
        }
      }

    })
    if(requiredFieldsEmpty){
      const message = this.translateService.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
      this.commentService.addSnackBar.emit(message)
    }else{
      submission.data = data
      
    }
  }

  setWidgetStyle(data){
    const elementId = 'phoneSection-'+data.id
    const element = this.element.nativeElement.querySelector('#'+elementId);
    if(data?.widgetStyle){
      if(this.darkModeActive){
        if( data.widgetStyle.dark){
          if(data.widgetStyle.dark.backgroundColor){
            document.getElementById(elementId).style.backgroundColor = data.widgetStyle.dark.backgroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          }
          if( data.widgetStyle.dark.foregroundColor){
            document.getElementById(elementId).style.color = data.widgetStyle.dark.foregroundColor
            if(data.title)
              this.titleColor = data.widgetStyle.dark.foregroundColor
            if(data.subtitle)
              this.subtitleColor = data.widgetStyle.dark.foregroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
          }
        }else{
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
        }
      }else{
        if( data.widgetStyle.light){
          if( data.widgetStyle.light.backgroundColor){
            document.getElementById(elementId).style.backgroundColor = data.widgetStyle.light.backgroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          }

          if( data.widgetStyle.light.foregroundColor){
            document.getElementById(elementId).style.color = data.widgetStyle.light.foregroundColor
            if(data.title)
              this.titleColor = data.widgetStyle.light.foregroundColor
            if(data.subtitle)
              this.subtitleColor = data.widgetStyle.light.foregroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
            if(data.title)
              this.titleColor = "var(--title)"
            if(data.subtitle)
              this.subtitleColor = "var(--subtitle)"
          }
        }else{
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');

          if(data.title)
            this.titleColor = "var(--title)"
          if(data.subtitle)
            this.subtitleColor = "var(--subtitle)"
        }
      }
      if(data.widgetStyle.contentAlignment){
        if(data.widgetStyle.contentAlignment=='leading')
          document.getElementById(elementId).style.textAlign = 'left'

        if(data.widgetStyle.contentAlignment=='center')
          document.getElementById(elementId).style.textAlign = 'center'

        if(data.widgetStyle.contentAlignment=='trailing')
          document.getElementById(elementId).style.textAlign = 'end'

      }else{
        if(document.getElementById(elementId)?.style && document.getElementById(elementId)?.style.textAlign)
          this.renderer.removeStyle(element, 'textAlign');
      }
    }
  }


}
