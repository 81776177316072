import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { removeUndefined } from '../../../../../../backend/utils/object';
import { validColorValidator } from 'ngx-colors';

@Component({
  selector: '[app-widget-styling]',
  templateUrl: './widget-styling.component.html',
  styleUrls: ['./widget-styling.component.css']
})
export class WidgetStylingComponent {
  @Input('app-widget-styling') data:any;
  @Output() returnChanges = new EventEmitter<{newStyle: any, widget: any, widgetId:string}>()
  widgetStyleForm!:FormGroup
  widgetStyleData:any = {}
  colorPalette:any=[]
  widgetType:any

  // invalid colors
  invalid_lightBackground = false
  invalid_darkBackground = false
  invalid_lightTitle = false
  invalid_darkTitle = false
  invalid_lightSubtitle = false
  invalid_darkSubtitle = false
  invalid_lightPrimary = false
  invalid_darkPrimary = false
  invalid_lightAccent = false
  invalid_darkAccent = false

  // theme style colors
  lightBackground=false 
  lightTitle=false
  lightSubtitle=false
  lightPrimary=false
  lightAccent=false
  darkBackground=false
  darkTitle=false
  darkSubtitle=false
  darkPrimary=false
  darkAccent=false

  //other 
  lightbackgroundImageURL=false
  darkbackgroundImageURL=false
  contentAlignment= false
  fontName=false
  fontUrl=false

  constructor(public dialogRef: MatDialogRef<WidgetStylingComponent>,
    private fb: FormBuilder){}

    ngOnInit(): void{

      this.widgetType=this.data?.type

      this.initializePaletteColors()

      if(this.data.widgetStyle){
        this.widgetStyleData = this.data.widgetStyle

        switch(this.data.type){
          //set true to hide/disable
          case 'annotatedMap':{

            this.lightBackground=false 
            this.lightTitle=true
            this.lightSubtitle=true
            this.lightPrimary=true
            this.lightAccent=true
            this.darkBackground=false
            this.darkTitle=true
            this.darkSubtitle=true
            this.darkPrimary=true
            this.darkAccent=true

            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.contentAlignment=false
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'composed':{

            this.lightBackground=false 
            this.lightTitle=false
            this.lightSubtitle=false
            this.lightPrimary=false
            this.lightAccent=false
            this.darkBackground=false
            this.darkTitle=false
            this.darkSubtitle=false
            this.darkPrimary=false
            this.darkAccent=false

            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.contentAlignment=true
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'form':{

            this.lightBackground=false 
            this.lightTitle=false
            this.lightSubtitle=false
            this.lightPrimary=false
            this.lightAccent=false
            this.darkBackground=false
            this.darkTitle=false
            this.darkSubtitle=false
            this.darkPrimary=false
            this.darkAccent=false

            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.contentAlignment= false
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'image':{
            this.lightBackground=false 
            this.lightTitle=false
            this.lightSubtitle=false
            this.lightPrimary=false
            this.lightAccent=false
            this.darkBackground=false
            this.darkTitle=false
            this.darkSubtitle=false
            this.darkPrimary=false
            this.darkAccent=false

            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.contentAlignment= false
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'images':{
            this.lightBackground=false 
            this.lightTitle=false
            this.lightSubtitle=false
            this.lightPrimary=false
            this.lightAccent=false
            this.darkBackground=false
            this.darkTitle=false
            this.darkSubtitle=false
            this.darkPrimary=false
            this.darkAccent=false

            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.contentAlignment= false
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'spacer':{
            this.lightBackground=false 
            this.lightTitle=false
            this.lightSubtitle=false
            this.lightPrimary=false
            this.lightAccent=false
            this.darkBackground=false
            this.darkTitle=false
            this.darkSubtitle=false
            this.darkPrimary=false
            this.darkAccent=false

            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.contentAlignment= true
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'text':{
            this.lightBackground=false 
            this.lightTitle=false
            this.lightSubtitle=false
            this.lightPrimary=false
            this.lightAccent=true
            this.darkBackground=false
            this.darkTitle=false
            this.darkSubtitle=false
            this.darkPrimary=false
            this.darkAccent=true

            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.contentAlignment= true
            this.fontName=true
            this.fontUrl=true
            break;
          }

          case 'video':{
            this.lightBackground=false 
            this.lightTitle=false
            this.lightSubtitle=false
            this.lightPrimary=false
            this.lightAccent=false
            this.darkBackground=false
            this.darkTitle=false
            this.darkSubtitle=false
            this.darkPrimary=false
            this.darkAccent=false

            this.lightbackgroundImageURL=true
            this.darkbackgroundImageURL=true
            this.contentAlignment= false
            this.fontName=true
            this.fontUrl=true
            break;
          }

        }
      }
      const { lightColors, darkColors , contentAlignment, font} = this.widgetStyleData
      this.widgetStyleForm = this.fb.group({

        lightBackground: {value:lightColors?.background ? lightColors?.background : undefined, disabled: this.lightBackground}, 
        darkBackground:{value:darkColors?.background ? darkColors?.background : undefined, disabled: this.darkBackground},

        lightTitle: {value:lightColors?.title ? lightColors?.title : undefined, disabled: this.lightTitle}, 
        darkTitle:{value:darkColors?.title ? darkColors?.title : undefined, disabled: this.darkTitle},

        lightSubtitle: {value:lightColors?.subtitle ? lightColors?.subtitle : undefined, disabled: this.lightSubtitle}, 
        darkSubtitle:{value:darkColors?.subtitle ? darkColors?.subtitle : undefined, disabled: this.darkSubtitle},

        lightPrimary: {value:lightColors?.primary ? lightColors?.primary : undefined, disabled: this.lightPrimary}, 
        darkPrimary:{value:darkColors?.primary ? darkColors?.primary : undefined, disabled: this.darkPrimary},

        lightAccent: {value:lightColors?.accent ? lightColors?.accent : undefined, disabled: this.lightAccent}, 
        darkAccent:{value:darkColors?.accent ? darkColors?.accent : undefined, disabled: this.darkAccent},

        lightbackgroundImageURL: {value:lightColors?.backgroundImageURL ? lightColors?.backgroundImageURL : undefined, disabled: this.lightbackgroundImageURL},
        darkbackgroundImageURL: {value:darkColors?.backgroundImageURL ? darkColors?.backgroundImageURL : undefined, disabled: this.darkbackgroundImageURL},

        contentAlignment: {value:contentAlignment ? contentAlignment : undefined, disabled:this.contentAlignment}, 

        fontName:{value:font?.name ? font?.name : undefined, disabled: this.fontName}, 
        fontUrl:{value:font?.url ? font?.url : undefined, disabled: this.fontUrl}
      })

      this.widgetStyleForm.valueChanges.subscribe( value => {
        const newStyle = this.getfomatedDataStyling(value)
        this.returnChanges.emit({newStyle: newStyle, widget: this.data, widgetId: this.data.id})
      })

    }


    getfomatedDataStyling(data){

      const {
        lightBackground,
        lightTitle,
        lightSubtitle,
        lightPrimary,
        lightAccent,

        darkBackground,
        darkTitle,
        darkSubtitle,
        darkPrimary,
        darkAccent,

        lightbackgroundImageURL,
        darkbackgroundImageURL,

        contentAlignment,
        fontName,
        fontUrl
      } = data

      let style = {
        lightColors:{
          background: lightBackground ? lightBackground :undefined,
          title: lightTitle ? lightTitle :undefined,
          subtitle: lightSubtitle ? lightSubtitle :undefined,
          primary: lightPrimary ? lightPrimary :undefined,
          accent: lightAccent ? lightAccent :undefined,
          // backgroundImageURL: lightbackgroundImageURL ? lightbackgroundImageURL : undefined,
        },
        darkColors: {
          background: darkBackground ? darkBackground :undefined,
          title: darkTitle ? darkTitle :undefined,
          subtitle: darkSubtitle ? darkSubtitle :undefined,
          primary: darkPrimary ? darkPrimary :undefined,
          accent: darkAccent ? darkAccent :undefined,
          // backgroundImageURL: darkbackgroundImageURL ? darkbackgroundImageURL : undefined,
        },
        contentAlignment: contentAlignment ? contentAlignment :undefined,
        // font: {
        //   name: fontName ? fontName : undefined,
        //   url: fontUrl ? fontUrl : undefined
        // }
      }

      if(!lightBackground && !lightTitle && !lightSubtitle && !lightPrimary && !lightAccent) //&& !lightbackgroundImageURL
        delete style.lightColors
      if(!darkBackground && !darkTitle && !darkSubtitle && !darkPrimary && !darkAccent)//&& !darkbackgroundImageURL
        delete style.darkColors

      // if(!style.font?.name || !style.font?.url)
      //   delete style.font

      return removeUndefined(style)
    }


    initializePaletteColors(){
      var colors=[]
      const warmOrange= getComputedStyle(document.documentElement).getPropertyValue('--warmOrange')
      const darkblue= getComputedStyle(document.documentElement).getPropertyValue('--darkBlue')
      const pureWhite= getComputedStyle(document.documentElement).getPropertyValue('--pureWhite')
      const neutral= getComputedStyle(document.documentElement).getPropertyValue('--neutral')
      colors.push(warmOrange)
      colors.push(darkblue)
      colors.push(pureWhite)
      colors.push(neutral)
      this.colorPalette=colors
    }

 

    checkValidColors(){

      //-----backgroundColor
      this.invalid_lightBackground=this.widgetStyleForm.controls['lightBackground'].hasError('invalidColor')
      this.invalid_darkBackground=this.widgetStyleForm.controls['darkBackground'].hasError('invalidColor')
  
      //-----title
      this.invalid_lightTitle=this.widgetStyleForm.controls['lightTitle'].hasError('invalidColor')
      this.invalid_darkTitle=this.widgetStyleForm.controls['darkTitle'].hasError('invalidColor')
  
      //-----subtitle
      this.invalid_lightSubtitle=this.widgetStyleForm.controls['lightSubtitle'].hasError('invalidColor')
      this.invalid_darkSubtitle=this.widgetStyleForm.controls['darkSubtitle'].hasError('invalidColor')
  
      //-----primary
      this.invalid_lightPrimary=this.widgetStyleForm.controls['lightPrimary'].hasError('invalidColor')
      this.invalid_darkPrimary=this.widgetStyleForm.controls['darkPrimary'].hasError('invalidColor')
  
      //-----accent
      this.invalid_lightAccent=this.widgetStyleForm.controls['lightAccent'].hasError('invalidColor')
      this.invalid_darkAccent=this.widgetStyleForm.controls['darkAccent'].hasError('invalidColor')
    }

    
}
