import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { getFunctions,httpsCallable } from '@angular/fire/functions';
import { Tridys } from './../../../../backend/src/tridys.dto';
import { Functions } from '@angular/fire/functions';
import { QrCodeDialogComponent } from '../qr-code-dialog/qr-code-dialog.component';
import { CommentsService } from '../service/comments.service';
import { SettingsService } from '../service/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tridy-details-dialog',
  templateUrl: './tridy-details-dialog.component.html',
  styleUrls: ['./tridy-details-dialog.component.css']
})

export class TridyDetailsDialogComponent {

  tridyData:any
  routerUrl:any
  idToShow:any
  finishedTasks:any=[]
  allContentTypes:any=[]
  payload:any
  tridyValues: Tridys
  contentType:string
  panelOpenState=false
  tridyName:any
  tridyDescription:any
  tridyTagline:any
  tridyArray:any
  tridyMap:any
  finishedTasksData:any
  resize:number;
  backToPath:any
  backToPathName:any
  allpayload:any = []
  media:any={}
  mediaData:any
  urlData:any = {}
  outgoing:any
  incoming:any
  contentTypesList:any = []
  allTridysList:any

  
  constructor(
    private dialogRef: MatDialogRef<TridyDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingsService: SettingsService,
    private commentService: CommentsService,
    public dialog: MatDialog){
  }

  ngOnInit():void{
    this.commentService.progressSpin.emit(true)
       try{
        this.settingsService.observeTridysIdFirestore(this.data.idToShow).then(tridy => {
          this.tridyData = tridy
          if(this.tridyData?.outgoing){
            this.outgoing = this.tridyData.outgoing
          }
    
          if(this.tridyData.incoming){
            this.incoming = this.tridyData.incoming
          }
    
          this.tridyValues = new Tridys()
          this.tridyValues.setFromAny(this.tridyData)
          this.payload = this.tridyValues.getPayload()
    
          if(this.payload){
            this.allpayload = Object.entries(this.payload)
            if(this.payload.name){
              this.tridyName=this.payload.name
            }
      
            if(this.payload.description){
              this.tridyDescription=this.payload.description
            }
      
            if(this.payload.tagline){
              this.tridyTagline=this.payload.tagline
            }
      
            if(this.payload.array){
              this.tridyArray=this.payload.array
            }
      
            if(this.payload.map){
              this.tridyMap=this.payload.map
            }
          }
    
          if(this.tridyData && this.tridyData.media){
            this.mediaData = this.tridyData.media
            if(this.payload){
              const payloadKeys = Object.keys(this.payload)
            }
    
            this.tridyData.media.forEach( med=> {
              this.media[med.key] = med.files
              if(med.files){
                med.files.forEach(file => {
                  this.supportUrl(file.url, med.key)
                });
              }
            })
          }
          this.contentType = this.tridyValues.contentType
          this.contentTypesList = this.data.contentTypesList
          this.allTridysList = this.data.allTridysList
          this.commentService.progressSpin.emit(false)
        })
       }catch(error){
        console.log(error)
        this.commentService.progressSpin.emit(false)
    }

  }

  dialogCancel(data?){
    this.dialogRef.close([false]);
  }

  supportUrl(url, key){
    if(url){
      this.urlData[key]=url

      try{
      const imgUrl = new URL(url)
      let hostname = environment.urlHostName
      
      if(imgUrl.protocol=="pass:" ){
        const parts = url.match(/pass:\/\/(.+)\/(.+)/)
        if(parts){
          const passTemplateID = parts[1]
          const path = parts[2]
          const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          this.urlData[key]=firebaseUrl
        }else{
          const newparts = url.match(/pass:\/\/(.+)/)
          if(newparts){
            let firebaseUrl = `https://${hostname}/v1/pass/${this.data.idToShow}`
            newparts.forEach( (part,index)=> {
              if(index>0){
                firebaseUrl += '/' + part 
              }
            })
            this.urlData=firebaseUrl
          }
        }
      } 

      if(imgUrl.protocol =="tridy:"){
        const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
        if(parts){
          let path =""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`

          this.urlData[key]=firebaseUrl
        }else{
          let path = "/" + this.data.idToShow
          const partsx = url.match(/tridy:\/\/(.+)/)

          partsx.forEach((parte, index) =>{
            if(index>0 && index<partsx.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`

          this.urlData[key]=firebaseUrl
        }
      }
      }catch(erro){}
    }
  }


  isObject(obj){
    return typeof obj === 'object'; 
  }

  getObjectData(payload){
    const values = Object.keys(payload)
    return values
  }

  getContentTypeFieldbyId(id, field) {
    const content = this.contentTypesList.find(ct => ct.id == id);
    if (content && content[field]) {
      return content[field];
    } else {
      return null;
    }
  }
  
  getTRIDYIdData(tridyId, field){
    if(this.allTridysList){
      const tridy = this.allTridysList.find( id => tridyId == id.id)
      if(tridy && tridy[field]){
        return tridy[field]
      }
    }
  }

  getTitleKeyOfTridy(data, titleKey){
    const foundTitleKey = Object.keys(data).find(key => key == titleKey)
    if(foundTitleKey){
      return data[foundTitleKey]
    }
  }
  getSubtitleKeyOfTridy(data, subtitleKeys){
    const foundSubtitleKeys = Array.isArray(subtitleKeys) ? subtitleKeys : Object.keys(data);
    return foundSubtitleKeys.map(key => data[key]);
  } 

}
