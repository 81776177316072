<!-- <h2 mat-dialog-title>{{'taskTemplates.field' | translate}}</h2> -->
<h2 mat-dialog-title>{{fieldForm?.value?.title}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <div class="mt-1 mb-4 d-flex" style="color: var(--warmOrange);font-size: 14px;">
                <mat-icon>info</mat-icon>
                <span class="ps-2">{{'workTask.key_without_space' | translate}}</span>
            </div>
            <form [formGroup]="fieldForm" class="pt-2">
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'taskTemplates.wt_title' | translate}}</mat-label>
                    <input matInput name="title" formControlName="title" class="form-control" placeholder="{{'taskTemplates.wt_title' | translate}}"  type="text" [readonly]="!tasktemplateUserRights_update">
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100 mb-2">
                    <mat-label>{{'taskTemplates.key' | translate}}</mat-label>
                    <input matInput name="key" formControlName="key" class="form-control" [required]="fieldForm.value.mandatory" [errorStateMatcher]="matcher" placeholder="{{'taskTemplates.key' | translate}}" id="key" type="text" [readonly]="!tasktemplateUserRights_update">
                    <mat-error *ngIf="fieldForm.get('key')?.invalid"> {{'invalid_characters' | translate}} </mat-error>
                    <mat-hint>{{'taskTemplates.valid-key' | translate}}</mat-hint>
                </mat-form-field>
        
                <div *ngIf="fieldForm.value.type!='display' && fieldForm.value.type!='hidden' && fieldForm.value.type!='showText' && fieldForm.value.type!='showImage'" class="form-control p-3 mb-3  d-flex justify-content-between">
                    <label for="title" >{{'taskTemplates.mandatory' | translate}}</label>
                    <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="mandatory" ></mat-slide-toggle>
                    <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="fieldForm.value.mandatory"></mat-slide-toggle>
                </div>

                <div *ngIf="fieldForm.value.type!='hidden' && fieldForm.value.type!='showText' && fieldForm.value.type!='showImage'" class="form-control p-3 d-flex justify-content-between">
                    <label for="title" >{{'taskTemplates.read_only' | translate}}</label>
                    <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="readOnly" ></mat-slide-toggle>
                    <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="fieldForm.value.readOnly"></mat-slide-toggle>
                </div>
                <!-- hint missing -->
        
               <div class="pb-4">
                    <mat-form-field  appearance="outline" class="w-100 mt-3"><!--hintLabel="{{'taskTemplates.payload_info' | translate}}"-->
                        <mat-label>{{'taskTemplates.payload_key' | translate}}</mat-label>
                        <input matInput name="payloadkey" formControlName="payloadKey" [required]="fieldForm.value.mandatory" [errorStateMatcher]="matcher" class="form-control" placeholder="{{'taskTemplates.payload_key' | translate}}" id="payloadkey" type="text" [readonly]="!tasktemplateUserRights_update" >
                        <mat-error *ngIf="fieldForm.get('payloadKey').invalid">{{'invalid_characters' | translate}}</mat-error>
                        <mat-hint>{{'taskTemplates.valid-key' | translate}}  {{'taskTemplates.payload_info' | translate}} </mat-hint>
                        <button mat-icon-button [disabled]="!tasktemplateUserRights_update" matSuffix (click)="copyKeyValue();" matTooltip="{{'taskTemplates.copyKey' | translate}}" [attr.aria-label]="'Copy Key'">
                            <mat-icon>content_paste</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="pt-3" *ngIf="fieldForm.value.type === 'text' || fieldForm.value.type === 'longText' || fieldForm.value.type === 'barcode'">
                    <mat-form-field appearance="outline" class="w-100 mt-3">
                        <mat-label>{{'taskTemplates.identifierKey' | translate}}</mat-label>
                        <input matInput name="identifierKey" formControlName="identifierKey" class="form-control" placeholder="{{'taskTemplates.identifierKey' | translate}}" id="identifierKey" type="text" [readonly]="!tasktemplateUserRights_update" >
                        <mat-error *ngIf="fieldForm.get('identifierKey')?.invalid"> {{'invalid_characters' | translate}} </mat-error>
                    </mat-form-field>
                </div>
        
                <div *ngIf="fieldForm.value.type=='weight'" class="pt-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'taskTemplates.wt-unit' | translate}}</mat-label>
                        <mat-select formControlName="unit">
                            <mat-option value="g">g</mat-option>
                            <mat-option value="kg">kg</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
        
                <div *ngIf="fieldForm.value.type=='options'">
                    <div class="form-control p-3 m1-2 mb-3  d-flex justify-content-between">
                        <label for="searchable" >{{'taskTemplates.searchable' | translate}}</label>
                        <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="fieldForm.value.searchable"></mat-slide-toggle>
                        <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="searchable" ></mat-slide-toggle>
                    </div>
        
                    <div class="form-control p-3 mt-1 mb-3  d-flex justify-content-between">
                        <label for="searchable" >{{'taskTemplates.extendable' | translate}}</label>
                        <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="fieldForm.value.extendable"></mat-slide-toggle>
                        <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="extendable" ></mat-slide-toggle>
                    </div>
        
                    <mat-tab-group>
                        <mat-tab style="color:var(--warmOrange);" label="{{'taskTemplates.static' | translate}}" defaultColor="blue">


        
                            <div>
                                <mat-form-field appearance="outline" class="w-100 mt-4">
                                    <mat-label>{{'taskTemplates.defaultValue' | translate}}</mat-label>
                                    <mat-select formControlName="defaultValue">
                                        <mat-option *ngFor="let option of fieldForm.value.options" value="{{option}}" [disabled]="!tasktemplateUserRights_update">{{option}}</mat-option>
                                        <mat-option >-</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <button mat-button 
                                    class="secondary-gray-button mb-3" 
                                    id="btn-add"
                                    [disabled]="!tasktemplateUserRights_update"
                                    (click)="addNewOptionToField()"
                                >
                                    <mat-icon>add_circle</mat-icon> {{'add' | translate}}
                                </button>

                                <div *ngIf="fieldForm.value.options">
                                    <div *ngFor="let option of fieldForm.value.options; let index=index;">
                                        <mat-card class="mb-1">
                                            <mat-card-content class="py-0">
                                                <div class="d-flex align-items-center" style="justify-content: space-between;">
                                                    <div>{{option}}</div>
                                                    <div>
                                                        <button style="color:var(--warmOrange);vertical-align: middle;" mat-icon-button (click)="editOptionToField(option, index);" [disabled]="!tasktemplateUserRights_update">
                                                            <mat-icon class="material-symbols-outlined">edit</mat-icon>
                                                        </button>
                                                        <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteOption(option, index);" [disabled]="!tasktemplateUserRights_update">
                                                            <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>


                            </div>
        
        
        
                        </mat-tab>
                        <mat-tab label="{{'taskTemplates.dynamic' | translate}}"> 
                            <mat-form-field appearance="outline" class="w-100 mt-2 ">
                                <mat-label>{{'taskTemplates.contentKey' | translate}}</mat-label>
                                <input matInput name="contentKey" formControlName="optionsKey" class="form-control" placeholder="{{'taskTemplates.contentKey' | translate}}" id="contentKey" type="text" [readonly]="!tasktemplateUserRights_update">
                            </mat-form-field>
                        
                        </mat-tab>
                      </mat-tab-group>
                </div>
        
                <div *ngIf="fieldForm.value.type=='boolean'">
                    <div class="form-control p-3 mb-3  d-flex justify-content-between">
                        <label for="title" >{{'taskTemplates.defaultValue' | translate}}</label>
                        <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="fieldForm.value.defaultValue"></mat-slide-toggle>
                        <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="defaultValue" ></mat-slide-toggle>
                    </div>

                    <mat-form-field appearance="outline" class="w-100 mt-2 ">
                        <mat-label>{{'taskTemplates.value' | translate}}</mat-label>
                        <input matInput name="value" formControlName="value" class="form-control" placeholder="{{'taskTemplates.value' | translate}}" id="value" type="text" [readonly]="!tasktemplateUserRights_update">
                    </mat-form-field>
                </div>
        
                <mat-form-field *ngIf="fieldForm.value.type=='hidden'" appearance="outline" class="w-100 mt-2 ">
                    <mat-label>{{'taskTemplates.value' | translate}}</mat-label>
                    <input matInput name="value" formControlName="value" class="form-control" placeholder="{{'taskTemplates.value' | translate}}" id="value" type="text" [readonly]="!tasktemplateUserRights_update">
                </mat-form-field>
                
        
                <div *ngIf="fieldForm.value.type=='dateTime'">
                    <mat-form-field  appearance="outline" class="w-100 mt-2">
                        <mat-label>{{'taskTemplates.style' | translate}}</mat-label>
                        <mat-select formControlName="style">
                            <mat-option value="date" [disabled]="!tasktemplateUserRights_update">{{'taskTemplates.date' | translate}}</mat-option>
                            <mat-option value="time" [disabled]="!tasktemplateUserRights_update">{{'taskTemplates.time' | translate}} </mat-option>
                            <mat-option value="dateTime" [disabled]="!tasktemplateUserRights_update">{{'taskTemplates.date_time' | translate}} </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field  appearance="outline" class="w-100 mt-2">
                        <mat-label>{{'taskTemplates.defaultValue' | translate}}</mat-label>
                        <mat-select formControlName="defaultSelection">
                            <mat-option value="currentDate" [disabled]="!tasktemplateUserRights_update">{{'taskTemplates.current_time' | translate}} </mat-option>
                            <mat-option value="customDate" [disabled]="!tasktemplateUserRights_update">{{'taskTemplates.custom_date_time' | translate}} </mat-option>
                            <mat-option value="" [disabled]="!tasktemplateUserRights_update">{{'taskTemplates.leaveEmpty' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div *ngIf="fieldForm.value.defaultSelection=='customDate'">
                        <mat-form-field appearance="outline" class="w-100 mt-2 " *ngIf="fieldForm.value.style=='dateTime'">
                            <mat-label>{{'taskTemplates.set_date' | translate}}</mat-label>
                            <input matInput name="defaultValue" formControlName="defaultValue" class="form-control" placeholder="{{'taskTemplates.set_date' | translate}}" value="{{defaultDate}}" id="defaultValue" type="datetime-local" [readonly]="!tasktemplateUserRights_update"  max="9999-12-31T23:59">
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100 mt-2 " *ngIf="fieldForm.value.style=='date'">
                            <mat-label>{{'taskTemplates.set_date' | translate}}</mat-label>
                            <input matInput [matDatepicker]="picker"  placeholder="{{'taskTemplates.set_date' | translate}}" formControlName="defaultValue" value="{{defaultDate}}" id="defaultValue" [readonly]="!tasktemplateUserRights_update">
                            <mat-hint>{{'dd.mm.yyyy' | translate}}</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100 mt-2 " *ngIf="fieldForm.value.style=='time'">
                            <mat-label>{{'taskTemplates.set_date' | translate}}</mat-label>
                            <input matInput type="time" formControlName="defaultValue" class="form-control" placeholder="{{'taskTemplates.set_date' | translate}}" value="{{defaultDate}}" id="defaultValue" [readonly]="!tasktemplateUserRights_update">
                        </mat-form-field>
                    </div>
                </div>
        
        
<!--                TEMPORARY DISABLED
                    <mat-form-field *ngIf="fieldForm.value.type=='barcode'" appearance="outline" class="w-100 mt-2">
                    <mat-label>{{'taskTemplates.type' | translate}}</mat-label>
                    <mat-select formControlName="barcodeType">
                        <mat-option value="qr" [disabled]="!tasktemplateUserRights_update">QR</mat-option>
                        <mat-option value="barcode"[disabled]="!tasktemplateUserRights_update" >Barcode </mat-option>
                        <mat-option value="ean" [disabled]="!tasktemplateUserRights_update">EAN</mat-option>
                        <mat-option value="digatag" [disabled]="!tasktemplateUserRights_update">DigaTag</mat-option>
                        <mat-option value="tridy" [disabled]="!tasktemplateUserRights_update">Tridy</mat-option>
                        <mat-option value="code128" [disabled]="!tasktemplateUserRights_update">Code 128</mat-option>
                        <mat-option value="interleaved2of5" [disabled]="!tasktemplateUserRights_update">Interleaved 2 of 5</mat-option>
                        <mat-option value="codabar" [disabled]="!tasktemplateUserRights_update">Codabar</mat-option>
                        <mat-option value="code39" [disabled]="!tasktemplateUserRights_update">Code 39</mat-option>
                        <mat-option value="code93" [disabled]="!tasktemplateUserRights_update">Code 93</mat-option>
                        <mat-option value="upce" [disabled]="!tasktemplateUserRights_update">UPCE</mat-option>
                        <mat-option value="aztec" [disabled]="!tasktemplateUserRights_update">Aztec</mat-option>
                        <mat-option value="dataMatrix" [disabled]="!tasktemplateUserRights_update">Data Matrix</mat-option>
                        <mat-option value="pdf417" [disabled]="!tasktemplateUserRights_update">PDF417</mat-option>
                    </mat-select>
                </mat-form-field> -->

                <div  *ngIf="fieldForm.value.type=='tridy'">

                    <div class="mt-3">
                        <mat-form-field appearance="outline" class="w-100 p-0">
                            <mat-label>{{'contentTypes.contentType' | translate}}</mat-label>
                            <mat-select formControlName="contentType" required>
                                <mat-option value="" [disabled]="!tasktemplateUserRights_update"> - </mat-option>
                                <mat-option *ngFor="let content of contentTypesList" value="{{content.id}}" [disabled]="!tasktemplateUserRights_update">{{content.displayName}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="form-control p-3 mb-3  d-flex justify-content-between">
                            <label for="title" >{{'taskTemplates.searchIdentifiers' | translate}}</label>
                            <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="searchIdentifiers" ></mat-slide-toggle>
                            <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="fieldForm.value.searchIdentifiers"></mat-slide-toggle>
                        </div>

                        <div class="form-control p-3 mb-3  d-flex justify-content-between">
                            <label for="title" >{{'taskTemplates.listSelection' | translate}}</label>
                            <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="listSelection" ></mat-slide-toggle>
                            <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="fieldForm.value.listSelection"></mat-slide-toggle>
                        </div>
                    </div>
    
                    <div  class="mt-4">
                        <label class="mt-2 mb-4 text-muted fs-6 text-uppercase">
                            {{'taskTemplates.importedData' | translate}}
                            <mat-icon class="label-info" matTooltip="{{'taskTemplates.importTooltip' | translate}}">info</mat-icon>
                        </label>

                        <mat-accordion multi>
                            <mat-expansion-panel expanded>
                                <mat-expansion-panel-header>
                                    Payload {{'taskTemplates.importedFields' | translate}}
                                </mat-expansion-panel-header>
                                <div class="my-2">
                                    <form [formGroup]="importedDataForm"> 
                                        <mat-form-field appearance="outline" style="width: 43%;">
                                            <mat-label>{{'taskTemplates.sourceKey' | translate}}</mat-label>
                                            <input matInput minlength=1 formControlName="tridyPayloadKey" placeholder="{{'taskTemplates.sourceKey' | translate}}">
                                        </mat-form-field>
                                        <mat-icon style="vertical-align: middle; margin:5px;text-align: center; width: 10%;">arrow_forward</mat-icon>
                                        <mat-form-field appearance="outline" style="width: 43%;">
                                            <mat-label>{{'taskTemplates.targetKey' | translate}}</mat-label>
                                            <input matInput minlength=1 formControlName="key" placeholder="{{'taskTemplates.targetKey' | translate}}">
                                        </mat-form-field>
                                        <br>
                                        <button mat-button *ngIf="!editImportedField" class="secondary-gray-button" id="btn-add" [disabled]="!importedDataForm.valid || !tasktemplateUserRights_update || !importedDataForm.value.tridyPayloadKey || !importedDataForm.value.key" (click)="addFieldItem()">
                                            <mat-icon>add_circle</mat-icon> {{'add' | translate}}
                                        </button>
                                    </form>
                            
                                    <hr *ngIf="fieldForm.value.importedFields.length > 0" class="my-3">
                            
                                    <div *ngFor="let importedField of fieldForm.value.importedFields; let index = index;">
                                        <mat-card class="mb-1">
                                            <mat-card-content class="py-0">
                                                <div class="d-flex align-items-center" style="justify-content: space-between;">
                                                    <div style="word-break: break-all;">
                                                        {{importedField.tridyPayloadKey}}
                                                        <mat-icon style="vertical-align: middle; margin-right:5px;margin-left:5px;">arrow_forward</mat-icon>
                                                        {{importedField.key}}
                                                    </div>
                                                    <div>
                                                        <button style="color:var(--warmOrange);vertical-align: middle;" mat-icon-button (click)="openEditFieldDialog(importedField, index)">
                                                            <mat-icon class="material-symbols-outlined">edit</mat-icon>
                                                        </button>
                                                        <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteFieldItem(importedField, index)" [disabled]="editImportedField">
                                                            <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    Payload {{'taskTemplates.importedMedia' | translate}}
                                </mat-expansion-panel-header>
                                <div class="my-2">
                                    <form [formGroup]="importedMediaForm"> 
                                        <mat-form-field appearance="outline" style="width: 43%;">
                                            <mat-label>{{'taskTemplates.sourceKey' | translate}}</mat-label>
                                            <input matInput minlength=1 formControlName="sourceKey" placeholder="{{'taskTemplates.sourceKey' | translate}}">
                                        </mat-form-field>
                                        <mat-icon style="vertical-align: middle; margin:5px;text-align: center; width: 10%;">arrow_forward</mat-icon>
                                        <mat-form-field appearance="outline" style="width: 43%;">
                                            <mat-label>{{'taskTemplates.targetKey' | translate}}</mat-label>
                                            <input matInput minlength=1 formControlName="targetKey" placeholder="{{'taskTemplates.targetKey' | translate}}">
                                        </mat-form-field>
                                        <br>
                                        <button mat-button *ngIf="!editImportedMedia" class="secondary-gray-button" id="btn-add" [disabled]="!importedMediaForm.valid || !tasktemplateUserRights_update || !importedMediaForm.value.sourceKey || !importedMediaForm.value.targetKey" (click)="addMediaItem()">
                                            <mat-icon>add_circle</mat-icon> {{'add' | translate}}
                                        </button>
                                    </form>
                            
                                    <hr *ngIf="fieldForm.value.importedMedia.length > 0" class="my-4">
                            
                                    <div *ngFor="let importedMedia of fieldForm.value.importedMedia; let index = index;">
                                        <mat-card class="mb-1">
                                            <mat-card-content class="py-0">
                                                <div class="d-flex align-items-center" style="justify-content: space-between;">
                                                    <div style="word-break: break-all;">
                                                        {{importedMedia.sourceKey}}
                                                        <mat-icon style="vertical-align: middle; margin-right:5px;margin-left:5px;">arrow_forward</mat-icon>
                                                        {{importedMedia.targetKey}}
                                                    </div>
                                                    <div>
                                                        <button style="color:var(--warmOrange);vertical-align: middle;" mat-icon-button (click)="openEditMediaDialog(importedMedia, index)">
                                                            <mat-icon class="material-symbols-outlined">edit</mat-icon>
                                                        </button>
                                                        <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteMediaItem(importedMedia, index)" [disabled]="editImportedMedia">
                                                            <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        
                    </div>
    
                    <div class="mt-3">
                        <label for="filter">{{'workTask.filter' | translate}}</label>   
                        <div class="list-group mt-1 mb-2">
                            <div class="list-group-item" *ngFor="let s of fieldForm.value.predicates; let index= index;">
                                <div class="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                                    <span id="{{s}}" class="w-75 text-wrap text-break" (click)="updatePredicate(s, index);" style="cursor:pointer;">{{s}}</span> 
                                    <div (click)="deletePredicate(s, index);">
                                        <button mat-icon-button [disabled]="!tasktemplateUserRights_update">
                                            <span class="material-symbols-outlined" style="color: var(--accentGlobal);">delete</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button mat-button  class="secondary-gray-button my-2" (click)="addPredicate();" [disabled]="!tasktemplateUserRights_update">
                            <mat-icon>add_circle</mat-icon> {{'workTask.f_add' | translate}}
                        </button>
                    </div> 
                </div>
                

                <!-- WYSWIG EDITOR -->
                 <div *ngIf="fieldForm.value.type=='showText'" class="mt-4">

                    <div id="toolbar">
                        <div class="d-flex justify-content-between">
                            <div class="ql-default-buttons">
                                <button class="ql-bold" matTooltip="{{'pass.bold' | translate}}"></button>
                                <button class="ql-italic" matTooltip="{{'pass.italic' | translate}}"></button>
                                <button class="ql-link" matTooltip="{{'pass.link-URL' | translate}}"></button>
                                <button class="ql-list" value="ordered" matTooltip="{{'pass.ordered_list' | translate}}"></button>
                                <button class="ql-list" value="bullet" matTooltip="{{'pass.bullet_list' | translate}}"></button>
                                <button class="ql-clean" matTooltip="{{'pass.clean' | translate}}"></button>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Quill Editor -->
                    <quill-editor
                        #editor
                        formControlName="value"
                        class="mb-4 w-100"
                        [ngClass]="getLanguageClass()"
                        id="text"
                        [modules]="quillModules"
                        placeholder="{{'insertText' | translate}}"
                        [styles]="{ height: '280px', width: '100%' }"
                        (onEditorCreated)="onEditorCreated($event)">
                    </quill-editor>
                 </div>


<!--                 <mat-form-field appearance="outline" class="w-100 mt-4" *ngIf="fieldForm.value.type=='showText'">
                    <mat-label>{{'pass.widgets.text' | translate}}</mat-label>
                    <textarea matInput cdkTextareaAutosize matInput formControlName="value"
                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" class="form-control" id="text"></textarea>  
                    <mat-hint>
                        <span>{{'pass.markDown_supports' | translate}} <span style="color:var(--warmOrange);cursor: help;" matTooltip="{{'pass.markDown' | translate}}" matTooltipPosition="below">Markdown</span></span>
                    </mat-hint>
                </mat-form-field> -->
        
                <div *ngIf="fieldForm.value.type=='document'" class="mt-3">
                    <span class="text-uppercase text-muted">{{'taskTemplates.sources' | translate}}</span>
                    <form [formGroup]="sourceForm" class="mt-3">
                        <div class="form-control p-3 mb-3 d-flex justify-content-between">
                            <label for="camera">{{'taskTemplates.camera' | translate}}</label>
                            <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="camera"></mat-slide-toggle>
                            <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="sourceForm.value.camera"></mat-slide-toggle>
                        </div>
        
                        <div  class="form-control p-3 mb-3  d-flex justify-content-between">
                            <label for="library" >{{'taskTemplates.library' | translate}}</label>
                            <mat-slide-toggle  *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="sourceForm.value.library"></mat-slide-toggle>
                            <mat-slide-toggle  *ngIf="tasktemplateUserRights_update" formControlName="library"></mat-slide-toggle>
                        </div>
        
                        <div  class="form-control p-3 mb-3  d-flex justify-content-between">
                            <label for="scan" >{{'taskTemplates.scan' | translate}}</label>
                            <mat-slide-toggle  *ngIf="tasktemplateUserRights_update" formControlName="scan" ></mat-slide-toggle>
                            <mat-slide-toggle  *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="sourceForm.value.scan"></mat-slide-toggle>
                        </div>
        
                        <div  class="form-control p-3 mb-3  d-flex justify-content-between">
                            <label for="documents" >{{'taskTemplates.documents' | translate}}</label>
                            <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="sourceForm.value.documents"></mat-slide-toggle>
                            <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="documents"></mat-slide-toggle>
                        </div>
                    </form>
                <!--
                    <span class="text-uppercase text-muted">{{'taskTemplates.metaData' | translate}}</span>
                    <div  class="form-control p-3 mb-3  d-flex justify-content-between">
                        <label for="metaData" >{{'taskTemplates.metaData' | translate}}</label>
                        <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [disabled]="!tasktemplateUserRights_update" [checked]="fieldForm.value.metaData"></mat-slide-toggle>
                        <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="metaData" [disabled]="!tasktemplateUserRights_update"></mat-slide-toggle>
                    </div>
                    -->
                </div>

                <div *ngIf="fieldForm.value.type=='showImage'">
                    <mat-card class="mb-4">
                        <mat-card-content>
                                <div class="p-2">
                                    <div *ngIf="urlData">
                                        <div class="d-flex align-items-center mb-3">
                                            <mat-icon class="material-symbols-outlined" >image</mat-icon>
                                            <span class="px-2">{{'pass.widgets.img' | translate}}</span>
                                        </div>
                                        <div class="d-flex justify-content-stretch flex-column">
                                            <div *ngIf="urlData" class="custom-form-field" >
                                                <img [src]="urlData" style="width:100%; border-radius:5px;" onerror="this.src='./../../assets/img/default.jpg'">
                                            </div>

                                            <div *ngIf="!urlData" class="custom-form-field" [ngStyle]="{'background-image': 'url( ./../../assets/img/default.jpg)'}"></div>
                                            <div class="form-fields mt-3">
                                                <div class="d-flex align-items-center">
                                                    <mat-form-field appearance="outline" class="flex-grow-1">
                                                        <mat-icon matIconPrefix class="material-symbols-outlined">link</mat-icon>
                                                        <mat-label>{{ 'pass.url' | translate }}</mat-label>
                                                        <input type="text" formControlName="defaultValue" matInput (change)="checkNewUrl()" placeholder="{{'taskTemplates.image_url' | translate}}" [readonly]="!tasktemplateUserRights_update">
                                                    </mat-form-field>

                                                    <button mat-icon-button class="delete-button" style="margin-top:-20px;" (click)="deleteImg()" matTooltip="Delete Image">
                                                        <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!urlData" appDragAndDrop (files)="filesDropped($event)">
                                        <mat-card class="upload-section text-center justify-content-center">
                                            <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">

                                                <div class="p-2 mb-4 text-center">
                                                    <mat-icon class="decoration-icon material-symbols-outlined">add_photo_alternate</mat-icon>

                                                    <button mat-button class="my-3 orange-button" [disabled]="!tasktemplateUserRights_update" [matMenuTriggerFor]="menu">
                                                        {{'pass.pick' | translate}}
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item type="button" (click)="triggerFileInput()">
                                                            <mat-icon class="material-symbols-outlined">folder</mat-icon>{{'pass.browse' | translate}}
                                                        </button>
                                                        <button mat-menu-item type="button"(click)="typeURL()">
                                                            <mat-icon class="material-symbols-outlined">link</mat-icon>{{'enterURL' | translate}}
                                                        </button>
                                                    </mat-menu>

                                                    <input #fileInput type="file" id="imageFile" name="imageFile" style="display: none;" accept=".jpg, .jpeg, .png, .webp" (change)="readURL($event)">
                                                </div>
                                                <p class="py-2 mt-4">{{'pass.supportedFiles' | translate}}: .jpg, .jpeg, .png, .webp</p>

                                            </div>
                                        </mat-card>
                                    </div>
                                </div>
                        </mat-card-content>
                    </mat-card>
                <!-- <mat-form-field appearance="outline" class="w-100 mt-3 ">
                    <mat-label>{{'taskTemplates.image_url' | translate}}</mat-label>
                    <input matInput name="value" formControlName="defaultValue" class="form-control" placeholder="{{'taskTemplates.image_url' | translate}}" value="{{defaultDate}}" id="value" [readonly]="!tasktemplateUserRights_update">
                </mat-form-field> -->
                </div>

                @if(enableValidations){
                    <div [app-work-task-template-field-rules] = "fieldForm.value" (returnChanges)="returnChangesFieldRules($event)"></div>
                }

            </form>
        </section>
    </div>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >
        {{'cancel' | translate}}
    </button>
    <div style="margin-left: 5px;" [matTooltip]="!tasktemplateUserRights_update ? ('home.tooltip' | translate) : null">
        <button mat-button 
                class="orange-button" 
                [disabled]="!tasktemplateUserRights_update || fieldForm.invalid"
                (click)="onSave();"
        >
            {{'apply' | translate}}
        </button>
    </div>

</mat-dialog-actions>
