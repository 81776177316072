import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BillboardVideoEditComponent } from '../../editWidgets/billboard-video-edit/billboard-video-edit.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { checkDynamicContent, supportUrlStructure } from '../../../../../../backend/utils/object';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-billboard-video-phone]',
  templateUrl: './billboard-video-phone.component.html',
  styleUrls: ['./billboard-video-phone.component.css']
})
export class BillboardVideoPhoneComponent implements OnInit{
  @Input('app-billboard-video-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any, images:any}>()

  billboardVideoData:any
  editMode:any
  widgetNumber:any
  videoToStorage:any=[]
  routerUrl:any
  idToShow:any
  noUrl=false
  url:any
  dynamic=false

  constructor(public dialog:MatDialog,
    private router: ActivatedRoute,
    private settingsService:SettingsService
    ){
      this.routerUrl=this.router.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    }

  ngOnInit(): void{
    this.billboardVideoData=this.data[0]
    this.editMode=this.data[1]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }

    if(!this.billboardVideoData.url){
      this.noUrl=true
    }

    if(this.billboardVideoData.url){
      try{
        const partsd = this.billboardVideoData.url.includes("${")
        if(partsd){
          this.dynamic = true
          //this.billboardVideoData.url = checkDynamicContent(this.billboardVideoData.url)
        }
        let url = supportUrlStructure(this.billboardVideoData.url, this.idToShow, this.settingsService.contextId$)
        if(url)
        this.url = url

        // const imgUrl = new URL(this.billboardVideoData.url)
        // let hostname = environment.urlHostName
        // if(imgUrl.protocol=="pass:" ){
        //   const parts = this.billboardVideoData.url.match(/pass:\/\/(.+)\/(.+)/)
        //   if(parts){
        //     const passTemplateID = parts[1]
        //     const path = parts[2]
        //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
        //     this.url=firebaseUrl
        //   }else{
        //     const newparts = this.billboardVideoData.url.match(/pass:\/\/(.+)/)
        //     if(newparts){
        //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
        //       newparts.forEach( (part,index)=> {
        //         if(index>0){
        //           firebaseUrl += '/' + part 
        //         }
        //       })
        //       this.url=firebaseUrl
        //     }
        //   }
        // }

        // if(imgUrl.protocol =="tridy:"){
        //   const parts = this.billboardVideoData.url.match(/tridy:\/\/(.+)\/(.+)/)
        //   let path =""
        //   parts.forEach((parte, index) =>{
        //     if(index>0 && index<parts.length){
        //       path += "/" + parte
        //     }
        //   })
        //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
        //   this.url=firebaseUrl
        // }
      }catch(error){

      }
    }
  }

  openDialogEdit():void{
    let dialogRef = this.dialog.open(BillboardVideoEditComponent, {
      data:{
        values: this.billboardVideoData,
        widget: this.widgetNumber,
        passId: this.idToShow,
        url: this.url
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.billboardVideoData=result[0]
        if(result[2].length!=0){
          this.videoToStorage.push(result[2])
        }
        this.returnChanges.emit({newdata: this.billboardVideoData, widget: this.widgetNumber, widgetType: this.billboardVideoData.type,images: this.videoToStorage})

      }  
    })
  }
}
