<h2 mat-dialog-title>{{'workTask.advanced_settings' | translate}}</h2>

<mat-dialog-content>
    <div class="pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >

            <form [formGroup]="workTaskTemplateForm">
                
                <div>
                    <label for="titleKeys">{{'workTask.title_keys' | translate}}</label>  
                    <p class="text-muted py-3" style="font-size: 14px;">{{'workTask.tk_description' | translate}}</p>

                    <button mat-button 
                            class="secondary-gray-button mb-3"
                            (click)="addTitleKey();"
                            [disabled]="!tasktemplateUserRights_update"
                    >
                        <mat-icon>add_circle</mat-icon> {{'workTask.tk_add' | translate}}
                    </button>

                    <div *ngFor="let s of workTaskTemplateForm.value.titleKeys; let index= index;">
                        <mat-card class="mb-1">
                            <mat-card-content class="py-0">
                                <div class="d-flex align-items-center p-1" style="justify-content: space-between;">
                                    <div>{{s}}</div>
                                    <div>
                                        <button style="color:var(--warmOrange);vertical-align: middle;" mat-icon-button (click)="updateTitleKey(s, index);">
                                            <mat-icon class="material-symbols-outlined">edit</mat-icon>
                                        </button>
                                        <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deleteTitleKey(s, index);">
                                            <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
        
                <div class="mt-3">
                    <label for="filter">{{'workTask.filter' | translate}}</label>   
                    <p class="text-muted py-3" style="font-size: 14px;">{{'workTask.f_description' | translate}}</p>

                    <button mat-button 
                            class="secondary-gray-button mb-3"
                            (click)="addPredicate();"
                            [disabled]="!tasktemplateUserRights_update"
                    >
                        <mat-icon>add_circle</mat-icon> {{'workTask.f_add' | translate}}
                    </button>                    

                    <div *ngFor="let s of workTaskTemplateForm.value.predicates; let index= index;">
                        <mat-card class="mb-1">
                            <mat-card-content class="py-0">
                                <div class="d-flex align-items-center p-1" style="justify-content: space-between;">
                                    <div>{{s}}</div>
                                    <div>
                                        <button style="color:var(--warmOrange);vertical-align: middle;" mat-icon-button (click)="updatePredicate(s, index);">
                                            <mat-icon class="material-symbols-outlined">edit</mat-icon>
                                        </button>
                                        <button class="delete-button" style="vertical-align: middle;" mat-icon-button (click)="deletePredicate(s, index);">
                                            <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div> 
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onDone();" [disabled]="!tasktemplateUserRights_update">{{'apply' | translate}}</button>
</mat-dialog-actions>
