import { Injectable } from '@angular/core';
import { getAuth, onAuthStateChanged } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from '../service/settings.service';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router,
    private settingsService: SettingsService,
    private authService: AuthService){
    
  }
  canActivate(
    route: import("@angular/router").ActivatedRouteSnapshot,
    state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
      console.log(route, state)

    return this.canActivateChild(route, state)
  }

  canActivateChild(route: import("@angular/router").ActivatedRouteSnapshot,
  state: import("@angular/router").RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      console.log(route, state)
      const auth = getAuth(); // Get the Firebase auth instance
      onAuthStateChanged(auth, (user) => {
        // Perform user authentication check
        if (auth.currentUser && auth.currentUser.uid ) {          
          const routeGo =state.url
          this.settingsService.observeReloadLoggedData(routeGo)   
          observer.next(true);
        } else {
          // User is not authenticated or email is not verified, redirect to login page
          console.log('User is not authenticated or email is not verified, redirect to login page');
          this.authService.signOut()
          observer.next(false);

        }
        observer.complete();
      });
    });
  }
}
