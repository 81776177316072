import { ChangeDetectorRef, Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { ContentService } from '../service/content.service';
import { defaultContentData } from './../../../../backend/src/defaultContent.dto';
import { CommentsService } from '../service/comments.service';
import { MatDialog } from '@angular/material/dialog';
import { ContentEditDialogComponent } from '../content-edit-dialog/content-edit-dialog.component';
import { Router } from '@angular/router';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LocalStorageService } from '../service/local-storage-service.service';
import { isDate, isDateTime, isTime } from '../../../../backend/src/filter';
import { Subject } from 'rxjs';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  constructor(private translate: TranslateService){}

  firstPageLabel = this.translate.instant("first_page");
  itemsPerPageLabel = this.translate.instant("items_page");
  lastPageLabel =this.translate.instant("last_page")
  nextPageLabel = this.translate.instant("next_page");
  previousPageLabel = this.translate.instant("previous_page");

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.instant("page1_1");
    }
    const amountPages = Math.ceil(length / pageSize);
    return this.translate.instant("page_of_1") + (page + 1) +this.translate.instant("page_of_2") + amountPages;
  }
}

export interface ContentData {
  id: string;
  key: string;
  type: string;
  value?: any;
  timeStamp:Date;
  creationDate: Date;
}

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit{
  contentData:any
  contentUserRights_update:any
  contentUserRights_create:any
  contentUserRights_delete:any
  emptyList = false
  dataSource = new MatTableDataSource<any>()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['key', 'value', 'creationDate', 'timeStamp', 'deleteContent'];
  paginatorSize = this.settingsService.paginator$

  constructor(private settingsService: SettingsService,
    private contentServce: ContentService,
    private commentService: CommentsService,
    public dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private localStorageService:LocalStorageService,
    private changeDetectorRef: ChangeDetectorRef,

    ){
  }

  ngOnInit():void{
    this.contentUserRights_update = this.settingsService.modulesRolesRights$.value['content']['U']
    this.contentUserRights_create = this.settingsService.modulesRolesRights$.value['content']['C']
    this.contentUserRights_delete= this.settingsService.modulesRolesRights$.value['content']['D']

    this.settingsService.modulesRolesRights$.subscribe( value => {
      this.contentUserRights_update = this.settingsService.modulesRolesRights$.value['content']['U']
      this.contentUserRights_create = this.settingsService.modulesRolesRights$.value['content']['C']
      this.contentUserRights_delete= this.settingsService.modulesRolesRights$.value['content']['D']
    })
    this.getContent()
  }

  async getContent(){
    try{
      this.commentService.progressSpin.emit(true)
      this.settingsService.getContentEntries().subscribe(allcontents => {

        if(allcontents){
          this.contentData = allcontents
          this.dataSource = new MatTableDataSource<ContentData>(this.contentData); 
          if(this.contentData.length==0){
            this.emptyList = true
          }
          this.shorTableAgain();
        }
      })
      // this.contentData.sort((a, b) => (a.key < b.key) ? -1 : 1);
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED") 
      this.commentService.addSnackBar.emit(message)
    }
  }


  shorTableAgain(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    setTimeout(() => {
      this.dataSource.filter = " ";
      this.commentService.progressSpin.emit(false)
    }, 500);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator.page.subscribe(() => this.storeTablePreferences());
    this.sort.sortChange.subscribe(() => this.storeTablePreferences());
    this.loadTablePreferences();

    setTimeout(() => {
      if (this.contentData && this.contentData.length != 0) {
        this.dataSource.paginator = this.paginator;
        const preferences = this.localStorageService.get('contentTypeTablePreferences');
        if (preferences) {
          this.paginator.pageIndex = preferences.pageIndex;
          this.paginator._changePageSize(this.paginator.pageSize);
        } else {
          this.dataSource.paginator.firstPage();
        }
        this.commentService.progressSpin.emit(false);
      } else {
        this.commentService.progressSpin.emit(false);
      }
      this.dataSource.filter = " ";
      this.commentService.progressSpin.emit(false)
    }, 500);
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator)
      this.dataSource.paginator.firstPage();
    
  }

  async addNewData(type:string){
    const data = defaultContentData[type]
    try{
      this.commentService.progressSpin.emit(true)
      const a=await this.contentServce.createContent(data)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.NEW_CONTENT_OK") 
      this.commentService.addSnackBar.emit(message) //showSnackbar
      this.getContent()
      this.openDialogEditContent(data.type, data)
    }catch(error){
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.NEW_CONTENT_FAILED") 
      this.commentService.addSnackBar.emit(message) //showSnackbar
      console.log(error)
    }
  }

  openDialogEditContent(type:string, data:any):void{
    let dialogRef= this.dialog.open(ContentEditDialogComponent, {
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        removeUndefinedValuesFromObject(result[0])
        try{
          this.commentService.progressSpin.emit(true)
          this.contentServce.updateContent(result[0].id, result[0])
          this.commentService.progressSpin.emit(false)

        }catch(error){
          console.log(error)
          this.commentService.progressSpin.emit(false)
        }
        this.getContent()
      }
    });
  }

  clickContentList(content:any){
    this.openDialogEditContent(content.type, content)
  }

  async getStorage(){
    this.router.navigate(['home',this.settingsService.contextId$,'content','media'])
  }

  deleteContent(data){
    let dialogRef= this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { 
        values:data,
        type:"Content"
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.getContent()
      }else{
        const message = this.translate.instant("SnackBarConstants.DELETE_DISMISS")
        this.commentService.addSnackBar.emit(message)
      }
    });
  }

  loadTablePreferences(): void {
    const preferences = this.localStorageService.get('contentTablePreferences');
    if (preferences) {
      this.paginator.pageSize = preferences.pageSize;
      this.sort.active = preferences.sortActive;
      this.sort.direction = preferences.sortDirection;
      this.paginator.pageIndex = preferences.pageIndex;
    } else {
      this.sort.active = 'timeStamp';
      this.sort.direction = 'desc';
    }
    this.changeDetectorRef.detectChanges();
  }

  storeTablePreferences(): void {
    const preferences = {
      pageSize: this.paginator.pageSize,
      sortActive: this.sort.active,
      sortDirection: this.sort.direction,
      pageIndex: this.paginator.pageIndex
    };
    this.localStorageService.set('contentTablePreferences', preferences);
  }

  isDate(vakue){
    return isDate(vakue)
  }

  isTime(value){
    return isTime(value)
  }

  isDateTime(value){
    return isDateTime(value)
  }
}
