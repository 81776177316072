import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { NetworkConstants } from 'src/network.constants';
import { deleteDoc, doc, getFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FinishedTasksService {
  db = getFirestore()
 
  constructor(private settingsService: SettingsService,) { }

    deleteFinishedTasks(finishedId):Promise<void>{
      return new Promise(async (resolve, reject) => {
        try {

          const docRef = doc(this.db, NetworkConstants.COLLECTION_CONTEXTS, this.settingsService.contextId$,NetworkConstants.COLLECTION_WORK_TASKS, finishedId)
          const d  = await deleteDoc( docRef)
          resolve(d)
        } catch (error) {
          reject(error)  
          console.log(error)      
        } 
      })
    }
}