import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {TaskTemplate} from './../../../../backend/src/tasktemplate.dto'
import { WorkTaskTemplateEditValueDialogComponent } from '../work-task-template-edit-value-dialog/work-task-template-edit-value-dialog.component';
import { deepCopy, removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { SettingsService } from '../service/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { WorkTaskTemplateDialogIcomingOutgoingComponent } from '../work-task-template-dialog-icoming-outgoing/work-task-template-dialog-icoming-outgoing.component';

@Component({
  selector: 'app-work-task-template-dialog',
  templateUrl: './work-task-template-dialog.component.html',
  styleUrls: ['./work-task-template-dialog.component.css']
})
export class WorkTaskTemplateDialogComponent implements OnInit{
  workTaskTemplateForm!:FormGroup
  workTaskTemplateData: TaskTemplate 
  allActionsTypes:any=[]
  allActionsNames:any=[]
  titleKeys:any=[]
  predicates:any=[]
  writeTag:any =""
  verifyTag:any =""
  taskTemplateGroups:any
  identify_p:any =""
  identify_face:any=""
  posibleActions:any=[
    {
      name:"Write Tag" ,
      translate:"Write Tag",
      added:false,
      value:"writeTag",
      pos:0,
      disabled:false
    },
    {
      name:"Verify Tag",
      translate:"{{'workTask.action_verify_tag' | translate}}",
      added:false,
      value:"verifyTag",
      pos:1,
      disabled:false
    },

    {
      name:"Lock Tag",
      translate:"{{'workTask.action_lock_tag' | translate}}",
      added:false,
      value:"lockTag",
      pos:2,
      disabled:false
    },
    
    {
      name:"Identify with Personalausweis",
      translate:"{{'workTask.action_identify_p' | translate}}",
      added:false,
      value:"verifyGermanPassport",
      pos:3,
      disabled:false
    },
    {
      name:"Identify with FaceID",
      translate:"{{'workTask.action_identify_faceId' | translate}}",
      added:false,
      value:"faceId",
      pos:4,
      disabled:false
    }
  ]
  panelOpenState = false;
  allActions:any=[]
  contentTypesList:any=[]
  tasktemplateUserRights_update:any

  tridySelection = {
    "scan":{
      translation:'workTask.select_scan',
      value:'scan',
    },
    "select":{
      translation:'workTask.select_list',
      value:'select'
    },
    "opticalScan":{
      translation:'workTask.select_opticalScan',
      value:'opticalScan'
    },
    "scanQR":{
      translation:'workTask.select_scanQR',
      value:'scanQR'
    },
    "scanNFC":{
      translation:'workTask.select_scanNFC',
      value:'scanNFC'
    }
  }

  multiplicity = [
    {//"0-*":
      translation:"workTask.multiplicity.endless",
      value:'0-*',
    },
    {//"1-*":
      translation:"workTask.multiplicity.atLEast_once",
      value:'1-*'
    },
    {//"0-1":
      translation:"workTask.multiplicity.maxOne",
      value:"0-1"
    },
    {//"1-1":
      translation:"workTask.multiplicity.one",
      value:"1-1"
    },
    {//"":
      translation:"workTask.multiplicity.custom",
      value:""
    }
  ]

  constructor(
    public dialogRef: MatDialogRef<WorkTaskTemplateDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialog: MatDialog,
    private settingsService : SettingsService,
    private translate:TranslateService
  ){
    dialogRef.disableClose = true;
    this.translate.get('workTask.action_write_tag').toPromise().then(value =>{
        this.writeTag = value
        this.posibleActions[0].translate = value
    })

    this.translate.get('workTask.action_verify_tag').toPromise().then(value =>{
      this.verifyTag = value
      this.posibleActions[1].translate = value
    })

    this.translate.get('workTask.action_lock_tag').toPromise().then(value =>{
      this.verifyTag = value
      this.posibleActions[2].translate = value
    })

    this.translate.get('workTask.action_identify_p').toPromise().then(value =>{
      this.identify_p = value
      this.posibleActions[3].translate = value
    }) 
    this.translate.get('workTask.action_identify_faceId').toPromise().then(value =>{
      this.identify_face = value
      this.posibleActions[4].translate = value
  })
  }

  ngOnInit(): void {
    this.tasktemplateUserRights_update = this.settingsService.userRolesRights$['workTaskTemplates']['U']
    this.contentTypesList = this.settingsService.settingsContentTypesWithId
    this.workTaskTemplateData = new TaskTemplate()
    this.workTaskTemplateData.setFromAny(this.data.values)
    this.taskTemplateGroups = this.data['groups']

    this.workTaskTemplateForm = this.fb.group({
      tridySource:[],
      contentType:[],
      visibleInPass:[],
      actions:[],
      titleKeys:[],
      predicates:[],
      createContent:[],
      incomingTridys:[],
      outgoingTridys:[],
      taskType:[, Validators.required],
      showImages:[],
      showComment:[],
      title:[, Validators.required],
      groupId:[],
      hidden:[],
      gpsMandatory:[],
    })

    this.initializeActions()
    const {tridySource, contentType, visibleInPass, actions, titleKeys, predicates,incomingTridys,
      outgoingTridys,taskType,groupId,showImages,showComment,title, hidden, gpsMandatory} = this.workTaskTemplateData

    this.workTaskTemplateForm.patchValue({
      tridySource:tridySource ? tridySource : undefined,
      contentType: contentType ? contentType : undefined,
      visibleInPass:visibleInPass ? visibleInPass : false,
      actions:actions ? actions : undefined,
      titleKeys: titleKeys ? titleKeys : undefined,
      predicates: predicates ? predicates : undefined,
      createContent:undefined,
      incomingTridys : incomingTridys ? incomingTridys : undefined,
      outgoingTridys: outgoingTridys ? outgoingTridys :undefined,
      taskType: taskType ? taskType : undefined,
      groupId: groupId ? groupId : undefined,
      showImages:showImages,
      showComment:showComment,
      title: title ? title : undefined,
      hidden: hidden ? hidden : undefined,
      gpsMandatory: gpsMandatory ? gpsMandatory : undefined,
    })
    if(taskType && taskType=='transform'){
    }else{
      this.workTaskTemplateForm.controls["contentType"].setValidators([Validators.required]);
      this.workTaskTemplateForm.controls["contentType"].updateValueAndValidity();
    }

    this.workTaskTemplateForm.get('taskType').valueChanges.subscribe( value => {
      if(value=='transform'){
        this.disableAfterTransform()
      }else
        this.ableWhenNoTransform()
    })

    if(taskType=='transform'){
      this.disableAfterTransform()
    }

    if(showComment==undefined){
      this.workTaskTemplateForm.patchValue({
        showComment:true 
      })
    }

    if(showImages==undefined){
      this.workTaskTemplateForm.patchValue({
        showImages:true 
      })
    }

    if(this.workTaskTemplateForm.value.titleKeys){
      this.titleKeys = this.workTaskTemplateForm.value.titleKeys
    }

    if(this.workTaskTemplateForm.value.predicates){
      this.predicates = this.workTaskTemplateForm.value.predicates
    }

  }

  ableWhenNoTransform(){
    // const action_writeTag = this.posibleActions.find( act => act.value == 'writeTag')
    // action_writeTag.disabled = false
    // const action_lockTag = this.posibleActions.find( act => act.value == 'lockTag')
    // action_lockTag.disabled = false

    this.posibleActions.forEach(action => {
      action.disabled = false
    });
    this.workTaskTemplateForm.controls["contentType"].setValidators([Validators.required]);
    this.workTaskTemplateForm.controls["contentType"].updateValueAndValidity();
  }

  disableAfterTransform(){
    // const action_writeTag = this.posibleActions.find( act => act.value == 'writeTag')
    // action_writeTag.disabled = true
    // const action_lockTag = this.posibleActions.find( act => act.value == 'lockTag')
    // action_lockTag.disabled = true
    // if(this.workTaskTemplateForm.value.actions){
    //   if(this.workTaskTemplateForm.value.actions.includes('writeTag')){
    //     let cleanedAction = []
    //     this.workTaskTemplateForm.value.actions.forEach((element, index) => {
    //       if(element!='writeTag'){
    //         cleanedAction.push(element)
    //       }else{
    //         action_writeTag.added = false
    //       }
    //     });
    //     this.workTaskTemplateForm.patchValue({actions: cleanedAction})
    //   }
    //   if(this.workTaskTemplateForm.value.actions.includes('lockTag')){
    //     let cleanedAction = []
    //     this.workTaskTemplateForm.value.actions.forEach((element, index) => {
    //       if(element!='lockTag'){
    //         cleanedAction.push(element)
    //       }else{
    //         action_lockTag.added = false
    //       }
    //     });
    //     this.workTaskTemplateForm.patchValue({actions: cleanedAction})
    //   }
    // }
    let actions = deepCopy(this.workTaskTemplateForm.value.actions)

    if(actions){
      this.posibleActions.forEach((action, index) => {
        action.added = false
        action.disabled = true
      });
    }
    this.workTaskTemplateForm.patchValue({actions: []})
    this.workTaskTemplateForm.controls["contentType"].removeValidators(Validators.required);
    this.workTaskTemplateForm.controls["contentType"].clearValidators();
    this.workTaskTemplateForm.controls["contentType"].updateValueAndValidity();
  }

  initializeActions(){

    if(this.workTaskTemplateData.actions){
      this.workTaskTemplateData.actions.forEach((n:any) => {
        this.posibleActions.forEach((pn:any)=> {
          if(n==pn.value){
            pn.added=true

            const tn= {
              name:pn.name,
              value: n
            }
            
          this.allActions.push(tn)
          this.allActionsNames.push(tn.value)
          }
        })
      })
    }
  }

  onDone(){
    this.orderActions()
    if(this.workTaskTemplateForm.value.amountInteger){
      const amount = Math.trunc(this.workTaskTemplateForm.value.amount)
      this.workTaskTemplateForm.patchValue({amount: amount})
    }
    const newValues = this.workTaskTemplateForm.value
    this.dialogRef.close([newValues, true])
  }

  onCancel(){
    this.dialogRef.close([this.data, false])
  }

  addAction(name:string){
    const n={
      name:name,
      value:""
    }

    if(!this.posibleActions.includes(name)){
      this.posibleActions.forEach( (pa:any) => {
        if(pa.name == name){
          pa.added=true
          n.value = pa.value
        }
      })
      this.allActions.push(n)
      this.posibleActions.sort((a,b) => a.pos - b.pos); // b - a for reverse sort
      this.allActionsNames.push(n.value)
      this.workTaskTemplateForm.patchValue({
        actions:this.allActionsNames
      })
    }
  }

  orderActions(){
    let orderActions = []
    const actionsAll = deepCopy(this.workTaskTemplateForm.value.actions)
    this.posibleActions.forEach( actions => {
      if(actionsAll.includes(actions.value)){
        orderActions.push(actions.value)
      }
    })

    this.workTaskTemplateForm.patchValue({actions: orderActions})
  }

  deleteAction(action:any, index:number){
    if(this.tasktemplateUserRights_update){
      this.posibleActions.forEach((pn:any)=> {
        if(pn==action){
          pn.added=false
          this.allActions.forEach((a:any, i:any)=>{
            if(a.name==action.name){
              this.allActions.splice(i,1)
            }
          })

          this.allActionsNames.forEach( (a:any, ind:any) =>{
            if(a == action.value){
              this.allActionsNames.splice(ind,1)
            }
          })
        }
      })
      this.workTaskTemplateForm.patchValue({
        actions:this.allActionsNames
      })
    }
    

  }

  deleteTitleKey(titleKey, index){
    if(this.tasktemplateUserRights_update){
      this.titleKeys.splice(index,1)
      this.workTaskTemplateForm.patchValue({titleKeys:this.titleKeys})
    }
  }

  addTitleKey(){
    const value = "new"
    this.titleKeys.push(value)
    this.workTaskTemplateForm.patchValue({titleKeys:this.titleKeys})
  }

  updateTitleKey(s, index){
    if(this.tasktemplateUserRights_update){
      let dialogRef = this.dialog.open(WorkTaskTemplateEditValueDialogComponent, {
        data: { 
          value: s,
          type:'Title Key'
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[1]){
          this.titleKeys[index] = result[0].value

        }
      });
    }
  }

  addPredicate(){
    const value = "field = value"
    this.predicates.push(value)
    this.workTaskTemplateForm.patchValue({predicates:this.predicates})
  }

  updatePredicate(s, index){
    if(this.tasktemplateUserRights_update){
      let dialogRef = this.dialog.open(WorkTaskTemplateEditValueDialogComponent, {
        data: { 
          value: s,
          type:'Predicate'
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[1]){
          this.predicates[index] = result[0].value
          this.workTaskTemplateForm.patchValue({predicates:this.predicates})
        }
      });
    }
    
  }

  deletePredicate(predicate, index){
    if(this.tasktemplateUserRights_update){
      this.predicates.splice(index,1)
      this.workTaskTemplateForm.patchValue({predicates:this.predicates})
    }
  }

  addIncomingTridys(){
    if(this.tasktemplateUserRights_update){
      let dialogRef = this.dialog.open(WorkTaskTemplateDialogIcomingOutgoingComponent, {
        data: { 
          values:'',
          function: 'new',
          type:'Incoming',
          fields:['actions', 'contentTypeId', 'displayTitle', 'multiplicity', 'source'],
          contentTypes: this.contentTypesList
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[1]){ 
          let incomingTridys = deepCopy(this.workTaskTemplateForm.value.incomingTridys)
          if(!incomingTridys)
            incomingTridys = []
          incomingTridys.push(result[0])
          this.workTaskTemplateForm.patchValue({incomingTridys:incomingTridys})
        }
      });
    }
  }

  openIncomingTridy(incoming, index){
    if(this.tasktemplateUserRights_update){
      let dialogRef = this.dialog.open(WorkTaskTemplateDialogIcomingOutgoingComponent, {
        data: { 
          values:incoming,
          function: 'update',
          type:'Incoming',
          fields:['actions', 'contentTypeId', 'displayTitle', 'multiplicity', 'source'],
          contentTypes: this.contentTypesList
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[1]){ 
          const incomingTridys = deepCopy(this.workTaskTemplateForm.value.incomingTridys)
          incomingTridys[index] = result[0]
          this.workTaskTemplateForm.patchValue({incomingTridys:incomingTridys})
        }
      });
    }
  }

  addOutgoingTridys(){
    if(this.tasktemplateUserRights_update){
      let dialogRef = this.dialog.open(WorkTaskTemplateDialogIcomingOutgoingComponent, {
        data: { 
          values:'',
          function: 'new',
          type:'Outgoing',
          fields:['actions', 'contentTypeId', 'displayTitle', 'multiplicity', 'source'],
          contentTypes: this.contentTypesList
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[1]){
          let outgoingTridys = deepCopy(this.workTaskTemplateForm.value.outgoingTridys)
          if(!outgoingTridys)
            outgoingTridys = []
          outgoingTridys.push(result[0])
          this.workTaskTemplateForm.patchValue({outgoingTridys:outgoingTridys})
        }
      });
    }
  }

  openOutgoingTridy(outgoing, index){
    if(this.tasktemplateUserRights_update){
      let dialogRef = this.dialog.open(WorkTaskTemplateDialogIcomingOutgoingComponent, {
        data: { 
          values:outgoing,
          function: 'update',
          type:'Outgoing',
          fields:['actions', 'contentTypeId', 'displayTitle', 'multiplicity', 'source'],
          contentTypes: this.contentTypesList
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result[1]){ 
          const outgoingTridys = deepCopy(this.workTaskTemplateForm.value.outgoingTridys)
          outgoingTridys[index] = result[0]
          this.workTaskTemplateForm.patchValue({outgoingTridys:outgoingTridys})
        }
      });
    }
  }

  deleteIncomingTridy(outgoing, index){
    if(this.tasktemplateUserRights_update){
      const incomingTridys = deepCopy(this.workTaskTemplateForm.value.incomingTridys)
      incomingTridys.splice(index,1)
      this.workTaskTemplateForm.patchValue( {incomingTridys:incomingTridys})
    }
  }
  deleteOutgoingTridy(outgoing, index){
    if(this.tasktemplateUserRights_update){
      const outgoingTridys = deepCopy(this.workTaskTemplateForm.value.outgoingTridys)
      outgoingTridys.splice(index,1)
      this.workTaskTemplateForm.patchValue( {outgoingTridys:outgoingTridys})
    }
  }

  getContentTypeIdName(id){
    const content = this.contentTypesList.find( contentType => contentType.id == id )
    if(content)
    return content.displayName
  }

  getTridyMultiplicity(multiplicity){
    let translation
    const multipli = this.multiplicity.find( option => option.value == multiplicity )
    if(multipli)
    translation = this.translate.instant(multipli.translation)
    else{
      const values = multiplicity.split('-')
      translation = values[0] + ' to ' + values[1]
    }
    return translation
  }

  getTridyAction(actions: string[]): string | undefined {
    if (actions && actions.length > 0) {
      // Define the possible translations based on action values
      const actionTranslations = {
        writeTag: 'workTask.action_new_tag',
        readTag: 'workTask.action_scan_existing_tag',
        readTagQR: 'workTask.action_scan_existing_QR'
      };
  
      return actions
        .map(action => actionTranslations[action] ? actionTranslations[action] : '')
        .filter(Boolean)
        .map(translationKey => this.translate.instant(translationKey))
        .join(', ');
    }
    return undefined;
  }

  getTridySelection(incoming){
    let all = ''
    if(incoming){
      if(incoming.source && !incoming.sources){
        const source = incoming.source
        if(this.tridySelection[source]){
          const message = this.translate.instant(this.tridySelection[source].translation)
          all += '-'+message + ' '
        }
      }

      if(incoming.sources){
        incoming.sources.forEach( source => {
          if(this.tridySelection[source]){
            const message = this.translate.instant(this.tridySelection[source].translation)
            all += '-'+message + ' '
          }
        })
      }
    }
    return all
  }
}
