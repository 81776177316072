import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/app/service/comments.service';
import {  deepCopy,removeUndefinedValuesFromObject } from '../../../../../backend/utils/object';
import { UploadService } from 'src/app/service/upload.service';
import { SettingsService } from 'src/app/service/settings.service';
import { languagesList, languagesList_ } from '../../../../../backend/src/languagesList.dto';

@Component({
  selector: 'app-buttons-translation-edit',
  templateUrl: './buttons-translation-edit.component.html',
  styleUrls: ['./buttons-translation-edit.component.css']
})
export class ButtonsTranslationEditComponent {
  buttonsForm!:FormGroup
  buttonsData:any
  oldData:any
  buttonsStyleData:any
  buttonsStyleForm!:FormGroup
  buttonsStyleFormOriginal!:FormGroup
  colorPalette:any=[]
  invalid_backgroundColor=false
  invalid_foregroundColor=false
  invalid_borderColor=false
  buttonStyleDefault:any = {
    "backgroundColor": "#FF5E38",
    "borderColor": "#FF5E38",
    "borderWidth": 0,
    "foregroundColor": "#fefefe"
  }
  newImage:any=[]
  allbuttonsForm!:FormGroup
  allButtons:any = []
  allButtonsIds:any = []
  allButtonsNumber:any = 0
  panelOpenState=false
  urlRegex = /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegexDoc = /^pass?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  passId:any
  widgetNumber:any
  url:any
  uploadDocument:any={}
  showOrginalActive = false
  languagesList = languagesList
  translationForm!:FormGroup
  firstLanguageContexts:any
  passLanguagesList:any
  passTranslations:any
  oldTranslations:any
  selectedLanguage:string
  widgetId:string
  buttonsFormOriginal!:FormGroup
  allbuttonsFormOriginal!:FormGroup
  allButtonsOriginal:any = []
  allButtonsIdsOriginal:any = []
  allButtonsNumberOriginal:any = 0

  constructor(public dialogRef: MatDialogRef<ButtonsTranslationEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private translate:TranslateService,
    private settingsService: SettingsService,
    private uploadService: UploadService){
      dialogRef.disableClose = true;
      const currentLang = this.translate.currentLang;
      this.languagesList = languagesList_[currentLang]
  }

  ngOnInit():void{
    // this.initializePaletteColors()
    this.firstLanguageContexts= this.settingsService.firstLanguageContexts$
    this.selectedLanguage = this.data.selectedLanguage
    this.widgetId = this.data.values.id

    if(this.data.translations){
      this.passTranslations = deepCopy(this.data.translations)
      this.oldTranslations = deepCopy(this.data.translations)
    }else{
      this.passTranslations = {}
      this.oldTranslations = {}
    }

    this.widgetNumber= this.data.widget
    this.passLanguagesList = this.data.languages

    this.translationForm = this.fb.group({translations:[]})
    this.translationForm.patchValue({
      translations: this.passTranslations ? this.passTranslations : undefined
    })

    this.buttonsStyleForm = this.fb.group({
      backgroundColor: [{value:undefined, disabled:true}],
      borderColor:[{value:undefined, disabled:true}],
      foregroundColor:[{value:undefined, disabled:true}],
      borderWidth:[{value:undefined, disabled:true}],
    })

    this.allbuttonsForm = this.fb.group({})

    this.buttonsForm = this.fb.group({
      buttons:[{value:undefined, disabled:true}],
      buttonsStyle:[{value:undefined, disabled:true}],
      cornerRadius:[{value:undefined, disabled:true}],
      id:[{value:undefined, disabled:true}],
      type:[{value:undefined, disabled:true}],
      name:[{value:undefined, disabled:true}]
    })

    this.buttonsData = deepCopy(this.data.values)
    this.passId = this.data.passId
    this.widgetNumber= this.data.widget

    if(this.buttonsData){
      this.oldData = deepCopy(this.buttonsData)
      const {buttons, buttonsStyle, cornerRadius, id, type, name} = this.buttonsData

      this.buttonsForm.patchValue({
        buttons:buttons ? buttons : [],
        buttonsStyle: buttonsStyle ? buttonsStyle : undefined,
        cornerRadius:cornerRadius ? cornerRadius : 0,
        id:id ? id : undefined,
        type:type ? type : undefined,
        name:name ? name : undefined
      })
      
      if(this.buttonsData.buttons){
        this.buttonsData.buttons.forEach( (button, index) => {
          this.allButtons.push(button)
          this.allButtonsIds.push(button.id)
          this.allButtonsNumber++
          const buttonId = button.id
          const title = this.data.valuesTranslated[this.selectedLanguage +'.'+id +'_'+buttonId+'.title']
          const url = this.data.valuesTranslated[this.selectedLanguage +'.'+id+'_'+buttonId+'.url']
          const buttonName = button.id
  
          const btn_backgroundColor = buttonName + '.' + 'backgroundColor'
          const backgroundColor_value = button.backgroundColor ? button.backgroundColor : undefined
          this.allbuttonsForm.addControl(btn_backgroundColor, new FormControl({value:backgroundColor_value, disabled:true}))

          const btn_foregroundColor = buttonName + '.' + 'foregroundColor'
          this.allbuttonsForm.addControl(btn_foregroundColor, new FormControl({value:button.foregroundColor, disabled:true}))
  
          const btn_borderColor = buttonName + '.' + 'borderColor'
          this.allbuttonsForm.addControl(btn_borderColor, new FormControl({value:button.borderColor, disabled:true}))

          const btn_borderWidth = buttonName + '.' + 'borderWidth'
          const borderWidth_value = button.borderWidth ? button.borderWidth : 0
          this.allbuttonsForm.addControl(btn_borderWidth, new FormControl({value:borderWidth_value, disabled:true}))
  
          const btn_customize = buttonName + '.' + 'customize'
          this.allbuttonsForm.addControl(btn_customize, new FormControl({value:button.customize, disabled:true}))
  
          const btn_id = buttonName + '.' + 'id'
          this.allbuttonsForm.addControl(btn_id, new FormControl({value:button.id, disabled:false}))

          const btn_url = buttonName + '.' + 'url'
          this.allbuttonsForm.addControl(btn_url, new FormControl({value:url, disabled:false}, [ Validators.pattern(this.urlRegex)]))
  
          const btn_title = buttonName + '.' + 'title'
          this.allbuttonsForm.addControl(btn_title, new FormControl({value:title, disabled:false}))
        })
      }
    }

    if(this.buttonsData.buttonsStyle){
      this.buttonsStyleData = this.buttonsData.buttonsStyle
      
      const { backgroundColor, foregroundColor, borderColor,borderWidth} = this.buttonsStyleData
      this.buttonsStyleForm.patchValue({
        backgroundColor: backgroundColor ? backgroundColor : undefined,
        borderColor: borderColor ? borderColor : undefined,
        foregroundColor:foregroundColor ? foregroundColor : undefined,
        borderWidth: borderWidth ? borderWidth : 0
      })
    }else{
      this.buttonsStyleData = this.buttonStyleDefault
      
      const { backgroundColor, foregroundColor, borderColor,borderWidth} = this.buttonsStyleData
      this.buttonsStyleForm.patchValue({
        backgroundColor: backgroundColor ? backgroundColor : undefined,
        borderColor: borderColor ? borderColor : undefined,
        foregroundColor:foregroundColor ? foregroundColor : undefined,
        borderWidth: borderWidth ? borderWidth : 0
      })
    }
    this.loadOriginalLanguageData(this.data?.defaultLanguageWidgetData)
  }

  loadOriginalLanguageData(defaultLanguageWidgetData){
    this.allbuttonsFormOriginal = this.fb.group({})
    const {buttons, buttonsStyle, cornerRadius, id, type, name} = defaultLanguageWidgetData

    this.buttonsFormOriginal = this.fb.group({
      buttons:[{value:buttons ? buttons : [], disabled:true}],
      buttonsStyle:[{value: buttonsStyle ? buttonsStyle : undefined, disabled:true}],
      cornerRadius:[{value:cornerRadius ? cornerRadius : 0, disabled:true}],
      id:[{value:id ? id : undefined, disabled:true}],
      type:[{value:type ? type : undefined, disabled:true}],
      name:[{value:name ? name : undefined, disabled:true}]
    })
    
    if(buttons){
      buttons.forEach( (button, index) => {
        this.allButtonsOriginal.push(button)
        this.allButtonsIdsOriginal.push(button.id)
        this.allButtonsNumberOriginal++
        const buttonId = button.id
        const title = button?.title
        const url = button?.url
        const buttonName = button.id

        const btn_backgroundColor = buttonName + '.' + 'backgroundColor'
        const backgroundColor_value = button.backgroundColor ? button.backgroundColor : undefined
        this.allbuttonsFormOriginal.addControl(btn_backgroundColor, new FormControl({value:backgroundColor_value, disabled:true}))

        const btn_foregroundColor = buttonName + '.' + 'foregroundColor'
        this.allbuttonsFormOriginal.addControl(btn_foregroundColor, new FormControl({value:button.foregroundColor, disabled:true}))

        const btn_borderColor = buttonName + '.' + 'borderColor'
        this.allbuttonsFormOriginal.addControl(btn_borderColor, new FormControl({value:button.borderColor, disabled:true}))

        const btn_borderWidth = buttonName + '.' + 'borderWidth'
        const borderWidth_value = button.borderWidth ? button.borderWidth : 0
        this.allbuttonsFormOriginal.addControl(btn_borderWidth, new FormControl({value:borderWidth_value, disabled:true}))

        const btn_customize = buttonName + '.' + 'customize'
        this.allbuttonsFormOriginal.addControl(btn_customize, new FormControl({value:button.customize, disabled:true}))

        const btn_id = buttonName + '.' + 'id'
        this.allbuttonsFormOriginal.addControl(btn_id, new FormControl({value:button.id, disabled:true}))

        const btn_url = buttonName + '.' + 'url'
        this.allbuttonsFormOriginal.addControl(btn_url, new FormControl({value:url, disabled:true}, [ Validators.pattern(this.urlRegex)]))

        const btn_title = buttonName + '.' + 'title'
        this.allbuttonsFormOriginal.addControl(btn_title, new FormControl({value:title, disabled:true}))
      })
    }

    let data = buttonsStyle ?? this.buttonStyleDefault
    const { backgroundColor, foregroundColor, borderColor,borderWidth} = data

    this.buttonsStyleFormOriginal = this.fb.group({
      backgroundColor: [{value:backgroundColor ? backgroundColor : undefined, disabled:true}],
      borderColor:[{value:borderColor ? borderColor : undefined, disabled:true}],
      foregroundColor:[{value:foregroundColor ? foregroundColor : undefined, disabled:true}],
      borderWidth:[{value:borderWidth ? borderWidth : 0, disabled:true}],
    })
  }

  getCustomizeOptionOfButton(index,id){
    const name = id+'.customize'
    return this.allbuttonsForm.value[name]
  }

  readDocument(event: any, button: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/webp',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'text/csv',
        'text/vcard',
        'application/rtf'
      ];
      
      // Validate file type
      if (!allowedTypes.includes(file.type)) {
        const message = this.translate.instant("SnackBarConstants.INVALID_FILE_FORMAT");
        this.commentService.addSnackBar.emit(message);
        return;
      }
      
      // Optional: Validate file size
      const maxSizeInBytes = 10 * 1024 * 1024; // Example: 10 MB size limit
      if (file.size > maxSizeInBytes) {
        const message = this.translate.instant("SnackBarConstants.FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
      
      // Proceed with reading and uploading the file
      const reader = new FileReader();
      this.newImage = file;
      reader.readAsDataURL(file); // Read file as data URL
      
      reader.onload = async (event) => { // Called once readAsDataURL is completed
        if (event.target) {
          const urlNew = event.target.result;
  
          try {
            const uploadURL = await this.uploadService.uploadDocument(this.passId, this.newImage, this.widgetNumber);
            if (uploadURL) {
              this.url = uploadURL;
              const name = button.id + '.url';
              this.allbuttonsForm.patchValue({[name]: this.url});
              this.uploadDocument[button.id] = true;
            }
          } catch (error) {
            console.error('Error uploading document', error);
          }
        }
      };
    }
  }
  

  onCancelEdit(){
    this.dialogRef.close([this.buttonsData, false])
  }

  onSaveEdit(){
    const buttonsStyle = deepCopy( this.buttonsStyleForm.value)
    removeUndefinedValuesFromObject(buttonsStyle)
    const allbuttons = Object.entries(this.allbuttonsForm.value)
    let newbuttons = []

    this.allButtonsIds.forEach((button, index) => {
      
      let field = {}

      allbuttons.forEach( (value, index)=> {
        const key = value[0].split(".")
        const vale = value[1]
        if(key[0] == button && key[1]){
          field[key[1]]  = vale
          if(key[1]=='borderWidth' && !vale)
            field[key[1]]  = 0
        }
      })
      newbuttons.push(field)
    });

    const buttons = newbuttons

    const data = {
      buttons: buttons,
      buttonsStyle: buttonsStyle,
      cornerRadius: this.buttonsForm.value.cornerRadius,
      id: this.buttonsForm.value.id,
      type:"buttons",
      name: this.buttonsForm.value.name
    }

    if(!data.cornerRadius)
      data.cornerRadius = 0

    if(!data.buttonsStyle.borderWidth)
      data.buttonsStyle.borderWidth = 0

    const clean = removeUndefinedValuesFromObject(data)
    // this.dialogRef.close([clean, true, , this.translationForm.value.translations])

    const newdata = this.getWidgetTranslationsFormatedData(buttons)
    this.commentService.progressSpin.emit(false)

    this.dialogRef.close([newdata, true]);
  }
  
  getWidgetTranslationsFormatedData(data){
    let newvalues = {}
    if(data){
      data.forEach( (button, index)=> {
        let {title, url} = button
        newvalues[this.selectedLanguage + '.' + this.widgetId + '_' + button.id + '.title'] = title ? title : undefined,
        newvalues[this.selectedLanguage + '.' + this.widgetId + '_' + button.id + '.url'] = url ? url : undefined
      })
    }

    return newvalues
    // return {
    //   [this.selectedLanguage+'.'+this.widgetId+'.title']: title ? title : undefined,
    //   [this.selectedLanguage+'.'+this.widgetId+'.url']: url ? url : undefined
    // }
  }

  showOriginal(){
    this.showOrginalActive = true
  }

  hideOriginal(){
    this.showOrginalActive = false
  }
}
