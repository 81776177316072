import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { UploadService } from 'src/app/service/upload.service';
import { MediaDetailsDialogComponent } from '../media-details-dialog/media-details-dialog.component';
import { ImageCompressionService } from 'src/app/service/image-compression.service';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { environment } from 'src/environments/environment';
import { deepCopy, supportUrlStructure } from '../../../../../../backend/utils/object';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'src/app/service/settings.service';
import { languagesList, languagesList_ } from '../../../../../../backend/src/languagesList.dto';
import { MediaDialogHandlerComponent } from 'src/app/media-dialog-handler/media-dialog-handler.component';
import { DragFileBrowseData } from 'src/app/shared/media';


const checkURLValid = async (control: AbstractControl): Promise<ValidationErrors | null> => {
  const urlRegex = /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  const urlRegexDoc = /^pass?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  const urlRegexDocContext = /^context?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  const nregexp = new RegExp("(?=^\\S*$)(^(https:\\/\\/|http:\\/\\/)([A-Za-z0-9].*\\.[a-z].*)\\/$)", "gm")
  const forbidden = !nregexp.test(control.value as string)
  return forbidden ? {urlVlaid: {value: control.value}} : null;
};

@Component({
  selector: 'app-billboard-image-edit',
  templateUrl: './billboard-image-edit.component.html',
  styleUrls: ['./billboard-image-edit.component.css']
})

export class BillboardImageEditComponent {
  billboardData:any
  oldData:any
  changes=false
  billboardImgEditForm!: FormGroup 
  url:any
  noUrl=false
  newImage:any=[]
  idToShow:any
  widgetNumber:any
  passId:any
  files: FileHandle[] = []
  drop=false
  urlData:any
  linkURL:any
  urlRegex = /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegexDoc = /^pass?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  urlRegexDocContext = /^context?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  addUrl: boolean = false;
  languagesList = languagesList
  defaultLanguage:string
  dynamicUrl = false
  dragFileData: DragFileBrowseData 

  constructor(public dialogRef: MatDialogRef<BillboardImageEditComponent>,
            @Inject(MAT_DIALOG_DATA) public data: any,
            private fb: FormBuilder,
            public dialog: MatDialog,
            private uploadService: UploadService,
            private imageCompressionService: ImageCompressionService,
            private commentService: CommentsService,
            private translate:TranslateService,
            private settingsService: SettingsService
    ){
        dialogRef.disableClose = true;
        const currentLang = this.translate.currentLang;
        this.languagesList = languagesList_[currentLang]
  }

  ngOnInit():void{
    this.defaultLanguage = this.data.defaultLanguage
    this.billboardData=this.data.values
    this.oldData=this.billboardData
    this.url=this.oldData.url
    this.passId = this.data['passId']
    this.idToShow = this.passId
    this.widgetNumber= this.data['widget']

    if(this.data['url']){
      this.urlData = this.data['url']
    }else{
      if(this.billboardData.url )
        this.urlData = supportUrlStructure(this.billboardData.url, this.passId, this.settingsService.contextId$)
        // this.supportUrl(this.billboardData.url)

      if(this.billboardData.linkURL)
        this.linkURL = supportUrlStructure(this.billboardData.linkURL, this.passId, this.settingsService.contextId$)
        // this.supportLinkUrl(this.billboardData.linkURL)
    }

    this.billboardImgEditForm = this.fb.group({
      id:[],
      imageSize:[],
      type:[],
      title:[],
      subtitle: [],
      linkURL:[, [ Validators.pattern(this.urlRegex)]],
      url: [ ,[Validators.required]],
      name:[],
      filter:[]
    })

    const {id, filter, imageSize, type, title, subtitle, url, linkURL, name} = this.billboardData
    this.billboardImgEditForm.patchValue({
      id: id ? id : undefined,
      imageSize:imageSize ? imageSize : undefined,
      type:type ? type : undefined,
      title:title ? title :undefined,
      subtitle: subtitle ? subtitle : undefined,
      url:this.url ? this.url : undefined,
      linkURL:linkURL ? linkURL : 'https://',
      name : name ? name : undefined,
      filter: filter ? filter : undefined
    })

    this.billboardImgEditForm.get("url").valueChanges.subscribe( (newurl) => {
      let urlVlaid
      if(newurl?.match(/\${(.+)\}/)){
        this.dynamicUrl = true
        this.urlData = './../../assets/img/default.jpg'
      }else
        this.dynamicUrl = false

        try{
          urlVlaid = new URL(newurl)
        }catch(eror){}

      if( !this.dynamicUrl && urlVlaid){//&& this.addUrl
        this.changes = true
        this.url = newurl
        // this.supportUrl(newurl)
        this.urlData = supportUrlStructure(newurl, this.idToShow, this.settingsService.contextId$)
        this.uploadService.calculateImgFileSize(this.urlData).then(value => {
          this.billboardImgEditForm.patchValue({imageSize: value})
        })
      }
    })

    this.billboardImgEditForm.get("linkURL").valueChanges.subscribe( (newurl) => {
      let url;
      try {
        url = new URL(newurl)
        if(url.protocol === "http:" || url.protocol === "https:"){
          this.billboardImgEditForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegex)]);
        }
        if(url.protocol === "pass:" ){
          this.billboardImgEditForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegexDoc)]);
        }
        if(url.protocol === "context:" ){
          this.billboardImgEditForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegexDocContext)]);
        }
      } catch (error) {}
    })

    this.billboardImgEditForm.valueChanges.subscribe( (newurl) => {
      if(!this.dynamicUrl){
        if(this.billboardImgEditForm.value?.imageSize?.length == 0 || !this.billboardImgEditForm.value?.imageSize || (this.billboardImgEditForm.value?.imageSize && this.billboardImgEditForm.value?.imageSize[0] == 0 && this.billboardImgEditForm.value?.imageSize[1] == 0) ){
          this.uploadService.calculateImgFileSize(this.urlData).then(value => {
            this.billboardImgEditForm.patchValue({imageSize: value})
          })
        }
      }
    })


    this.dragFileData = {
      calledFrom: BillboardImageEditComponent,
      calledFromData:{
        passId: this.passId,
        widgetNumber:this.widgetNumber,
        widget: "billboardImage",
      },
      enableDragDrop: true,
      enableBrowse:true,
      enableMedia:true,
      enableEnterUrl:true,
      enableMultipleDragDrop:false,
      supportedFileTypes:['image/jpeg', 'image/png', 'image/webp'],
      supportedFileAccept:".jpg, .jpeg, .png, .webp",
      maxSizeInBytes: 2 * 1024 * 1024, // 2 MB size limit for compression,
      maxAllowedSizeInBytes:20 * 1024 * 1024, // 20 MB size limit for direct upload,
      returnSupportUrl: undefined,
      returnUrlData: undefined,
      enterUrl:false,
      returnUrlType:undefined
    }

  }

  readDocument(event: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'image/webp',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'text/csv',
        'text/vcard',
        'application/rtf'
      ];
  
      if (!allowedTypes.includes(file.type)) {
        const message = this.translate.instant("SnackBarConstants.INVALID_FILE_FORMAT");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      const maxSizeInBytes = 10 * 1024 * 1024; // Example: 10 MB size limit
      if (file.size > maxSizeInBytes) {
        const message = this.translate.instant("SnackBarConstants.FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }

      const reader = new FileReader();
      this.newImage = file;
      reader.readAsDataURL(file);
  
      reader.onload = async (event) => { 
        if (event.target) {
          const urlNew = event.target.result;
  
          try {
            const uploadURL = await this.uploadService.uploadDocument(this.passId, this.newImage, this.widgetNumber);
            if (uploadURL) {
              this.billboardImgEditForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegexDoc)]);
              this.linkURL = uploadURL;
              this.billboardImgEditForm.patchValue({
                linkURL: this.linkURL
              });
            }
          } catch (error) {
            console.error('Error uploading document', error);
          }
        }
      };
    }
  }
  

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
      const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload

      // Check for supported file types
      const supportedTypes = ['image/jpeg', 'image/png', 'image/webp'];
      if (!supportedTypes.includes(file.type)) {
        // Unsupported file type
        const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_IMG_TYPE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      if (file.size > maxAllowedSizeInBytes) {
        // File is too large for both upload and compression
        const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      if (file.size > maxSizeInBytes) {
        // File is between 2 MB and 20 MB, attempt compression
        this.imageCompressionService.compressImage(file, maxSizeInBytes).then(compressedImage => {
          if (!compressedImage) {
            // Compression failed
            const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
            this.commentService.addSnackBar.emit(message);
            return;
          }
          this.newImage = compressedImage;
          this.processFile(compressedImage);
        }).catch(error => {
          // Error during compression
          console.error("Compression error:", error);
          const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
          this.commentService.addSnackBar.emit(message);
        });
      } else {
        // File is less than or equal to 2 MB, no compression needed
        this.newImage = file;
        this.processFile(file);
      }
    }
  }  
  
  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  async filesDropped(files: FileHandle[]): Promise<void> {
    this.files = files;
    const fileToUpload = files[0].file;
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB size limit for compression
    const maxAllowedSizeInBytes = 20 * 1024 * 1024; // 20 MB size limit for direct upload
  
    if (fileToUpload.size > maxAllowedSizeInBytes) {
      // File is too large for both upload and compression
      const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    }
  
    if (fileToUpload.size > maxSizeInBytes) {
      // File is between 2 MB and 20 MB, attempt compression
      try {
        const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
        if (!compressedImage) {
          const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }
        this.newImage = compressedImage;
      } catch (error) {
        console.error("Compression error:", error);
        const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
        this.commentService.addSnackBar.emit(message);
        return;
      }
    } else {
      // File is less than or equal to 2 MB, no compression needed
      this.newImage = fileToUpload;
    }
  
    const newUrl = files[0].url;
  
    if (fileToUpload.type === 'image/jpeg' || fileToUpload.type === 'image/png' || fileToUpload.type == 'image/webp') {
      if (newUrl) {
        this.url = newUrl;
        try {
          const uploadURL = await this.uploadService.uploadImage(this.passId, this.newImage, this.widgetNumber);
          this.urlData = "";
  
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = newUrl;
          }
  
          this.changes = true;
          this.billboardImgEditForm.patchValue({
            url: this.url
          });
          this.noUrl = false;
  
        } catch (error) {
          console.error(error);
          const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
          this.commentService.addSnackBar.emit(message);
        }
      }
    }
  }

  private processFile(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      if (e.target) {
        const urlNew = e.target.result as string;
  
        if (urlNew) {
          this.url = urlNew;
          try {
            const uploadURL = await this.uploadService.uploadNewBillboardImage(this.passId, this.newImage, this.widgetNumber);
            this.urlData = "";
  
            if (uploadURL) {
              this.url = uploadURL;
              this.urlData = urlNew;
            }
  
            this.changes = true;
            this.billboardImgEditForm.patchValue({ url: this.url });
            this.noUrl = false;
          } catch (error) {
            console.error(error);
            const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
            this.commentService.addSnackBar.emit(message);
          }
        }
      }
    };
  }

  typeURL(){
    this.addUrl = true
    this.urlData = './../../assets/img/default.jpg';
  }

  checkNewUrl(){
    if(!this.dynamicUrl){
      this.url = this.billboardImgEditForm.value.url
      let validURL
      try{
        validURL = new URL(this.url)
        validURL = true
      }catch(eror){
        validURL = false
      }
      // this.supportUrl(this.billboardImgEditForm.value.url)
      if(validURL)
      this.urlData = supportUrlStructure(this.billboardImgEditForm.value.url, this.idToShow, this.settingsService.contextId$)
    }
  }

  async addFile(event:any){
    if (event.target.files && event.target.files[0]) {
      try{
        const urls = this.uploadService.getImagesUrls()
        this.url = urls['0']
        this.changes=true
        this.billboardImgEditForm.patchValue({
          url: this.url
        })
        this.urlData=""

      }catch(error){
        console.log(error)
      }
    }
  }

  dialogCancel(){
    this.dialogRef.close([this.oldData,false]);
  }

  async dialogSave(){
    this.commentService.progressSpin.emit(true)
      let size = this.billboardImgEditForm.value.imageSize
      if(this.changes){
        if(size?.length==0)
        this.billboardImgEditForm.patchValue({imageSize: [0,0]})
      }

      if(this.billboardImgEditForm.value.url && !this.dynamicUrl){
        if(this.billboardImgEditForm.value?.imageSize?.length == 0 || !this.billboardImgEditForm.value?.imageSize || (this.billboardImgEditForm.value.imageSize && this.billboardImgEditForm.value?.imageSize[0] == 0 && this.billboardImgEditForm?.value.imageSize[1] == 0)  ){
          const url = supportUrlStructure(this.billboardImgEditForm.value.url, this.idToShow, this.settingsService.contextId$)
          await this.uploadService.calculateImgFileSize(url).then(value => {
            this.billboardImgEditForm.patchValue({imageSize: value})
          })
        }
      }else{
        this.billboardImgEditForm.patchValue({imageSize: undefined})
      }
      if(this.billboardImgEditForm.value.linkURL=='https://'){
        this.billboardImgEditForm.patchValue({linkURL: undefined})
      }

      if(this.billboardImgEditForm.value.url){
        this.commentService.progressSpin.emit(false)
        this.dialogRef.close([this.billboardImgEditForm.value, true]);
      }else{
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.COVER_IMAGE_NEEDED")
        this.commentService.addSnackBar.emit(message)
      }
  }

  deleteImg(url){
    this.changes=true
    this.url=""
    this.billboardImgEditForm.patchValue({url: this.url, imageSize:undefined})
    this.urlData=""

  }

  editImg(url){
    let dialogRef= this.dialog.open(MediaDetailsDialogComponent, {
      data: { 
        values: this.billboardImgEditForm.value,
        type: this.billboardImgEditForm.value.type,
        passId: this.passId,
        widgetNumber: this.widgetNumber
      },
    });

    dialogRef.afterClosed().subscribe(result => {     
      if(result[1]){
        if(result[0].url){
          if(result[0].url!=this.billboardImgEditForm.value.url){
            this.urlData =""
          }
          this.url = result[0].url
          this.billboardImgEditForm.patchValue({url: this.url})
          
          if(this.url){
            // this.supportUrl(this.url)
            this.urlData = supportUrlStructure(this.url, this.idToShow, this.settingsService.contextId$)
          }
        }
      }
    });
  }

  supportUrl(url){
    this.urlData = url
    if(url){
     try{
      const imgUrl = new URL(url)
      let hostname = environment.urlHostName
      
      if(imgUrl.protocol=="pass:" ){
        const parts = url.match(/pass:\/\/(.+)\/(.+)/)
        if(parts){
          const passTemplateID = parts[1]
          const path = parts[2]
          const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          this.urlData=firebaseUrl
        }else{
          const newparts = url.match(/pass:\/\/(.+)/)
          if(newparts){
            let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
            newparts.forEach( (part,index)=> {
              if(index>0){
                firebaseUrl += '/' + part 
              }
            })
            this.urlData=firebaseUrl
          }
        }
      } 

      if(imgUrl.protocol =="tridy:"){
        const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
        let path =""
        parts.forEach((parte, index) =>{
          if(index>0 && index<parts.length){
            path += "/" + parte
          }
        })
        const firebaseUrl = `https://${hostname}/v1/pass${path}`

        this.urlData=firebaseUrl
      }

      if(imgUrl.protocol == "context:"){
        const parts = url.match(/context:\/\/(.+)\/(.+)/)
        let path =""
        parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
                path += "/" + parte
            }
        })
        const firebaseUrl = `https://${hostname}/v1/context/${this.settingsService.contextId$}${path}`
        this.urlData=firebaseUrl
      }
     }catch(erro){}
    }
  }

  supportLinkUrl(linkurl){
    if(linkurl){
      try{
      const imgUrl = new URL(linkurl)
      let hostname = environment.urlHostName
      
      if(imgUrl.protocol=="pass:" ){
        const parts = linkurl.match(/pass:\/\/(.+)\/(.+)/)        
        if(parts){
          const passTemplateID = parts[1]
          const path = parts[2]
          const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
          this.linkURL=firebaseUrl
        }else{
          const newparts = linkurl.match(/pass:\/\/(.+)/)
          if(newparts){
            let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
            newparts.forEach( (part,index)=> {
              if(index>0){
                firebaseUrl += '/' + part 
              }
            })
            this.linkURL=firebaseUrl
          }
        }
      }

      if(imgUrl.protocol =="tridy:"){
        const parts = linkurl.match(/tridy:\/\/(.+)\/(.+)/)
        let path =""
        parts.forEach((parte, index) =>{
          if(index>0 && index<parts.length){
            path += "/" + parte
          }
        })
        const firebaseUrl = `https://${hostname}/v1/pass${path}`

        this.linkURL=firebaseUrl
      }

      if(imgUrl.protocol == "context:"){
        const parts = linkurl.match(/context:\/\/(.+)\/(.+)/)
        let path =""
        parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
                path += "/" + parte
            }
        })
        const firebaseUrl = `https://${hostname}/v1/context/${this.settingsService.contextId$}${path}`
        this.linkURL=firebaseUrl
      }
      }catch(erro){
      }
    }
  }


  returnChangesFilters($event){
    this.billboardImgEditForm.patchValue({filter: $event.newFilters})
  }

  browserFromMediaFolder(){
    let dialogRef= this.dialog.open(MediaDialogHandlerComponent, {
      data: {
        supportedFiles: ['.jpg', '.jpeg', '.png', '.webp']
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if(result[0]){
        const url = result[1]
        const supportUrl = result[2]
        
        this.url = url
        this.urlData = supportUrl
        this.changes = true;
        this.billboardImgEditForm.patchValue({ url: this.url });
        this.noUrl = false;
      }
    });
  }

  returnChangesDragFileBrowseUI($event){
    if($event){
      const {calledFrom, calledFromData, enableDragDrop, enableBrowse, enableMedia,enableEnterUrl, enableMultipleDragDrop, 
        supportedFileTypes,supportedFileAccept, maxSizeInBytes,maxAllowedSizeInBytes, 
        returnSupportUrl,returnUrlData, enterUrl} = $event

        this.dragFileData = $event
      if(enterUrl){
        this.addUrl = true
        this.urlData = './../../assets/img/default.jpg'
      }else{
        this.addUrl = false
        this.urlData = undefined
      }

      if(returnUrlData)
      this.urlData = returnUrlData

      if(returnSupportUrl){
        this.changes = true
        this.noUrl = false
        this.url = returnSupportUrl
        this.billboardImgEditForm.patchValue({ url: returnSupportUrl })
      }
    }
  }
}
