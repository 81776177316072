import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from '../service/comments.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { deepCopy } from '../../../../backend/utils/object';

@Component({
  selector: 'app-filter-ui-dialog',
  templateUrl: './filter-ui-dialog.component.html',
  styleUrls: ['./filter-ui-dialog.component.css']
})
export class FilterUIDialogComponent {
  filterForm!:FormGroup
  operatorsList:any = [
    {
      displayName: '(==) equal to',
      translation: 'filters.operators.equal',
      id:' = '
    },
    {
      displayName: '(!=) not equal to',
      translation: 'filters.operators.not_equal',
      id:' != '
    },
    {
      displayName: '(>) greater than',
      translation: 'filters.operators.greather_than',
      id:' > '
    },
    {
      displayName: '(<) less than',
      translation: 'filters.operators.less_than',
      id:' < '
    },
    {
      displayName: '(>=) greater than or equal to',
      translation: 'filters.operators.greather_than_equal_to',
      id:' >= '
    },
    {
      displayName: '(<=) less than or equal to',
      translation: 'filters.operators.less_than_equal_to',
      id:' <= '
    },
    // {
    //   displayName:"(in) equal to any of the following",
    //   translation: 'filters.operators.in',
    //   id:"in"
    // },
    // {
    //   displayName:"(!in) not equal to any of the following",
    //   translation: 'filters.operators.not_in',
    //   id:"!in"
    // },
    // {
    //   displayName:"(contains) an array containing",
    //   translation: 'filters.operators.contains',
    //   id:"contains"
    // },
    // {
    //   displayName:"(!contains) array not containing",
    //   translation: 'filters.operators.not_contains',
    //   id:"!contains"
    // },
    // {
    //   displayName:"(startsWith) starts with..",
    //   translation: 'filters.operators.startsWith',
    //   id:"startsWith"
    // },
    // {
    //   displayName:"(!startsWith) not starting with..",
    //   translation: 'filters.operators.not_startsWith',
    //   id:"!startsWith"
    // }
  ]
  constructor(
    public dialogRef: MatDialogRef<FilterUIDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commentsService: CommentsService,
    private translate:TranslateService
  ){
    dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.filterForm = this.fb.group({
      key:[],
      operator:[],
      value:[]
    })

    if(this.data['value']){
      const filterValue = deepCopy(this.data.value)
      let operator =''

      if( filterValue.match(/(.+)\=(.+)/) ){
        operator = ' = '
      }

      if(filterValue.match(/(.+)\!=(.+)/)){
        operator = ' != '
      }

      if(filterValue.match(/(.+)\>(.+)/)){
        operator = ' > '
      }

      if(filterValue.match(/(.+)\<(.+)/)){
        operator = ' < '
      }

      if(filterValue.match(/(.+)\>=(.+)/)){
        operator = ' >= '
      }

      if( filterValue.match(/(.+)\<=(.+)/)){
        operator = ' <= '
      }

      let key, value = undefined

      switch(operator){
        case ' = ':{
          const values = filterValue.match(/(.+)\s\=\s(.+)/)
          key = values[1]
          value = values[2]
          break;
        }

        case ' != ':{
          const values = filterValue.match(/(.+)\s\!=\s(.+)/)
          key = values[1]
          value = values[2]
          break;
        }

        case ' > ':{
          const values = filterValue.match(/(.+)\s\>\s(.+)/)
          key = values[1]
          value = values[2]
          break;
        }

        case ' < ':{
          const values = filterValue.match(/(.+)\s\<\s(.+)/)
          key = values[1]
          value = values[2]
          break;
        }

        case ' >= ':{
          const values = filterValue.match(/(.+)\s\>=\s(.+)/)
          key = values[1]
          value = values[2]
          break;
        }

        case ' <= ':{
          const values = filterValue.match(/(.+)\s\<=\s(.+)/)
          key = values[1]
          value = values[2]
          break;
        }
      }

      this.filterForm.patchValue({
        key: key ? key : undefined,
        operator: operator ? operator : undefined,
        value:value ? value: undefined
      })
      
    }
  
  }
  
  onSave(){
    if(this.filterForm.value.key && this.filterForm.value.operator){
      let value 
      if(this.filterForm.value){
        value = this.filterForm.value.key + this.filterForm.value.operator + this.filterForm.value.value
      }else{
        value = this.filterForm.value.key + this.filterForm.value.operator + this.filterForm.value.value
      }
      this.dialogRef.close([true, value])
    }else{
      const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY")
      this.commentsService.addSnackBar.emit(message)
    }
  }

  onCancel(){
    this.dialogRef.close([false])
  }
}
