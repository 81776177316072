import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-changes-dialog',
  templateUrl: './confirm-changes-dialog.component.html',
  styleUrl: './confirm-changes-dialog.component.css'
})
export class ConfirmChangesDialogComponent {
  message :any

  constructor(public dialogRef: MatDialogRef<ConfirmChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
      dialogRef.disableClose = true;
  }

  ngOnInit():void{
    if(this.data.message)
    this.message = this.data.mesage
  }
}
