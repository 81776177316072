<section class="container">
    <div class="row mt-4">
        <div class="col-12">

            <div class="topNavbar mb-1"> 
                <button mat-button class="m-1" style="color:var(--warmOrange);" (click)="backTo();"> 
                    <mat-icon>arrow_back_ios_new</mat-icon> {{'goBack' | translate}}
                </button>
                <span class="fs-5">Details</span>
                <button mat-button class="m-1 secondary-gray-button" (click)="QRoptions()"> 
                    <mat-icon> qr_code_2_add</mat-icon> Sample QR
                </button>
            </div>

            <div class="my-4 section-title">
                <mat-icon class="custom-card-icon material-symbols-outlined">info</mat-icon>{{'tridys.tridy_info' | translate}}
            </div>

            <mat-card class="mb-2">
                <mat-card-content class="my-2">
                    <h2 class="title-key" *ngIf="titleValue && titleKey"><strong>{{titleValue}}</strong></h2>
                    <div *ngIf="subtitleValues && subtitleValues.length>0">
                        <div *ngFor="let subtitle of subtitleValues" class="subtitle-key my-1 font-weight-bold">
                            <span *ngIf="subtitle?.contentType === 'public.image' && subtitleKeys">{{subtitle.fileName}}</span>
                            <span *ngIf="subtitle?.contentType !== 'public.image' && subtitleKeys">{{subtitle}}</span>
                        </div>
                        <mat-divider *ngIf="subtitleKeys" class="my-3"></mat-divider>
                    </div>
                     <!-- if content type has this defined, then those should be shown and set according to payload -->
                    <span class="font-weight-bold">ID: </span> <span style="opacity:0.65;padding-right: 5px;">{{idToShow}}</span><mat-icon matTooltip="{{'copyToClipboard' | translate}}" [cdkCopyToClipboard]="idToShow" class="copy_id">content_copy</mat-icon><br>
                    <span class="font-weight-bold" *ngIf="tridyData && tridyData.creationDate">{{'creation_date' | translate}}: </span> <span  *ngIf="tridyData && tridyData.creationDate" style="opacity:0.65;">{{tridyData.creationDate.toDate() | date: 'dd.MM.yyyy, HH:mm'}}</span>
                </mat-card-content>
            </mat-card>

            <div class="my-4 section-title">
                <mat-icon class="custom-card-icon material-symbols-outlined">task</mat-icon>{{'tridys.finished' | translate}}
            </div>
            <mat-card class="mb-2">
                <mat-card-content class="my-2">
                    <div *ngIf="finishedTasks">
                        <div *ngFor="let ftask of finishedTasks;" class="item-content my-1" (click)="redirectTo(ftask)">
                            <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                                <div class="d-flex flex-column flex-nowrap">
                                    <span style="color:var(--warmOrange);">{{ftask.title}}</span>
                                    <div>
                                        <span class="text-muted">{{ftask.creationDate.toDate() | date:'dd.MM.yyyy, HH:mm' }}</span>  
                                        <span *ngIf="ftask.timeStamp" class="text-muted">  - {{ftask.timeStamp.toDate() | date:'dd.MM.yyyy, HH:mm' }}</span>
                                    </div>
                                </div>
                                <span class="material-symbols-outlined" style="color:var(--warmOrange);">
                                    keyboard_arrow_right
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="p-3" *ngIf="finishedTasks.length==0">
                        <span class="text-muted">{{'tridys.no_finishedTasks' | translate}}</span>
                    </div>
                </mat-card-content>
            </mat-card>

            <!-- INCOMING -->
            <div *ngIf="incoming && incoming.length!=0">
                <div class="my-4 section-title">
                    <mat-icon class="custom-card-icon material-symbols-outlined">input</mat-icon>{{'workTask.incoming_tridys' | translate}}
                </div>
                <mat-card class="mb-2">
                    <mat-card-content class="my-2">
                        <div *ngFor="let incom of incoming; let index" class="item-content my-1" (click)="openTridyDetailsDialog(incom.tridyId)">
                            <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                                <div class="d-flex flex-column flex-nowrap">

                                    <div *ngIf="incom.payload">
                                        <div *ngIf="getContentTypeFieldById(incom.contentTypeId, 'titleKey')" class="title-key-2">
                                            {{getTitleKeyOfTridy(getTRIDYIdData(incom.tridyId, 'payload'), getContentTypeFieldById(incom.contentTypeId, 'titleKey'))}}
                                        </div>
                                        
                                        <div *ngIf="getContentTypeFieldById(incom.contentTypeId, 'subtitleKeys')">
                                            <div *ngFor="let subtitle of getSubtitleKeyOfTridy(getTRIDYIdData(incom.tridyId, 'payload'), getContentTypeFieldById(incom.contentTypeId, 'subtitleKeys'))">
                                                <div class="subtitle-key-2" *ngIf="subtitle?.contentType === 'public.image'">{{subtitle.fileName}}</div>
                                                <div class="subtitle-key-2" *ngIf="subtitle?.contentType !== 'public.image'">{{subtitle}}</div>
                                            </div>
                                        </div>
                                        
                                        <span *ngIf="!getContentTypeFieldById(incom.contentTypeId, 'titleKey') && !getContentTypeFieldById(incom.contentTypeId, 'subtitleKeys')" class="title-key-2">
                                            {{getContentTypeFieldById(incom.contentTypeId, 'displayName')}}
                                        </span>
                                    </div>


                                    <div *ngIf="!incom.payload">
                                        <span class="title-key-2">
                                            {{getContentTypeFieldById(incom.contentTypeId, 'displayName')}}
                                        </span>
                                    </div>

                                    <div class="mt-1">ID: {{incom.tridyId}}</div>
                                    <div class="d-flex flex-column text-muted">
                                        <span *ngIf="getTRIDYIdData(incom.tridyId, 'creationDate')">
                                            {{'creation_date' | translate}}: {{getTRIDYIdData(incom.tridyId, 'creationDate').toDate() | date:'dd.MM.yyyy, HH:mm' }}
                                        </span>
                                        <span *ngIf="getTRIDYIdData(incom.tridyId, 'timeStamp')">
                                            {{'last_update' | translate}}: {{getTRIDYIdData(incom.tridyId, 'timeStamp').toDate() | date:'dd.MM.yyyy, HH:mm' }}
                                        </span>
                                    </div> 
                                </div>
                                <span class="material-symbols-outlined" style="color:var(--warmOrange);">
                                    keyboard_arrow_right
                                </span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <!-- OUTGOING -->
            <div *ngIf="outgoing && outgoing.length!=0">
                <div class="my-4 section-title">
                    <mat-icon class="custom-card-icon material-symbols-outlined">output</mat-icon>{{'workTask.outgoing_tridys' | translate}}
                </div>
                <mat-card class="mb-2">
                    <mat-card-content class="my-2">
                        <div *ngFor="let out of outgoing; let index" class="item-content my-1" (click)="openTridyDetailsDialog(out.tridyId)">
                            <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                                <div class="d-flex flex-column flex-nowrap">

                                    <div *ngIf="out.payload">
                                        <div *ngIf="getContentTypeFieldById(out.contentTypeId, 'titleKey')" class="title-key-2">
                                            {{getTitleKeyOfTridy(getTRIDYIdData(out.tridyId, 'payload'), getContentTypeFieldById(out.contentTypeId, 'titleKey'))}}
                                        </div>
                                        
                                        <div *ngIf="getContentTypeFieldById(out.contentTypeId, 'subtitleKeys')">
                                            <div *ngFor="let subtitle of getSubtitleKeyOfTridy(getTRIDYIdData(out.tridyId, 'payload'), getContentTypeFieldById(out.contentTypeId, 'subtitleKeys'))">
                                                <div class="subtitle-key-2" *ngIf="subtitle?.contentType === 'public.image'">{{subtitle.fileName}}</div>
                                                <div class="subtitle-key-2" *ngIf="subtitle?.contentType !== 'public.image'">{{subtitle}}</div>
                                            </div>
                                        </div>
                                        
                                        <span *ngIf="!getContentTypeFieldById(out.contentTypeId, 'titleKey') && !getContentTypeFieldById(out.contentTypeId, 'subtitleKeys')" class="title-key-2">
                                            {{getContentTypeFieldById(out.contentTypeId, 'displayName')}}
                                        </span>
                                    </div>

                                    <div *ngIf="!out.payload">
                                        <span class="title-key-2">
                                            {{getContentTypeFieldById(out.contentTypeId, 'displayName')}}
                                        </span>
                                    </div>

                                    <div class="mt-1">ID: {{out.tridyId}}</div>
                                    <div class="d-flex flex-column text-muted">
                                        <span *ngIf="getTRIDYIdData(out.tridyId, 'creationDate')">
                                            {{'creation_date' | translate}}: {{getTRIDYIdData(out.tridyId, 'creationDate').toDate() | date:'dd.MM.yyyy, HH:mm' }}
                                        </span>
                                        <span *ngIf="getTRIDYIdData(out.tridyId, 'timeStamp')">
                                            {{'last_update' | translate}}: {{getTRIDYIdData(out.tridyId, 'timeStamp').toDate() | date:'dd.MM.yyyy, HH:mm' }}
                                        </span>
                                    </div> 
                                </div>
                                <span class="material-symbols-outlined" style="color:var(--warmOrange);">
                                    keyboard_arrow_right
                                </span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <!-- DISPLAY TEMPLATE -->
             <div *ngIf="contentTypeData?.displaySections">

                <div class="my-4 section-title">
                    <mat-icon class="custom-card-icon material-symbols-outlined">description</mat-icon>{{'tridys.payloadData' | translate}}
                </div>
    
                <div *ngFor="let group of contentTypeData.displaySections">
                    <mat-card class="mb-4">
                        <mat-card-header>
                            <strong>{{group.title}}</strong>
                        </mat-card-header>
                        <mat-card-content class="p-3">
                            <div *ngFor="let field of group.fields; let last = last;">
                                <div class="d-flex justify-content-between">
                                    <div class="py-2 px-4 key-styles"><span class="font-weight-bold">{{field.title}}</span></div>
                                    <div class="py-2 px-4 value-styles">
                                        <span>{{getPayloadDataByFieldKey(field.key)}}</span>
                                    </div>
                                </div>
                                <mat-divider *ngIf="!last"></mat-divider>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
             </div>

             <!-- show all button -->
             <div class="w-100 d-flex justify-content-center">
                <button mat-button 
                        *ngIf="contentTypeData?.displaySections"
                        [class.d-none]="contentTypeData?.displaySections && showAll"
                        (click)="showAllPayload()"
                        class="mt-1 mb-4 secondary-gray-button">
                           {{'showAll' | translate}}
                </button>
             </div>

             <!-- all payload -->
            <div #payloadCard [class.d-none]="contentTypeData?.displaySections && !showAll">
                <div class="my-4 section-title">
                    <mat-icon class="custom-card-icon material-symbols-outlined">description</mat-icon>{{'tridys.allPayloadData' | translate}}
                </div>

                <mat-card class="mb-4">
                    <mat-card-content class="p-3">
                        <div class="p-3" *ngIf="allpayload.length==0">
                            <span class="text-muted">{{'tridys.no_payload_data' | translate}}</span>
                        </div>
                        <div *ngFor="let payload of allpayload; let last = last">
                            <div *ngIf="!isObject(payload[1])" class="d-flex justify-content-between">
                                <!-- <div class="input-group mt-1 mb-1 w-100" *ngIf="payload[0]!='name' && payload[0]!='description' && payload[0]!='tagline' && payload[0]!='array' && payload[0]!='map'"> -->
                                <div class="py-2 px-4 key-styles"><span class="font-weight-bold">{{payload[0]}}</span></div>
                                <div class="py-2 px-4 value-styles">
                                    <span *ngIf="!payload[1].nanoseconds && !payload[1].seconds">{{payload[1]}}</span>
                                    <span *ngIf="payload[1].nanoseconds || payload[1].seconds">{{payload[1].toDate() | date: 'dd.MM.yyyy, HH:mm' }}</span>
                                </div>
                            </div>
                            <div *ngIf="isObject(payload[1]) && payload[1].contentType" class="d-flex justify-content-between">
                                <div class="py-2 px-4 key-styles">
                                    <span class="font-weight-bold">{{payload[0]}}</span> <span class="material-symbols-outlined text-muted" style="vertical-align: middle;font-size: 20px;">attach_file</span>
                                </div>
                                <div class="py-2 px-4 value-styles">
                                    <div *ngIf="payload[1]?.fileName && mediaData && media">
                                        <a *ngIf="payload[1]?.fileName" href="{{urlData[payload[0]]}}" class="pdf-title" target="_blank">
                                            <mat-icon class="material-symbols-outlined">open_in_new</mat-icon>{{payload[1].fileName}}
                                        </a>
                                    </div>
    
                                    <div *ngIf="!payload[1]?.fileName && mediaData && media && media[payload[0]]">
                                        <a *ngIf="urlData[payload[0]]" href="{{urlData[payload[0]]}}" target="_blank" style="cursor:zoom-in;">
                                            <img src="{{urlData[payload[0]]}}" style="height:150px;width:auto;">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isObject(payload[1]) && !payload[1].contentType" class="d-flex justify-content-between">
                                <div class="py-2 px-4 key-styles"><span class="font-weight-bold">{{payload[0]}}</span></div>
                                <span class="py-2 px-4 value-styles" *ngIf="!payload[1]"> - </span>
                                <span class="py-2 px-4 value-styles" *ngIf="payload[1] && !payload[1].nanoseconds && !payload[1].seconds"> * </span>
                                <span class="py-2 px-4 value-styles" *ngIf="payload[1] && payload[1].nanoseconds || payload[1].seconds"> {{payload[1].toDate() | date: 'dd.MM.yyyy, HH:mm' }} </span>
                            </div>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </div>
                    </mat-card-content>
                </mat-card>
    
                <div class="mb-4">
                    <mat-accordion *ngIf="tridyArray" class="w-100 mt-4">
                        <mat-expansion-panel (opened)="panelOpenState=true" (closed)="panelOpenState=false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{'tridys.array' | translate}}
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <p *ngFor="let array of tridyArray">{{array}}</p>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="tridyMap" class="w-100 mt-2">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{'tridys.map' | translate}}
                                </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                            </mat-expansion-panel-header>
                            <p *ngFor="let map of tridyMap | keyvalue"> {{map.key}} : {{map.value}}</p>
                        </mat-expansion-panel>
                    </mat-accordion>  
                </div>
            </div>
        </div>
    </div>
</section>




