import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ContentTypeService } from '../../service/content-type.service';
import { Router } from '@angular/router';
import { CommentsService } from '../../service/comments.service';
import { SettingsService } from '../../service/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { languagesList, languagesList_ } from "../../../../../backend/src/languagesList.dto";
import { deepCopy } from '../../../../../backend/utils/object';

@Component({
  selector: 'app-create-content-type-dialog',
  templateUrl: './create-content-type-dialog.component.html',
  styleUrls: ['./create-content-type-dialog.component.css']
})
export class CreateContentTypeDialogComponent implements OnInit{
  newContentForm!:FormGroup
  languagesList:any = languagesList
  accountLanguages:any

  constructor(
    public dialogRef: MatDialogRef<CreateContentTypeDialogComponent>,
    private fb: FormBuilder,
    private contentTypeService: ContentTypeService,
    private settingsService: SettingsService,
    private commentService: CommentsService,
    private router: Router,
    private translate:TranslateService
  ){
    dialogRef.disableClose = true;
    const currentLang = this.translate.currentLang;
    this.languagesList = deepCopy(languagesList_[currentLang])
  }

  ngOnInit(): void {
    this.accountLanguages = this.settingsService.languagesContexts$
    this.newContentForm = this.fb.group({
      displayName:[,Validators.required],
      language: [this.accountLanguages.length > 0 ? this.accountLanguages[0] : '', Validators.required]
    })
  }

  cancelNewContentType(){
    this.dialogRef.close([false]);
  }

  async saveNewContentType() {
    if (this.newContentForm.value.displayName && this.newContentForm.value.language) {
      try {
        const language = this.newContentForm.value.language;
        let updatedLanguages = [language, ...this.accountLanguages];
  
        // Ensure the array contains only unique values
        updatedLanguages = Array.from(new Set(updatedLanguages));
  
        // Update the form's language array to the new, unique, and ordered array
        this.newContentForm.value.language = updatedLanguages;
    
        this.commentService.progressSpin.emit(true);
  
        const newContent = await this.contentTypeService.createContentType(this.newContentForm.value);
        const contypeId = newContent.contentTypeId;
        const passid = newContent.passTemplateId;
  
        const newRoute = 'home/' + this.settingsService.contextId$ + '/contentTypes/' + contypeId + '/passTemplate/' + passid;
        this.dialogRef.close([true, newContent, newRoute]);
  
        this.commentService.progressSpin.emit(false);
        const message = this.translate.instant("SnackBarConstants.CREATE_OK");
        this.commentService.addSnackBar.emit(message);
  
      } catch (error) {
        this.commentService.progressSpin.emit(false);
        const message = this.translate.instant("SnackBarConstants.CREATE_FAILED");
        this.commentService.addSnackBar.emit(message);
      }
    } else {
      const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS_EMPTY");
      this.commentService.addSnackBar.emit(message);
    }
  }
  
}
