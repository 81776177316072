import { Injectable } from '@angular/core';
import { getStorage, ref, listAll, deleteObject, getBlob } from "@angular/fire/storage";
import { SettingsService } from './settings.service';
import opentype from 'opentype.js';
import { HttpClient } from '@angular/common/http';
import { CommentsService } from './comments.service';
import { NetworkConstants } from 'src/network.constants';
import { isDateTime } from '../../../../backend/src/filter';
import { getDownloadURL, getMetadata } from '@angular/fire/storage';
import { Observable, Subject } from 'rxjs';
import { deepCopy, supportUrlStructure } from '../../../../backend/utils/object';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  storage = getStorage();
  constructor( private settingsService: SettingsService,
    private commentsService : CommentsService,
    private http: HttpClient
    ) { }
  insideFolders:any=[]
  folders: any=[]
  folder:any=[]
  items: any=[]

imageCover:any
  insideItems:any=[]
  allImageCovers:any={}
  workTaskItem = {}
  workTaskFolder= []
  workTaskItemAllValues$ : Subject<any> = new Subject<any>();

  getStorageContentOfContextId(ro:string){
    this.folders=[]
    this.folder=[]
    this.insideFolders=[]
    this.insideItems=[]
    const route = 'Contexts/' + this.settingsService.contextId$ + ro
    const listRef = ref(this.storage, route);

    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          this.folder.push({folder:folderRef.name, reference:folderRef.fullPath})
          this.insideFolders.push({folder:folderRef.name, reference:folderRef.fullPath})
        });
        
        res.items.forEach(async (itemRef) => {

          await getDownloadURL(ref(this.storage, itemRef.fullPath))
            .then((url) => {
              this.folder.push({item:itemRef.name, reference:itemRef.fullPath,url: url})
              this.insideItems.push({name:itemRef.name, reference:itemRef.fullPath, url: url})
            })
            .catch((error) => {
            });

        });
      }).catch((error) => {
        console.log(error)
      });
      return this.folder
  }

  getStorageMediaOfContextId(route:string){
    this.folders=[]
    this.folder=[]
    this.insideFolders=[]
    this.insideItems=[]
    let baseFolder = `${NetworkConstants.COLLECTION_CONTEXTS}/${this.settingsService.contextId$}`
    const routeComplete = baseFolder + route
    const listRef = ref(this.storage, routeComplete);
    // return new Promise(async (resolve, reject) => {
    return new Observable<any[]> ((observer) => {
      listAll(listRef).then(async (res) => {
        const prefixes = res.prefixes.map((folderRef) => {
          this.folder.push({folder:folderRef.name, reference:folderRef.fullPath})
          this.insideFolders.push({folder:folderRef.name, reference:folderRef.fullPath})
          return {name:folderRef.name,folder:folderRef.name, reference:folderRef.fullPath}
        });
        
        const items = res.items.map( async(itemRef) => {
          const supportUrl = 'context:/' +route + itemRef.name
          const url = supportUrlStructure(supportUrl, '', this.settingsService.contextId$ )
          let fileRef = ref(this.storage, itemRef.fullPath)
          return getMetadata(fileRef).then((metadata) => {
            this.folder.push({item:itemRef.name, reference:itemRef.fullPath,url:url,  supportUrl: supportUrl, metadata:metadata})
            this.insideItems.push({name:itemRef.name, reference:itemRef.fullPath, url:url, supportUrl: supportUrl, metadata:metadata})
            return {name:itemRef.name,item:itemRef.name, reference:itemRef.fullPath,url:url,  supportUrl: supportUrl, metadata:metadata}
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
          });
        });
        observer.next([...prefixes, ...await Promise.all(items)])
      }).catch((error) => {
        console.log(error)
      });
      return this.folder
    })
  }

async getCdFonts(contextId: string): Promise<{ name: string, url: string, filename: string }[]> {
  const folderPath = `Contexts/${contextId}/fonts/`;
  const storageRef = ref(this.storage, folderPath);
  const fontsList: { name: string, url: string, filename: string }[] = [];

  try {
    const result = await listAll(storageRef);
    for (const itemRef of result.items) {
      const url = await getDownloadURL(itemRef);
      const filename = itemRef.name;

      // Download the font, extract the real name
      const fontBlob = await this.downloadFontBlob(url);
      const fontBuffer = await this.readAsArrayBuffer(fontBlob);
      const loadedFont = opentype.parse(fontBuffer);
      const actualFontName = loadedFont.names.fullName.en || filename; 

      fontsList.push({
        name: actualFontName, // Real font name
        url: url, // Original URL
        filename: filename // Filename used for URL
      });
    }
  } catch (error) {
    console.error('Error fetching CD fonts', error);
  }

  return fontsList;
}


async getPassFonts(passId: string): Promise<{ name: string, url: string, filename: string }[]> {
  const folderPath = `passes/${passId}/`;
  const storageRef = ref(this.storage, folderPath);
  const fontsList: { name: string, url: string, filename: string }[] = [];

  try {
    const result = await listAll(storageRef);
    for (const itemRef of result.items) {
      if (itemRef.name.endsWith('.ttf') || itemRef.name.endsWith('.otf')) {
        const url = await getDownloadURL(itemRef);
        const filename = itemRef.name;

        // Download the font, extract the real name
        const fontBlob = await this.downloadFontBlob(url);
        const fontBuffer = await this.readAsArrayBuffer(fontBlob);
        const loadedFont = opentype.parse(fontBuffer);
        const actualFontName = loadedFont.names.fullName.en || filename;

        fontsList.push({
          name: actualFontName, // Real font name
          url: url, // Original URL
          filename: filename // Filename used for URL
        });
      }
    }
  } catch (error) {
    console.error('Error fetching Pass fonts', error);
  }

  return fontsList;
}

// Download font file as Blob
async downloadFontBlob(url: string): Promise<Blob> {
  const storageRef = ref(this.storage, url);
  return await getBlob(storageRef);
}

// Convert Blob to ArrayBuffer
async readAsArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

  async deleteFont(filePath: string): Promise<void> {
    const storageRef = ref(this.storage, filePath);
    try {
      await deleteObject(storageRef);
      // console.log(`Deleted ${filePath} successfully`);
    } catch (error) {
      console.error('Error deleting font', error);
      throw error;
    }
  }

  async deleteImage(filePath: string): Promise<void> {
    const storageRef = ref(this.storage, filePath);
    try {
      await deleteObject(storageRef);
      // console.log(`Deleted ${filePath} successfully`);
    } catch (error) {
      console.error('Error deleting image', error);
      throw error;
    }
  }

  async deleteFile(filePath: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {

    const storageRef = ref(this.storage, filePath);
    try {
       deleteObject(storageRef).then( value => {
        resolve(true)
      });
      console.log(`Deleted ${filePath} successfully`);
    } catch (error) {
      reject(false)
      console.error('Error deleting image', error);
      throw error;
    }

    })
  }

  getStorageWorkTaskFolder(route, tridyId,key, hostname){
    let image = ''
   this.workTaskItem = []
   this.workTaskFolder = []
    const listRef = ref(this.storage, route);
    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
        });
        
        res.items.forEach(async (itemRef) => {
          let firebaseUrl = `https://${hostname}/v1/pass/${route}/`

          const fullName = itemRef?.name.split('.')
          const extension  = fullName[fullName.length-1]
          const name = fullName[0]

          if(itemRef.name.includes('WT-'+key) && itemRef.name == 'WT-'+ key + '.' +extension){//if(itemRef.name.includes(key)){
            firebaseUrl += itemRef.name
            image = firebaseUrl
            this.workTaskItem[key]=deepCopy({item:itemRef.name, reference:itemRef.fullPath,url: image})
            this.workTaskItemAllValues$.next(this.workTaskItem)
          }

          // await getDownloadURL(ref(this.storage, itemRef.fullPath))
          //   .then((url) => {
          //     this.workTaskItem[key]=deepCopy({item:itemRef.name, reference:itemRef.fullPath,url: url})
          //     this.workTaskItemAllValues$.next(this.workTaskItem)
          //   })
          //   .catch((error) => {});


        });
      }).catch((error) => {
        console.log(error)
      });
      return image
  }

  downloadFileFromStorage(file, url, name){
    this.commentsService.progressSpin.emit(true)
    const date = new Date()    
    this.http.get(url, { responseType: 'blob' }).subscribe((response: Blob) => {
      const link = document.createElement('a');
      const blob = new Blob([response], { type: response.type });
      link.href = URL.createObjectURL(blob);
      link.download = `${ isDateTime(date)}_${name.replace(' ', '')}`;
      link.click();
      this.commentsService.progressSpin.emit(false)

    });
  }

}
