import { Component, Injectable, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from '../service/report.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { deepCopy, removeUndefined } from '../../../../backend/utils/object';
import { ReportFilterService } from '../service/report-filter.service';
import { isTime ,isDate, isDateTime, isDateTimeGetTimeSeconds, Filter} from '../../../../backend/src/filter';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  constructor(private translate: TranslateService){}

  firstPageLabel = this.translate.instant("first_page");
  itemsPerPageLabel = this.translate.instant("items_page");
  lastPageLabel =this.translate.instant("last_page")
  nextPageLabel = this.translate.instant("next_page");
  previousPageLabel = this.translate.instant("previous_page");

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.instant("page1_1");
    }
    const amountPages = Math.ceil(length / pageSize);
     
    return this.translate.instant("page_of_1") + (page + 1) +this.translate.instant("page_of_2") + amountPages;
  }
}

@Component({
  selector: 'app-reports-item-tridys-list',
  templateUrl: './reports-item-tridys-list.component.html',
  styleUrls: ['./reports-item-tridys-list.component.css']
})

export class ReportsItemTridysListComponent {
  reportId:any
  routerUrl:any
  idToShow:string
  reportsList:any
  reportData:any
  reportDisplayName:any
  tridysList:any
  contentTypeId:any
  contentTypeName:any
  displayName:any
  displayedColumns: string[] = [ 'timeStamp', "creationDate"];
  dataSource=new MatTableDataSource();

  inputOptions:any=[
    {
      type:'string',
      input:'text'
    },
    {
      type:'number',
      input:'number'
    },
    {
      type:'boolean',
      input:'toggle'
    },]
  operatorOptions = [
    {
      name:"(==) equal to",
      translation: 'filters.operators.equal',
      value:"="
    },
    {
      name:"(!=) not equal to",
      translation: 'filters.operators.not_equal',
      value:"!="
    },
    {
      name:"(>) greather than",
      translation: 'filters.operators.greather_than',
      value:">"
    },
    {
      name:"(<) less than",
      translation: 'filters.operators.less_than',
      value:"<"
    },
    {
      name:"(>=) greter than or equal to",
      translation: 'filters.operators.greather_than_equal_to',
      value:">="
    },
    {
      name:"(<=) less than or equal to",
      translation: 'filters.operators.less_than_equal_to',
      value:"<="
    },
    {
      name:"(inrange) value between",
      translation: 'filters.operators.inrange',
      value:"inrange"
    }
  ]

  typeOptions = [
    {
      name:"Text",
      type:"string",
      translation:"typeOptions.text"
    },
    {
      name:"Number",
      type:"number",
      translation:"typeOptions.number"
    },
    {
      name:"Decimal",
      type:"decimal",
      translation:"typeOptions.decimal"
    },
    {
      name:"Boolean",
      type:"boolean",
      translation:"typeOptions.boolean"
    },
    {
      name:"Date",
      type:"date",
      translation:"typeOptions.date"
    },
    {
      name:"Time",
      type:"time",
      translation:"typeOptions.time"
    },
    {
      name:"Date/Time",
      type:"dateTime",
      translation:"typeOptions.dateTime"
    },
    {
      name:"Media",
      type:"media",
      translation:"typeOptions.media"
    },
  ]

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  breakpoint: number;
  paginatorSize = this.settingService.paginator$
  searchText = ''; 
  reportsDataList:any
  showFiller = false;
  filterForm!:FormGroup
  reportTemplate:any
  panelOpenState = false;
  lastRouteValue:any
  resultFields:any = []

  filtersValues:any

  constructor(private router: Router,
    private route: ActivatedRoute,
    private settingService: SettingsService,
    private commentService: CommentsService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private reportService:ReportService,
    private reportFilterService: ReportFilterService,
    private translate: TranslateService
    ){
    this.routerUrl=this.route.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    this.reportId = this.routerUrl.value[3].path
    this.lastRouteValue=this.routerUrl.value[this.routerUrl.value.length-2].path  
  }

  ngOnInit(){
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 6;
    const last = this.reportService.getReportIdLastOpened()//last==reportId

    this.reportsList = this.reportService.getReports(last)//this.idToShow
    this.reportTemplate = this.reportService.getTemplate(last)//this.idToShow

    this.reportFilterService.reportDataResultsFilteredValues$.subscribe( value => {
      this.commentService.progressSpin.emit(true)
      this.reportsList = value
      this.updateTable()
      this.commentService.progressSpin.emit(false)
    })

    if(this.reportTemplate['resultFields']){
      if(this.reportTemplate.length!=0){
        this.displayedColumns = [ ]
        // this.displayedColumns = ['id' ]
      }
      this.reportTemplate['resultFields'].forEach( field => {
        this.resultFields.push(field)
        this.displayedColumns.push(field.key)
      })

    }else{

      this.displayedColumns = [ ]
      if(this.reportTemplate[last]){
        this.reportsList = this.reportsList[last]
        if(this.reportTemplate[last]['resultFields']){
          this.reportTemplate[last]['resultFields'].forEach( field => {
            this.resultFields.push(field)
            this.displayedColumns.push(field.key)
          })
        }
      }
    }
    this.filterForm= this.fb.group({})

    if(this.lastRouteValue=="filter"){
      this.filtersValues = this.reportService.getFilterValues()
    }

    if(this.reportsList){
      this.dataSource = new MatTableDataSource<any>(this.reportsList);      
    }

    if(this.reportTemplate){
      if(this.reportTemplate.filters){
        const filters = deepCopy(this.reportTemplate.filters)

        filters.forEach((filter, index) => {
          if(filter.key){
            const key = index + '_' + filter.key
            if(this.filtersValues && this.filtersValues[filter.key]){
              this.filterForm.addControl(filter.key,new FormControl(this.filtersValues[filter.key]))
            }else{
              this.filterForm.addControl(filter.key,new FormControl(undefined))
            }
            const name = filter.key + '_operator'
            this.filterForm.addControl(name,new FormControl(filter.operator, Validators.required))

            const nametype = filter.key + '_type'
            this.filterForm.addControl(nametype,new FormControl(filter.type, Validators.required))

            const nameDisplayName = filter.key + '_displayName'
            this.filterForm.addControl(nameDisplayName,new FormControl(filter.displayName, Validators.required))

            const nameKey = filter.key + '_key'
            this.filterForm.addControl(nameKey,new FormControl(filter.key, Validators.required))

            const nameDescription = filter.key + '_description'
            this.filterForm.addControl(nameDescription,new FormControl(filter.description))
          }
        });
      }
    }


    this.reportsDataList = this.settingService.allReports$
    if(this.reportsDataList){
      this.reportsDataList.forEach(element => {
        if(element.id == this.reportId){
          this.reportData = element
          this.reportDisplayName = element.displayName
          this.contentTypeId = element.contentTypeId
          this.displayName = element.displayName
          // this.getTridysList()
          // this.getTridysContentTypeName()
        }
      });
    }

  }

  getTridysList(){
    if(this.reportData){
      try {
        this.commentService.progressSpin.emit(true)
        this.tridysList = this.settingService.observeTridysOfReportFirestore(this.contentTypeId)

      }catch(error){
        console.log(error)
        this.commentService.progressSpin.emit(false)
      }
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // this.paginator.page.subscribe(() => this.storeTablePreferences());
    if(this.sort){
      this.sort.sortChange.subscribe((value) => {
        const column = this.resultFields.find( result => result.key === value.active)
        if(column && column.type){
          if( column.type=="time" || column.type=="dateTime" || column.type=="date"){
            this.sortByColumn(column, value)
          }
        }
      });
    }

    setTimeout(() => {
      if(this.reportsList && this.reportsList.length!=0){
        // if(this.tridysList.length!=0){
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.firstPage()
        this.commentService.progressSpin.emit(false)
      }else{
        this.commentService.progressSpin.emit(false)
      }

    }, 600);
  }

  updateTable(){
    this.dataSource = new MatTableDataSource<any>(this.reportsList); 

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    setTimeout(() => {
      if(this.reportsList && this.reportsList.length!=0){
        // if(this.tridysList.length!=0){
        this.dataSource.paginator = this.paginator;
        if(this.dataSource.paginator)
        this.dataSource.paginator.firstPage()
        this.commentService.progressSpin.emit(false)
      }else{
        this.commentService.progressSpin.emit(false)
      }

    }, 600);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getTridysContentTypeName(){
    try{
      this.settingService.observeTridyContentTypeName(this.contentTypeId).then( value => {
        this.contentTypeName = value
      })
    }catch(error){
      console.log(error)
    }
  }

  backTo(){
    this.router.navigate(['home', this.settingService.contextId$,'reports'])
  
  }

  createNewFilters(){
    const firstFilters = deepCopy(this.reportTemplate.filters)
    let newFiltersTemplate = []
    firstFilters.forEach((filter, index) => {
      const key = filter.key ? filter.key : undefined
      const description = filter.description ? filter.description : undefined
      const name = filter.name ? filter.name : undefined
      const operator = this.filterForm.value[filter.key+'_operator'] ? this.filterForm.value[filter.key+'_operator'] : undefined
      const type = this.filterForm.value[filter.key+'_type'] ?  this.filterForm.value[filter.key+'_type'] :  undefined
      const value = this.filterForm.value[filter.key] ?  this.filterForm.value[filter.key] :  undefined
      const newFilter = {
        key, 
        value
      }

      removeUndefined(newFilter)
      if(newFilter.value)
        newFiltersTemplate.push(newFilter)
    })

    const data = { 
      contentTypeId: this.reportTemplate.contentTypeId,
      displayName:this.reportTemplate.displayName,
      id: this.reportId,
      filters: newFiltersTemplate
     }
    try{
      this.commentService.progressSpin.emit(true)
      const response = this.reportService.reports_createReport(this.settingService.contextId$, data, 'applyFilter')

      setTimeout(() => {
        this.reportsList = this.reportService.getReports(this.reportId)
        this.dataSource = new MatTableDataSource<any>(this.reportsList);
        this.updateTable()
        this.reportTemplate = this.reportService.getTemplate(this.reportId)
  
        if(this.lastRouteValue=="filter"){
          this.filtersValues = this.reportService.getFilterValues()
        }
      }, 500);

    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
    }
  }

  openRowView(field, element){
    if(field.type!='media'){
      this.router.navigate(['home', this.settingService.contextId$,'reports',this. reportId , 'tridy', element.id])
    }
  }

  clearFilters(){
    const filters = deepCopy(this.reportTemplate.filters)
      filters.forEach(filter => {
        if(filter.key){
          this.filterForm.setControl(filter.key,new FormControl(undefined))
          const name = filter.key + '_operator'
          this.filterForm.setControl(name,new FormControl(filter.operator))
          const nametype = filter.key + '_type'
          this.filterForm.setControl(nametype,new FormControl(filter.type))
        }
      });

      this.createNewFilters()
  }

  isDateTime(element){
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toUTCString()//----------
      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date
        // dateValue = date.toUTCString()//----------
      }

      if( typeof element === 'object'){
        const date = new Date(element)
        dateValue = date
        // dateValue = date.toUTCString()//----------
      }

      if( typeof element === 'string'){
        const date = new Date(element)
        dateValue = date
        // dateValue = date.toUTCString()//----------
      }

      try{
        let date = new Date(dateValue)
        dateValue = date
        // dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
      }catch(e){}
    }
   return dateValue
  }

  isDate(element){
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        // dateValue = date.toDateString()
        dateValue = date
      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date
        // dateValue = date.toDateString()
      }

      if( typeof element === 'string'){
        const date = new Date(element)
        dateValue = date
        // dateValue = date.toDateString()
      }
      if(!element['_nanoseconds'] && !element['_seconds'] && !element['nanoseconds'] && !element['seconds']){
        // if( typeof element === 'date'){
          const date = new Date(element)
        dateValue = date
        // dateValue = date.toDateString()
        // }
      }
      if( typeof element === 'object'){
        const date = new Date(element)
        dateValue = date
        // dateValue = date.toDateString()
      }

      try{
        let date = new Date(dateValue)
        dateValue = date
        // dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
      }catch(e){}
    }
   return dateValue
  }

  isTime(element){
    let dateValue
    dateValue = element
    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date
        // dateValue = date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
      }
      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date
        // dateValue = date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
      }

      if( typeof element === 'object'){
        const date = new Date(element)
        dateValue = date
        // dateValue = date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
      }

      if( typeof element === 'string'){
        const date = new Date(element)
        dateValue = date
        // dateValue = date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
      }

      // try{
      //   let date = new Date(dateValue)
      //   dateValue = date
      //   // dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
      // }catch(e){}
    }
   return dateValue
  }

  isMedia(element, field){
    let elementMedia 

    if(element[field.key]){
      elementMedia = element[field.key].split(' ')
    }

    return elementMedia
  }

  checkTypeOfArray(element, field){
    let type = false
    if(element[field.key]){
      let otype = typeof element[field.key]
      if(otype=='object'){
        type = Array.isArray(element[field.key])
      }
    }
    return type
  }
  
  showElementFieldKey(element, field){
    if(element[field.key]){

      switch ( field.type){
        case 'string' :{
          return element[field.key]
          break;
        }

        case 'number' :{
          return element[field.key]
          break;
        }
        case 'decimal' :{
          return element[field.key]
          break;
        }

        case 'boolean' :{
          return element[field.key]
          break;
        }

        case 'date' :{
          return this.isDate(element[field.key])
          break;
        }

        case 'time' :{
          return this.isTime(element[field.key])
          break;
        }

        case 'dateTime' :{
          return this.isDateTime(element[field.key])
          // return this.isDate(element[field.key]) + ', '+ this.isTime(element[field.key])///this.isDateTime(element[field.key])
          break;
        }

        case 'media' :{
          return element[field.key]
          break;
        }
      }
    }
  }

  filterTable(){
    const filterBy = this.getAllFiltersValues()
  }


  getAllFiltersValues(){
    const firstFilters = deepCopy(this.reportTemplate.filters)

    let newFiltersTemplate = []
    firstFilters.forEach((filter, index) => {
      const key = filter.key ? filter.key : undefined
      const description = filter.description ? filter.description : undefined
      const name = filter.name ? filter.name : undefined
      const displayName = filter.displayName ? filter.displayName : undefined
      const operator = this.filterForm.value[filter.key+'_operator'] ? this.filterForm.value[filter.key+'_operator'] : undefined
      const type = this.filterForm.value[filter.key+'_type'] ?  this.filterForm.value[filter.key+'_type'] :  undefined
      const value = this.filterForm.value[filter.key] ?  this.filterForm.value[filter.key] :  undefined
      const newFilter = {
        key, 
        value
      }
      removeUndefined(newFilter)
      if(newFilter.value)
        newFiltersTemplate.push(newFilter)
    })

    return newFiltersTemplate
  }

  updateFilters(drawer){
    this.commentService.progressSpin.emit(true)

    const firstFilters = deepCopy(this.reportTemplate.filters)
    const form = deepCopy(this.filterForm.value)

    let newFiltersTemplate = []
    firstFilters.forEach((filter, index) => {

      if(filter.key){
        if(form[filter.key+'_key']){
          const key = this.filterForm.value[filter.key+'_key'] ? this.filterForm.value[filter.key+'_key'] : filter.key
          const description = this.filterForm.value[filter.key+'_description'] ? this.filterForm.value[filter.key+'_description'] : undefined
          const displayName = this.filterForm.value[filter.key+'_displayName']  ? this.filterForm.value[filter.key+'_displayName']  : filter.displayName
          const operator = this.filterForm.value[filter.key+'_operator'] ? this.filterForm.value[filter.key+'_operator'] : filter.operator
          const type = this.filterForm.value[filter.key+'_type'] ?  this.filterForm.value[filter.key+'_type'] :  filter.type

          const newFilter = {
            key,  displayName, operator, type
          }
          if(description)
            newFilter['description'] = description

          if(key && displayName && operator && type)
          newFiltersTemplate.push(newFilter)

        }
      }
    })
    const data = {filters: newFiltersTemplate, id: this.idToShow}

    try{
      const a = this.reportService.updateReportAndReload(data)
      a.then( rsult => {
        this.reportService.setReportNewFilters(data)
        drawer.toggle()
        this.commentService.progressSpin.emit(false)
      })

    }catch(errror){ 
    this.commentService.progressSpin.emit(false)
    console.log(errror)
    }
  }

  extractNameFromUrl(url: string): string {
    const pattern = /([^/]+)\.pdf$/;
    const match = url.match(pattern);
    return match ? match[1] : '';
  }

  sortByColumn(field, value){
    if(value.direction == 'asc'){
      this.reportsList.sort(function(a, b){
        var x = a[field.key]; var y = b[field.key];
          
        if(field.type=='time'){
          x = isTime(x);
          y = isTime(y);
        }

        if(field.type=='date'){
          // x = isDate(x) // y = isDate(y)
          x = isDateTimeGetTimeSeconds(x)
          y = isDateTimeGetTimeSeconds(y) 
        }

        if(field.type=='dateTime'){
          x = isDateTimeGetTimeSeconds(x);
          y = isDateTimeGetTimeSeconds(y);
        }  
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
    }

    if(value.direction == 'desc'){
      this.reportsList.sort(function(a, b){
        var x = b[field.key]; var y = a[field.key];
        if(field.type=='time'){
          x = isTime(x);
          y = isTime(y);
        }

        if(field.type=='date'){
          // x = isDate(x) // y = isDate(y)
          x = isDateTimeGetTimeSeconds(x);
          y = isDateTimeGetTimeSeconds(y);
        }

        if(field.type=='dateTime'){
          x = isDateTimeGetTimeSeconds(x); 
          y = isDateTimeGetTimeSeconds(y);
        }
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
    }
  }


  exportThisReportTo(extension){
    this.reportService.exportDataToFile(this.dataSource.data, extension, this.reportTemplate )
  }

  checkFilterTypeOperatorRange(filter, type){
    if(filter == 'string' || filter == 'boolean' || filter == 'media'){
      if(type.value == 'inrange'){
        return true
      }else
      return false
    }else
    return false
  }

}
