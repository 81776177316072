import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from 'src/app/service/comments.service';
import { UploadService } from 'src/app/service/upload.service';
import { removeUndefinedValuesFromObject } from '../../../../../../backend/utils/object';

@Component({
  selector: 'app-annotated-map-annotation-dynamic-dialog',
  templateUrl: './annotated-map-annotation-dynamic-dialog.component.html',
  styleUrls: ['./annotated-map-annotation-dynamic-dialog.component.css']
})
export class AnnotatedMapAnnotationDynamicDialogComponent {

  annotationColumns:any
  actualPolygonOptions:any 
  annotationData:any
  newAnnotationForm!:FormGroup
  propertiesForm!:FormGroup
  passId:any
  widgetNumber:any  
  annotatedMapEditData:any

  constructor(public dialogRef: MatDialogRef<AnnotatedMapAnnotationDynamicDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private fb: FormBuilder,
    private commentService: CommentsService,
    private translate:TranslateService,
    private uploadService: UploadService){

    dialogRef.disableClose = true;
  }

  ngOnInit(): void{
    if(this.data['encodedContent']){
      this.actualPolygonOptions = this.data['encodedContent']
    }
    this.passId = this.data['passId']
    this.widgetNumber= this.data['widgetNumber']

    if(this.data['columns']){
      this.annotationColumns = this.data['columns']
    }

    if(this.data['annotationMapData']){
      this.annotatedMapEditData = this.data['annotationMapData']
    }

    
    this.newAnnotationForm = this.fb.group({
      dynamic:true,
      source:'workTasks',
      properties:[{}],
      id:[]
    })

    this.propertiesForm = this.fb.group({
      name:[],
      lat:[ ],
      lng:[],

      badge:[],
      coordinate:[],
      id:[],
      subtitle:[],
      title:[],
      linkURL:[],

      encodedContent:  [],
      fillColor : [],
      lineCap: [],
      lineWidth: [],
      strokeColor:  [],
      type: [] ,

      pinColor:[],
      lineColor:[]
    })

    if(this.data.values){
      this.annotationData=this.data.values
      const {dynamic, source, properties,id } = this.annotationData
      // const [name, lat, lng, badge, coordinate, id, subtitle, title ,linkURL,
      //   encodedContent ] = Object.keys(properties)


      let {strokeColor,fillColor, lineCap, lineWidth, type, pinColor, lineColor } = this.annotationData

      this.newAnnotationForm.patchValue({
        dynamic:dynamic ? dynamic : true,
        source:source ? source : 'workTasks',
        properties: properties ? properties : {},
        id:id ? id : undefined

      })

      if( Object.entries(properties).length != 0){
        const {name, lat, lng, badge, coordinate, id, subtitle, title ,linkURL, encodedContent } = properties

        this.propertiesForm.patchValue({
          name:name ? name : undefined,
          lat:lat ? lat : undefined,
          lng:lng ? lng : undefined,
    
          badge:badge ? badge : undefined,
          coordinate:coordinate ? coordinate : undefined,
          id:id ? id : undefined,
          subtitle:subtitle ? subtitle : undefined,
          title:title ? title :  undefined,
          linkURL: linkURL ? linkURL : undefined,
  
          encodedContent: encodedContent ? encodedContent : undefined,
          // fillColor: fillColor ? fillColor : undefined,
          // lineCap: lineCap ? lineCap : undefined,
          // lineWidth: lineWidth ? lineWidth : undefined,
          // strokeColor: strokeColor ? strokeColor : undefined,
          type: type ? type : undefined,
  
          pinColor: pinColor ? pinColor: undefined,
          // lineColor: lineColor ? lineColor : undefined
        })
      }
    
    }else{
      let badge = undefined
      if(this.data['index'])
        badge = this.data['index']
      
      this.newAnnotationForm.patchValue({
        dynamic: true,
        source: 'workTasks',
        properties: {}
      })

      this.propertiesForm.patchValue({
        // name: undefined,
        lat: undefined,
        lng: undefined,
        badge: undefined,
        coordinate: undefined,
        id: undefined,
        subtitle: undefined,
        title: undefined,
        linkURL: undefined,
        encodedContent:  undefined,
        // fillColor : undefined,
        // lineCap: undefined,
        // lineWidth: undefined,
        // strokeColor:  undefined,
        type: undefined,
        pinColor: undefined,
        // lineColor: undefined,
      })
    }

  }

  onSaveEdit(){
    const propertiesForm = Object.entries( this.propertiesForm.value )
    let properties = {}

    propertiesForm.forEach( keyvalue => {
      const key = keyvalue[0]
      let value=  keyvalue[1]

      if(value){
        properties[key] = value
      }

    })

    this.newAnnotationForm.patchValue({properties:properties})
    const data = this.newAnnotationForm.value
    this.dialogRef.close([true, data])
  }

}