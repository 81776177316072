<h2 *ngIf="setMailToResetPassword" mat-dialog-title>{{'resetPassword' | translate}}</h2>
<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section">

            <div *ngIf="messageConfirmation">
                <div class="p-2 pb-4 mb-3 d-flex flex-column justify-content-center align-items-center">
                    <span class="p-1 text-wrap"> {{message_mail}} </span>
            
                    <span class="p-1 fw-bolder fs-5 text-wrap text-break">{{email}}</span>
            
                    <span class="p-1 text-wrap"> {{message_mail_2}}</span>
                </div>
            </div>

            <div *ngIf="USER_REINVITE_OK">
                <div class="p-2  d-flex flex-column justify-content-center align-items-center">
                    <span class="p-2 text-wrap text-center"> {{message_mail}}. {{message_mail_2}}</span>            
                    <span class="p-1 fw-bolder fs-5 text-wrap text-break">{{email}}</span>
                </div>
            </div>

            <div *ngIf="setMailToResetPassword">
                <p class="pb-3">{{'enter_mail_to_reset_password' | translate}}</p>
                <form [formGroup]="resetForm">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'login.email' | translate}}</mat-label>
                        <input matInput type="email" class="form-control" formControlName="email" placeholder="{{'login.email' | translate}}">
                    </mat-form-field>
                </form>
            </div>
        
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button  *ngIf="messageConfirmation || USER_REINVITE_OK"  mat-button style="color:var(--warmOrange);" (click)="closeInfoMessage();"  >
        {{'Close' | translate}}
    </button>
    <button *ngIf="setMailToResetPassword" mat-button style="color:var(--warmOrange);" (click)="cancelResetPassword();"  >
        {{'cancel' | translate}}
    </button>
    <button *ngIf="setMailToResetPassword" mat-button class="orange-button" (click)="sendMailToResetPassword();">{{'resetPassword' | translate}}</button>
</mat-dialog-actions>
