<section>
    <div class="d-flex flex-row justify-content-between align-items-center">
        <span class="text-muted">FILTERS</span>

        <div>
            <button mat-button class="secondary-gray-button my-3" id="btn-add" (click)="deleteAllFilter()"  [disabled]="!filtersData">
                <mat-icon>close</mat-icon> {{'delete' | translate}}
            </button>

            <button mat-button class="ms-1 secondary-gray-button my-3" id="btn-add" [matMenuTriggerFor]="filtersOptions" [disabled]="disableAddFilters">
                <mat-icon>add_circle</mat-icon> {{'add' | translate}}
            </button>
        </div>
        
        <mat-menu #filtersOptions="matMenu">
            <button mat-menu-item *ngFor="let filter of filterTypes; let index = index;" (click)="addFilterType(filter);" [disabled]="disableAddFilters"> {{filter.translate | translate}} </button>
        </mat-menu>
    </div>

    <div *ngIf="filterKeys">

        <div *ngIf="filterKeys.includes('field') && filterKeys.includes('value') ">
            <div>
                <div class="form-control p-2 px-3 mb-1 d-flex justify-content-between w-100">
                    <div class="d-flex align-items-center">
                        <span class="me-2">{{filtersData['field']}}</span>
                        <span class="font-italic me-2 text-muted">{{getOperatorText(filtersData['operator']) | translate}}</span>
                        <span>{{filtersData['value']}}</span>
                    </div>

                    <div>
                        <button mat-icon-button style="color:var(--warmOrange);" (click)="editFilterType(filtersData, '=', 0);" >
                            <mat-icon>edit</mat-icon>
                        </button>
    
                        <!-- <button mat-icon-button class="secondary-gray-button" style ="color:red;" id="btn-add" (click)="clearFilter()"  [disabled]="!filtersData">
                            <mat-icon class="material-symbols-outlined">delete</mat-icon>
                        </button> -->
                    </div>
                </div>
                <span class="pl-2">If this evaluates to false, the widget will be hidden</span>
            </div>
        </div>

        <div *ngIf="filterKeys.includes('and') || filterKeys.includes('or')">
            <div id="tree-section">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree w-100 m-auto shadow-sm ">

                    <mat-nested-tree-node *matTreeNodeDef="let node;let index=level; when: isArrayType || hasChild" #treeNodeElement>
                        <div class="mat-tree-node border-bottom ">
                            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.key">
                                <mat-icon class="mat-icon-rtl-mirror"> {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
                            </button>

                            <!-- **{{treeNodeElement.ariaLevel}} ++{{index}} -->

                            <div *ngIf="!isObject(node)" class="d-flex flex-row flex-wrap justify-content-start align-items-center ps-2">
                                <span class="p-1" style="color:black;" >##{{getKeysObject(node)}}</span>
                            </div>

                            <div *ngIf="isObject(node)" class="d-flex flex-row flex-wrap justify-content-between align-items-center ps-2">
                                <div  class="d-flex flex-row justify-content-start align-items-center ps-2">
                                    <span *ngIf="node.field" class="p-1" style="color:black;" >++{{node.field}}  {{node.operator}}  {{node.value}}</span>
                                    <span *ngIf="node.and" class="p-1" style="color:black;" >{{'pass.widgets.filter.all_of' | translate}}</span>
                                    <span *ngIf="node.or" class="p-1" style="color:black;" >{{'pass.widgets.filter.any_of' | translate}}</span>
                                </div>

                                <div>
                                    <button mat-icon-button  style="color:var(--warmOrange);" [matMenuTriggerFor]="filterEditList" > <!--(click)="addNewProperty(node,index);"-->
                                        <mat-icon>edit</mat-icon>
                                    </button>

                                    <mat-menu #filterEditList="matMenu">
                                        <button mat-menu-item (click)="editFilterType(node, 'or', index);"> {{'pass.widgets.filter.any_of' | translate}} </button>
                                        <button mat-menu-item (click)="editFilterType(node, 'and',index);"> {{'pass.widgets.filter.all_of' | translate}} </button>
                                    </mat-menu>
                                    
                                    <button mat-icon-button  style="color:var(--warmOrange);" [matMenuTriggerFor]="filterAddList" [disabled]="getDisableNodeChilds(node)"> <!--(click)="addNewProperty(node,index);"-->
                                        <mat-icon>add_circle</mat-icon>
                                    </button>

                                    <mat-menu #filterAddList="matMenu">
                                        <button mat-menu-item *ngFor="let type of filterTypes" (click)="addFilterTypeToNode(type, node, index);" [disabled]="getDisableNodeChilds(node)"> {{type.name}} </button>
                                    </mat-menu>
                                </div>
                            </div>

                        </div>

                        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </div>
                    </mat-nested-tree-node>

                    <mat-nested-tree-node *matTreeNodeDef="let node;let index=level; when: isComparisson" #treeNodeElementC>
                        <div class="mat-tree-node border-bottom">
                        <!-- **{{treeNodeElementC.ariaLevel}}  ++{{index}} -->

                            <div class="d-flex flex-row justify-content-start flex-wrap align-items-center ps-2">
                                <span class="p-1" style="color:black;" >{{node.field }}</span> 
                                <span class="p-1 font-italic text-muted" style="color:black;" >{{getOperatorText(node.operator) | translate}}</span>
                                <span class="p-1" style="color:black;" >{{node.value }}</span> 
                            </div>

                            <div>
                                <button mat-icon-button style="color:var(--warmOrange);" (click)="editFilterType(node, '=', index);" >
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-nested-tree-node>
                </mat-tree>   
            </div>
        </div>

    </div>
</section>

