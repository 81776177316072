<h2 mat-dialog-title>{{'taskTemplates.option' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >

            <form [formGroup]="optionsForm">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'taskTemplates.option' | translate}}</mat-label>
                    <input matInput name="option" formControlName="option" class="form-control" placeholder="{{'taskTemplates.option' | translate}}" id="option" type="text" [readonly]="!tasktemplateUserRights_update" >
                </mat-form-field>
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onSave();" [disabled]="!tasktemplateUserRights_update" >{{'save' | translate}}</button>
</mat-dialog-actions>
