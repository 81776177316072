export const widgetTypes = {
    image:"Image",
    billboardImage:"Billboard Image",
    billboardVideo:"Billboard Video",
    buttons:"Buttons",
    callToAction: "Social Media",
    text:"Text",
    imageBlocks:"Images",
    imagesHorizontal:"Images",
    // imageBlocks:"Image Blocks",
    // imagesHorizontal:"Images (Horizontal)",
    images:"Image Gallery",
    list:"List",
    annotatedMap:"Map",
    annotations:"Annotation",
    annotationGroups:"Annotation Group",
    template:"Template",
    user:"User",
    video:"Video",
    widgets:"Widgets",
    products:"Products",
    splashVideo:"Splash Video",
    spacer:"Spacer",
    shopify:"Shopify",
    "3dmodel":"3D Model",
    instagram:"Instagram",
    form: "Form",
    notification:"Notification",
    productRegistration:"Registration",
    countdown:"Countdown",
    composed:"Composed"


}