


    <div *ngIf="contentData.type=='text'" class=" ps-1 containerWidth" >
        <span *ngIf="contentData?.attributes?.text" class="container text-wrap text-break"
            [ngStyle]=" {'width': contentData?.attributes?.width ? contentData?.attributes?.width : '100%',
                          'height': contentData?.attributes?.height ? contentData?.attributes?.height: '100%',
                          'font-family': fontfamily,

                        }">
            {{contentData?.attributes?.text}}
        </span>
    </div>

    <div *ngIf="contentData.type=='image'" class="ps-1 containerWidth">
        <img *ngIf="contentData?.attributes?.url" src="{{supportUrl(contentData?.attributes?.url)}}" class="containerWidth" onerror="this.src='./../../assets/img/default.jpg'" 
                alt="..."
                [ngStyle]="{
                    'width.px': contentData?.attributes?.width ? contentData?.attributes?.width : 250,
                    'height.px': contentData?.attributes?.height ? contentData?.attributes?.height : 400,
                }"
            />
    </div>

    <div *ngIf="contentData.type=='hstack'" class="d-flex flex-row justify-content-center p-1 containerWidth" [style]="{ 'align-items' : verticalAlignment, 'align-content': horizontalAlignment, 'height': height , 'width': width  }" >
        <section *ngFor="let content of contentData?.children" [style]="{ 'padding.px' : contentData?.attributes?.spacing ? contentData?.attributes?.spacing : 0 }">
            <div [app-composed-content-phone]="[content]"></div>
        </section>
    </div>

    <div *ngIf="contentData.type=='vstack'" class="d-flex flex-column justify-content-start p-1 containerWidth" [style]="{ 'align-items' : horizontalAlignment , 'align-content' :horizontalAlignment, 'justify-content' : horizontalAlignment, 'height': height , 'width': width }">
        <section *ngFor="let content of contentData?.children" [style]="{ 'padding.px' : contentData?.attributes?.spacing ? contentData?.attributes?.spacing : 0 }">
            <div [app-composed-content-phone]="[content]"></div>
        </section>
    </div>


    <div *ngIf="contentData.type=='zstack'" class="zstack containerWidth" [style]="{ 'align-items' : horizontalAlignment , 'align-content' :horizontalAlignment, 'height': height , 'width': width }"><!--d-flex flex-column justify-content-center p-1-->
        <section *ngFor="let content of contentData?.children; let index=index" class="zstack-item" [style]="{ 'padding.px' : contentData?.attributes?.spacing ? contentData?.attributes?.spacing : 0 , 'z-index': index + 1  }">
            <div [app-composed-content-phone]="[content]"></div>
        </section>
    </div>

    <div *ngIf="contentData.type=='circle'" class="ps-1 containerWidth">
        <div style="border-radius: 50%; background-color: black;"
                [ngStyle]="{
                    'width.px': contentData?.attributes?.width ? contentData?.attributes?.width : 200,
                    'height.px': contentData?.attributes?.height ? contentData?.attributes?.height : 200,
                }">
        </div>
    </div>


    <div *ngIf="contentData.type=='rectangle'" class="ps-1 containerWidth">
        <div style="background-color: black;"
                [ngStyle]="{
                    'width.px': contentData?.attributes?.width ? contentData?.attributes?.width : 280,
                    'height.px': contentData?.attributes?.height ? contentData?.attributes?.height : 200,
                }">
        </div>
    </div>
