import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { Product } from '../../../../backend/src/products.dto';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommentsService } from '../service/comments.service';
import { UploadService } from '../service/upload.service';
import { removeUndefined, removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { ProductsService } from '../service/products.service';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products-item',
  templateUrl: './products-item.component.html',
  styleUrls: ['./products-item.component.css']
})
export class ProductsItemComponent implements OnInit{
  productData:Product
  productValues:any
  routerUrl:any
  idToShow:string
  priceAmount:any
  productForm!:FormGroup
  allImages:any=[]
  newImage:any=[]
  changes=false
  imgToDelete:any=[]
  productsUserRights_update:any
  productsUserRights_delete:any

  constructor(private router: Router,
    private route: ActivatedRoute,
    private settingService: SettingsService,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private uploadService: UploadService,
    private productsService: ProductsService,
    public dialog: MatDialog,
    private translate:TranslateService){

    this.routerUrl=this.route.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }

  ngOnInit(): void {
    this.productsUserRights_delete = this.settingService.modulesRolesRights$.value['products']['D']
    this.productsUserRights_update= this.settingService.modulesRolesRights$.value['products']['U']

    this.settingService.modulesRolesRights$.subscribe( value => {
      this.productsUserRights_delete = this.settingService.modulesRolesRights$.value['products']['D']
      this.productsUserRights_update= this.settingService.modulesRolesRights$.value['products']['U']
    })
    this.productData = new Product()

    if(!this.productsUserRights_update){
        document.getElementById("btn-save").style.backgroundColor='#e0e0e0'
        document.getElementById("btn-cancel").style.color='#e0e0e0'
    }

    if(!this.productsUserRights_delete){
      document.getElementById("btn-delete").style.color='#e0e0e0'
    }


    this.productForm = this.fb.group({
      description:[],
      group:[],
      id:[],
      images:[],
      manufacturer:[],
      price:[],
      productId:[],
      tags:[],
      title:[],
      type:[],
    })

    try{
      this.commentService.progressSpin.emit(true)
      this.settingService.observeContextsItemsIdFirestore(this.idToShow).then( value=>{
        this.productValues = value
        this.productData.setFromAny(value)
        this.initializeForm()
      })
      this.commentService.progressSpin.emit(false)

    }catch(error){
      this.commentService.progressSpin.emit(false)

    }

    
  }


  initializeForm(){
    const { description, group, id, images, manufacturer, price, productId, tags, title, type} = this.productData
    
    let priceAmount
    if(price){
      priceAmount = price.amount
    }else{
      priceAmount = undefined
    }
    let allTags=""
    if(tags){
      tags.forEach( (tag, index) =>{
        if(tag){
          if(index < tags.length -1 ){
            allTags += tag +", "
          }else{
            allTags += tag 
          }
        }
          
      })
    }
    this.productForm.patchValue({
      description: description ? description : undefined,
      group: group ? group : undefined,
      id: id ? id : this.idToShow,
      images:images ?  images : undefined,
      manufacturer: manufacturer ? manufacturer : undefined,
      price:priceAmount ? priceAmount : undefined,
      productId: productId ? productId : undefined,
      tags:allTags ? allTags : undefined,
      title:title ? title : undefined,
      type:type ? type : undefined,
    })
    
    this.allImages = this.productForm.value.images ? this.productForm.value.images : undefined
    if(price){
      this.priceAmount = this.productForm.value.price.amount ? this.productForm.value.price.amount : undefined
    }else{
      this.priceAmount = '0'
    }
    this.productForm.valueChanges.subscribe( value=>{
    })
  }

  backTo(){
    this.newImage.forEach( image=>{
      this.uploadService.deleteFromStorage(image.url)
    })
    this.router.navigate(['home', this.settingService.contextId$,'products'])
    const message = this.translate.instant("SnackBarConstants.UPDATE_DISMISS")
    this.commentService.addSnackBar.emit(message)
  }

  saveProduct(){
    this.imgToDelete.forEach( image => {
      this.uploadService.deleteFromStorage(image)

    })
    const { description, group, id, images, manufacturer, price, productId, tags, title, type} = this.productForm.value
    const data = { description, group, id, images, manufacturer, price, productId, tags, title, type}

    if(price){
      data['price']={ 
        amount : price,
        currency:"euro",
        vat:0
      }
    }

    if(tags){
      const allTgs = tags.split(",")
      data['tags']=allTgs
    }
    
    const newData=removeUndefinedValuesFromObject(data)

    try{
      this.commentService.progressSpin.emit(true)
      this.productsService.updateProduct(data)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.UPDATE_OK")
      this.commentService.addSnackBar.emit(message)
      this.router.navigate(['home', this.settingService.contextId$,'products'])
    }catch(error){
      console.log(error)
      const message = this.translate.instant("SnackBarConstants.UPDATE_FAILED")
      this.commentService.addSnackBar.emit(message)

    }
  }

  deleteImg(url,index){
    if(this.allImages && this.allImages.length == 0){//the img that are shown on the interface are from productForm
      var a:any=[]
      this.productForm.value.images.forEach((element:any, index:any) => {
        if(element.url!=url){
          a.push(element)
        }
      });
      this.productForm.patchValue({
        images: a
      })
      this.imgToDelete.push(url)
      
    }else{

      if(this.changes){//ok
        var a:any=[]
        this.productForm.value.images.forEach((element:any, index:any) => {
          if(element.url!=url){
            a.push(element)
          }
        });
        this.productForm.patchValue({
          images: a
        })
        this.imgToDelete.push(url)
      }else{//ok
        this.changes=true
        var a:any=[]
        this.productForm.value.images.forEach((element:any, index:any) => {
          if(element.url!=url){
            a.push(element)
          }
        });
        this.productForm.patchValue({
          images: a
        })
        this.imgToDelete.push(url)
      }
    }
  }

  readURL(event:any){
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      let newURL
      try{
        this.commentService.progressSpin.emit(true)
        this.uploadService.uploadImageItem(event.target.files[0], this.settingService.contextId$, this.idToShow)
        setTimeout(()=>{
          newURL = this.uploadService.getImageItem()
          const a=this.productForm.value.images
          if(!this.productForm.value.images){
            
            let images = []
            images.push(newURL)
            this.productForm.patchValue({
              images: images
            })
          }else{
            this.productForm.patchValue({
              images: [newURL]
            })
          }
          
          this.newImage.push(newURL)
          this.changes=true
          this.commentService.progressSpin.emit(false)
        },3000)
        
      }catch(error){
        console.log(error)
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED")
        this.commentService.addSnackBar.emit(message)
      }
    }
  }

  deleteProduct(){
    let dialogRef= this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { 
        values:this.productForm.value,
        type:"Product"
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.router.navigate(['home', this.settingService.contextId$,'products'])
      }else{
        const message = this.translate.instant("SnackBarConstants.DELETE_DISMISS")
        this.commentService.addSnackBar.emit(message)
      }
    });
  }
}
