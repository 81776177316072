import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { deepCopy } from '../../../../backend/utils/object';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { languagesList, languagesList_ } from '../../../../backend/src/languagesList.dto';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../service/settings.service';
import { CommentsService } from '../service/comments.service';
import { TranslationsDPPService } from '../service/translations-dpp.service';
import { ConfirmChangesDialogComponent } from '../confirm-changes-dialog/confirm-changes-dialog.component';

@Component({
  selector: 'app-pass-templates-settings-edit',
  templateUrl: './pass-templates-settings-edit.component.html',
  styleUrls: ['./pass-templates-settings-edit.component.css']
})

export class PassTemplatesSettingsEditComponent {
  passData:any
  passEditForm!:FormGroup
  oldData:any
  contextId:any
  languagesList = languagesList
  languagesPass:any=[]
  oldLanguages:any
  languages:any
  firstLanguageBeforeChange:any
  contextLanguages
  firstLanguageAfterChange:string
  widgetsBeforeChange:any
  languagesPassAfterChanges:any
  firstPassDataLanguage:string

  constructor(public dialogRef: MatDialogRef<PassTemplatesSettingsEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private fb: FormBuilder,
    private dialog: MatDialog,
    private translateService:TranslateService,
    private settingsService:SettingsService,
    private commentService:CommentsService,
    private dppTranslations:TranslationsDPPService){
      dialogRef.disableClose = true;
      const currentLang = this.translateService.currentLang;
      this.languagesList = deepCopy(languagesList_[currentLang])
  }

  ngOnInit():void{
    this.contextLanguages = deepCopy(this.settingsService.languagesContexts$)

    this.passData=deepCopy(this.data[0])
    this.oldData=deepCopy(this.data[0])
    this.contextId=deepCopy(this.data[1])
    this.languages = Object.entries(this.languagesList)
    this.languages.sort( (a,b) => { return a[1].toLowerCase().localeCompare(b[1].toLowerCase());})

    if(this.passData.languages){
      if(this.passData.languages.length!=0){
        this.firstPassDataLanguage = this.passData.languages[0]
        this.firstLanguageBeforeChange = this.passData.languages[0]
        this.firstLanguageAfterChange = this.passData.languages[0]
      }
      this.languagesPass = deepCopy(this.passData.languages)
      this.languagesPassAfterChanges = this.languagesPass
      this.oldLanguages = deepCopy(this.passData.languages)
    }else{
      this.languagesPass= []
    }

    if(this.passData.widgets)
      this.widgetsBeforeChange = deepCopy( this.passData.widgets)

    this.passEditForm = this.fb.group({
      id:[this.passData.id],
      widgets:[this.passData.widgets],
      contextId:[this.passData.contextId],
      translations:[this.passData.translations],
      theme: [this.passData.theme],
      comment:[this.passData.comment],
      name:[this.passData.name, Validators.required],
      templateName:[this.passData.templateName],
      contentType:[this.passData.contentType],
      customer:[this.passData.customer],
      tridyId:[this.passData.tridyId],
      languages:[this.passData.languages],
      enableShoppingCart: [this.passData.enableShoppingCart],
      enableCustomerLogin: [this.passData.enableCustomerLogin]
    })


    this.passEditForm.get('languages').valueChanges.subscribe( newLanguages => {
      console.log('firstLanguage', this.firstLanguageAfterChange)
      if(newLanguages.length!=0){
        const newFirstLanguage = newLanguages[0]
        this.firstLanguageAfterChange = newFirstLanguage
      }else{
        //check contextlanguages
        console.log('.check contextlanguages',this.contextLanguages)
        if(this.contextLanguages && this.contextLanguages.length!= 0){
          const newFirstLanguage = this.contextLanguages[0]
          // this.languagesPassAfterChanges = newFirstLanguage
          this.firstLanguageAfterChange = newFirstLanguage

        }
      }
    })

  }

  openConfirmDefaultLanguageChangeDialog(newLanguage, newlanguages, previousLanguages?){
    console.log(newLanguage, newlanguages, previousLanguages)
    const message = this.translateService.instant("pass.CONFIRM_LANGUAGE_CHANGE_MESSAGE")

    let dialogRef= this.dialog.open(ConfirmChangesDialogComponent, {
      data: {
        message:'pass.CONFIRM_LANGUAGE_CHANGE_MESSAGE' 
      }
    });

    dialogRef.afterClosed().subscribe(value => {
      console.log(value)
      if(value){
        this.firstLanguageAfterChange = newLanguage
        this.firstLanguageBeforeChange = newLanguage
        this.passEditForm.patchValue({languages: newlanguages})
      }else{
        // const bdelete = [this.firstLanguageBeforeChange , ... this.languagesPass]
        
        // this.firstLanguageAfterChange = this.firstLanguageBeforeChange
        // this.firstLanguageBeforeChange = newLanguage
        // this.firstLanguageAfterChange = newLanguage
        // this.passEditForm.patchValue({languages: previousLanguages})
      }
    })
  }


  addLanguageToPass(lang){
    if(this.passEditForm.value.languages){
      if(this.passEditForm.value.languages.length!=0){
        if(!this.passEditForm.value.languages.includes(lang)){
          const langform = this.passEditForm.value.languages
          langform.push(lang)
          this.languagesPass = langform
          this.passEditForm.patchValue({languages: langform})
        }
      }else{

        let languages = []
        languages.push(lang)
        this.languagesPass = languages
        this.passEditForm.patchValue({languages: languages})
      }
    }else{
      let languages = []
      languages.push(lang)
      this.languagesPass = languages
      this.passEditForm.patchValue({languages: languages})
    }
    
  }

  deleteLanguageOfPass(lang, index){
    const bdelete = this.languagesPass
    bdelete.splice(index,1)
    this.passEditForm.patchValue({languages: bdelete})
  }

  languagesIsAdded(lang){
    let exists = false
    if(this.passEditForm.value.languages){
      if(this.passEditForm.value.languages.includes(lang)){
        exists=true
      }
    }
    return exists
  }
  

  dropLanguage(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.languagesPass, event.previousIndex, event.currentIndex);
    this.passEditForm.patchValue({languages: this.languagesPass})

  }


  onCancelEdit(){
    this.dialogRef.close([false,this.oldData])
  }

  onSaveEdit(){
    console.log(this.firstPassDataLanguage, this.firstLanguageBeforeChange , this.firstLanguageAfterChange)
    if(this.firstLanguageBeforeChange != this.firstLanguageAfterChange){
      console.log('---- change default languages values')
      this.commentService.progressSpin.emit(true)
      
      const languages = this.languagesPass ?? this.contextLanguages
      console.log(languages)
      //this keeps previous language field value if newlanguage has no translations on one field
      this.dppTranslations.updateWidgetsWithNewDefaultLanguage( this.firstPassDataLanguage, this.firstLanguageAfterChange, this.widgetsBeforeChange, this.passEditForm.value.translations,  languages).subscribe( value => {
        this.passEditForm.patchValue( {translations:value.translations, widgets: value.widgets})
        this.dialogRef.close([true,this.passEditForm.value])

        this.commentService.progressSpin.emit(false)
      })
    }else{
      this.dialogRef.close([true,this.passEditForm.value])
    }
    // remove empty strings?
  }

}