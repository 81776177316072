import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { NetworkConstants } from 'src/network.constants';
import { doc, getFirestore, updateDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  db = getFirestore()

  constructor(private settingsService: SettingsService) { }

    updateContextDataAccount(newValues){
      return new Promise<void>(async (resolve, reject) => {

        try{ 
          const docRef = doc(this.db, NetworkConstants.COLLECTION_CONTEXTS, this.settingsService.contextId$)
          await updateDoc( docRef, newValues)

          resolve()
        }catch(error){
          reject(error)
        }
      }) 
    }

}