<section class="container">
    <div class="row mt-4">


        <div class="mt-3">
            <h1 class="mb-1">{{'topNavbar.media' | translate}}</h1>
            <p style="font-family:'MyFont-Light';">{{'headlines.subMedia' | translate}}</p>
        </div>

        <div class="d-flex justify-content-between my-4">
            <button mat-button class="orange-button" id="btn-upload" (click)="triggerFileInput()">
                <mat-icon>add_circle</mat-icon> {{'upload' | translate}}
                <input type="file" id="myfile-upload-media" name="myfile-upload-media" style="display: none;" accept=".jpg, .jpeg, .png, video/* ,.pdf"  (change)="readURL($event);">
            </button>
        </div>

        <!-- bread crumbs? -->
        <div style="min-width:50%;" >
            <button [hidden]="!showBack" mat-icon-button (click)="navBack()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
        </div>
    </div>
</section>
<ng-template injectComp></ng-template>
