<h2 mat-dialog-title>{{data.ruleStructure.translation | translate}}</h2>
<mat-dialog-content>

    <section class="p-2 w-100" style="min-width:300px;">
        @if(data.ruleStructure && data.ruleStructure.parameters){
            <form [formGroup]="ruleForm">
                @for( rule of data.ruleStructure.parameters; track rule; let index = $index){
                    @if(rule.key != 'decimal'){
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{rule.description | translate}}</mat-label>
                            <input matInput type="{{rule.inputType}}" formControlName="{{rule.key}}" />
                        </mat-form-field>
                    }
                    
                    @if(rule.key == 'decimal'){
                        <div class="p-3 form-control d-flex justify-content-between">
                            <label for="value">{{rule.description | translate}}</label>
                            <mat-slide-toggle class="example-margin" formControlName="{{rule.key}}"></mat-slide-toggle>  
                        </div>
                    }
                }
                
            </form>
        }
    </section>
  
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="[false]" style="color:var(--warmOrange);">{{'cancel' | translate}}</button>
  <button mat-button class="orange-button"  (click)="saveRule()" [disabled]="!tasktemplateUserRights_update" cdkFocusInitial>{{'apply' | translate}}</button>
</mat-dialog-actions>