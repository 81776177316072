
<section>
    <img *ngIf="!billboardImgData.linkURL && !url" src={{billboardImgData.url}} alt={{billboardImgData.id}} class="w-100" onerror="this.src='./../../assets/img/default.jpg'">
    <img *ngIf="!billboardImgData.linkURL && url" src={{url}} alt={{billboardImgData.id}} class="w-100" onerror="this.src='./../../assets/img/default.jpg'">
    <a *ngIf="linkURL && !url" href="{{linkURL}}" target="_blank"><img  src={{billboardImgData.url}} alt={{billboardImgData.id}} class="w-100" onerror="this.src='./../../assets/img/default.jpg'"></a>
    <a *ngIf="linkURL && url" href="{{linkURL}}" target="_blank"><img  src={{url}} alt={{billboardImgData.id}} class="w-100" onerror="this.src='./../../assets/img/default.jpg'"></a>

    <span *ngIf="dynamic" class="material-symbols-outlined" style="right: 10px; position: absolute;">
        finance_chip
    </span>
</section>



