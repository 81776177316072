import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommentsService } from 'src/app/service/comments.service';
import { removeUndefinedValuesFromObject } from '../../../../../../backend/utils/object';
import { UploadService } from 'src/app/service/upload.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-media-details-dialog',
  templateUrl: './media-details-dialog.component.html',
  styleUrls: ['./media-details-dialog.component.css']
})
export class MediaDetailsDialogComponent implements OnInit{
oldData:any
mediaDetailsData:any
mediaDetailsForm!: FormGroup
billboardImage=false
billboardVideo=false
text=false
image=false
callToAction=false
video=false
images=false
widgetType:any
imagesHorizontal=false
imageBlocks=false
splashVideo=false
newDocument:any
passId:any
linkUrlTransform:any
widgetNumber:any
urlRegex = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
urlRegexDoc = /^pass?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  constructor(public dialogRef: MatDialogRef<MediaDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private commentService: CommentsService,
    private fb: FormBuilder,
    private translate:TranslateService,
    private uploadService: UploadService){
      dialogRef.disableClose=true
    }

    ngOnInit():void{
      this.oldData = this.data.values
      this.widgetType = this.data['type']
      this.mediaDetailsData = this.data.values
      this.passId = this.data['passId']
      this.widgetNumber= this.data['widgetNumber']

      this.mediaDetailsForm = this.fb.group({
        url:[],
        fallbackURL:[],
        title:[null, [Validators.maxLength(40)]],
        copyright:[],
        linkURL:[,[ Validators.pattern(this.urlRegex)]],
        description:[],
        visibility:[],
        size:[]
      })

      const { url, fallbackURL, title, copyright, linkURL, description, visibility, type, size} = this.mediaDetailsData
      
      this.mediaDetailsForm.patchValue({
        url: url ? url : undefined,
        fallbackURL: fallbackURL ? fallbackURL : undefined,
        title: title ? title : undefined,
        copyright: copyright ? copyright : undefined,
        linkURL: linkURL ? linkURL : 'https://',
        description: description ? description : undefined,
        visibility: visibility ? visibility : 'visible',
        size: size ? size : undefined
      })

      this.mediaDetailsForm.get("linkURL").valueChanges.subscribe( (newurl) => {
        let url;
    
        try {
          url = new URL(newurl)
          if(url.protocol === "http:" || url.protocol === "https:"){
            this.mediaDetailsForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegex)]);
          }
          if(url.protocol === "pass:" ){
            this.mediaDetailsForm.get("linkURL").setValidators([Validators.required, Validators.pattern(this.urlRegexDoc)]);
          }
        } catch (error) {}
      })
      
      switch(this.widgetType){
        case 'billboardImage': {
          this.billboardImage=true
          break;
        }

        case 'billboardVideo': {
          this.billboardVideo=true
          break;
        }

        case 'text': {
          this.text=true
          if(this.mediaDetailsData.image){
            if(this.mediaDetailsData.image.url)
              this.mediaDetailsForm.patchValue({url: this.mediaDetailsData.image.url})

            if(this.mediaDetailsData.image.linkURL)
              this.mediaDetailsForm.patchValue({linkURL: this.mediaDetailsData.image.linkURL})

            if(this.mediaDetailsData.image.changingThisBreaksApplicationSecurity)
              this.mediaDetailsForm.patchValue({url: this.mediaDetailsData.image})
          }

          break;
        }

        case 'image': {
          this.image=true
          break;
        }

        case 'callToAction': {
          this.callToAction = true
          break;
        }
        case 'video': {
          this.video = true
          break;
        }

        case 'imagesHorizontal': {
          this.imagesHorizontal = true
          break;
        }

        case 'imageBlocks': {
          this.imageBlocks = true
          break;
        }
        case 'splashVideo': {
          this.splashVideo = true
          break;
        }

      }
    }

    readDocument(event: any): void {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB size limit
        
        // Define allowed file types
        const allowedTypes = [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'image/webp',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/plain',
          'text/csv',
          'text/vcard',
          'application/rtf'
        ];
    
        // Validate file type
        if (!allowedTypes.includes(file.type)) {
          const message = this.translate.instant("SnackBarConstants.INVALID_FILE_FORMAT");
          this.commentService.addSnackBar.emit(message);
          return;
        }
        
        // Validate file size
        if (file.size > maxSizeInBytes) {
          const message = this.translate.instant("SnackBarConstants.FILE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }
        
        // Proceed with reading and uploading the file
        const reader = new FileReader();
        this.newDocument = file;
        reader.readAsDataURL(file);
        reader.onload = async (e) => {
          if (e.target) {
            const urlNew = e.target.result as string;
            try {
              const uploadURL = await this.uploadService.uploadDocument(this.passId, this.newDocument, this.widgetNumber);
              if (uploadURL) {
                this.mediaDetailsForm.get("linkURL")?.setValidators([Validators.required, Validators.pattern(this.urlRegexDoc)]);
                this.linkUrlTransform = uploadURL;
                this.mediaDetailsForm.patchValue({ linkURL: this.linkUrlTransform });
              }
            } catch (error) {
              console.error(error);
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
              this.commentService.addSnackBar.emit(message);
            }
          }
        };
      }
    }
    

    saveChanges(){
      const data = this.mediaDetailsForm.value

      if(data.linkURL=='https://'){
        data.linkURL = undefined
      }

      removeUndefinedValuesFromObject(data)

      this.dialogRef.close([data, true])
    }
    cancelChanges(){
      this.dialogRef.close([this.oldData, false])

    }
}
