import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FileHandle } from '../directive/drag-and-drop.directive';
import { MediaDialogHandlerComponent } from '../media-dialog-handler/media-dialog-handler.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from '../service/comments.service';
import { ImageCompressionService } from '../service/image-compression.service';
import { UploadService } from '../service/upload.service';
import { BillboardImageEditComponent } from '../widgets/editWidgets/billboard-image-edit/billboard-image-edit.component';
import { DragFileBrowseData } from '../shared/media';
import { TextEditComponent } from '../widgets/editWidgets/text-edit/text-edit.component';
import { ComposedEditComponent } from '../widgets/editWidgets/composed-edit/composed-edit.component';
import { BillboardVideoEditComponent } from '../widgets/editWidgets/billboard-video-edit/billboard-video-edit.component';
import { ImagesEditComponent } from '../widgets/editWidgets/images-edit/images-edit.component';
import { Model3dEditComponent } from '../widgets/editWidgets/model3d-edit/model3d-edit.component';
import { ProductRegistrationEditComponent } from '../widgets/editWidgets/product-registration-edit/product-registration-edit.component';
import { ProductsEditComponent } from '../widgets/editWidgets/products-edit/products-edit.component';
import { SplashVideoEditComponent } from '../widgets/editWidgets/splash-video-edit/splash-video-edit.component';
import { VideoEditComponent } from '../widgets/editWidgets/video-edit/video-edit.component';
import { ImageEditComponent } from '../widgets/editWidgets/image-edit/image-edit.component';
import { CallToActionEditComponent } from '../widgets/editWidgets/call-to-action-edit/call-to-action-edit.component';

@Component({
  selector: '[app-drag-file-browse-ui]',
  templateUrl: './drag-file-browse-ui.component.html',
  styleUrls: ['./drag-file-browse-ui.component.css']
})

export class DragFileBrowseUIComponent {
  @Input('app-drag-file-browse-ui') data:DragFileBrowseData;
  @Output() returnChanges = new EventEmitter<DragFileBrowseData>()
  // @Output() returnChanges = new EventEmitter<{calledFrom: any, calledFromData:any,widget: any, enableDragDrop:boolean, enableBrowse: boolean, enableMedia:boolean, enableMultipleDragDrop:boolean, supportedFileTypes:string[],supportedFileAccept:string, maxSizeInBytes:number,maxAllowedSizeInBytes:number, returnSupportUrl:string,returnUrlData:string, enterUrl:boolean}>()

  addUrl: boolean
  urlData: any
  url: any
  changes: boolean
  noUrl: boolean
  newImage: any
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  files: FileHandle[] = []
  dragFileBrowseForm!:FormGroup
  maxAllowedSizeInBytes:number
  maxSizeInBytes:number
  returnUrlType = undefined
  constructor(public dialogRef: MatDialogRef<DragFileBrowseUIComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private commentService:CommentsService,
    private imageCompressionService: ImageCompressionService,
    private translate: TranslateService,
    private uploadService: UploadService){}


  ngOnInit(): void{
    console.log(this.data)    
  }

  ngOnChanges() {
    const {calledFrom, calledFromData, enableDragDrop, enableBrowse, enableMedia, enableEnterUrl,enableMultipleDragDrop, 
      supportedFileTypes,supportedFileAccept, maxSizeInBytes,maxAllowedSizeInBytes, 
      returnSupportUrl,returnUrlData, enterUrl, returnUrlType} = this.data

    this.dragFileBrowseForm = this.fb.group({
      calledFrom: [calledFrom],
      calledFromData: [calledFromData],
      enableDragDrop:[enableDragDrop],
      enableBrowse: [enableBrowse],
      enableMedia:[enableMedia],
      enableEnterUrl:[enableEnterUrl],
      enableMultipleDragDrop: [enableMultipleDragDrop],
      supportedFileTypes:[supportedFileTypes],
      supportedFileAccept:[supportedFileAccept],
      maxSizeInBytes:[maxSizeInBytes],
      maxAllowedSizeInBytes:[maxAllowedSizeInBytes],
      returnSupportUrl:[returnSupportUrl],
      returnUrlData:[returnUrlData],
      enterUrl:[enterUrl],
      returnUrlType: [returnUrlType]
    })

    this.dragFileBrowseForm.valueChanges.subscribe( value => {
      this.returnChanges.emit(value)
    })
  }
  typeURL(){
    // this.addUrl = true
    this.urlData = './../../assets/img/default.jpg'
    this.dragFileBrowseForm.patchValue({returnUrlData: this.urlData, enterUrl: true})
  }

  browserFromMediaFolder(){
    let dialogRef= this.dialog.open(MediaDialogHandlerComponent, {
      data: {
        supportedFiles: this.data.supportedFileTypes
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if(result[0]){
        const url = result[1]
        const supportUrl = result[2]
        
        this.url = url
        this.urlData = supportUrl
        this.changes = true
        this.dragFileBrowseForm.patchValue({ returnSupportUrl: this.url , returnUrlData: this.urlData, returnUrlType: 'media'})
        this.noUrl = false
      }
    });
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        if (e.target) {
          const urlNew = e.target.result as string;
          this.returnUrlType = 'browse'
          if (urlNew) {
            this.urlData = urlNew;
            if(this.data.calledFrom == BillboardVideoEditComponent || this.data.calledFrom == SplashVideoEditComponent || this.data.calledFrom == VideoEditComponent)
              this.compressVideoFileAndUpload(file, this.data.calledFromData.widgetNumber,this.urlData)
            else
            this.compressFileAndUpload(file, this.data.calledFromData.widgetNumber,this.urlData)
          }
        }
      };
    }
  }  
  
  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  async filesDropped(files: FileHandle[]): Promise<void> {
    this.files = files;
    if(this.data.enableDragDrop){
      if(this.data.enableMultipleDragDrop){
        files.forEach( (file:any, index:any)=> {
          console.log(file.file.type)
          const urlData = file.url;
          console.log(this.data.supportedFileTypes.includes(file.file.type))
          if(this.data.supportedFileTypes.includes(file.file.type))
          this.compressFileAndUpload(file.file, index,urlData)
          else{
            const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_FILE")
            this.commentService.addSnackBar.emit(message)
          }
        })
        this.returnUrlType = 'dragDrop'
  
      }else{
        const fileToUpload = files[0].file;
        const urlData = files[0].url;
        this.returnUrlType = 'dragDrop'
        console.log(fileToUpload.type)
  
        if(this.data.calledFrom == BillboardVideoEditComponent || this.data.calledFrom == SplashVideoEditComponent || this.data.calledFrom == VideoEditComponent){
          const type = fileToUpload.type
          const subtype = type.match(/video\/(.+)/)
          console.log(subtype)
          if( subtype)
          this.compressVideoFileAndUpload(fileToUpload, 0,urlData)
          else{
            const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_FILE")
            this.commentService.addSnackBar.emit(message)
          }
  
        }else{
          if(this.data.supportedFileTypes.includes(fileToUpload.type))
            this.compressFileAndUpload(fileToUpload, 0,urlData)
          else{
            const message = this.translate.instant("SnackBarConstants.UNSUPPORTED_FILE")
            this.commentService.addSnackBar.emit(message)
          }
        }
      }
    }
    
  }

  // private processFile(file: File): void {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = async (e) => {
  //     if (e.target) {
  //       const urlNew = e.target.result as string;
  
  //       if (urlNew) {
  //         this.url = urlNew;
  //         try {
  //           const uploadURL = await this.uploadService.uploadNewBillboardImage(this.passId, this.newImage, this.widgetNumber);
  //           this.urlData = "";
  
  //           if (uploadURL) {
  //             this.url = uploadURL;
  //             this.urlData = urlNew;
  //           }
  
  //           this.changes = true;
  //           this.billboardImgEditForm.patchValue({ url: this.url });
  //           this.noUrl = false;
  //         } catch (error) {
  //           console.error(error);
  //           const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
  //           this.commentService.addSnackBar.emit(message);
  //         }
  //       }
  //     }
  //   };
  // }



  ////-----------------
  async compressFileAndUpload(file, index, urlData){
    const fileToUpload = file;
    const maxSizeInBytes = this.maxSizeInBytes
    const maxAllowedSizeInBytes = this.maxAllowedSizeInBytes
  
    if (maxAllowedSizeInBytes && (fileToUpload.size > maxAllowedSizeInBytes)) {// File is too large for both upload and compression
      const message = this.translate.instant("SnackBarConstants.IMAGE_FILE_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    }
  
    if (maxSizeInBytes && (fileToUpload.size > maxSizeInBytes)) {// File is between 2 MB and 20 MB, attempt compression
      try {
        const compressedImage = await this.imageCompressionService.compressImage(fileToUpload, maxSizeInBytes);
        if (!compressedImage) {
          const message = this.translate.instant("SnackBarConstants.IMAGE_TOO_LARGE");
          this.commentService.addSnackBar.emit(message);
          return;
        }else{
          await this.uploadFile(compressedImage, index, urlData)
        }
      } catch (error) {
        console.error("Compression error:", error);
        const message = this.translate.instant("SnackBarConstants.IMAGE_COMPRESSION_FAILED");
        this.commentService.addSnackBar.emit(message);
        return;
      }
    } else {// File is less than or equal to 2 MB, no compression needed
      await this.uploadFile(fileToUpload, index, urlData)
    }
  }


  async compressVideoFileAndUpload(file, index, urlData){
    const fileToUpload = file;
    const maxSizeInBytes = this.maxSizeInBytes
  
    if (file.size > maxSizeInBytes) {
      const message = this.translate.instant("SnackBarConstants.VIDEO_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    } else {// File is less than or equal to 2 MB, no compression needed
      await this.uploadFile(fileToUpload, index, urlData)
    }
  }


  async uploadFile(file, index, urlData){
    try{
      const passId = this.data.calledFromData.passId
      const widgetNumber = this.data.calledFromData.widgetNumber
      this.commentService.progressSpin.emit(true)
    
      switch(this.data.calledFrom){
        case BillboardImageEditComponent:{
          console.log('-----BillboardImageEditComponent')
          const uploadURL = await this.uploadService.uploadNewBillboardImage(passId, file, widgetNumber);
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = urlData;
            this.dragFileBrowseForm.patchValue({returnUrlData: urlData, returnSupportUrl: uploadURL, returnUrlType: this.returnUrlType})
          }
          break;
        }
  
        case BillboardVideoEditComponent:{
          console.log('-----BillboardVideoEditComponent', file, index, urlData)
          const uploadURL = await this.uploadService.uploadNewBillboardVideo(this.data.calledFromData.passId, [file], this.data.calledFromData.widgetNumber);
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = urlData;
          }
          this.dragFileBrowseForm.patchValue({returnUrlData: urlData, returnSupportUrl: uploadURL})

          break;
        }
  
        case TextEditComponent:{
          console.log('-----TextEditComponent')
          const uploadURL = await this.uploadService.uploadNewTextImage(this.data.calledFromData.passId, file, this.data.calledFromData.widgetNumber);
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = urlData;
            this.dragFileBrowseForm.patchValue({returnUrlData: urlData, returnSupportUrl: uploadURL})
          }

          break;
        }

        case ProductRegistrationEditComponent:{
          console.log('-----ProductRegistrationEditComponent')

          const uploadURL = await this.uploadService.uploadNewProductRegistrationImage(passId, file, widgetNumber);
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = urlData;
            this.dragFileBrowseForm.patchValue({returnUrlData: urlData, returnSupportUrl: uploadURL, returnUrlType: this.returnUrlType})
          }
          break;
        }
  
        case VideoEditComponent:{
          console.log('-----VideoEditComponent')
          const uploadURL = await this.uploadService.uploadNewVideo(passId, [file], widgetNumber);
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = urlData;
            this.dragFileBrowseForm.patchValue({returnUrlData: urlData, returnSupportUrl: uploadURL, returnUrlType: this.returnUrlType})
          }
          break;
        }
  
        case SplashVideoEditComponent:{
          console.log('-----SplashVideoEditComponent')
          const uploadURL = await this.uploadService.uploadNewSplashVideo(this.data.calledFromData.passId, [file], this.data.calledFromData.widgetNumber);
          if (uploadURL) {
            this.url = uploadURL
            this.urlData = urlData
          }
          this.dragFileBrowseForm.patchValue({returnUrlData: urlData, returnSupportUrl: uploadURL})

          break;
        }
        case ImageEditComponent:{
          console.log('-----ImageEditComponent')

          const uploadURL = await this.uploadService.uploadImage(passId, file, widgetNumber);
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = urlData;
            this.dragFileBrowseForm.patchValue({returnUrlData: urlData, returnSupportUrl: uploadURL})
          }

          break;
        }
  
        case ImagesEditComponent:{
          console.log('-----ImagesEditComponent', file, passId, index, widgetNumber)
          const uploadURL = await this.uploadService.uploadNewImages(passId, file, index, widgetNumber);
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = urlData;
            this.dragFileBrowseForm.patchValue({returnUrlData: urlData, returnSupportUrl: uploadURL, returnUrlType: this.returnUrlType})

          }

          break;
        }

        case CallToActionEditComponent :{
          console.log('---------CallToActionEditComponent')
          const uploadURL = await this.uploadService.uploadNewCallToActionImage(passId,file, widgetNumber);
  
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = urlData;
            this.dragFileBrowseForm.patchValue({returnUrlData: urlData, returnSupportUrl: uploadURL, returnUrlType: this.returnUrlType})
          }
          break;
        }
    
      }
      setTimeout(() =>{
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.UPLOAD_FILE_OK")
        this.commentService.addSnackBar.emit(message)
      }, 800);
    }catch(error){
      console.log(error)
      const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }
}
