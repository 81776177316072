import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import {routes} from '../routes';
import { CommentsService } from '../service/comments.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../service/storage.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-finished-tasks-item',
  templateUrl: './finished-tasks-item.component.html',
  styleUrls: ['./finished-tasks-item.component.css']
})
export class FinishedTasksItemComponent implements OnInit{
  routerUrl:any
  idToShow:any
  finishedTask:any
  sections:any
  template:any
  locationCoordinates:any
  titleTask:any
  previousPage:string
  routesNames=routes
  workTasksUserRights_delete:any
  workTaskMedia:any
  docs:any ={}
  reports:any
  tridys:any
  contentTypesList:any
  tridysList:any
  incomingTridys: any
  outgoingTridys: any
  allTridysList:any
  tridyId:any

  constructor(private router: Router,
    private route: ActivatedRoute,
    private settingService: SettingsService,
    private commentService: CommentsService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private storage: StorageService,
    private location:Location,
    private settingsService: SettingsService
    ){
    this.routerUrl=this.route.url
    this.reports = this.routerUrl.value.find( route => route.path=='reports')
    this.tridys = this.routerUrl.value.find( route => route.path=='tridy')
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }

  ngOnInit():void{
    this.getContent()

    if(this.settingsService.settingsContentTypesWithId?.length==0){
      this.getContentTypes()
    }else{
      this.contentTypesList = this.settingsService.settingsContentTypesWithId
    }
    this.workTasksUserRights_delete = this.settingsService.modulesRolesRights$.value['workTask']['D']

    this.settingsService.modulesRolesRights$.subscribe( value => {
      this.workTasksUserRights_delete = this.settingsService.modulesRolesRights$.value['workTask']['D']
    })


    if(this.settingService.allTridys$.length!=0){
      this.allTridysList = this.settingService.allTridys$
    }else{
      this.settingService.observeTridysFirestore()
      this.allTridysList = this.settingService.allTridys$
    }

    if(!this.workTasksUserRights_delete){
      document.getElementById("btn-delete").style.color="#e0e0e0"
    }
    this.previousPage = this.routerUrl.value[this.routerUrl.value.length-2].path 
    if(this.reports && this.tridys){
      this.previousPage=''
      this.routerUrl.value.forEach((route, index) => {
        if(index>=2 && index < this.routerUrl.value.length-2){
          this.previousPage += this.routerUrl.value[index].path +'/'
          
        }
      }); 
      // this.previousPage = this.routerUrl.value[this.routerUrl.value.length-3].path 
    }
    if(this.routerUrl.value[this.routerUrl.value.length-2].path=="tridy"){
    }
    this.storage.workTaskItemAllValues$.subscribe(value => {
      this.docs = value
    })
  }

  async getContent(){
    try{
      this.commentService.progressSpin.emit(true)
      this.finishedTask = await this.settingService.observeContextsWorkTasksFirestore(this.idToShow)
      this.tridyId = this.finishedTask?.tridyId
      this.commentService.progressSpin.emit(false)
    }catch(error){
      this.commentService.progressSpin.emit(false)
    }
 
    if(this.finishedTask.title){
      this.titleTask=this.finishedTask.title
    }

    if(this.finishedTask.template){
      this.template = this.finishedTask.template
      this.sections = this.finishedTask.template.formSections

      if(this.sections){
        this.sections.forEach( section => {
          if(section.fields){
            section.fields.forEach( field => {
              if(field.type=="document" || field.type=="signature"){
                if(field.key){
                  const newdoc = this.getStorageDocFolder(this.idToShow, this.finishedTask.tridyId, field.key )
                }
              }
            })
          }
        })
      }
    }else{
      this.template = []
      this.sections = undefined
    }

    this.locationCoordinates = this.finishedTask.locationCoordinates
  }

  backTo(){
    this.route.params.subscribe( (route_:ActivatedRoute) => {
      const contextId = this.settingService.contextId$
      if(route_ && route_['finishedTaskId'] && route_['tridyId'] && !route_['reportId']){
        this.router.navigate(['home', contextId, 'tridys', route_['tridyId'] ])
      }
  
      if(route_ && !route_['tridyId'] && route_['finishedTaskId'] && !route_['reportId']){
        this.router.navigate(['home', contextId, 'finishedTasks' ])
      }

      if(route_ && route_['tridyId'] && route_['finishedTaskId'] && route_['reportId']){
        this.router.navigate(['home', contextId, 'reports', route_['reportId'] , 'tridys', route_['tridyId'] ])
      }
    })
    
    // this.location.back()
  }

  convertDateTime(element, style){
    let value 

    switch(style){
      case 'date':{
        value = this.isDate(element)
        break;
      }

      case 'time':{
        value = this.isTime(element)

        break;
      }

      case 'dateTime':{
        value = this.isDateTime(element)

        break;
      }
    }

    return value
  }

  isDateTime(element){
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleString('de-DE')
      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleString('de-DE')
      }

      try{
        const newdate = new Date(element)
        if(newdate)
            dateValue = newdate.toLocaleString('de-DE')
      }catch(errro){}
    }
   return dateValue
  }

  isDate(element){
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
      }

      try{
        const newdate = new Date(element)
        if(newdate)
            dateValue = newdate
            dateValue = newdate.toLocaleDateString('de-DE',{year: 'numeric', month: '2-digit', day:'2-digit'})
      }catch(errro){}
    }
   return dateValue
  }

  isTime(element){
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })

      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })

      }
      try{
        const newdate = new Date(element)
        if(newdate)
            dateValue = newdate.toLocaleTimeString('de-DE', { hour: "2-digit", minute: "2-digit" })
      }catch(errro){}
    }
   return dateValue
  }

  getContentTypes(){
    try{
      this.settingsService.observeContextsContentTypesFirestoreWithId(this.settingsService.contextId$).subscribe(value => {
        this.contentTypesList = value
      })
    }catch(error){
      console.log(error)
    }
  }

  getStorageDocFolder( id ,tridyId, key){
    let hostname = environment.urlHostName
    let firebaseUrl = `https://${hostname}/v1/pass/${tridyId}/wt-${this.idToShow}/`

    const route = `passes/${tridyId}/wt-${this.idToShow}`
    try{
      const media = this.storage.getStorageWorkTaskFolder(route, tridyId, key, hostname)
      
      setTimeout(() => {

        if(this.storage.workTaskItem[key] && this.storage.workTaskItem[key]['item']){
          firebaseUrl += this.storage.workTaskItem[key]['item']
          this.docs[key] = firebaseUrl
        }

        if(this.storage.workTaskItem[key] && this.storage.workTaskItem[key]['folder']){
          firebaseUrl += this.storage.workTaskItem[key]['folder']
          const media = this.storage.getStorageWorkTaskFolder(this.storage.workTaskItem[key]['folder'], tridyId, key, hostname)
          this.docs[key] = firebaseUrl
        }
      }, 1000);
      
    }catch(error){}
  }

  getContentTypeIdName(id){
    const content =  this.contentTypesList.find( ct => ct.id == id)
    if(content && content.displayName)
    return content.displayName
  }

  getContentTypeIdData(id, field){
    const content =  this.contentTypesList.find( ct => ct.id == id)
    if(content && content[field])
    return content[field]
  }

  getTRIDYIdData(tridyId, field){
    if(this.allTridysList){
      const tridy = this.allTridysList.find( id => tridyId == id.id)
      if(tridy && tridy[field]){
        return tridy[field]
      }
    }
  }

  async getTridysList(id){
    try{
      this.tridysList = await this.settingService.observeTridysIdFinishedTasksFirestore(id)
    }catch(e){}
  }


  redirectTo(trids){
    if(trids.tridyId){
      const contextId = this.settingService.contextId$
      let tridyId = trids.tridyId
      // this.router.navigate(['home', this.settingService.contextId$,'finishedTasks', this.idToShow, 'tridys', tridyId])

      this.route.params.subscribe( (route_:ActivatedRoute) => {
        if(route_ && route_['finishedTaskId'] && route_['tridyId'] && !route_['reportId']){
          this.router.navigate(['home', contextId, 'tridys', tridyId ])
        }
    
        if(route_ && !route_['tridyId'] && route_['finishedTaskId'] && !route_['reportId']){
          this.router.navigate(['home', contextId ,'finishedTasks' , route_['finishedTaskId'], 'tridys', tridyId ])
        }

        if(route_ && !route_['tridyId'] && route_['finishedTaskId'] && route_['reportId']){
          this.router.navigate(['home', contextId ,'reports',  route_['reportId'], 'tridys', tridyId ])
        }

        if(route_ && route_['tridyId'] && route_['finishedTaskId'] && route_['reportId']){
          this.router.navigate(['home', contextId,'reports',  route_['reportId'], 'tridys', tridyId ])
        }
      })

    }    
  }

  fileIsPDF(key){
    try{
      if(key && this.docs[key]){
        const url = this.docs[key]
        if(url?.endsWith('.pdf'))
          return true
        else
          return false
      }else{
        return false
      }
    }catch(e){
      return false
    }
  }
}