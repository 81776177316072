import { Component, Injectable, ViewChild } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { ProductsService } from '../service/products.service';
import { CommentsService } from '../service/comments.service';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();
  constructor(private translate: TranslateService){}

  firstPageLabel = this.translate.instant("first_page");
  itemsPerPageLabel = this.translate.instant("items_page");
  lastPageLabel =this.translate.instant("last_page")
  nextPageLabel = this.translate.instant("next_page");
  previousPageLabel = this.translate.instant("previous_page");

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.instant("page1_1");
    }
    const amountPages = Math.ceil(length / pageSize);
     
    return this.translate.instant("page_of_1") + (page + 1) +this.translate.instant("page_of_2") + amountPages;
  }
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  providers: [{provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl}],
})
export class ProductsComponent {
  productsList:any = []
  description:any=[]
  searchText=""
  displayedColumns: string[] = ['title', 'creationDate', 'timeStamp'];
  productsUserRights_create:any
  dataSource = new MatTableDataSource(this.productsList);

  listdata= new MatTableDataSource()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paginatorSize = this.settingsService.paginator$
  
  constructor(private settingsService: SettingsService,
    private productsService: ProductsService,
    private commentService: CommentsService,
    private router: Router,
    private translate: TranslateService){

  }

  ngOnInit():void{
    this.productsUserRights_create = this.settingsService.modulesRolesRights$.value['products']['C']
    this.settingsService.modulesRolesRights$.subscribe( value => {
      this.productsUserRights_create = this.settingsService.modulesRolesRights$.value['products']['C']
    })

    this.productsList =[]
    try{
      this.commentService.progressSpin.emit(true)
      this.productsList = this.settingsService.observeContextsItemsFirestore()
      this.listdata = new MatTableDataSource<any>(this.productsList)
    }catch(error){
      console.log(error)
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }

  }
  ngAfterViewInit(): void {
    this.listdata.paginator = this.paginator;
    this.listdata.sort = this.sort;
    setTimeout(() => {
      this.listdata.filter = " ";
      this.commentService.progressSpin.emit(false)
    }, 500);
  }

  createNewProduct(){
    try{
      this.commentService.progressSpin.emit(true)
       const id = this.productsService.createProduct()
       id.then( value => {
        this.router.navigate(['home', this.settingsService.contextId$,'products', value])
       })
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.CREATE_OK")
      this.commentService.addSnackBar.emit(message)
    }catch(error){
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.CREATE_FAILED")
      this.commentService.addSnackBar.emit(message)
      console.log(error)
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.listdata.filter = filterValue.trim().toLowerCase();

    if (this.listdata.paginator) {
      this.listdata.paginator.firstPage();
    }
  }
}
