import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SplashVideoEditComponent } from '../../editWidgets/splash-video-edit/splash-video-edit.component';
import { environment } from 'src/environments/environment';
import { supportUrlStructure } from '../../../../../../backend/utils/object';
import { SettingsService } from 'src/app/service/settings.service';
//import { checkDynamicContent } from '../../../../../backend/utils/object';

@Component({
  selector: '[app-splash-video-phone]',
  templateUrl: './splash-video-phone.component.html',
  styleUrls: ['./splash-video-phone.component.css']
})
export class SplashVideoPhoneComponent {
  @Input('app-splash-video-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any, images:any}>()

  routerUrl:any
  idToShow:any
  editMode:any
  splashVideoData:any
  widgetNumber:any
  noUrl=false
  videoToStorage:any=[]
  url:any
  urlData:any
  dynamic = false
  passId:any

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    private settingsService: SettingsService
    ){
      this.routerUrl=this.router.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
  }


  ngOnInit(): void{
    this.splashVideoData=this.data[0]
    this.editMode=this.data[1]
  
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }

    if(!this.splashVideoData.url){
      this.noUrl=true
    }else{
      this.url = this.splashVideoData.url
      this.noUrl = false
      try{
        const partsd = this.splashVideoData.url.includes("${")
        if(partsd){
          //this.splashVideoData.url = checkDynamicContent(this.splashVideoData.url)
          this.dynamic = true
        }

        if(this.splashVideoData.url ){
          let url = supportUrlStructure(this.splashVideoData.url, this.idToShow, this.settingsService.contextId$)
          if(url)
          this.urlData = url
        }
        // const imgUrl = new URL(this.splashVideoData.url)
        // let hostname = environment.urlHostName
       
        // if(imgUrl.protocol=="pass:" ){
        //   const parts = this.splashVideoData.url.match(/pass:\/\/(.+)\/(.+)/)
        //   if(parts){
        //     const passTemplateID = parts[1]
        //     const path = parts[2]
        //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
        //     this.urlData=firebaseUrl
        //   }else{
        //     const newparts = this.splashVideoData.url.match(/pass:\/\/(.+)/)
        //     if(newparts){
        //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
        //       newparts.forEach( (part,index)=> {
        //         if(index>0){
        //           firebaseUrl += '/' + part 
        //         }
        //       })
        //       this.urlData=firebaseUrl
        //     }
        //   }
        // }
  
        // if(imgUrl.protocol =="tridy:"){
        //   const parts = this.splashVideoData.url.match(/tridy:\/\/(.+)\/(.+)/)
        //   let path =""
        //   parts.forEach((parte, index) =>{
        //     if(index>0 && index<parts.length){
        //       path += "/" + parte
        //     }
        //   })
        //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
        //   this.url=firebaseUrl
        // }

      }catch(error){}
    }

  }

  openDialogEdit():void{
  let dialogRef = this.dialog.open(SplashVideoEditComponent, {
    data:{
      values: this.splashVideoData,
      widget: this.widgetNumber,
      passId: this.idToShow,
      url:this.urlData
    },
  });

  dialogRef.afterClosed().subscribe(result => {
   
    if(result[1]){
      this.splashVideoData=result[0]
      this.url=result[0].url
      if(result[2].length!=0){
        this.videoToStorage.push(result[2])
      }
    this.returnChanges.emit({newdata: this.splashVideoData, widget: this.widgetNumber, widgetType: this.splashVideoData.type,images: this.videoToStorage})

    }

  })
}


}
