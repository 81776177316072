<section class="container">
    <div class="row mt-4">
        <div class="mt-3 mb-2">
            <h1 class="mb-1">{{'taskTemplateGropus.title' | translate}}</h1>
            <p style="font-family:'MyFont-Light';">{{'headlines.subTracerGroups' | translate}}</p>
        </div>
        <div class="col-12">
            <section class="topNavbar d-flex flex-row flex-wrap justify-content-between">
                <button mat-button 
                        class="orange-button" 
                        id="btn-create" 
                        (click)="addGroup();" 
                        [disabled]="!tasktemplateUserRights_create"
                >
                    <mat-icon>add_circle</mat-icon> {{'taskTemplateGropus.add_group' | translate}}
                </button>
            </section>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            
            <mat-card class="mt-3">
                <mat-tab-group>
                    <mat-tab label="Groups">
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon">group_work</mat-icon>
                            {{'taskTemplateGropus.groups' | translate}}
                          </ng-template>
                        <div class="expansion-panel-content p-4">

                            @if(allGroups.length == 0) {
                            <div class="empty-list p-5 my-4 justify-content-center text-center">
                                <span class="text-muted">{{'taskTemplateGropus.no_groups' | translate}}</span>
                            </div>
                            }
                            <mat-accordion multi class="mt-3 form-field-section">
                                @for(group of allGroups; track group;let index = $index) {
                                <mat-expansion-panel expanded>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span class="text-wrap text-break">{{ group.title }}</span>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <button mat-icon-button [matMenuTriggerFor]="editDelete" style="color:var(--warmOrange);" (click)="$event.stopPropagation()">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #editDelete="matMenu">
                                                <button mat-menu-item (click)="renameGroup(group, index);"><mat-icon>edit</mat-icon> {{"taskTemplates.editSectionTitle" | translate}}</button>
                                                <button mat-menu-item (click)="deleteGroup(group, index);">
                                                    <mat-icon class="material-symbols-outlined delete">delete</mat-icon> {{"delete" | translate}}
                                                </button>
                                            </mat-menu>
                                            
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="expansion-panel-content">
                                        <div cdkDropList class="shadow-sm rounded" (cdkDropListDropped)="drop($event, group.id)">
                
                                            <div cdkDrag *ngFor="let task of getTasksForGroup(group.id)" id="fields-list">
                                                
                                                <div class="group-task-item d-flex flex-row flex-nowrap px-3 justify-content-between">
                                                    <div class="py-3 d-flex flex-column justify-content-center">
                                                        <span class="text-break text-wrap">{{task.title}}</span>
                                                        <!-- <span>{{task.sortIndex}}</span>
                                                        <span>{{task.id}}</span> -->
                                                    </div>
                                                    <!-- add deep link vor the template -->
                                                    <!-- <button mat-icon-button matTooltip="Remove from the group" class="remove-from">
                                                        <span class="material-symbols-outlined">
                                                            do_not_disturb_on
                                                        </span>
                                                    </button> -->
                                                </div>
                                            </div>
                                        </div>
                
                                    </div>
                                
                                </mat-expansion-panel>
                                }
                            </mat-accordion>
                        </div>
                    </mat-tab>
                    <mat-tab label="Tasks">
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon">view_list</mat-icon>
                            {{'taskTemplates.ungrouped' | translate}}
                          </ng-template>
                        <div class="p-4">
                            <div class="empty-list p-5 my-4 justify-content-center text-center" *ngIf="taskTemplates?.length == 0">
                                <span class="text-muted">{{'empty_templates' | translate}}</span>
                            </div>

                            @for(task of taskTemplates; track task;) {
                            <div id="others-list" [hidden]="task.groupId">
                                <div class="task-item d-flex flex-row flex-nowrap px-3 justify-content-between">
                                    <div class="py-3 d-flex flex-column justify-content-center" matTooltip="{{task.id}}">
                                        <span class="text-break text-wrap">{{task.title}}</span>
                                    </div>
                                    <!-- <button mat-icon-button [matMenuTriggerFor]="addToGroupMenu" matTooltip="Add to group" class="add-to">
                                        <span class="material-symbols-outlined">
                                            add_circle
                                        </span>
                                    </button>
                                    <mat-menu #addToGroupMenu="matMenu">
                                        <button mat-menu-item *ngFor="let group of allGroups" (click)="addTaskToGroup(task.id, group.id);"> {{group.title}} </button>
                                    </mat-menu> -->
                                </div>
                            </div>
                            }
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card>
            
        </div>
    </div>
</section>