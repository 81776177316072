<section class="topNavbar py-3 px-4 sticky-top" id="editNavbar">
    <div class="row align-items-center">
        <div class="col-md-3 col-lg-3 d-flex my-2 my-md-auto text-white flex-nowrap align-content-center align-items-center">
            <span class="material-symbols-outlined">translate</span>
            <span class="component-headline fw-light">{{ 'translationMode' | translate }}</span>
        </div>
        <!-- desktop -->
        <div class="col-9 d-none d-lg-flex justify-content-end align-items-center">
            <div class="text-center">
                <span class="text-white me-3">{{'pass.selectLanguage' | translate}}</span>
                <button mat-stroked-button [matMenuTriggerFor]="languageMenu" class="language-button">
                    <div class="d-flex flex-row align-items-center">
                        <span class="px-2">{{languagesList[languageSelected]}}</span>
                        <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
                    </div>
                </button>
                <mat-menu #languageMenu="matMenu">
                    <div *ngFor="let lang of languagesToTranslate" >
                        <button mat-menu-item *ngIf="lang != passFirstLanguage" (click)="changePassLanguage(lang)" [disabled]="lang === languageSelected">
                            <span [ngStyle]="{'color': lang === languageSelected ? 'var(--warmOrange)' : 'inherit'}">{{languagesList[lang]}}</span>
                        </button>

                        <!-- <button mat-menu-item *ngIf="lang == passFirstLanguage" (click)="changePassLanguage(lang)" disabled>
                            <span [ngStyle]="{'color': lang === languageSelected ? 'var(--warmOrange)' : 'inherit'}">{{languagesList[lang]}}</span>
                        </button> -->
                    </div>
                </mat-menu>
            </div>
            <div class="vr custom-vr d-none mx-4 d-md-inline-block"></div>
            <div class="d-flex align-items-center justify-content-end me-2">
                <button mat-button class="orange-button" (click)="addTranslations()">{{'done' | translate}}</button>
                <!-- <button mat-icon-button style="color:white;" (click)="closeTranslations()"><mat-icon>close</mat-icon></button> -->
            </div>
        </div>
        <!-- mobile -->
        <div class="col-md-9 d-lg-none d-flex align-items-center justify-content-end">
            <div class="text-center me-5">
                <button mat-button [matMenuTriggerFor]="languageMenu" class="language-button" style="color:var(--warmOrange);">
                    <div class="d-flex flex-row align-items-center">
                        <mat-icon>language</mat-icon>
                        <span class="text-uppercase px-2">{{languagesList[languageSelected]}}</span>
                        <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
                    </div>
                </button>
        
                <mat-menu #languageMenu="matMenu">
                    <div *ngFor="let lang of languagesToTranslate" >
                        <button mat-menu-item *ngIf="lang != passFirstLanguage" (click)="changePassLanguage(lang)" [disabled]="lang === languageSelected">
                            <span [ngStyle]="{'color': lang === languageSelected ? 'var(--warmOrange)' : 'inherit'}">{{languagesList[lang]}}</span>
                        </button>

                        <button mat-menu-item *ngIf="lang == passFirstLanguage" (click)="changePassLanguage(lang)" disabled>
                            <span [ngStyle]="{'color': lang === languageSelected ? 'var(--warmOrange)' : 'inherit'}">{{languagesList[lang]}}</span>
                        </button>
                    </div>
                </mat-menu>
            
            </div>
            <div class="d-flex align-items-center justify-content-end">
                <button mat-button class="cancel-editing" (click)="addTranslations()">{{'add' | translate}}</button>
                <!-- <button mat-icon-button style="color:white;"(click)="closeTranslations()" ><mat-icon>close</mat-icon></button> -->
            </div>
        </div>
    </div>
</section>

<!-- mobile-message -->
<div class="row p-3 d-md-none" style="background-color: #dd2f2f;color:white">
    <div class="col-1 d-flex align-items-center align-content-center"><span class="material-symbols-outlined">error</span></div>
    <div class="col-11">{{'mobile_info' | translate}}</div>
</div>

<div class="container">
    <div class="row mt-4">
        <div class="col-xs-6 col-md-6">
            <div class="d-flex mobile-wrapper justify-content-center align-self-center" [class.full-view]="phoneFullHeightView">
                <section class="mobile">
                    <div class="d-flex mb-3 justify-content-center align-items-center">
                        <h2 class="text-center mb-0 px-2">{{'content.preview' | translate}} <span class="text-uppercase">({{languageSelected}})</span></h2>
                    </div>

                    <div class="mobile-frame-container"></div>
                    <div class="mobile-frame">
                        <!-- tablet -->
                        <div *ngIf="!this.isTablet">
                            <div *ngFor="let elem of allWidgets; let isFirst = first; let index = index;" 
                                    [ngClass]="{'first-widget': isFirst}" style="padding-top: var(--widgetSpacing);" [attr.id]="'widget-' + elem.id">
                        
                                <div *ngIf="elem.type=='annotatedMap'">
                                    <div [app-annotated-map-phone]="[elem,editTemp,index,templateForm.value.translations,languagesPassList]"></div>
                                </div>

                                <div *ngIf="elem.type=='buttons'">
                                    <div [app-buttons-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='billboardImage'">
                                    <div [app-billboard-image-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='billboardVideo'">
                                    <div [app-billboard-video-phone]="[elem,editTemp, index]"></div>
                                </div>
                        
                                <div *ngIf="elem.type=='callToAction'">
                                    <div [app-call-to-action-phone]="[elem,editTemp,index]"></div>
                                </div>

                                <div *ngIf="elem.type=='form'">
                                    <div [app-form-phone]="[elem,editTemp, index,templateForm.value.translations,languagesPassList]"></div>
                                </div> 

                                <div *ngIf="elem.type=='image'">
                                    <div [app-image-phone]="[elem,editTemp, index,templateForm.value.translations,languagesPassList]"></div>
                                </div>    

                                <div *ngIf="elem.type=='images'" >
                                    <div [app-images-phone]="[elem,editTemp, index]"></div>
                                </div>

                                <div *ngIf="elem.type=='imageBlocks'">
                                    <div [app-images-phone]="[elem,editTemp, index]"></div>
                                </div> 
                                <div *ngIf="elem.type=='imagesHorizontal'">
                                    <div [app-images-phone]="[elem,editTemp, index]"></div>
                                </div>

                                <div *ngIf="elem.type=='instagram'">
                                    <div [app-instagram-phone]="[elem,editTemp]"></div>
                                </div>

                                <div *ngIf="elem.type=='list'">
                                    <div [app-list-phone]="[elem,editTemp, index]"></div>
                                </div>

                                <div *ngIf="elem.type=='products'">
                                    <div [app-products-phone]="[elem,editTemp,index]"></div>
                                </div>

                                <div *ngIf="elem.type=='productRegistration'">
                                    <div [app-product-registration-phone]="[elem,editTemp,index]"></div>
                                </div>

                                <div *ngIf="elem.type=='shopify'">
                                    <div [app-shopify-phone]="[elem,editTemp]"></div>
                                </div>

                                <div *ngIf="elem.type=='spacer'">
                                    <div [app-spacer-phone]="[elem,editTemp, index]"></div>
                                </div>

                                <div *ngIf="elem.type=='splashVideo'">
                                    <div [app-splash-video-phone]="[elem,editTemp,index]"></div>
                                </div>

                                <div *ngIf="elem.type=='text'">
                                    <div [app-text-phone]="[elem,editTemp,index,templateForm.value.translations,languagesPassList]"></div>
                                </div> 
                                <div *ngIf="elem.type=='video'">
                                    <div [app-video-phone]="[elem,editTemp,index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='3dmodel'">
                                    <div [app-model3d-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='countdown'">
                                    <div [app-countdown-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='composed'">
                                    <div [app-composed-phone]="[elem,editTemp, index]" ></div>
                                </div>
                            </div>
                            <p *ngIf="allWidgets.length==0" class="p-2">{{'empty' | translate}}</p>
                        </div>
                        <!-- desktop -->
                        <div *ngIf=" this.isTablet" >        
                            <div *ngFor="let elem of allWidgets; let isFirst = first; let index = index;"
                                    style="cursor:grab;padding-top: var(--widgetSpacing);"
                                    [ngClass]="{'first-widget': isFirst}" [attr.id]="'widget-' + elem.id" >
                        
                                <div *ngIf="elem.type=='annotatedMap'">
                                    <div [app-annotated-map-phone]="[elem,editTemp,index,templateForm.value.translations,languagesPassList]" ></div>
                                </div>

                                <div *ngIf="elem.type=='buttons'" >
                                    <div [app-buttons-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='billboardImage'" >
                                    <div [app-billboard-image-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='billboardVideo'" >
                                    <div [app-billboard-video-phone]="[elem,editTemp, index]" ></div>
                                </div>
                        
                                <div *ngIf="elem.type=='callToAction'" >
                                    <div [app-call-to-action-phone]="[elem,editTemp,index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='form'" >
                                    <div [app-form-phone]="[elem,editTemp,index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='image'" >
                                    <div [app-image-phone]="[elem,editTemp, index,templateForm.value.translations,languagesPassList]" ></div>
                                </div>    

                                <div *ngIf="elem.type=='images'" >
                                    <div [app-images-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='imageBlocks'" >
                                    <div [app-images-phone]="[elem,editTemp, index]" ></div>
                                </div> 
                                <div *ngIf="elem.type=='imagesHorizontal'" >
                                    <div [app-images-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='instagram'" >
                                    <div [app-instagram-phone]="[elem,editTemp]"></div>
                                </div>

                                <div *ngIf="elem.type=='list'" >
                                    <div [app-list-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='products'" >
                                    <div [app-products-phone]="[elem,editTemp,index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='productRegistration'" >
                                    <div [app-product-registration-phone]="[elem,editTemp,index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='shopify'" >
                                    <div [app-shopify-phone]="[elem,editTemp]"></div>
                                </div>

                                <div *ngIf="elem.type=='spacer'" >
                                    <div [app-spacer-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='splashVideo'" >
                                    <div [app-splash-video-phone]="[elem,editTemp,index]"></div>
                                </div>

                                <div *ngIf="elem.type=='text'" >
                                    <div [app-text-phone]="[elem,editTemp,index,templateForm.value.translations,languagesPassList]"></div>
                                </div> 
                                <div *ngIf="elem.type=='video'" >
                                    <div [app-video-phone]="[elem,editTemp,index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='3dmodel'" >
                                    <div [app-model3d-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='countdown'" >
                                    <div [app-countdown-phone]="[elem,editTemp, index]" ></div>
                                </div>

                                <div *ngIf="elem.type=='composed'" >
                                    <div [app-composed-phone]="[elem,editTemp, index]" ></div>
                                </div>
                            </div>
                            <p *ngIf="allWidgets.length==0" class="p-2">{{'empty' | translate}}</p>
                        </div>
                    </div>
                </section> 
            </div>
        </div>
        <div class="col-xs-6 col-md-6">
            <div class="d-flex flex-row bd-highlight mb-4 justify-content-between align-items-center">
                <div>
                    <mat-button-toggle-group hideSingleSelectionIndicator class="me-4" name="darkModeToggle" [(ngModel)]="darkMode">
                        <mat-button-toggle matTooltip="Light-Mode" (click)="changeTheme(false);" [value]="false"><mat-icon>light_mode</mat-icon></mat-button-toggle>
                        <mat-button-toggle matTooltip="Dark-Mode" (click)="changeTheme(true);" [value]="true"><mat-icon>dark_mode</mat-icon></mat-button-toggle>
                    </mat-button-toggle-group> 

                    <mat-slide-toggle class="screen-switcher" [(ngModel)]="phoneFullHeightView">{{ 'fullView' | translate }}</mat-slide-toggle>
                </div>

            </div>
            <!-- widget list -->
            <div *ngIf="allWidgets.length!=0" class="example-list-edit mb-5">
                <div class="example-box-edit" *ngFor="let el of allWidgets; let i=index;" (click)="onLanguageEditor(el,i);" >
                    <div class="d-inline-flex " style="width: 90%;" > 
                        <div *ngIf="el.type=='annotatedMap'" class="list-icon" >
                            <span class="material-symbols-outlined" >map</span>
                        </div>
            
                        <div *ngIf="el.type=='annotation'" class="list-icon" >
                            <span class="material-symbols-outlined">home_pin</span>
                        </div>
            
                        <div *ngIf="el.type=='annotationGroup'" class="list-icon" >
                            <span class="material-symbols-outlined">add_location_alt</span>
                        </div>
            
                        <div *ngIf="el.type=='billboardImage'" class="list-icon" >
                            <span class="material-symbols-outlined" >photo_album</span>
                        </div>
            
                        <div *ngIf="el.type=='billboardVideo'" class="list-icon" >
                            <span class="material-symbols-outlined">subscriptions</span>
                        </div>

                        <div *ngIf="el.type=='buttons'" class="list-icon" >
                            <span class="material-symbols-outlined" >toast</span>
                        </div>
            
                        <div *ngIf="el.type=='callToAction'" class="list-icon" >
                            <span class="material-symbols-outlined">badge</span>
                        </div>

                        <div *ngIf="el.type=='form'" class="list-icon" >
                            <span class="material-symbols-outlined">article</span>
                        </div>
                        
                        <div *ngIf="el.type=='instagram'" class="list-icon" >
                            <span class="material-symbols-outlined"><i class="bi bi-instagram"></i></span>
                        </div>
            
                        <div *ngIf="el.type=='image'" class="list-icon" >
                            <span class="material-symbols-outlined">imagesmode</span>
                        </div>
            
                        <div *ngIf="el.type=='images' || el.type=='imagesHorizontal' || el.type=='imageBlocks' " class="list-icon" >
                            <span class="material-symbols-outlined">burst_mode</span>
                        </div>
            
                        <div *ngIf="el.type=='list'" class="list-icon" >
                            <span class="material-symbols-outlined">list</span>
                        </div>
            
                        <div *ngIf="el.type=='text'" class="list-icon" >
                            <span class="material-symbols-outlined">article</span>
                        </div>
            
                        <div *ngIf="el.type=='products'" class="list-icon" >
                            <span class="material-symbols-outlined">shopping_cart</span>
                        </div>

                        <div *ngIf="el.type=='productRegistration'" class="list-icon" >
                            <span class="material-symbols-outlined">box_edit</span>
                        </div>
            
                        <div *ngIf="el.type=='splashVideo'" class="list-icon" >
                            <span class="material-symbols-outlined">video_file</span>
                        </div>
            
                        <div *ngIf="el.type=='shopify'" class="list-icon" >
                            <span class="material-symbols-outlined">shopping_bag</span>
                        </div>

                        <div *ngIf="el.type=='spacer'" class="list-icon" >
                            <span class="material-symbols-outlined">space_bar</span>
                        </div>
            
                        <div *ngIf="el.type=='video'" class="list-icon" >
                            <span class="material-symbols-outlined">slideshow</span>
                        </div>
            
                        <div *ngIf="el.type=='3dmodel'" class="list-icon">
                            <span class="material-symbols-outlined">view_in_ar</span>
                        </div>

                        <div *ngIf="el.type=='notification'" class="list-icon">
                            <span class="material-symbols-outlined">mark_chat_unread</span>
                        </div>

                        <div *ngIf="el.type=='countdown'" class="list-icon">
                            <span class="material-symbols-outlined">av_timer</span>
                        </div>

                        <div *ngIf="el.type=='composed'" class="list-icon">
                            <span class="material-symbols-outlined">extension</span>
                        </div>
                        <div class="d-flex flex-column align-items-start" style=" width:80%;">
                            <span *ngIf="!el.name" style="font-size: 1.1em;">{{widgetsNames[el.type]}} {{checkTypeNumber(el,i)}}</span>
                            <span *ngIf="el.name" style="font-size: 1.1em;">{{el.name}} </span>
                            <span style="font-size: 0.8em;" translate>pass.widgets.types.{{el.type}}</span>
                            <span *ngIf="el.type=='annotatedMap'" style="font-size: 0.8em;" >{{el.annotations.length}} {{'pass.annotations'| translate}}</span>
                        </div>
                    </div>

                    <span *ngIf="widgetsTranslationsFields[el.type]"><mat-icon>edit</mat-icon></span>
                    <!-- <button mat-icon-button *ngIf="widgetsTranslationsFields[el.type]"><mat-icon>edit</mat-icon></button> -->
                    
                    <!-- if widgtes empty -->
                    <!-- <div *ngIf="allWidgets.length==0" class="example-list-edit">
                        <p>{{'empty' | translate}}</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>