import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../service/settings.service';
import { httpsCallable, getFunctions, Functions } from '@angular/fire/functions';
import { CommentsService } from '../service/comments.service';
import { getApp } from '@angular/fire/app';


export type PUPassTemplate = {
  id: string,
  widgetIds: string[]
}

export type PUWorktaskTemplate = {
  id: string,
  title?: string,
  fieldIds: string[]
}

export type PUContentType = {
  id: string,
  description?: string
}

export type PlaceholderUsages = {
  passTemplates: PUPassTemplate[],
  worktaskTemplates: PUWorktaskTemplate[],
  contentTypes: PUContentType[]
}

export type Placeholders = {[key: string]: PlaceholderUsages}
@Component({
  selector: 'app-placeholder-list',
  templateUrl: './placeholder-list.component.html',
  styleUrls: ['./placeholder-list.component.css']
})


// export type PlaceholdersKey = [key: string, dta:PlaceholderUsages]

export class PlaceholderListComponent {

  allPlaceholders:any = []

  passDefinitionsWithId: any = []
  contentTypesWithId: any = []
  workTaskWithId: any = []

  showSpinner:boolean=false
  private readonly functions: Functions;

  constructor(
    private dialogRef: MatDialogRef<PlaceholderListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingsService: SettingsService,
    // private fb: FormBuilder, 
    // private accountService: AccountService,
    // private commentsService: CommentsService,
    // private route:ActivatedRoute,
    private commentService: CommentsService,
    private translateService: TranslateService){

      const functions = getFunctions(getApp(), 'europe-west1');
      this.functions = functions;
  }

  ngOnInit():void{
    if(this.settingsService.settingsPassDefinitionsWithId?.length!=0)
      this.passDefinitionsWithId = this.settingsService.settingsPassDefinitionsWithId
    else{
      this.settingsService.observeContextsPassDefinitionsFirestoreWithId(this.settingsService.contextId$).subscribe( dpp => {
        this.passDefinitionsWithId = dpp
      })
    }

    if(this.settingsService.settingsContentTypesWithId?.length!=0){
      this.contentTypesWithId = this.settingsService.settingsContentTypesWithId
    }else{
      this.settingsService.observeContextsContentTypesFirestoreWithId(this.settingsService.contextId$).subscribe( cts => {
        this.contentTypesWithId = cts
      })
    }

    if(this.settingsService.settingsWorkTaskTemplates?.length!=0){
      this.workTaskWithId = this.settingsService.settingsWorkTaskTemplates;
    }else{
      this.settingsService.observeContextsWorkTasksTemplatesFirestoreWithId().subscribe( value => {
        this.workTaskWithId = value
      })
    }

    this.callPlaceholdersGetList(this.settingsService.contextId$)
  }

  callPlaceholdersGetList(contextId){
  // info-getPlaceholders
  this.showSpinner=true
  //this.commentService.progressSpin.emit(true)

  const placeholderList = httpsCallable(this.functions, 'info-getPlaceholders');
  placeholderList({contextId:contextId}).then((result) => {
        const data = result.data;
        this.allPlaceholders = Object.entries(data)

        // const message = this.translateService.instant("SnackBarConstants.LOAD_OK")
        // this.commentService.addSnackBar.emit(message)
        this.showSpinner=false
        //this.commentService.progressSpin.emit(false)

      })
      .catch((error) => {

        this.showSpinner=false
        //this.commentService.progressSpin.emit(false)
        console.log(error)
        const message = this.translateService.instant("SnackBarConstants.LOAD_FAILED")
        this.commentService.addSnackBar.emit(message)
        this.commentService.addSnackBar.emit(error)
      });


      // const json = Object.entries(example.data)
      // this.allPlaceholders = json
      // json.forEach( key => {
      // } )
      // this.commentService.progressSpin.emit(false)

  }


  getPlaceholderInfo(id, type){
    switch(type){
      case 'passTemplates':{
        const pass = this.passDefinitionsWithId.find( pass => pass.id == id)
        return pass?.name ?? pass?.id
      }

      case 'contentTypes':{
        const ct = this.contentTypesWithId.find( ct => ct.id == id)
        return ct?.name ?? ct?.displayName
      }

      case 'worktaskTemplates':{
        const wt = this.workTaskWithId.find( wt => wt.id == id)
        return wt?.title ?? wt?.id
      }
    }
  }

  dialogCancel(data?){
    this.dialogRef.close([false]);
  }

}
