import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as THREE from "three";
import { MatDialog } from '@angular/material/dialog';
import { Model3dViewComponent } from '../model3d-view/model3d-view.component';
import { Model3dEditComponent } from '../../editWidgets/model3d-edit/model3d-edit.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[app-model3d-phone]',
  templateUrl: './model3d-phone.component.html',
  styleUrls: ['./model3d-phone.component.css']
})

export class Model3dPhoneComponent implements OnInit, AfterViewInit {
  @Input('app-model3d-phone') data:any;
  @ViewChild('canvasPhone') private canvasRef!: ElementRef;
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any}>()

  model3dData:any
  modelTorender:any
  editMode:any
  widgetNumber:any
  routerUrl:any
  idToShow:any

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }
 
  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    ){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    }


  create(){
    const scene = new THREE.Scene()

    const loader = new GLTFLoader()
    var model3d!:any 
    this.modelTorender= './../../assets/models/Case_ORCA_Open.glb'
    // loader.load('./../../assets/models/Case_ORCA_Open.glb', function(glb){
    loader.load(this.modelTorender, function(glb){
      scene.background = new THREE.Color(0xffffff)
      model3d=glb.scene

      const root= glb.scene
      scene.add(root)
    }, function (xhr) {
    }, function (error){
    })

    const light = new THREE.DirectionalLight(0xffffff,1)
    light.position.set(2,2,5)
    scene.add(light)


    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    const camera = new THREE.PerspectiveCamera(75, 500/500, 0.1, 100)
    // const camera = new THREE.PerspectiveCamera(75, sizes.width/sizes.height, 0.1, 100)

   
    //set camera position
    camera.position.set(0,5,10)
    camera.rotation.set(-0.4,0,0)
    scene.add(camera)
    
    
    const renderer = new THREE.WebGL1Renderer({
      canvas: this.canvas
    })

    renderer.setSize(this.canvas.clientWidth, this.canvas.clientWidth);
    // renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio,2))
    renderer.shadowMap.enabled=true
    
    let component: Model3dPhoneComponent = this;
    (function render() {
      requestAnimationFrame(render);
      renderer.render(scene, camera);
    }());

    (function animate() {
      requestAnimationFrame(animate);
      model3d.rotation.y += 0.01

      renderer.render(scene, camera);
    }());
  }

  animate() {
      // this.cube.rotation.x += this.rotationSpeedX;
  }

  ngOnInit():void{
    this.model3dData=this.data[0]
    this.editMode=this.data[1]
    this.modelTorender=this.model3dData.url
    if(this.data[2]){
      this.widgetNumber= this.data[2]
    }
  }

  ngAfterViewInit(): void {
    this.create()
  }

  openView(){
    this.openDialogView()
  }

  openDialogView():void{
    let dialogRef = this.dialog.open(Model3dViewComponent, {
      data:{
        values: this.model3dData
      },
    });
  }

  openDialogEdit():void{
    let dialogRef = this.dialog.open(Model3dEditComponent, {
      data:{
        values: this.model3dData,
        widget: this.widgetNumber,
        passId: this.idToShow 
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.model3dData=result
        this.returnChanges.emit({newdata: this.model3dData, widget: this.widgetNumber, widgetType: this.model3dData.type})
        
      }

    })
  }
}
