import { Component, Input } from '@angular/core';
import { MediaDialogInsideFolderData } from '../shared/media';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from '../service/comments.service';
import { SettingsService } from '../service/settings.service';
import { deepCopy, getSizeBytes } from '../../../../backend/utils/object';

@Component({
  selector: 'app-media-dialog-content',
  templateUrl: './media-dialog-content.component.html',
  styleUrls: ['./media-dialog-content.component.css']
})
export class MediaDialogContentComponent {
  @Input() data: MediaDialogInsideFolderData
  emptyMedia = false
  folder = []
  selectedFile:any

  constructor(
    private settingsService: SettingsService,
    private commentService: CommentsService,
    private translate: TranslateService,
    public dialog: MatDialog,
    ){  
    }

  ngOnInit():void{

    if(this.data?.folderItems){
    console.log(this.data)

      this.commentService.progressSpin.emit(true)

        if(this.data.folderItems.length ==  0)
          this.emptyMedia = true
        else
          this.emptyMedia = false
        this.folder = deepCopy(this.data.folderItems)


      this.commentService.progressSpin.emit(false)
    }
  }

  checkFileType(file){
    let type 

    if(file.folder)
      type = 'folder'
    if(file.metadata){
      // if(file.metadata?.contentType === 'application/pdf')
      if(file.metadata?.contentType.includes('application'))
        type = 'file'

      if(file.metadata?.contentType.startsWith('image'))
        type = 'image'
        
      if(file.metadata?.contentType.startsWith('video') )
        type = 'video'
        
    }else{

    }

    return type
  }

  selectFilePreview(file){
    console.log(file)
    if(file.folder){
      
    }else
    this.selectedFile = file

    this.data.setSelectedFile(file)

  }

  openFilePreview( file){
    console.log('openFilePreview ...',file)
  }

  getFileSize(size){
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    return getSizeBytes(size,units ,2)
  }

  onSaveEdit(){}
  onCancelEdit(){}

}
