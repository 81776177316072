<h2 mat-dialog-title>{{'customizeTheme' | translate}}</h2>

<mat-dialog-content>
    <div class="pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="passEditForm" class="m-1 mt-0">
                
                <label class="mt-2 mb-5 text-muted fs-6 d-flex">
                    <mat-icon style="margin-right: 10px;">splitscreen</mat-icon> {{'pass.widgetSettings' | translate}}
                </label>
                <form [formGroup]="themeEditForm" class="mt-3">
                    <mat-form-field appearance="outline" class="w-100 mb-3">
                        <mat-label>{{'pass.cornerRadius' | translate}}</mat-label>
                        <input formControlName="cornerRadius" min="0" type="number" matInput placeholder="10px Standard">
                        <span matSuffix class="p-3"> px</span>
                        <mat-hint>{{'pass.cornerRadiusHint' | translate}}</mat-hint>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'pass.widgetSpacing' | translate}}</mat-label>
                        <input formControlName="widgetSpacing" type="number" min="0" matInput placeholder="20px Standard">
                        <span matSuffix class="p-3"> px</span>
                        <mat-hint>{{'pass.widgetSpacingHint' | translate}}</mat-hint>
                    </mat-form-field>
                </form>
        
            <!-- colors -->
                <div class="mt-2 py-2">

                    <label class="my-3 text-muted fs-6 d-flex">
                        <mat-icon style="margin-right: 10px;" class="material-symbols-outlined">palette</mat-icon> {{'pass.themeColors' | translate}}
                    </label>
                    <!-- <label class="text-muted fs-6 py-2 text-uppercase">{{'pass.themeColors' | translate}}</label> -->

                    <mat-tab-group animationDuration="300ms">
                        <mat-tab label="{{'pass.light' | translate}}">
                            <div class="d-flex flex-row-reverse my-3">
                                <button mat-button class="secondary-gray-button" (click)="resetLightColors()">
                                    <mat-icon>format_color_reset</mat-icon> {{'pass.resetColors' | translate}}
                                </button>
                            </div>

                            <!-- color validations -->
                            <form [formGroup]="lightColorsForm">
                                <div class="list-group ">
                                    <div class="list-group-item">
                                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                            <span class="w-50 bg-white" >{{'pass.background' | translate}}</span>
                                        
                                            <label class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="background" style="margin-left:10px;"></ngx-colors>
                                                <span *ngIf="invalid_background" id="invalid_accent" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                            </label>
                                        </div>
                                    </div>
            
                                    <div class="list-group-item">
                                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                            <span class="w-50 bg-white" >{{'pass.title' | translate}}</span>
                                        
                                            <label class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="title" style="margin-left:10px;"></ngx-colors>
                                                <span *ngIf="invalid_title" id="invalid_title" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                            </label>
                                        </div>
                                    </div>
            
                                    <div class="list-group-item">
                                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                            <span class="w-50 bg-white" >{{'pass.subtitle' | translate}}</span>
                                            
                                            <div class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="subtitle" style="margin-left:10px;"></ngx-colors>
                                                <span *ngIf="invalid_subtitle" id="invalid_subtitle" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="list-group-item">
                                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                            <span class="w-50 bg-white" >{{'pass.primary' | translate}}</span>
                                            
                                            <label class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="primary" style="margin-left:10px;"></ngx-colors>
                                                <span *ngIf="invalid_primary" id="invalid_primary" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                            </label>
                                        </div>
                                    </div>
            
                                    <!--    <div class="list-group-item">
                                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
            
                                            <span class=" w-50 bg-white" >{{'pass.secondary' | translate}}</span>
                                            
                                            <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" formControlName="secondary" style="margin-left:10px;"></ngx-colors>
                                                <span *ngIf="invalid_secondary" id="invalid_secondary" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                            </label>
                                        </div>
                                    </div> -->
            
                                    <div class="list-group-item">
                                        <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
            
                                            <span class="w-50 bg-white" >{{'pass.accent' | translate}}</span>
                                            <label class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;">
                                                <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="accent" style="margin-left:10px;"></ngx-colors>                        
                                                <span *ngIf="invalid_accent" id="invalid_accent" style="color:var(--accentGlobal);">{{'pass.invalid_color' | translate}}</span>
                                                <!-- {{ lightColorsForm.value.accent }} -->
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </mat-tab>
                        <mat-tab label="{{'pass.dark' | translate}}"> 

                            <div class="d-flex justify-content-end my-3">
                                <button mat-button class="secondary-gray-button me-2" (click)="equalDarkColorsAsLightColors()">
                                    <mat-icon>copy_all</mat-icon> {{'pass.darkAsLight' | translate}}
                                </button>
                                <button mat-button class="secondary-gray-button" (click)="resetDarkColors()">
                                    <mat-icon>format_color_reset</mat-icon> {{'pass.resetColors' | translate}}
                                </button>
                            </div>

                            <form [formGroup]="darkColorsForm">
                                <div class="list-group"><!-- *ngIf="!darkAsLightValue"-->
                                        <div class="list-group-item">
                                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                <span class="w-50 bg-white" >{{'pass.background' | translate}}</span>
                                            
                                                <label class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;">
                                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="background" style="margin-left:10px;"></ngx-colors>
                                                    <span *ngIf="invalid_backgroundDark" id="invalid_accentDark" style="color:red;">{{'pass.invalid_color' | translate}}</span> 
                                                </label>
                                            </div>
                                        </div>
                
                                        <div class="list-group-item">
                                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                <span class="w-50 bg-white" >{{'pass.title' | translate}}</span>
                                            
                                                <label class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;">
                                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="title" style="margin-left:10px;"></ngx-colors>
                                                    <span *ngIf="invalid_titleDark" id="invalid_titleDark" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                                </label>
                                            </div>
                                        </div>
                
                
                                        <div class="list-group-item">
                                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                <span class="w-50 bg-white">{{'pass.subtitle' | translate}}</span>
                                                
                                                <div class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;">
                                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="subtitle" style="margin-left:10px;"></ngx-colors>
                                                    <span *ngIf="invalid_subtitleDark" id="invalid_subtitleDark" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div class="list-group-item">
                                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                <span class="w-50 bg-white">{{'pass.primary' | translate}}</span>
                                                
                                                <label class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;">
                                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="primary" style="margin-left:10px;"></ngx-colors>
                                                    <span *ngIf="invalid_primaryDark" id="invalid_primaryDark" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                                </label>
                                            </div>
                                        </div>
                
                                        <!--    <div class=" list-group-item">
                                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                <span class=" w-50 bg-white" >{{'pass.secondary' | translate}}</span>
                                                <label class=" d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;" >
                                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" formControlName="secondary" style="margin-left:10px;"></ngx-colors>
                                                    <span *ngIf="invalid_secondaryDark" id="invalid_secondaryDark" style="color:red;">{{'pass.invalid_color' | translate}}</span>
                                                </label>
                                            </div>
                                        </div> -->
                
                                        <div class="list-group-item">
                                            <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                
                                                <span class="w-50 bg-white" >{{'pass.accent' | translate}}</span>
                                                <label class="d-flex flex-row-reverse justify-content-start align-items-center" style="border-left:none;">
                                                    <ngx-colors ngx-colors-trigger [palette]="colorPalette" [hideTextInput]="false" [format]="'hex'" cancelLabel="{{'pass.colorPickerCancel' | translate}}" acceptLabel="{{'pass.colorPickerConfirm' | translate}}" formControlName="accent" style="margin-left:10px;"></ngx-colors>                        
                                                    <span *ngIf="invalid_accentDark" id="invalid_accentDark" style="color:var(--accentGlobal);">{{'pass.invalid_color' | translate}}</span>
                                                    <!-- {{ darkColorsForm.value.accent }} -->
                                                </label>
                                            </div>
                                        </div>
                                </div>
                            </form>
                            
                        </mat-tab>
                      </mat-tab-group>

                </div>
        
            <!-- fonts -->
                <div class="mt-3 pt-1 pb-2">

                    <div class="mb-4">
                        <label class="my-2 text-muted fs-6 d-flex">
                            <mat-icon style="margin-right: 10px;">text_fields</mat-icon> {{'pass.fonts' | translate}}
                        </label>
                        <mat-hint class="mb-2" style="font-size: 14px;">{{'pass.fonts_info' | translate}}</mat-hint>
                    </div>

                    <div *ngIf="showSpinner" class="mt-5" style="position: relative;">
                        <mat-spinner color="accent"></mat-spinner>
                    </div>

                    <form *ngIf="!showSpinner" [formGroup]="fontsForm" class="pt-3">

                        <div class="d-flex" *ngFor="let field of fontFields">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{ 'pass.' + field.label | translate }}</mat-label>
                                <mat-select [formControlName]="field.controlName">
                                <mat-optgroup *ngIf="uploadedCDFonts.length > 0" label="{{'defaultFonts' | translate}}">
                                    <mat-option *ngFor="let font of uploadedCDFonts" [value]="font">{{font.name}}</mat-option>
                                </mat-optgroup>
                                <mat-optgroup *ngIf="uploadedPassFonts.length > 0" label="{{'customFonts' | translate}}">
                                    <mat-option *ngFor="let font of uploadedPassFonts" [value]="font">{{font.name}}</mat-option>
                                </mat-optgroup>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <mat-expansion-panel expanded>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="color:#595c5f;">
                                    <mat-icon style="margin-right: 10px;">library_add</mat-icon>
                                    {{'pass.manageCustomFonts' | translate}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div *ngIf="uploadedPassFonts.length == 0" appDragAndDrop (files)="fontsDropped($event)">
                                <mat-card class="font-upload-section text-center justify-content-center my-2">


              

                                    <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">

                                        <div class="p-2 mb-4 text-center">
                                            <mat-icon class="decoration-icon material-symbols-outlined">upload_file</mat-icon>
                            
                                            <button mat-button class="my-3 orange-button" (click)="triggerFontFileInput()">
                                                {{'pass.pick' | translate}}
                                            </button>
                                            <input type="file" id="myfile-pick-font" name="myfile-pick-font" style="display: none;" accept=".ttf, .otf" (change)="readURL($event)">
                                        </div>
                                        <p class="py-2 mt-3">{{ 'pass.supportedFiles' | translate }}: .ttf, .otf</p>
                                        
                                    </div>
                                </mat-card>
                            </div>

                            <div *ngIf="uploadedPassFonts.length > 0" class="my-2">
                                <mat-list class="font-list">
                                    <div *ngFor="let font of uploadedPassFonts">
                                        <mat-list-item>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="font-name">{{ font.name }}</div>
                                                <div class="delete-icon">
                                                    <button mat-icon-button style="color: var(--warmOrange);" (click)="deleteFont(font)">
                                                        <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </mat-list-item>
                                        <mat-divider></mat-divider>
                                    </div>
                                </mat-list>
                                <button mat-button class="secondary-gray-button mt-3" (click)="triggerFontFileInput()">
                                    <mat-icon>upload</mat-icon>
                                    <span>{{'uploadNewFont' | translate}}</span>
                                </button>
                                <input type="file" id="myfile-pick-font" name="myfile-pick-font" style="display: none;" accept=".ttf, .otf" (change)="readURL($event)">
                                <p style="font-size:12px; color:var(--warmOrange);" class="py-2 mt-3">{{ 'pass.supportedFiles' | translate }}: .ttf, .otf</p>
                            </div>
    
                        </mat-expansion-panel>
                    </form>

                </div>
                
        
            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" [disabled]="
        invalid_background ||
        invalid_title ||
        invalid_subtitle ||
        invalid_primary ||
        invalid_accent ||
        invalid_backgroundDark ||
        invalid_titleDark ||
        invalid_subtitleDark ||
        invalid_primaryDark ||
        invalid_accentDark
    " (click)="onSaveEdit();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>


