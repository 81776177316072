import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../../service/settings.service';
import { CommentsService } from '../../service/comments.service';
import { WorkTaskTemplateGroupsService } from '../../service/work-task-template-groups.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-worktask-templates-groups-dialog',
  templateUrl: './worktask-templates-groups-dialog.component.html',
  styleUrls: ['./worktask-templates-groups-dialog.component.css']
})
export class TaskTemplatesGroupsDialogComponent {
  newGroupForm!:FormGroup
  option:any
  groupSelected:any
  groupSelectedId:any
  
  constructor(public dialogRef: MatDialogRef<TaskTemplatesGroupsDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any[],
    public dialog: MatDialog,
    private settingService: SettingsService,
    private commentsService: CommentsService,
    private worktaskGroupsService: WorkTaskTemplateGroupsService,
    private translate:TranslateService){

      dialogRef.disableClose = true;

  }

  ngOnInit(){
    this.newGroupForm = this.fb.group({
      title:[, Validators.pattern]
    })

    if(this.data){
      if(this.data['option']){
        this.option = this.data['option']

        if(this.option == 'edit'){
          this.groupSelected = this.data['group']
          this.groupSelectedId = this.groupSelected.id
          this.newGroupForm.patchValue({title: this.groupSelected.title})
        }
      }
    }

  }

  onCancel(){
    this.dialogRef.close([false, this.newGroupForm.value])
  }

  async onSave(){
    if(this.newGroupForm.value.title){
      try{
        this.commentsService.progressSpin.emit(true)
        if(this.option == 'create'){
          const newid = await this.worktaskGroupsService.createWorkTaskTemplateGroup(this.newGroupForm.value, this.data['contextId'], this.data['sortIndex'])
        }

        if(this.option == 'edit'){
          const newid = await this.worktaskGroupsService.updateWorkTaskTemplateGroup(this.newGroupForm.value, this.groupSelectedId, this.data['contextId'])
        }

        this.dialogRef.close([true, this.newGroupForm.value])
        this.commentsService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.CREATE_OK")
        this.commentsService.addSnackBar.emit(message)
      }catch(error){
        this.commentsService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.CREATE_FAILED")
        this.commentsService.addSnackBar.emit(message)
      }

    }else{
      const message = this.translate.instant("SnackBarConstants.MANDATORY_FIELDS")
      this.commentsService.addSnackBar.emit(message)
    }
  }
}
