import { Injectable } from '@angular/core';
import { Storage, ref, uploadString , uploadBytesResumable , getDownloadURL, deleteObject} from '@angular/fire/storage';
import { removeUndefinedValuesFromObject } from '../../../../backend/utils/object';
import { CommentsService } from './comments.service';
import { TranslateService } from '@ngx-translate/core';
import { contentypeForMetadata } from './../../../../backend/src/filesMetadata'
import { NetworkConstants } from 'src/network.constants';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  basePath:string="passes"
  imageUrl:{[url:string]:any} = {}
  documentUrl:{[url:string]:any} = {}
  coverImageUrl:{[url:string]:any} = {}
  coverImageFileName: any
  billboardImgUrl:{[url:string]:any} = {}
  billboardImgUrlForSize:{[url:string]:any} = {}
  billboarImgSize:any=[]
  callToActionImgSize:any=[]
  imageSizeNew:any=[]
  imageSizeNewCover:any=[]
  billboardVideoUrl:{[url:string]:any} = {}
  videoUrl:{[url:string]:any} = {}
  splashVideoUrl:{[url:string]:any} = {}
  callToactionImgUrl:{[imageUrl:string]:any} = {}
  callToactionImgUrlForSize:{[imageUrl:string]:any} = {}
  productRegistrationImgUrl:{[imageUrl:string]:any} = {}
  textImgUrl:{[url:string]:any} = {}
  workTaskImgUrl:{[url:string]:any} = {}
  multipleImages=[]
  imagesBlocksUrls=[]
  imagesHorizontalUrls=[]
  fontData:any
  fontURL:{[url:string]:any} = {}
  fonts:any = {
    title:undefined,
    subtitle:undefined,
    plain:undefined,
    bold:undefined,
    italic:undefined
  }

  imagesUploaded:any=[]
  fontsUploaded:any=[]
  newImage:any
  imageItem:{[url:string]:any}={}

  constructor(
    private storage: Storage,
    private commentsService: CommentsService,
    private translate:TranslateService,
    private settingsService: SettingsService
    ) { }


  async uploadImage(folderPath:any, image:any, widgetNumber){
    this.commentsService.progressSpin.emit(true)
    this.imageUrl={}
    let imageUrl=""
    const name= this.getRandomName()
    const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}.jpg`
    // const newURL = `pass://${folderPath}/${name}${widgetNumber}.jpg`
    const newURL = `pass://${name}${widgetNumber}.jpg`
    this.imagesUploaded.push(filePath)
    
    const storageRef = ref(this.storage, filePath);
    
    const uploadTask = uploadBytesResumable(storageRef, image)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
       
      },
      (error) => {
        console.log(error.message)
      },
      async() => {
        this.imageUrl['url']=newURL
        this.commentsService.progressSpin.emit(false)

        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // this.imageUrl['url']=downloadURL
          // return downloadURL
        })
        return newURL;
      }
    )
    return newURL

  }

    getImagesUrls(){
      return this.imageUrl
    }

    getImagesUploaded(){
      return this.imagesUploaded
    }

    setImagesUploaded(){
      this.imagesUploaded=[]
    }


    async uploadDocument(folderPath:any, image:any, widgetNumber){
      this.commentsService.progressSpin.emit(true)
      this.imageUrl={}
      let nameFile = image.name.split(".")
      let type = nameFile[1]
      let imageUrl=""
      const name= this.getRandomName()
      const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}.${type}`
      const newURL = `pass://${name}${widgetNumber}.${type}`
      // const newURL = `pass://${folderPath}/${name}${widgetNumber}.${type}`
      this.imagesUploaded.push(filePath)
      
      const storageRef = ref(this.storage, filePath);
      
      const uploadTask = uploadBytesResumable(storageRef, image)
  
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
         
        },
        (error) => {
          console.log(error.message)
        },
        async() => {
          this.documentUrl['url']=newURL
          this.commentsService.progressSpin.emit(false)
  
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // this.documentUrl['url']=downloadURL
            // return downloadURL
          })
          return newURL;
        }
      )
      return newURL
  
    }


    getFontsUploaded(){
      return this.fontsUploaded
    }

    setFontsUploaded(){
      this.fontsUploaded=[]
    }


  async uploadNewBillboardImage(folderPath:any, image:any, widgetNumber:any){
    this.commentsService.progressSpin.emit(true)
    this.billboardImgUrl={}
    let imageUrl=""
    const name= this.getRandomName()
    const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}.jpg`
    const newURL = `pass://${name}${widgetNumber}.jpg`
    // const newURL = `pass://${folderPath}/${name}${widgetNumber}.jpg`
    const storageRef = ref(this.storage, filePath);
    this.imagesUploaded.push(filePath)
    const uploadTask =  uploadBytesResumable(storageRef, image)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      },
      (error) => {
        console.log(error.message)
      },
      async () => {
        this.billboardImgUrl['url']=newURL
        this.commentsService.progressSpin.emit(false)

        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          this.billboardImgUrlForSize['url']=downloadURL
          this.setNewBillboardImageSize()
          // return downloadURL
        })
        return newURL
      }
    )
    return newURL
  }

  getNewBillboardImage(){
    return this.billboardImgUrl
  }

  getNewBillboardImageSize(){
    return this.billboarImgSize
  }

  setNewBillboardImageSize(){
    const img  = new Image()
    img.src = this.billboardImgUrlForSize['url']
    let imageSize =[]
    img.onload = ()=>{
      imageSize = [img.width, img.height]
      this.billboarImgSize = [img.width, img.height]
      return imageSize
    }
  }


  uploadMultipleImage(folderPath:any, images:any, widgetNumber:any){
    this.multipleImages=[]
    images.forEach( (image,index) =>{
      let imageUrl=""
      const name= this.getRandomName()
      const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}${index}.jpg`
      const storageRef = ref(this.storage, filePath);
      this.imagesUploaded.push(filePath)

      const uploadTask = uploadBytesResumable(storageRef, image)

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
        },
        (error) => {
          console.log(error.message)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            this.multipleImages.push({url:downloadURL})
            return downloadURL
          })
        }   
      )
    })

  }
  getMultipleImages(){
    return this.multipleImages
  }

  uploadImageBlocks(folderPath:any, images:any, widgetNumber:any){
    this.imagesBlocksUrls=[]
    images.forEach( (image,index) =>{
      let imageUrl=""
      const name= this.getRandomName()
      const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}${index}.jpg`
      const storageRef = ref(this.storage, filePath);
      this.imagesUploaded.push(filePath)
      const uploadTask = uploadBytesResumable(storageRef, image)

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
        },
        (error) => {
          console.log(error.message)
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            this.imagesBlocksUrls.push({url:downloadURL})
            return downloadURL
          })
        }   
      )
    })

  }
  getImageBlocks(){
    return this.imagesBlocksUrls
  }


  uploadImageHorizontal(folderPath:any, images:any, widgetNumber:any){
    this.imagesHorizontalUrls=[]
    images.forEach( (image,index) =>{
      let imageUrl=""
      const name= this.getRandomName()
      const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}${index}.jpg`
      const storageRef = ref(this.storage, filePath);
      this.imagesUploaded.push(filePath)
      const uploadTask = uploadBytesResumable(storageRef, image)

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
        },
        (error) => {
          console.log(error.message)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            this.imagesHorizontalUrls.push({url:downloadURL})
            return downloadURL
          })
        }   
      )
    })

  }
  
  getImagesHorizontal(){
    return this.imagesHorizontalUrls
  }

  uploadNewImages(folderPath:any, image:any, index:any, widgetNumber:any){
    this.commentsService.progressSpin.emit(true)
    this.imagesHorizontalUrls=[]
    let imageUrl=""
    const name= this.getRandomName()
    const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}${index}.jpg`
    // const newURL = `pass://${folderPath}/${name}${widgetNumber}${index}.jpg`
    const newURL = `pass://${name}${widgetNumber}${index}.jpg`
    const storageRef = ref(this.storage, filePath);
    this.imagesUploaded.push(filePath)

    const uploadTask =  uploadBytesResumable(storageRef, image)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      },
      (error) => {
        console.log(error.message)
      },
      async () => {
        this.imagesHorizontalUrls.push({url:newURL})
        this.commentsService.progressSpin.emit(false)
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // return downloadURL
        })
        return newURL
      }
    )
    return newURL
  }

  uploadNewTextImage(folderPath:any, image:any, widgetNumber:any){
    this.commentsService.progressSpin.emit(true)

    this.textImgUrl={}
    let imageUrl=""
    const name= this.getRandomName()
    const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}.jpg`
    const newURL = `pass://${name}${widgetNumber}.jpg`
    // const newURL = `pass://${folderPath}/${name}${widgetNumber}.jpg`

    const storageRef = ref(this.storage, filePath);
    this.imagesUploaded.push(filePath)
    const uploadTask = uploadBytesResumable(storageRef, image)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      },
      (error) => {
        console.log(error.message)
      },
      async () => {
        this.textImgUrl['url']=newURL
        this.commentsService.progressSpin.emit(false)

        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // this.textImgUrl['url']=downloadURL
          // return downloadURL
        })
        return newURL
      }
    )
    return newURL

  }

  uploadNewProductRegistrationImage(folderPath:any, image:any, widgetNumber:any){
    this.commentsService.progressSpin.emit(true)

    this.textImgUrl={}
    let imageUrl=""
    const name= this.getRandomName()
    const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}.jpg`
    const newURL = `pass://${name}${widgetNumber}.jpg`
    // const newURL = `pass://${folderPath}/${name}${widgetNumber}.jpg`

    const storageRef = ref(this.storage, filePath);
    this.imagesUploaded.push(filePath)
    const uploadTask = uploadBytesResumable(storageRef, image)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      },
      (error) => {
        console.log(error.message)
      },
      async () => {
        this.textImgUrl['url']=newURL
        this.commentsService.progressSpin.emit(false)

        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // this.textImgUrl['url']=downloadURL
          // return downloadURL
        })
        return newURL
      }
    )
    return newURL

  }


  getNewTextImage(){
    return this.textImgUrl
  }

  uploadNewCallToActionImage(folderPath:any, image:any, widgetNumber:any){
    this.commentsService.progressSpin.emit(true)
    this.callToactionImgUrl={}
    let imageUrl=""
    const name= this.getRandomName()
    const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}.jpg`
    const newURL =`pass://${name}${widgetNumber}.jpg`
    // const newURL =`pass://${folderPath}/${name}${widgetNumber}.jpg`
    const storageRef = ref(this.storage, filePath);
    this.imagesUploaded.push(filePath)

    const uploadTask = uploadBytesResumable(storageRef, image)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      },
      (error) => {
        console.log(error.message)
      },
      () => {
        this.callToactionImgUrl['imageUrl']=newURL

        this.commentsService.progressSpin.emit(false)
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          this.callToactionImgUrlForSize['imageUrl']=downloadURL
          this.setCallToActionImageSize()
          // return downloadURL
        })
        return newURL
      }
    )
    return newURL
  }

  getNewCallToActionImage(){
    return this.callToactionImgUrl
  }

  getNewProductRegistrationImage(){
    return this.productRegistrationImgUrl
  }

  getCallToActionImageSize(){
    return this.callToActionImgSize
  }

  setCallToActionImageSize(){
    const img  = new Image()
    img.src = this.callToactionImgUrlForSize['imageUrl']
    let imageSize =[]
    img.onload = ()=>{
      imageSize = [img.width, img.height]
      this.callToActionImgSize = [img.width, img.height]
      return imageSize
    }
  }

  calculateImageSize(url){
    const img  = new Image()
    img.src = url
    let imageSize =[]
    img.onload = ()=>{
      imageSize = [img.width, img.height]
      this.imageSizeNew = [img.width, img.height]
      return imageSize
    }
    return this.imageSizeNew
  }


  calculateImgFileSize(url):Promise<any>{
    return new Promise<any>((resolve) => {
      const img  = new Image()
      img.src = url
      let imageSize =[]
      img.onload = ()=>{
        imageSize = [img.width, img.height]
        this.imageSizeNew = [img.width, img.height]
        resolve(imageSize)
      }
    });    
  }


  async uploadNewBillboardVideo(folderPath:any, image:any, widgetNumber:any){
    this.commentsService.progressSpin.emit(true)
    this.billboardVideoUrl={}
    let imageUrl=""
    const name= this.getRandomName()
    const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}.mov`
    // const newURL = `pass://${folderPath}/${name}${widgetNumber}.mov`
    const newURL = `pass://${name}${widgetNumber}.mov`
    let contentype = contentypeForMetadata['mov']
    const storageRef = ref(this.storage, filePath);
    this.imagesUploaded.push(filePath)

    let metadata = { contentType: contentype };
    const uploadTask = uploadBytesResumable(storageRef, image[0],metadata)//metadata

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      },
      (error) => {
        console.log(error.message)
      },
      async () => {
        this.billboardVideoUrl['url']=newURL
        this.commentsService.progressSpin.emit(false)
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // this.billboardVideoUrl['url']=downloadURL
          // return downloadURL
        })
        return newURL;
      }
    )
    return newURL;
  }

  getNewBillboardVideo(){
    return this.billboardVideoUrl
  }


  async uploadNewVideo(folderPath:any, image:any, widgetNumber:any){
    this.commentsService.progressSpin.emit(true)
    this.videoUrl={}
    let imageUrl=""
    const name= this.getRandomName()
    const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}.mov`
    const newURL = `pass://${name}${widgetNumber}.mov`
    // const newURL = `pass://${folderPath}/${name}${widgetNumber}.mov`
    const storageRef = ref(this.storage, filePath);
    this.imagesUploaded.push(filePath)

    const uploadTask = uploadBytesResumable(storageRef, image[0])
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      },
      (error) => {
      },
      async () => {
        this.videoUrl['url']=newURL
        this.commentsService.progressSpin.emit(false)

        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // this.videoUrl['url']=downloadURL
          // return downloadURL
        })
        return newURL
      }
    )
    return newURL
  }

  getNewVideo(){
    return this.videoUrl
  }

  async uploadNewSplashVideo(folderPath:any, image:any, widgetNumber:any){
    this.commentsService.progressSpin.emit(true)
    this.splashVideoUrl={}
    let imageUrl=""
    const name= this.getRandomName()
    const filePath = `${this.basePath}/${folderPath}/${name}${widgetNumber}.mov`
    const newURL = `pass://${name}${widgetNumber}.mov`
    // const newURL = `pass://${folderPath}/${name}${widgetNumber}.mov`
    const storageRef = ref(this.storage, filePath);
    this.imagesUploaded.push(filePath)

    const uploadTask = uploadBytesResumable(storageRef, image[0])
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      },
      (error) => {
      },
      async () => {
        this.splashVideoUrl['url']=newURL
        this.commentsService.progressSpin.emit(false)

        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // this.splashVideoUrl['url']=downloadURL
          // return downloadURL
        })
        return newURL
      }
    )
    return newURL
  }

  getNewSplashVideo(){
    return this.splashVideoUrl
  }


  getRandomName(){
    const now = new Date()  
    const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000)  
    const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000)  
    return utcSecondsSinceEpoch
  }

  deleteFromStorage(downloadURL){
    const desertRef = ref(this.storage, downloadURL);
    deleteObject(desertRef).then( ()=>{
    }).catch( (error)=>{
      console.log(error)
    })
  }


  addToStorage(file:File, route:string, index){
    return new Promise<any>((resolve) => {

    const name= this.getRandomName() + index
    const nameFile = file.name.split(".")
    const type=this.getFileExtension(file.name)
    const basePath = `${NetworkConstants.COLLECTION_CONTEXTS}/${this.settingsService.contextId$}`
    const filePath = basePath + route + name + '.'+ type
    const storageRef = ref(this.storage, filePath);
    this.imagesUploaded.push(filePath)
    const newurl = 'context:/'+ route + name + '.'+ type

    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      },
      (error) => {
        console.log(error.message)
      },
      async () => {
        resolve(newurl)
        return newurl
        // await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        //   this.newImage = downloadURL
        //   return downloadURL
        // })
      }
    )
    })
  }

  getAddedToStorage(){
    return this.newImage
  }

  createFolderStorage(route:string, name:string){
    const folderPath = route +'/'+ name + '/'
    const storageRef = ref(this.storage, folderPath)
    const uploadTask = uploadString(storageRef, '')

    return storageRef.fullPath
  }


  async uploadImageItem( image:any, contextId:string, itemId:string){
    this.imageUrl={}
    let imageUrl=""
    const name= this.getRandomName()
    const folderPath = `Contexts/${contextId}/Items/${itemId}/`
    const filePath = `${folderPath}/${name}.jpg`
    const storageRef = ref(this.storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, image)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      },
      (error) => {
        console.log(error.message)
      },
      async() => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          this.imageItem['url']=downloadURL
          return downloadURL
        })
      }
    )
  }

  getImageItem(){
    return this.imageItem
  }

  async uploadCoverImage(image, prefix, url) {
    this.commentsService.progressSpin.emit(true);
  
    const originalFileType = image.type.split('/')[1];
  
    if (originalFileType === 'jpeg' || originalFileType === 'png') {
      // Convert to JPEG format
      const convertedImageDataUrl = await this.convertToJpg(image);
      const convertedBlob = await fetch(convertedImageDataUrl).then((res) => res.blob());
  
      const fileName = `${prefix}.jpg`; // Save as .jpg
      const filePath = `/experiences/${fileName}`;
      this.coverImageFileName = fileName;
  
      const storageRef = ref(this.storage, filePath);
      const uploadTask = uploadBytesResumable(storageRef, convertedBlob);
  
      if (this.imageSizeNewCover[0] >= 1800 && this.imageSizeNewCover[1] >= 1200) {
        this.imagesUploaded.push(filePath);
  
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes);
          },
          (error) => {
            console.log(error.message);
          },
          async () => {
            this.commentsService.progressSpin.emit(false);
  
            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              this.coverImageUrl['url'] = downloadURL;
              return downloadURL;
            });
          }
        );
      } else {
        const message = this.translate.instant('SnackBarConstants.IMG_SIZE');
        this.commentsService.addSnackBar.emit(message);
      }
    } else {
      const message = this.translate.instant('SnackBarConstants.INVALID_FILE_TYPE');
      this.commentsService.addSnackBar.emit(message);
    }
  }
  
  async convertToJpg(image) {
    const img = new Image();
    img.src = URL.createObjectURL(image);
  
    return new Promise<string>((resolve) => {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        // Setting Canvas
        canvas.width = img.width;
        canvas.height = img.height;
  
        // Make white bg for alpha
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
  
        ctx.drawImage(img, 0, 0, img.width, img.height);
  
        // Convert the image
        const convertedImage = canvas.toDataURL('image/jpeg', 1.0);
  
        resolve(convertedImage);
      };
    });
  }

  calculateImageSizeCoverImage(image, prefix,url, dragDrop?:boolean){
    const img  = new Image()
    img.src = url

    if(dragDrop){
      img.src = url['changingThisBreaksApplicationSecurity']
    }

    let imageSize =[]
    img.onload = ()=>{
      imageSize = [img.width, img.height]
      this.imageSizeNewCover = [img.width, img.height]
      this.uploadCoverImage( image, prefix, url)
      return imageSize
    }
    return this.imageSizeNewCover
  }

  getCoverImage(){
    return this.coverImageUrl;
  }

  getCoverImageFileName(){
    return this.coverImageFileName
  }

  getCoverImageSize(){
    return this.imageSizeNewCover;
  }

  uploadCdLogo(file: File, type: string, contextId: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.commentsService.progressSpin.emit(true);
      this.imageUrl = {};
      
      // Get the file extension
      const nameFile = file.name.split(".");
      const extension = nameFile[nameFile.length - 1];
      
      // Generate a random name
      const randomName = this.getRandomName();
      
      // Construct the folder path and file path
      const folderPath = `Contexts/${contextId}/images/`;
      const filePath = `${folderPath}${randomName}.${extension}`;
      this.imagesUploaded.push(filePath);
  
      // Construct the new URL
      const newURL = `context://images/${randomName}.${extension}`;
      const storageRef = ref(this.storage, filePath);
      
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes);
        },
        (error) => {
          console.log(error.message);
          reject(error.message);
        },
        async() => {
          this.imageUrl['url'] = newURL;
          this.commentsService.progressSpin.emit(false);
  
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // this.imageUrl['url']=downloadURL
          // return downloadURL
            resolve(newURL);
          }).catch((error) => {
            reject(error);
          });
        }
      );
    });
  }

  uploadCdFont(file: File, contextId: string): Promise<{ name: string, url: string }> {
    this.commentsService.progressSpin.emit(true);
    this.fontURL = {};
    let nameFile = file.name.split(".");
    const name = nameFile[0].replace(/\s/g, "");
    const folderPath = `Contexts/${contextId}/fonts/`;
    const filePath = `${folderPath}${name}.${nameFile[1]}`;
    this.fontsUploaded.push(filePath);
    const newURL = `context://fonts/${name}.${nameFile[1]}`;
    const newdata = {
      name: name,
      url: newURL
    };

    const storageRef = ref(this.storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes);
        },
        (error) => {
          this.commentsService.progressSpin.emit(false);
          console.error(error.message);
          reject(error);
        },
        async () => {
          this.commentsService.progressSpin.emit(false);
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            this.fontURL['url'] = downloadURL;
            this.fontURL['name'] = name;

            resolve({
              name: this.fontURL['name'],
              url: this.fontURL['url']
            });
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  }

  uploadFont(file, passTemplate, contextId){
    this.commentsService.progressSpin.emit(true)
    this.fontURL={}
    this.fonts = {name:"", url:""}
    let nameFile = file.name.split(".")
    const name = nameFile[0].replace(/\s/g, "")
    const folderPath = `passes/${passTemplate}`
    const filePath = `${folderPath}/${name}.${nameFile[1]}`
    this.fontsUploaded.push(filePath)
    const newURL = `pass://${name}.${nameFile[1]}`
    // const newURL = `pass://${passTemplate}/${name}.${nameFile[1]}`
    const newdata = {
      name: name,
      url: newURL
    }

    const storageRef = ref(this.storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
      },
      (error) => {
        console.log(error.message)
      },
      async() => {
        this.commentsService.progressSpin.emit(false)
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          this.fontURL['url']=downloadURL
          this.fontURL['name']=name

          return downloadURL
        })
      }
    )
      return newdata
  }

  emptyFonts(){
    this.fontURL={}
    this.fonts = {
      title:undefined,
      subtitle:undefined,
      plain:undefined,
      bold:undefined,
      italic:undefined
    }
  }

  getFonts(){
    removeUndefinedValuesFromObject(this.fonts)
    return this.fonts
  }

  getFileExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }

  async uploadimportedImage(folderPath:any, image:any, filename:any,  ilesUrlLoaded, filesTouploadSize){
    //this.commentsService.progressSpin.emit(true)
    const filePath = `${this.basePath}/${folderPath}/${filename}`
    const newURL = `pass://${filename}`
    let extension = this.getFileExtension(filename)
    let contentype = ''
    let allcontentypes = contentypeForMetadata

    if(!allcontentypes[extension] )
      contentype = 'application/octet-stream'
    else
      contentype = allcontentypes[extension]

    const storageRef = ref(this.storage, filePath);
    let metadata = { contentType: contentype };
    const uploadTask =  uploadBytesResumable(storageRef, image, metadata)

    uploadTask.on('state_changed',
      (snapshot) => {
        this.commentsService.progressSpin.emit(true)
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes)
        if(progress === 1){
          this.commentsService.progressSpin.emit(false)
          if( ilesUrlLoaded == filesTouploadSize){
            const message = this.translate.instant('SnackBarConstants.IMPORTED_PASS_MEDIA_LOAD_SUCCESSFULLY')
            this.commentsService.addSnackBar.emit(message)
          }
        }
      },
      (error) => {
        console.log(error.message)
        this.commentsService.progressSpin.emit(false)
      },
      async () => {
        return newURL
      }
    )
    return newURL
  }

  uploadWorkTaskImgae(contextId,taskId, image,sectionNumber, fieldNumber){
    this.commentsService.progressSpin.emit(true)
    this.workTaskImgUrl={}
    const name= this.getRandomName()
    const filePath = `Contexts/${contextId}/workTasks/${taskId}_${name}${sectionNumber}${fieldNumber}.jpg`
    const newURL = `context://workTasks/${taskId}_${name}${sectionNumber}${fieldNumber}.jpg`
    // this.imagesUploaded.push(filePath)

    const storageRef = ref(this.storage, filePath);    
    const uploadTask = uploadBytesResumable(storageRef, image)

    uploadTask.on('state_changed',
      (snapshot) => {const progress = (snapshot.bytesTransferred / snapshot.totalBytes)},
      (error) => {
        console.log(error.message)
        this.commentsService.progressSpin.emit(false)
      },
      async() => {
        this.workTaskImgUrl['url']=newURL
        this.commentsService.progressSpin.emit(false)
        return newURL;
      }
    )
    return newURL

  }
}
