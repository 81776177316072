<div class="container-fluid login-page" fxLayout="column" fxLayoutAlign="center center">

    <div class="position-top">

        <div class="d-flex flex-row align-self-end justify-content-end flex-wrap m-lg-2">
            <button mat-button [matMenuTriggerFor]="language" style="color:#fff;margin-bottom:10px;">
                <span *ngIf="languageFlag === 'gb'">
                    <span class="fi fi-gb dd-menu-image"></span> English
                </span>
                <span *ngIf="languageFlag === 'de'">
                    <span class="fi fi-de dd-menu-image"></span> Deutsch
                </span>
                <span *ngIf="languageFlag === 'es'">
                    <span class="fi fi-es dd-menu-image"></span> Español
                </span>
                
            </button>
            <mat-menu #language="matMenu">
                <button mat-menu-item (click)="useLanguage('en')"><span class="fi fi-gb dd-menu-image"></span>  English </button>
                <button mat-menu-item (click)="useLanguage('de')"><span class="fi fi-de dd-menu-image"></span>  Deutsch </button>
                <button mat-menu-item (click)="useLanguage('es')"><span class="fi fi-es dd-menu-image"></span>  Español </button>
            </mat-menu>
        </div>
    </div>

    <div class="login-card-wrapper">
        <div class="login-card p-4 mat-elevation-z8">
            <div class="login-logo mt-3">
                <img src="./../../assets/img/narravero-logo_website.svg" id="logo" alt="Narravero Logo">
            </div>

            <div *ngIf="!showSpinner" class="d-flex flex-column px-3 align-items-center justify-content-center flex-wrap form-section">

                <form class="" [formGroup]="loginForm" (keydown.enter)="logInAction();">

                    <mat-form-field appearance="outline" class="w-100 mt-2">
                        <mat-label>{{'login.email' | translate }}</mat-label>
                        <input matInput placeholder="{{'login.email' | translate }}" formControlName="email" type="email" id="emailAdress" required>
                        <mat-error *ngIf="loginForm.value.email.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{'login.pass' | translate}}</mat-label>
                        <input matInput formControlName="password" placeholder="{{'login.pass' | translate}}" id="passwordValue" [type]="hide ? 'password' : 'text'">
                        <button mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </button>
                    </mat-form-field>

                    <div *ngIf="badLogin" style="padding: 10px; background-color: var(--pureWhite); margin-bottom:10px; color:var(--accentGlobal); border-color: var(--accentGlobal);text-align:center; font-weight: bold; border: 1px solid var(--accentGlobal); border-radius:5px;">    
                        {{'login.invalid' | translate}}
                    </div>

                    <div>
                        <button mat-button (click)="logInAction();" class="w-100 mb-2 orange-button">{{'login.title' | translate}}</button>
                    </div>
                    
                </form>
            
            </div>

            <div *ngIf="!showSpinner">
                <button mat-button (click)="callForgotPassword();" class="w-100" style="color:var(--warmOrange)">{{ 'login.forgot' | translate}}?</button>
            </div>

    
            <div *ngIf="showSpinner" class="login-spinner">
                <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
            </div>
            
        </div>
        <div class="dev-note mobile-text-small"> {{buildEnvironment}} - {{version}} ( {{buildNumber}} )</div>
    </div>

    <div class="position-bottom">
        <div class="p-4 w-100">
            <div class="d-flex flex-row justify-content-between flex-wrap">
                <div class="footer-links mobile-text-small">
                    <span ><a class="text-decoration-none" target="_blank" href="https://www.narravero.com/impressum">{{'imprint' | translate}}</a></span> 
                    <span class="divider">   |  </span> 
                    <span> <a class="text-decoration-none" target="_blank" href ="https://www.narravero.com/datenschutz">{{'privacy_policy' | translate}}</a></span>
                </div>
            </div>
        </div>
    </div>

</div>