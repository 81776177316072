<h2 mat-dialog-title>{{'user' | translate}} {{'edit' | translate}}</h2>

<mat-dialog-content style="width:500px;">
    <div class="pb-3">
        <section class="mt-4 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="editUserForm" class="m-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'users.displayName' | translate}}</mat-label>
                    <input formControlName="displayName" type="text" matInput placeholder="{{'users.displayName' | translate}}" >
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'users.email' | translate}}</mat-label>
                    <input formControlName="email" type="email" matInput placeholder="{{'users.email' | translate}}" >
                </mat-form-field>

                <div class="roles-toggle">
                    <h4 class="text-uppercase my-4">{{ 'users.roles' | translate }}</h4>

                    <div class="p-2 my-1 d-flex justify-content-between align-items-center">
                        <label>{{ availableRoles[0].display }}</label>
                        <mat-slide-toggle formControlName="accountManager"></mat-slide-toggle>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="p-2 my-1 d-flex justify-content-between align-items-center">
                        <label>{{ availableRoles[1].display }}</label>
                        <mat-slide-toggle formControlName="contentManager"></mat-slide-toggle>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="p-2 my-1 d-flex justify-content-between align-items-center">
                        <label>{{ availableRoles[2].display }}</label>
                        <mat-slide-toggle formControlName="workTaskManager"></mat-slide-toggle>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="p-2 my-1 d-flex justify-content-between align-items-center">
                        <label>{{ availableRoles[3].display }}</label>
                        <mat-slide-toggle formControlName="tracer"></mat-slide-toggle>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="p-2 my-1 d-flex justify-content-between align-items-center">
                        <label>{{ availableRoles[4].display }}</label>
                        <mat-slide-toggle formControlName="tracingSupervisor"></mat-slide-toggle>
                    </div>
                    <mat-divider></mat-divider>
                    <div *ngIf="showBetaWidget" class="p-2 my-1 d-flex justify-content-between align-items-center">
                        <label>{{ availableRoles[5].display }}</label>
                        <mat-slide-toggle formControlName="betaWidgets"></mat-slide-toggle>
                    </div>
                </div>

            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="cancelEdit();" >
        {{'cancel' | translate}}
    </button>
    <button mat-button class="orange-button" (click)="saveEdit()" [disabled]="!editUserForm.value.displayName">{{'save' | translate}}</button>
</mat-dialog-actions>