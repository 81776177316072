import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MdbCarouselComponent } from 'mdb-angular-ui-kit/carousel';
import {MatDialog,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ImagesHorizontalEditComponent } from '../../editWidgets/images-horizontal-edit/images-horizontal-edit.component';
import { ActivatedRoute } from '@angular/router';
import { ImagesEditComponent } from '../../editWidgets/images-edit/images-edit.component';
import { environment } from 'src/environments/environment';
import { checkDynamicContent, supportUrlStructure } from '../../../../../../backend/utils/object';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-images-horizontal-phone]',
  templateUrl: './images-horizontal-phone.component.html',
  styleUrls: ['./images-horizontal-phone.component.css']
})
export class ImagesHorizontalPhoneComponent implements OnInit {
  @Input('app-images-horizontal-phone') data:any;
  @ViewChild('carousel') carousel!: MdbCarouselComponent;
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any, images:any}>()

  imagesHorizontalData:any
  oldData:any
  allImages:any
  editMode:any
  dialogRef:any
  imagesDisplay:any
  imageToStorage:any=[]
  widgetNumber:any
  routerUrl:any
  idToShow:any
  url:any={}
  linkURL:any={}
  dynamic = false

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    private settingsService: SettingsService
    ){
    this.routerUrl=this.router.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  

  }

  ngOnInit(): void{
    this.oldData=this.data
    this.imagesHorizontalData=this.data[0]
    this.editMode=this.data[1]
    if(this.imagesHorizontalData.images)
    this.allImages=this.imagesHorizontalData.images.length
    else
    this.allImages =[]

    if(this.editMode){
      this.widgetNumber = this.data[2]
    }

    if(this.imagesHorizontalData.images){
      this.imagesHorizontalData.images.forEach((image, index) => {
        if(image.url){
          try{
            const partsd = image.url.includes("${")
            if(partsd){
              //image.url = checkDynamicContent(image.url)
              this.dynamic = true
            }
            let url = supportUrlStructure(image.url, this.idToShow, this.settingsService.contextId$)
            if(url)
            this.url[image.url]=url
            // const imgUrl = new URL(image.url)
            // let hostname = environment.urlHostName
            // if(imgUrl.protocol=="pass:" ){
            //   const parts = image.url.match(/pass:\/\/(.+)\/(.+)/)
            //   if(parts){
            //     const passTemplateID = parts[1]
            //     const path = parts[2]
            //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            //     this.url[image.url]=firebaseUrl 
            //   }else{
            //     const newparts = image.url.match(/pass:\/\/(.+)/)
            //     if(newparts){
            //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
            //       newparts.forEach( (part,index)=> {
            //         if(index>0){
            //           firebaseUrl += '/' + part 
            //         }
            //       })
            //       this.url[image.url]=firebaseUrl 
            //     }
            //   }         
            // }
            // if(imgUrl.protocol =="tridy:"){
            //   const parts = image.url.match(/tridy:\/\/(.+)\/(.+)/)
            //   let path =""
            //   parts.forEach((parte, index) =>{
            //     if(index>0 && index<parts.length){
            //       path += "/" + parte
            //     }
            //   })
            //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
            //   this.url[index]=firebaseUrl
            //   this.url[image.url]=firebaseUrl 
            // }
          }catch(error){}
        }

        if(image.linkURL){
          try{
            //image.linkURL = checkDynamicContent(image.linkURL)
            this.linkURL[image.linkURL]=image.linkURL

            let linkurl = supportUrlStructure(image.linkURL, this.idToShow, this.settingsService.contextId$)
            if(linkurl)
            this.linkURL[image.linkURL]=linkurl            
            // const imgUrl = new URL(image.linkURL)
            // let hostname = environment.urlHostName
            // if(imgUrl.protocol=="pass:" ){
            //   const parts = image.linkURL.match(/pass:\/\/(.+)\/(.+)/)
            //   if(parts){
            //     const passTemplateID = parts[1]
            //     const path = parts[2]
            //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            //     this.linkURL[image.linkURL]=firebaseUrl    
            //   }else{
            //     const newparts = image.linkURL.match(/pass:\/\/(.+)/)
            //     if(newparts){
            //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
            //       newparts.forEach( (part,index)=> {
            //         if(index>0){
            //           firebaseUrl += '/' + part 
            //         }
            //       })
            //       this.linkURL[image.linkURL]=firebaseUrl    
            //     }
            //   }      
            // }
            // if(imgUrl.protocol =="tridy:"){
            //   const parts = image.linkURL.match(/tridy:\/\/(.+)\/(.+)/)
            //   let path =""
            //   parts.forEach((parte, index) =>{
            //     if(index>0 && index<parts.length){
            //       path += "/" + parte
            //     }
            //   })
            //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
            //   this.linkURL[index]=firebaseUrl
            //   this.linkURL[image.linkURL]=firebaseUrl
            // }
          }catch(error){
          }

        }
      });
    }

    if(this.imagesHorizontalData.mediaURL){
      this.dynamic = true
    }
    
  }

  ngAfterViewInit(): void {
    this.imagesDisplay=this.imagesHorizontalData
    this.carousel.stop
  }

  openDialogEdit(){
    this.dialogRef = this.dialog.open(ImagesEditComponent,
      {
        data: {
          values: this.imagesHorizontalData,
          widget: this.widgetNumber,
          passId: this.idToShow,
          url: this.url
        }
      },
    );
    
    this.dialogRef.afterClosed().subscribe((result:any) => {
      if(result[1]){
        this.imagesHorizontalData=result[0]
        const images = result[2]
        images.forEach( img => {
          this.imageToStorage.push(img)
       })
       this.returnChanges.emit({newdata: this.imagesHorizontalData, widget: this.widgetNumber, widgetType: this.imagesHorizontalData.type,images: this.imageToStorage})
      }
    });
  }

  closeDialogEdit(updatedData:any){
    this.dialogRef.closeDialog()
  }
}

