import { environment } from "src/environments/environment";


export const isArray = function (x: any): boolean {
    return Object.prototype.toString.call(x) === '[object Array]';
}
export const isObject = function (x: any): boolean {
    return Object.prototype.toString.call(x) === '[object Object]';
}

export const removeUndefined = (obj: any): any => {
    for (var propName in obj) {
        if (isArray(obj[propName])) {
            let newArray = []
            for(let o of obj[propName]) {
                newArray.push(
                    removeUndefined(o)
                )
            }
            obj[propName] = newArray
        } else if (isObject(obj[propName])) {
            obj[propName] = removeUndefined(obj[propName])
        } else if (obj[propName] === undefined) {

            delete obj[propName];
        }
    }
    return obj
}

export const removeUndefinedValuesFromObject = <T>(obj: T): T => {
    Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
    return obj;
};

export const prepareDataToFirestoreUpdates = (data:any): any => {
    let newData: any = {}
    for(let [key,value] of Object.entries(data)){
        const {dataValue} = value as any
        newData[key] = value
        // newData[key] = dataValue ? dataValue : undefined
    }

    return newData
}

export function deepCopy(obj: any, replacerFn?: (key: string) => boolean): any {
    var copy: any;
    if (null == obj || "object" != typeof obj) return obj;
    // Handle Date
    if (isFirebaseGeoPoint(obj)) {
        
        // const copy = new GeoPoint(obj.latitude, obj.longitude)
        // return copy

        const gepoint = {
            latitude: obj.latitude,
            longitude: obj.longitude
        }
        return gepoint
    }

    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }
    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) {
                // replacerFn if exists should return true for values to be included, 
                // false for values to be skipped 
                if (replacerFn) {
                    if (replacerFn(attr)) {
                        copy[attr] = deepCopy(obj[attr])
                    } else {
                        continue
                    }
                } else copy[attr] = deepCopy(obj[attr])
            }
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

export const isFirebaseGeoPoint = function (x: any): boolean {
    if (!x) return false
    else return (x.latitude || x.longitude)
}

// this breaks multiple dynamic value usage in dpp
export const checkDynamicContent = function (url): string{
    let newurl = url
    try{
        let result = url.match(/\${(.+)\}/);
        if(result && result[1].includes(" ")){
            let newkey = result[1].replaceAll(" ", "_")
            newurl = url.replace(result[0],'${'+newkey+'}')
        }
    }catch(error){}
    
    return newurl
}

export const openMapDirection = function (markerPosition): string{
    let linkdirection = 'https://www.google.com/maps/dir//'
    let linkdirectionMap = 'https://www.google.com/maps/dir//'
    let pinInMaps = 'https://maps.google.com/?q='//<lat>,<lng>
    if(markerPosition.lat && markerPosition.lng){
      linkdirection += markerPosition.lat +',' + markerPosition.lng
      linkdirectionMap += markerPosition.lat +',' + markerPosition.lng + '/@' + markerPosition.lat +',' + markerPosition.lng
      pinInMaps += markerPosition.lat +',' + markerPosition.lng
    }

    // return pinInMaps
    return linkdirectionMap
}

export const supportUrlStructure = function(url, id, contextId?): string{
    let newurl 
    // let newurl = url
    try{
        if(url ){
            if(url.includes('https://') || url.includes('http://') || url.includes('pass://') || url.includes('tridy://') || url.includes('context://')){
                const imgUrl = new URL(url)
                let hostname = environment.urlHostName
        
                if(imgUrl){
                    if(imgUrl.protocol=="pass:" ){
                        const parts = url.match(/pass:\/\/(.+)\/(.+)/)
                        if(parts){
                            const passTemplateID = parts[1]
                            const path = parts[2]
                            const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
                            newurl=firebaseUrl
                        }else{
                            const newparts = url.match(/pass:\/\/(.+)/)
                            if(newparts){
                                let firebaseUrl = `https://${hostname}/v1/pass/${id}`
                                newparts.forEach( (part,index)=> {
                                    if(index>0){
                                        firebaseUrl += '/' + part 
                                    }
                                })
                                newurl=firebaseUrl
                            }
                        }
                        
                    }
                
                    if(imgUrl.protocol =="tridy:"){
                        const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
                        let path =""
                        parts.forEach((parte, index) =>{
                            if(index>0 && index<parts.length){
                                path += "/" + parte
                            }
                        })
                        const firebaseUrl = `https://${hostname}/v1/pass${path}`
                        newurl=firebaseUrl
                    }
                
                    if(imgUrl.protocol == "https:" && imgUrl.host == "firebasestorage.googleapis.com"){
                        const parts = url.match(/https:\/\/firebasestorage.googleapis.com(.+)/)
                        const host = imgUrl.pathname.match(/narravero-eu-dev/)
                        const passes = imgUrl.pathname.match(/(.+)passes(.+)/)
                        const info = passes[2].split("%2F")
                        const passTemplateID = info[1]
                        const path = info[2]
                        const name = decodeURI(info[2])
                        const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
                        newurl=firebaseUrl
                    }
        
                    if(imgUrl.protocol == "context:"){
                        const parts = url.match(/context:\/\/(.+)\/(.+)/)
                        let path =""
                        parts.forEach((parte, index) =>{
                            if(index>0 && index<parts.length){
                                path += "/" + parte
                            }
                        })
                        const firebaseUrl = `https://${hostname}/v1/context/${contextId}${path}`
                        newurl=firebaseUrl
                    }

                    if(imgUrl.protocol == "https:"){
                        newurl=imgUrl.href
                    }
                }
            }else{
                const imgUrl = new URL(url)
                newurl = imgUrl
            }
        }
    }catch(e){
        console.log(e)
    }

    return newurl
  }

export const makeid = function(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}


export const checkHexColor = function (hex): string{
    let colorCode 
    try{
        let structure = hex.match(/\#(.+)/);
        if(structure){
            colorCode = hex
        }
        
    }catch(error){}
    
    return colorCode
}


export function getSizeBytes(numberbytes: number, units, numDecimals): string {
    if (numberbytes === 0) return '0 Bytes';
    
    const i = Math.floor(Math.log(numberbytes) / Math.log(1024));
  
    return (numberbytes / Math.pow(1024, i)).toFixed(numDecimals) + ' ' + units[i];
  }