export const languagesList = {
    // MARK: - Languages
    "be":"Belarusian",
    "bg":"Bulgarian",
    "bs":"Bosnian",
    "ca":"Catalan",
    "cs":"Czech",
    "da":"Danish",
    "de":"German",
    "el":"Greek",
    "en":"English",
    "es":"Spanish",
    "fi":"Finnish",
    "fr":"French",
    "gl":"Galician",
    "gsw":"Swiss German",
    "hr":"Croatian",
    "hu":"Hungarian",
    "it":"Italian",
    "lmo":"Lombard",
    "lt":"Lithuanian",
    "nap":"Neapolitan",
    "nl":"Dutch",
    "no":"Norwegian",
    "pl.":"Polish",
    "pt":"Portuguese",
    "ro":"Romanian",
    "ru":"Russian",
    "scn":"Sicilian",
    "sk":"Slovak",
    "sl":"Slovene",
    "sr":"Serbian",
    "sv":"Swedish",
    "tr":"Turkish",
    "tt":"Tatar",
    "uk":"Ukrainian",
    "vec":"Venetian"
}


export const languagesList_ = {
    // MARK: - Languages
    en: {
        "be":"Belarusian",
        "bg":"Bulgarian",
        "bs":"Bosnian",
        "ca":"Catalan",
        "cs":"Czech",
        "da":"Danish",
        "de":"German",
        "el":"Greek",
        "en":"English",
        "es":"Spanish",
        "fi":"Finnish",
        "fr":"French",
        "gl":"Galician",
        "gsw":"Swiss German",
        "hr":"Croatian",
        "hu":"Hungarian",
        "it":"Italian",
        "lmo":"Lombard",
        "lt":"Lithuanian",
        "nap":"Neapolitan",
        "nl":"Dutch",
        "no":"Norwegian",
        "pl.":"Polish",
        "pt":"Portuguese",
        "ro":"Romanian",
        "ru":"Russian",
        "scn":"Sicilian",
        "sk":"Slovak",
        "sl":"Slovene",
        "sr":"Serbian",
        "sv":"Swedish",
        "tr":"Turkish",
        "tt":"Tatar",
        "uk":"Ukrainian",
        "vec":"Venetian"
    },
    de: {
        "be": "Weißrussisch",
        "bg": "Bulgarisch",
        "bs": "Bosnisch",
        "ca": "Katalanisch",
        "cs": "Tschechisch",
        "da": "Dänisch",
        "de": "Deutsch",
        "el": "Griechisch",
        "en": "Englisch",
        "es": "Spanisch",
        "fi": "Finnisch",
        "fr": "Französisch",
        "gl": "Galicisch",
        "gsw": "Schweizerdeutsch",
        "hr": "Kroatisch",
        "hu": "Ungarisch",
        "it": "Italienisch",
        "lmo": "Lombardisch",
        "lt": "Litauisch",
        "nap": "Neapolitanisch",
        "nl": "Niederländisch",
        "no": "Norwegisch",
        "pl.": "Polnisch",
        "pt": "Portugiesisch",
        "ro": "Rumänisch",
        "ru": "Russisch",
        "scn": "Sizilianisch",
        "sk": "Slowakisch",
        "sl": "Slowenisch",
        "sr": "Serbisch",
        "sv": "Schwedisch",
        "tr": "Türkisch",
        "tt": "Tatarisch",
        "uk": "Ukrainisch",
        "vec": "Venezianisch"
    },
    es: {
        "be": "Bielorruso",
        "bg": "Búlgaro",
        "bs": "Bosnio",
        "ca": "Catalán",
        "cs": "Checo",
        "da": "Danés",
        "de": "Alemán",
        "el": "Griego",
        "en": "Inglés",
        "es": "Español",
        "fi": "Finlandés",
        "fr": "Francés",
        "gl": "Gallego",
        "gsw": "Suizo alemán",
        "hr": "Croata",
        "hu": "Húngaro",
        "it": "Italiano",
        "lmo": "Lombardo",
        "lt": "Lituano",
        "nap": "Napolitano",
        "nl": "Neerlandés",
        "no": "Noruego",
        "pl": "Polaco",
        "pt": "Portugués",
        "ro": "Rumano",
        "ru": "Ruso",
        "scn": "Siciliano",
        "sk": "Eslovaco",
        "sl": "Esloveno",
        "sr": "Serbio",
        "sv": "Sueco",
        "tr": "Turco",
        "tt": "Tártaro",
        "uk": "Ucraniano",
        "vec": "Veneciano"
    }
}