<h2 mat-dialog-title>{{'topNavbar.placeholderList' | translate}}</h2>

<mat-dialog-content style="width:500px;">
    <div class="pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >

            <div class="hint-text mb-3">
                {{'payloadkey_list_hint' | translate}}
                <!-- copy this component -->
            </div>

            <mat-divider class="mb-3"></mat-divider>

            <mat-card-content *ngIf="!showSpinner" class="px-2 py-3">
                <mat-accordion class="form-field-section">
                    <mat-expansion-panel  *ngFor="let placeholder of allPlaceholders; let index = index" ><!--expanded-->
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon style="margin-right: 10px;">data_object</mat-icon>
                                {{placeholder[0]}}
                            </mat-panel-title>
                            <mat-panel-description>
                                <button mat-icon-button style="color: var(--warmOrange);" [cdkCopyToClipboard]="placeholder[0]" (click)="$event.stopPropagation()">
                                    <mat-icon>content_copy</mat-icon>
                                </button>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div *ngIf="placeholder[1]">
                            <div *ngIf="placeholder[1].contentTypes?.length!=0">
                                <mat-divider class="pb-2"></mat-divider>
                                <span>{{'contentTypes.title' | translate }}</span>
                                <div *ngFor="let contentType of placeholder[1].contentTypes;" class="item-content my-1" >
                                    <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                                        <div class="d-flex flex-column flex-nowrap">
                                            <span style="color:var(--warmOrange);">{{getPlaceholderInfo(contentType.id, 'contentTypes')}}</span>
                                            <!-- <div>
                                                <span class="text-muted">{{ftask.creationDate.toDate() | date:'dd.MM.yyyy, HH:mm' }}</span>  
                                                <span *ngIf="ftask.timeStamp" class="text-muted">  - {{ftask.timeStamp.toDate() | date:'dd.MM.yyyy, HH:mm' }}</span>
                                            </div> -->
                                        </div>
                                        <!-- <span class="material-symbols-outlined" style="color:var(--warmOrange);">
                                            keyboard_arrow_right
                                        </span> -->
                                    </div>
                                </div>
                            </div>
    
    
                            <div *ngIf="placeholder[1].passTemplates?.length!=0">
                                <mat-divider class="pb-2"></mat-divider>
                                <span>{{'contentTypes.pass' | translate }}</span>
                                <div *ngFor="let passTemplates of placeholder[1].passTemplates;" class="item-content my-1" >
                                    <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                                        <div class="d-flex flex-column flex-nowrap">
                                            <span style="color:var(--warmOrange);">{{getPlaceholderInfo(passTemplates.id, 'passTemplates')}}</span>
                                            <!-- <div>
                                                <span class="text-muted">{{ftask.creationDate.toDate() | date:'dd.MM.yyyy, HH:mm' }}</span>  
                                                <span *ngIf="ftask.timeStamp" class="text-muted">  - {{ftask.timeStamp.toDate() | date:'dd.MM.yyyy, HH:mm' }}</span>
                                            </div> -->
                                        </div>
                                        <!-- <span class="material-symbols-outlined" style="color:var(--warmOrange);">
                                            keyboard_arrow_right
                                        </span> -->
                                    </div>
                                </div>
                            </div>
    
    
                            <div *ngIf="placeholder[1].worktaskTemplates?.length!=0">
                                <mat-divider class="pb-2"></mat-divider>
                                <span>{{'topNavbar.workTasks' | translate }}</span>
                                <div *ngFor="let worktaskTemplates of placeholder[1].worktaskTemplates;" class="item-content my-1" >
                                    <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                                        <div class="d-flex flex-column flex-nowrap">
                                            <span style="color:var(--warmOrange);">{{getPlaceholderInfo(worktaskTemplates.id, 'worktaskTemplates')}}</span>
                                            <!-- <div>
                                                <span class="text-muted">{{ftask.creationDate.toDate() | date:'dd.MM.yyyy, HH:mm' }}</span>  
                                                <span *ngIf="ftask.timeStamp" class="text-muted">  - {{ftask.timeStamp.toDate() | date:'dd.MM.yyyy, HH:mm' }}</span>
                                            </div> -->
                                        </div>
                                        <!-- <span class="material-symbols-outlined" style="color:var(--warmOrange);">
                                            keyboard_arrow_right
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card-content>

            <div *ngIf="showSpinner" class="login-spinner">
                <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
            </div>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="dialogCancel();"> {{'Close' | translate}} </button>
</mat-dialog-actions>