import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-worktask-template-import-field-edit-dialog',
  templateUrl: './worktask-template-import-field-edit-dialog.component.html',
  styleUrls: ['./worktask-template-import-field-edit-dialog.component.css']
})
export class TaskTemplateImportFieldEditDialogComponent {
  editFieldItemForm: FormGroup;
  editMediaItemForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<TaskTemplateImportFieldEditDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;

    this.editFieldItemForm = this.fb.group({
      key: [data.key, [Validators.required, Validators.pattern('^(?![_\\.])(?!.*[_\\.]$)(?!.*[\'\";,#%&<>(){}\\[\\]/\\\\|`*@$^=+?!]).*$')]],
      tridyPayloadKey: [data.tridyPayloadKey, [Validators.required, Validators.pattern('^(?![_\\.])(?!.*[_\\.]$)(?!.*[\'\";,#%&<>(){}\\[\\]/\\\\|`*@$^=+?!]).*$')]],
      id: [data.id]
    });
    this.editMediaItemForm = this.fb.group({
      targetKey: [data.targetKey, [Validators.required, Validators.pattern('^(?![_\\.])(?!.*[_\\.]$)(?!.*[\'\";,#%&<>(){}\\[\\]/\\\\|`*@$^=+?!]).*$')]],
      sourceKey: [data.sourceKey, [Validators.required, Validators.pattern('^(?![_\\.])(?!.*[_\\.]$)(?!.*[\'\";,#%&<>(){}\\[\\]/\\\\|`*@$^=+?!]).*$')]],
      id: [data.id]
    });
  }

  onSave(): void {
    if (this.editFieldItemForm.valid) {
      this.dialogRef.close(this.editFieldItemForm.value);
    } else if (this.editMediaItemForm.valid) {
      this.dialogRef.close(this.editMediaItemForm.value);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}