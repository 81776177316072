import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettingsService } from '../service/settings.service';
import { Account } from "./../../../../backend/src/account.dto"
import { languagesList, languagesList_ } from "./../../../../backend/src/languagesList.dto";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AccountService } from '../service/account.service';
import { CommentsService } from '../service/comments.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-account',
  templateUrl: './account-languages-dialog.component.html',
  styleUrls: ['./account-languages-dialog.component.css']
})
export class AccountLanguagesDialogComponent implements OnInit {
  accountForm!:FormGroup
  accountData:Account
  languagesList = languagesList
  languages:any
  languagesContexts:any
  editMode=false
  routerUrl:any
  idToShow:any
  accountUserRights_update=false
  accountUserRights_read=false
  accountUserRights_create=false

  constructor(
    private dialogRef: MatDialogRef<AccountLanguagesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private settingsService: SettingsService,
    private fb: FormBuilder, 
    private accountService: AccountService,
    private commentsService: CommentsService,
    private route:ActivatedRoute,
    private translateService: TranslateService){
      this.routerUrl=this.route.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path
      const currentLang = this.translateService.currentLang;
      this.languagesList = languagesList_[currentLang]
  }

  ngOnInit():void{
    if(this.settingsService.modulesRolesRights$.value['context']){
      this.accountUserRights_update = this.settingsService.modulesRolesRights$.value['context']['U']
      this.accountUserRights_read = this.settingsService.modulesRolesRights$.value['context']['R']
      this.accountUserRights_create = this.settingsService.modulesRolesRights$.value['context']['C']
    }

    this.settingsService.modulesRolesRights$.subscribe( value => {
      this.accountUserRights_update = this.settingsService.modulesRolesRights$.value['context']['U']
      this.accountUserRights_read = this.settingsService.modulesRolesRights$.value['context']['R']
      this.accountUserRights_create = this.settingsService.modulesRolesRights$.value['context']['C']
    })
    const data = this.settingsService.contextAccount$
    // const data = this.settingsService.fieldContexts$[0]
    this.accountData = new Account
    this.accountData.setFromAny(data)
    this.languagesContexts = this.settingsService.languagesContexts$
    this.languages = Object.entries(this.languagesList)
    this.languages.sort( (a,b) => { return a[1].toLowerCase().localeCompare(b[1].toLowerCase());})

    this.initializeFormAccountLanguage();
    this.settingsService.selectedContextDataChange$.subscribe( value => {
      const data = value
      this.accountData = new Account
      this.accountData.setFromAny(data)
      this.languagesContexts = data['languages']
      this.accountForm.patchValue({
        languages: data['languages'] ? data['languages'] : undefined
      })
    })
  }

  initializeFormAccountLanguage(){
    this.accountForm = this.fb.group({
      languages:[]
    })

    let languages  = []
    if(this.accountData.languages){
      this.accountData.languages.forEach(el => {
        languages.push(el)
      })
    }
    
    this.accountForm.patchValue({
      languages: languages ? languages : undefined
    })

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.accountForm.value.languages, event.previousIndex, event.currentIndex);
  }

  addLanguageToContexts(lang){
    if(this.accountForm.value.languages.includes(lang)){
    }else{
      const langform = this.accountForm.value.languages
      langform.push(lang)
      this.accountForm.patchValue({language: [...langform,lang]})
    }
    
  }


  saveEdit(){
    try{
      this.commentsService.progressSpin.emit(true)
      this.accountService.updateContextDataAccount(this.accountForm.value)
      this.settingsService.observeContextsIdLoggedFirestore(this.settingsService.contextId$)
      this.accountData.setLanguages(this.accountForm.value)
      if(this.accountData.languages)
      this.languagesContexts = this.accountData.languages
      const message = this.translateService.instant("SnackBarConstants.UPDATE_OK")
      this.commentsService.addSnackBar.emit(message)
      this.commentsService.progressSpin.emit(false)
      this.dialogRef.close();

    }catch(error){
      console.log(error)
      const message = this.translateService.instant("SnackBarConstants.UPDATE_FAILED")
      this.commentsService.addSnackBar.emit(message)
      this.commentsService.progressSpin.emit(false)
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  deleteLanguage(index){
    const bdelete = this.accountForm.value.languages
    bdelete.splice(index,1)
    this.accountForm.patchValue({languages: bdelete})
  }
}
