<section class="container">

  <div class="row mt-4">
    <section  class="d-flex flex-row justify-content-between align-items-center mt-4 mb-3">
      <h1 class="mb-1">{{'tridys.title' | translate}}</h1>
      <mat-form-field class="search">
          <mat-label>{{'search_title' | translate}}</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
          <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </section>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      @if(tridysData.length === 0) {
      <div class="p-2 empty-list" >{{'tridys.empty_title' | translate}}</div>
      }
      <div class="shadow-sm mat-elevation-z8 mb-4">
        <table mat-table [dataSource]="dataSource" matSort>
      
          <ng-container matColumnDef="contentName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'contentTypes.contentType' | translate}} </th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.contentName" class="text-wrap text-break">{{element.contentName}}</span> </td>
          </ng-container>
      
          <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'creation_date' | translate}} </th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.creationDate" class="text-wrap text-break">{{element.creationDate.toDate() |  date: 'dd.MM.yyyy, HH:mm'}}</span> 
            </td>
          </ng-container>
      
          <ng-container matColumnDef="timeStamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'last_update' | translate}} </th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.timeStamp" class="text-wrap text-break">{{element.timeStamp.toDate() |  date: 'dd.MM.yyyy, HH:mm'}} </span>        
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break border-bottom" routerLink="{{row.id}}"></tr>
        </table>
          
        <mat-paginator [pageSizeOptions]="paginatorSize" showFirstLastButtons aria-label="Select page of tridys" class="pb-2"></mat-paginator>
      </div>
    </div>
  </div>

</section>
