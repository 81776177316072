import { Component } from '@angular/core';
import { SettingsService } from '../../service/settings.service';
import { CommentsService } from '../../service/comments.service';
import { MatDialog } from '@angular/material/dialog';
import { TaskTemplatesGroupsDialogComponent } from '../worktask-templates-groups-dialog/worktask-templates-groups-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeleteConfirmationDialogComponent } from '../../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { WorkTaskTemplatesService } from '../../service/work-task-templates.service';

@Component({
  selector: 'app-worktask-templates-groups',
  templateUrl: './worktask-templates-groups.component.html',
  styleUrls: ['./worktask-templates-groups.component.css']
})
export class TaskTemplatesGroupsComponent {
allGroups:any=[]
tasktemplateUserRights_create:any
tasktemplateUserRights_update:any
tasktemplateUserRights_delete:any
emptyList=false
InGroupList:any
othersList:any
taskTemplates:any=[]
activeDelete=false
activeEdit = false
selectedTitle: string

constructor(
  private settingsService: SettingsService, 
  private commentsService: CommentsService,
  public dialog: MatDialog,
  private translate:TranslateService,
  private worktaskService: WorkTaskTemplatesService){
}

ngOnInit(): void {
  this.tasktemplateUserRights_create = this.settingsService.userRolesRights$['workTaskTemplates']['C']
  this.tasktemplateUserRights_update = this.settingsService.userRolesRights$['workTaskTemplates']['U']
  this.tasktemplateUserRights_delete = this.settingsService.userRolesRights$['workTaskTemplates']['D']

  if(!this.tasktemplateUserRights_create){
    document.getElementById('btn-create').style.color="#e0e0e0"
  }
  this.getTasksTemplatesGroups()
  this.getTasksTemplates()

}

  getTasksTemplatesGroups(){
    try{
      this.commentsService.progressSpin.emit(true)
      this.allGroups = this.settingsService.observeContextsWorkTasksTemplatesGroupsFirestoreWithId()
      if(this.allGroups.length==0)
        this.emptyList=true
      this.commentsService.progressSpin.emit(false)
    }catch(error){
      this.commentsService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentsService.addSnackBar.emit(message)
    }
  }

  drop(event: CdkDragDrop<any[]>, groupId: string) {
    let groupTasks = this.getTasksForGroup(groupId);
    // Reorder tasks
    moveItemInArray(groupTasks, event.previousIndex, event.currentIndex);
    // Reassign sortIndex to each task and update Firestore
    groupTasks.forEach((task, index) => {
      task.sortIndex = index; // Assigning new index from 0 to n
      this.updateTaskSortIndex(this.settingsService.contextId$, task.id, task.sortIndex);
    });
  }
  
  updateTaskSortIndex(contextId: string, taskId: string, sortIndex: number) {
    this.settingsService.updateTaskSortIndex(contextId, taskId, { sortIndex })
      //.then(() => console.log(`Updated task ${taskId} with sortIndex ${sortIndex} in context ${contextId}`))
      .catch(error => console.error('Error updating sortIndex:', error));
  }
  
  getTasksTemplates(){
    try{
      this.commentsService.progressSpin.emit(true)
      // this.taskTemplates = this.settingsService.observeContextsWorkTasksTemplatesFirestoreWithId()
      this.settingsService.observeContextsWorkTasksTemplatesFirestoreWithId().subscribe( value => {
        this.taskTemplates = value
        if(this.taskTemplates?.length==0){
          this.emptyList=true
        }    
        this.commentsService.progressSpin.emit(false)
      })
    }catch(error){
      this.commentsService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentsService.addSnackBar.emit(message)
    }
  }


  addGroup(){

    let dialogRef= this.dialog.open(TaskTemplatesGroupsDialogComponent, {
      data: { 
        contextId: this.settingsService.contextId$,
        sortIndex: this.settingsService.settingsWorkTaskTemplatesGroupsSize,
        option: 'create'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.getTasksTemplatesGroups()
      }
    });
    
  }

  deleteGroup(group, indexSelected){
    let dialogRef= this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { 
        values:group,
        type:"TaskTemplateGroup"
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.removeTasksFromDeletedGroup(group.id)
        this.getTasksTemplatesGroups()
        
      }else{
        const message = this.translate.instant("SnackBarConstants.DELETE_DISMISS")
        this.commentsService.addSnackBar.emit(message)
      }
    });
    // this should also update tasks to remove the group there too
  }

  getTasksForGroup(groupId: string) {
    return this.taskTemplates
      ?.filter(task => task.groupId === groupId)
      ?.sort((a, b) => (a.sortIndex ?? Infinity) - (b.sortIndex ?? Infinity));  // Sorting by sortIndex
  }
  
  renameGroup(taskSelected, indexSelected){
    let dialogRef= this.dialog.open(TaskTemplatesGroupsDialogComponent, {
      data: { 
         contextId: this.settingsService.contextId$,
         option: 'edit',
         group: taskSelected
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.getTasksTemplatesGroups()
      }
    });
  }


  async removeTasksFromDeletedGroup(group: string) {

      this.commentsService.progressSpin.emit(true)


      const tasksOnGroup = this.getTasksForGroup(group)

      await tasksOnGroup?.forEach(async task => {
          await this.worktaskService.removeTaskFromGroup(task, this.settingsService.contextId$)
      });
      this.commentsService.progressSpin.emit(false)
      this.getTasksTemplates()
  }
}
