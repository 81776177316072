import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { TridysService } from '../service/tridys.service';
import { CommentsService } from '../service/comments.service';
import * as QRCode from 'qrcode';
import { isDateTime } from '../../../../backend/src/filter';

@Component({
  selector: 'app-qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.css']
})
export class QrCodeDialogComponent {
  myAngularxQrCode: string = null
  QRdata:string =""
  QRId:string
  nfcURLPrefix: any;
  QRimage:string
  QRname:string

  constructor(
    public dialogRef: MatDialogRef<QrCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tridyService: TridysService,
    private commentsService: CommentsService
    ){
      this.myAngularxQrCode = environment.tagHostName
      dialogRef.disableClose = true;
    }

  ngOnInit(){
    this.QRId = this.data['id']
    this.nfcURLPrefix = this.data['nfcPrefix']
    const prefix = this.nfcURLPrefix ? this.nfcURLPrefix + '/' : '';
    this.QRdata = this.myAngularxQrCode + prefix + this.QRId;
    this.QRname = this.data.name

    QRCode.toDataURL(this.QRdata).then(url => {
      this.QRimage = url;
    }).catch(err => { console.error('Error generating QR code: ', err); });
  }

  closeQRDialog(){
    this.dialogRef.close()
  }

  downloadQR(){
    const link = document.createElement('a');
    link.href = this.QRimage;
    const date = new Date()
    const fileName = `${this.QRname.replace(' ', '')}_${ isDateTime(date)}.png`
    link.download = fileName; // Set the file name
    link.click();
  }

  openQRurl(){
    window.open(this.QRdata, "_blank");
  }
}
