import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { CommentsService } from '../service/comments.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../service/settings.service';
import { environment } from 'src/environments/environment';
import { build, version } from 'src/buildVersion';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { browserLocalPersistence, getAuth } from '@angular/fire/auth';
import { InfoMesageDialogComponent } from '../info-mesage-dialog/info-mesage-dialog.component';
import { HttpClient } from '@angular/common/http';
import { getFunctions, Functions, httpsCallable } from "@angular/fire/functions";
import { getApp } from '@angular/fire/app';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  routerUrl:any
  idToShow:any
  currentRoute:any
  environmentName=environment.firebase
  buildEnvironment:string
  version=version
  languageSelected:any
  linkOutdated:boolean=false
  showSpinner:boolean=true
  processingReinvite:boolean=false
  resendInvitationError:boolean=false
  languageFlag:any
  buildNumber=build
  //enterEmail:any=false
  passwordMismatch:any
  hidePasswort = true
  finishRegistrationForm!: FormGroup
  linkAuthentication:any
  private auth:any
  private readonly functions: Functions;

  constructor(
    private fireauth: AngularFireAuth,
    private http: HttpClient,
    private router:Router,
    private commentsService: CommentsService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private fb : FormBuilder,
    // private functions: Functions,
    private translate:TranslateService,
    private settingsService: SettingsService,
    private route: Router){
      this.routerUrl=this.router.url
      const currentRoute = window.location.href
      this.currentRoute=window.location.href
      this.idToShow=currentRoute.split('?')
      const functions = getFunctions(getApp(), 'europe-west1');
      this.functions = functions;
    }

  ngOnInit(){

    const langFromUrl =this.activatedRoute.snapshot.queryParams['lang'];
    const browserLang = this.translate.getBrowserLang();
    if (langFromUrl) {
      this.languageSelected = langFromUrl
    }
    else {
      this.languageSelected = browserLang
    }
    if(this.languageSelected=="en"){
      this.languageFlag="gb"
    }else{
      this.languageFlag=this.languageSelected
    }

    this.useLanguage(this.languageSelected)

    if(this.currentRoute){
      if(this.idToShow.length>1){
        const emailFromUrl = this.activatedRoute.snapshot.queryParams['email'];
        //const oobCode = this.activatedRoute.snapshot.queryParams['oobCode'];

        /* if(!emailFromUrl){
          this.enterEmail=false
        } */

        this.finishRegistrationForm = this.fb.group({
          email: ['', [Validators.required, Validators.email]],
          password: ['', [Validators.required, Validators.minLength(6)]],
          confirmPassword: ['', [Validators.required, this.passwordMatchValidator()]],
        });

          this.finishRegistrationForm.patchValue({ email: emailFromUrl });

          // If email is present in the URL, patch it to the form and call finishRegistration
          /*  if (emailFromUrl) {
            this.finishRegistrationForm.patchValue({ email: emailFromUrl });
          } else {
            this.enterEmail = true;
          } */

          setTimeout(() => this.showSpinner=false, 500);

      }else{
        this.route.navigate(['home'])
      }
    }
    if(this.environmentName.projectId=="narravero-eu-dev"){
      this.buildEnvironment="Narravero DEV "
    }
    if(this.environmentName.projectId=="narravero-eu-prod"){
      this.buildEnvironment="Narravero "
    }
    if(this.environmentName.projectId=="narravero-eu-test"){
      this.buildEnvironment="Narravero TEST "
    }
  }

  useLanguage(language: string): void {
    this.translate.use(language);
    if(language=="en"){
      this.languageFlag="gb"
    }else{
      this.languageFlag=language
    }
  }

  passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.finishRegistrationForm && control.value === this.finishRegistrationForm.get('password')?.value) {
        return null;
      }
      return { passwordMismatch: true };
    };
  }

  finishRegistration(){
    if(this.finishRegistrationForm.value.email){
      const emailConfirmation= this.finishRegistrationForm.value.email
      const emailLink = this.linkAuthentication

        this.showSpinner=true
        this.auth = getAuth();
        if (this.fireauth.isSignInWithEmailLink(emailLink)) {

          var email = emailConfirmation
          /*  if (!email) {
            email = window.prompt('Please provide your email for confirmation');
            this.showSpinner=false
          } */

          this.auth.setPersistence(browserLocalPersistence)
            .then(() => {
      
              return this.fireauth.signInWithEmailLink(email, window.location.href)
                  .then((result) => {
                    window.localStorage.removeItem('emailForSignIn');
                    setTimeout(() => this.onSubmit(), 1000);
                    return result
      
                  })
                  .catch((error) => {
                    this.linkOutdated = true
                    const message = this.translate.instant("SnackBarConstants.REGISTER_FAILED")
                    this.commentsService.addSnackBar.emit(message)
                    this.showSpinner=false
                    const object = { data:"logged user"}
                    return object
                  });
              })
              .catch((error) => {
                const message = this.translate.instant("SnackBarConstants.LOGIN_FAILED")
                this.commentsService.addSnackBar.emit(message)
                console.error('Error enabling local persistence:', error);
                this.showSpinner=false
              });
        }
    }
  }

  resendInvitation() {
    this.processingReinvite = true;
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const values = {
      email: queryParams['email'],
      oobCode: queryParams['oobCode']
    };
    
    this.http.post('https://' + this.environmentName.projectId + '.firebaseapp.com/v1/user/resendinvite', values, {responseType: 'text', observe: 'response'})
    .subscribe({
      next: (response) => {
        if (response.status == 200) {
          let dialogRef = this.dialog.open(InfoMesageDialogComponent, {
            data: { 
              values: {
                message: 'USER_REINVITE_OK',
                mail: queryParams['email']
              }
            },
          });
          dialogRef.afterClosed().subscribe(() => {
            this.router.navigate(['/']);
          });
        } else {
          this.resendInvitationError = true
          const message = this.translate.instant("SnackBarConstants.USER_REINVITE_FAILED");
          this.commentsService.addSnackBar.emit(message);
        }

        this.processingReinvite = false;
      },
      error: (error) => {
        this.processingReinvite = false;
        this.resendInvitationError = true
        const message = this.translate.instant("SnackBarConstants.USER_REINVITE_FAILED");
        this.commentsService.addSnackBar.emit(message);
      }
    });
  }

  onSubmit(){
    this.showSpinner=true
    if(this.finishRegistrationForm.value.password && this.finishRegistrationForm.value.confirmPassword 
      && this.finishRegistrationForm.value.password == this.finishRegistrationForm.value.confirmPassword){
      const auth = getAuth()

      const currentUser = auth.currentUser
      const uid = auth.currentUser.uid
      const email = currentUser.email
      const password = this.finishRegistrationForm.value.password
      const values = {"email": email, "password": password}
      const finishUserRegistration = httpsCallable(this.functions, 'admin-finishUserRegistration');

      finishUserRegistration(values).then((result) => {
            this.settingsService.getLoggedData()
            try{
              this.showSpinner=true
              this.commentsService.progressSpin.emit(true)
              setTimeout(() => {
              this.router.navigate(['home'])
              // this.router.navigate(['selectAccount'])
              this.showSpinner=false
              this.commentsService.progressSpin.emit(false)
              }, 1000);
              // Data is observed within selectAccount route
              //this.observeData()
            }catch(error){
              console.log(error)
            }
          })
          .catch((error) => {
            this.showSpinner=false
            console.log(error)
            const message = this.translate.instant("SnackBarConstants.CREATE_FAILED")
            this.commentsService.addSnackBar.emit(message)
            this.commentsService.addSnackBar.emit(error)
          });
    }
  }
}
