import { AbstractControl, ValidationErrors } from '@angular/forms';

export function checkUrlProtocol(control: AbstractControl): ValidationErrors | null {
    const urlRegex = /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    const urlRegexDoc = /^pass?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    const urlRegexDocContext = /^context?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    
    const value = control.value
    let urlPattern = false
    if(value){
        try {
            let url = new URL(value)
            if(url.protocol === "http:" || url.protocol === "https:"){
                urlPattern = true
            }
            if(url.protocol === "pass:" ){
                urlPattern = true
            }
            if(url.protocol === "context:" ){
                urlPattern = true
            }
        } catch (error) {}  
    }else{
        urlPattern = true
    }
  
    return urlPattern ? undefined : {pattern:true};
  }