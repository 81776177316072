import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileHandle } from 'src/app/directive/drag-and-drop.directive';
import { CommentsService } from 'src/app/service/comments.service';
import { UploadService } from 'src/app/service/upload.service';
import { SplashVideo } from './../../../../../../backend/src/splashVideo.dto'
import { MediaDetailsDialogComponent } from '../media-details-dialog/media-details-dialog.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { languagesList } from '../../../../../../backend/src/languagesList.dto';

@Component({
  selector: 'app-splash-video-edit',
  templateUrl: './splash-video-edit.component.html',
  styleUrls: ['./splash-video-edit.component.css']
})
export class SplashVideoEditComponent {
  splashVideoDataEdit:any
  oldData:any
  url:any
  noUrl=false
  splashVideoEditForm!:FormGroup
  changes = false
  newVideo:any=[]
  widgetNumber:any
  passId:any
  files:FileHandle[] = []
  drop = false
  urlData:any
  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  @ViewChild('media') myVideo: ElementRef;
  addUrl = false
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  languagesList = languagesList
  defaultLanguage:string
  
  constructor(public dialogRef: MatDialogRef<SplashVideoEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private uploadService: UploadService,
    private fb: FormBuilder,
    private commentService: CommentsService,
    private translate:TranslateService){
      dialogRef.disableClose = true;
  }

  ngOnInit():void{
    console.log(this.data.values)
    this.defaultLanguage = this.data.defaultLanguage
    this.oldData = new SplashVideo
    this.oldData.setFromAny(this.data.values)
    this.splashVideoDataEdit = this.data.values
    this.widgetNumber= this.data['widget']
    this.passId = this.data['passId']
    
    if(this.data['url'])
      this.urlData = this.data['url']
    else
      this.supportUrl(this.splashVideoDataEdit.url)

    if(this.splashVideoDataEdit.url)
      this.url=this.splashVideoDataEdit.url
    
    this.splashVideoEditForm = this.fb.group({
      id:[],
      type:[],
      url:[],
      copyright:[],
      title:[],
      autoPlay:[],
      autoRePlay:[],
      muted:[],
      videoControls:[],
      linkURL:[,[Validators.required, Validators.pattern(this.urlRegex)]],
      size:[],
      name:[],
      filter:[]
    })

    const {id,filter, url, type, copyright, title, autoPlay, autoRePlay, muted, videoControls, linkURL, size,name} = this.splashVideoDataEdit

    this.splashVideoEditForm.patchValue({
      id : id ? id : undefined,
      url : url ? url : undefined,
      copyright : copyright ? copyright : undefined,
      title: title ? title : undefined,
      type : type ? type : undefined,
      autoPlay: autoPlay ? autoPlay : false,
      autoRePlay: autoRePlay ? autoRePlay : false,
      muted: muted ? muted : false,
      videoControls: videoControls ? videoControls : false,
      linkURL: linkURL ? linkURL : undefined,
      size: size ? size : undefined,
      name:name ? name : undefined,
      filter: filter ? filter : undefined
    })
  }

  onCancelEdit(){
    this.dialogRef.close([this.splashVideoDataEdit, false]);
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const maxSizeInBytes = 25 * 1024 * 1024; // 25 MB size limit
  
      if (file.size > maxSizeInBytes) {
        const message = this.translate.instant("SnackBarConstants.VIDEO_TOO_LARGE");
        this.commentService.addSnackBar.emit(message);
        return;
      }
  
      var reader = new FileReader();
      this.newVideo.push(file);
      reader.readAsDataURL(file);
  
      reader.onload = async (event) => {
        if (event.target) {
          const urlNew = event.target.result as string;
          if (urlNew) {
            this.url = urlNew;
            this.changes = true;
            this.urlData = "";
            this.noUrl = false;
  
            try {
              const uploadURL = await this.uploadService.uploadNewSplashVideo(this.passId, this.newVideo, this.widgetNumber);
              if (uploadURL) {
                this.url = uploadURL;
                this.urlData = urlNew;
              }
  
              this.splashVideoEditForm.patchValue({ url: this.url });
            } catch (error) {
              console.log(error);
              const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
              this.commentService.addSnackBar.emit(message);
            }
          }
        }
      };
    }
  }

  async filesDropped(files: FileHandle[]): Promise<void> {
    this.files = files;
    const fileToUpload = files[0].file;
    const maxSizeInBytes = 25 * 1024 * 1024; // 25 MB size limit
  
    if (fileToUpload.size > maxSizeInBytes) {
      const message = this.translate.instant("SnackBarConstants.VIDEO_TOO_LARGE");
      this.commentService.addSnackBar.emit(message);
      return;
    }
  
    this.newVideo.push(fileToUpload);
    const newUrl = files[0].url;
    const type = fileToUpload.type.split("/");
  
    if (type[0] === 'video') {
      if (newUrl) {
        this.drop = true;
        this.url = newUrl;
        this.changes = true;
  
        try {
          const uploadURL = await this.uploadService.uploadNewSplashVideo(this.passId, this.newVideo, this.widgetNumber);
          if (uploadURL) {
            this.url = uploadURL;
            this.urlData = newUrl;
          }
  
          this.splashVideoEditForm.patchValue({ url: this.url });
  
        } catch (error) {
          console.log(error);
          const message = this.translate.instant("SnackBarConstants.UPLOAD_IMG_FAILED");
          this.commentService.addSnackBar.emit(message);
        }
      }
    } else {
      const message = this.translate.instant('SnackBarConstants.VIDEO_TYPE');
      this.commentService.addSnackBar.emit(message);
    }
  }

  delete(){
    this.url= undefined
    this.splashVideoEditForm.patchValue({ url:this.url, size: undefined })
    this.urlData=""
  }

  editVideo(url){
    let dialogRef= this.dialog.open(MediaDetailsDialogComponent, {
      data: { 
        values: this.splashVideoEditForm.value,
        type: this.splashVideoEditForm.value.type,
        passId: this.passId
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        if(url!=this.splashVideoEditForm.value.url){
          this.urlData=""
        }
        this.url = result[0].url
        this.splashVideoEditForm.patchValue({url: this.url})
        if(this.url ){
          this.supportUrl(this.url)
        }
      }
    });
  }

  async saveChanges(){
    this.commentService.progressSpin.emit(true)
    if(!this.url)
      this.noUrl=true

      if(this.splashVideoEditForm.value.url){
        if(this.splashVideoEditForm.value.size?.length==0 || !this.splashVideoEditForm.value.size || (this.splashVideoEditForm.value.size && this.splashVideoEditForm.value.size[0]==0 && this.splashVideoEditForm.value.size[1]==0)){
          await this.onVideoMetadataSplashVideo()
        }
      }
      
    this.dialogRef.close([this.splashVideoEditForm.value, true, this.newVideo]);
    this.commentService.progressSpin.emit(false)
  }

  cancelChanges(){
    this.dialogRef.close([this.oldData, false])
  }

  supportUrl(url){
    this.urlData = url
    if(url ){
      try{
        const imgUrl = new URL(url)
        let hostname = environment.urlHostName
        
        if(imgUrl.protocol=="pass:" ){
          const parts = url.match(/pass:\/\/(.+)\/(.+)/)
          if(parts){
            const passTemplateID = parts[1]
            const path = parts[2]
            const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            this.urlData=firebaseUrl
          }else{
            const newparts = url.match(/pass:\/\/(.+)/)
            if(newparts){
              let firebaseUrl = `https://${hostname}/v1/pass/${this.passId}`
              newparts.forEach( (part,index)=> {
                if(index>0){
                  firebaseUrl += '/' + part 
                }
              })
              this.urlData=firebaseUrl
            }
          }
        }
  
        if(imgUrl.protocol =="tridy:"){
          const parts = url.match(/tridy:\/\/(.+)\/(.+)/)
          let path =""
          parts.forEach((parte, index) =>{
            if(index>0 && index<parts.length){
              path += "/" + parte
            }
          })
          const firebaseUrl = `https://${hostname}/v1/pass${path}`
          this.urlData=firebaseUrl
        }
      }catch(errro){}
    }
  }

  onVideoMetadataSplashVideo(event?: Event): void {
    try{
      const videoElement: HTMLVideoElement = this.myVideo.nativeElement;
      const width: number = videoElement.videoWidth;
      const height: number = videoElement.videoHeight;

      this.splashVideoEditForm.patchValue({size : [ width, height]})    
    }catch(error){
      this.commentService.progressSpin.emit(false)
    }
  }

  typeURL(){
    this.addUrl = true
    this.urlData = '..'
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  checkNewUrl(){
    this.url = this.splashVideoEditForm.value.url
    this.supportUrl(this.splashVideoEditForm.value.url)
  }


  returnChangesFilters($event){
    console.log($event)
    this.splashVideoEditForm.patchValue({filter: $event.newFilters})
  }

}
