<h2 mat-dialog-title>{{'workTask.template_settings' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" style="max-width:650px;" id="section" >

            <form [formGroup]="workTaskTemplateForm">
                <mat-form-field appearance="outline" class="w-50 pe-1">
                    <mat-label>{{'taskTemplates.wt_title' | translate}}</mat-label>
                    <input matInput type="text" formControlName="title" name="title" class="form-control" required [readonly]="!tasktemplateUserRights_update">
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-50 ps-1">
                    <mat-label>{{'taskTemplates.wt_group' | translate}}</mat-label>
                    <mat-select formControlName="groupId">
                        <mat-option [disabled]="!tasktemplateUserRights_update"> - </mat-option>
                        <mat-option *ngFor="let group of taskTemplateGroups" value="{{group.id}}" [disabled]="!tasktemplateUserRights_update">{{group.title}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="w-100" required>
                    <mat-label>{{'workTask.taskType' | translate}}</mat-label>
                    <mat-select formControlName="taskType" required>
                        <mat-option value="create" [disabled]="!tasktemplateUserRights_update">{{'workTask.select_create' | translate}} </mat-option>
                        <mat-option value="update" [disabled]="!tasktemplateUserRights_update">{{'workTask.select_update_existing' | translate}}</mat-option>
                        <mat-option value="transform" [disabled]="!tasktemplateUserRights_update">{{'workTask.select_transform' | translate}} </mat-option>
                    </mat-select>
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100" *ngIf="workTaskTemplateForm.value.taskType=='update' ">
                    <mat-label>{{'workTask.tridy_select' | translate}}</mat-label>
                    <mat-select formControlName="tridySource">
                        <mat-option value="select" [disabled]="!tasktemplateUserRights_update">{{'workTask.select_list' | translate}}</mat-option>
                        <mat-option value="scan" [disabled]="!tasktemplateUserRights_update">{{'workTask.select_scan' | translate}} </mat-option>
                        <mat-option value="scanQR" [disabled]="!tasktemplateUserRights_update">{{'workTask.select_scanQR' | translate}} </mat-option>
                        <mat-option value="scanNFC" [disabled]="!tasktemplateUserRights_update">{{'workTask.select_scanNFC' | translate}} </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field appearance="outline" *ngIf="workTaskTemplateForm.value.taskType!='transform'" class="w-100 p-0" >
                    <mat-label>{{'contentTypes.contentType' | translate}}</mat-label>
                    <mat-select formControlName="contentType" >
                        <mat-option value="" [disabled]="!tasktemplateUserRights_update"> - </mat-option>
                        <mat-option *ngFor="let content of contentTypesList" value="{{content.id}}" [disabled]="!tasktemplateUserRights_update">{{content.displayName}}</mat-option>
                    </mat-select>
                </mat-form-field>
        
                <div class="form-control d-flex justify-content-between p-3">
                    {{'workTask.visible' | translate}}  
                    <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [checked]="workTaskTemplateForm.value.visibleInPass" [disabled]="!tasktemplateUserRights_update"></mat-slide-toggle>
                    <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="visibleInPass" ></mat-slide-toggle>
                </div>

                <div class="form-control d-flex justify-content-between p-3 mt-3">
                    {{'workTask.hidden' | translate}}  
                    <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [checked]="workTaskTemplateForm.value.hidden" [disabled]="!tasktemplateUserRights_update"></mat-slide-toggle>
                    <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="hidden" ></mat-slide-toggle>
                </div>

                <div class="form-control d-flex justify-content-between p-3 mt-3">
                    {{'workTask.gpsRequired' | translate}}
                    <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [checked]="workTaskTemplateForm.value.gpsMandatory" [disabled]="!tasktemplateUserRights_update"></mat-slide-toggle>
                    <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="gpsMandatory" ></mat-slide-toggle>
                </div>
        
                <div class="form-control d-flex justify-content-between p-3 mt-3">
                    {{'workTask.showImages' | translate}}  
                    <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [checked]="workTaskTemplateForm.value.showImages" [disabled]="!tasktemplateUserRights_update"></mat-slide-toggle>
                    <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="showImages" ></mat-slide-toggle>
                </div>
        
                <div class="form-control d-flex justify-content-between p-3 mt-3">
                    {{'workTask.showComments' | translate}}  
                    <mat-slide-toggle *ngIf="!tasktemplateUserRights_update" [checked]="workTaskTemplateForm.value.showComment" [disabled]="!tasktemplateUserRights_update"></mat-slide-toggle>
                    <mat-slide-toggle *ngIf="tasktemplateUserRights_update" formControlName="showComment" ></mat-slide-toggle>
                </div>
                
                <div class="mt-4 transform" *ngIf="workTaskTemplateForm.value.taskType=='transform'">
                    <label for="incoming_tridys" class="text-uppercase mb-3">{{'workTask.incoming_tridys' | translate}}</label>
                    <div class="list-group pb-1 pe-1" style="max-height:300px; overflow-y: auto;">
                        <div class="list-group-item" *ngFor="let incoming of workTaskTemplateForm.value.incomingTridys; let index= index;" >
                            <div class="d-flex align-items-center justify-content-between flex-nowrap" >
                                <div class="d-flex w-100 flex-column align-items-start" (click)="openIncomingTridy(incoming, index)">
                                    <span class="font-weight-bold"> {{incoming.displayTitle}}</span>
                                    <span class="font-weight-normal" style="font-size: 14px;"> {{getContentTypeIdName(incoming.contentTypeId)}}</span>
                                    <span class="text-muted" style="font-size: 14px;"> {{getTridyMultiplicity(incoming.multiplicity)}}</span>
                                    <span class="text-muted" style="font-size: 14px;"> {{getTridySelection(incoming)}}</span>
                                </div>
                                <button mat-icon-button [disabled]="!tasktemplateUserRights_update" (click)="deleteIncomingTridy(incoming, index);">
                                    <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                        delete
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <button mat-button 
                            class="secondary-gray-button my-2"
                            (click)="addIncomingTridys();"
                            [disabled]="!tasktemplateUserRights_update"
                    >
                        <mat-icon>add_circle</mat-icon> {{'workTask.add_incoming_tridys' | translate}}
                    </button>
                   
                </div>

                <div class="mt-4 transform"  *ngIf="workTaskTemplateForm.value.taskType=='transform'">
                    <label for="outgoing_tridys" class="text-uppercase mb-3">{{'workTask.outgoing_tridys' | translate}}</label>

                    <div class="list-group pb-1 pe-1" style="max-height:300px; overflow-y: auto;">
                        <div class="list-group-item" *ngFor="let outgoing of workTaskTemplateForm.value.outgoingTridys; let index= index;" >
                            <div class="d-flex align-items-center justify-content-between flex-nowrap">
                                <div class="d-flex w-100 flex-column align-items-start" (click)="openOutgoingTridy(outgoing, index);">
                                    <span class="font-weight-bold"> {{outgoing.displayTitle}}</span>
                                    <span class="font-weight-bold" style="font-size: 14px;"> {{getContentTypeIdName(outgoing.contentTypeId)}}</span>
                                    <span class="font-weight-bold text-muted" style="font-size: 14px;"> {{getTridyAction(outgoing.actions)}}</span>
                                </div>
                                <button mat-icon-button [disabled]="!tasktemplateUserRights_update" (click)="deleteOutgoingTridy(outgoing, index);">
                                    <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                        delete
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <button mat-button 
                            class="secondary-gray-button my-2"
                            (click)="addOutgoingTridys();"
                            [disabled]="!tasktemplateUserRights_update"
                    >
                        <mat-icon>add_circle</mat-icon> {{'workTask.add_outgoing_tridys' | translate}}
                    </button>
                </div>
                
                <div class="mt-4"  *ngIf="workTaskTemplateForm.value.taskType!='transform'">
                    <label for="actions">{{'workTask.actions' | translate}}</label>
                    <!-- <div class="input-group" *ngFor="let s of posibleActions; let index= index;"  style="margin-bottom:5px;">
                        <input *ngIf="s.added" matInput id="{{s.name}}" type="text" class="form-control rounded" value="{{s.translate}}" readonly>
                        <div *ngIf="s.added" >
                            <button mat-icon-button [disabled]="!tasktemplateUserRights_update" (click)="deleteAction(s, index);">
                                <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                    delete
                                </span>
                            </button>
                        </div>
                    </div> -->

                    <div class="mb-3 pt-2">
                        
                        <p class="text-muted" style="font-size: 14px;">{{'workTask.action_description' | translate}}</p>
                        <div class="list-group list-group-flush" *ngFor="let s of posibleActions; let index= index;" >
                            <div class="p-1 ps-3 list-group-item border-bottom" *ngIf="s.added">
                                <div class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <!-- <input matInput id="{{s.name}}" type="text" class="form-control rounded" value="{{s.translate}}" readonly> -->
                                    <span id="{{s.name}}"> {{s.translate}}</span>
                                    <div >
                                        <button mat-icon-button [disabled]="!tasktemplateUserRights_update" (click)="deleteAction(s, index);">
                                            <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                                delete
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        

                    <button mat-button 
                            class="secondary-gray-button my-2"
                            id="btn-add"
                            [disabled]="!tasktemplateUserRights_update"
                            [matMenuTriggerFor]="actionList"
                    >
                        <mat-icon>add_circle</mat-icon> {{'workTask.add' | translate}}
                    </button>
                    <mat-menu #actionList="matMenu">
                        <div *ngFor="let sn of posibleActions">
                            <button [disabled]="!tasktemplateUserRights_update || sn.added==true || sn.disabled==true || workTaskTemplateForm.value.taskType=='transform'" mat-menu-item (click)="addAction(sn.name);">
                                <mat-icon *ngIf="sn.value=='writeTag'">draw</mat-icon>
                                <mat-icon *ngIf="sn.value=='verifyTag'">security_update_good</mat-icon>
                                <mat-icon *ngIf="sn.value=='lockTag'">lock</mat-icon>
                                <mat-icon *ngIf="sn.value=='verifyGermanPassport'">badge</mat-icon>
                                <mat-icon *ngIf="sn.value=='faceId'">person_pin</mat-icon>
                                <!-- <mat-icon *ngIf="sn.value=='faceId'">person_pin</mat-icon> --> 
                                {{sn.translate}}
                            </button>
                        </div>
                    </mat-menu>

                </div>
        
                <!-- <div class="m-2"  >
                    <label for="titleKeys">{{'workTask.title_keys' | translate}}</label>            
                    
                    <div class="input-group" *ngFor="let s of workTaskTemplateForm.value.titleKeys; let index= index;"  style="margin-bottom:5px;">
                        <input matInput id="{{s}}" type="text" class="form-control rounded" value="{{s}}" (click)="updateTitleKey(s, index);" readonly style="cursor:pointer;">
                        <div (click)="deleteTitleKey(s, index);" >
                            <button mat-icon-button [disabled]="!tasktemplateUserRights_update">
                                <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                    delete
                                </span>
                            </button>
                        </div>
                    </div>
        
                    <button type="button" mat-button style="width:100%; color: var(--warmOrange);box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);" (click)="addTitleKey();" [disabled]="!tasktemplateUserRights_update">{{'workTask.tk_add' | translate}}</button>
        
                    <p class="text-muted" style="text-align:center;">{{'workTask.tk_description' | translate}}</p>
                </div>
        
                <div class="m-2"  >
                    <label for="filter">{{'workTask.filter' | translate}}</label>    
                    <div class="input-group" *ngFor="let s of workTaskTemplateForm.value.predicates; let index= index;"  style="margin-bottom:5px;">
                        <input matInput id="{{s}}" type="text" class="form-control rounded" value="{{s}}" (click)="updatePredicate(s, index);" readonly style="cursor:pointer;">
                        <div (click)="deletePredicate(s, index);">
                            <button mat-icon-button [disabled]="!tasktemplateUserRights_update">
                                <span class="material-symbols-outlined" style="color: var(--accentGlobal);">
                                    delete
                                </span>
                            </button>
                        </div>
                    </div>
        
                    <button type="button" [disabled]="!tasktemplateUserRights_update" mat-button style="width:100%; color: var(--warmOrange);box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);" (click)="addPredicate();">{{'workTask.f_add' | translate}}</button>
                    <p class="text-muted" style="text-align:center;">{{'workTask.f_description' | translate}}</p>
                </div> -->
                
            </form>

        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancel();" >{{'cancel' | translate}}</button>
    <button mat-button class="orange-button" (click)="onDone();" [disabled]="!tasktemplateUserRights_update || workTaskTemplateForm.invalid">{{'apply' | translate}}</button>
</mat-dialog-actions>
