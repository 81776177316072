import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageBlocksEditComponent } from '../../editWidgets/image-blocks-edit/image-blocks-edit.component';
import { ActivatedRoute } from '@angular/router';
import { ImagesEditComponent } from '../../editWidgets/images-edit/images-edit.component';
import { environment } from 'src/environments/environment';
import { checkDynamicContent, supportUrlStructure } from '../../../../../../backend/utils/object';
import { SettingsService } from 'src/app/service/settings.service';

@Component({
  selector: '[app-image-blocks-phone]',
  templateUrl: './image-blocks-phone.component.html',
  styleUrls: ['./image-blocks-phone.component.css']
})

export class ImageBlocksPhoneComponent implements OnInit{
  @Input('app-image-blocks-phone') data:any;
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any, images:any}>()
  imageBlocksData:any
  editMode:any
  dialogRef:any
  layout:any
  images:any=[]
  widgetNumber:any
  imageToStorage:any=[]
  routerUrl:any
  idToShow:any
  url:any={}
  linkURL:any={}
  dynamic = false

  constructor(public dialog: MatDialog,
    private router: ActivatedRoute,
    private settingsService: SettingsService
    ){  
      this.routerUrl=this.router.url
      this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path  
    }

  ngOnInit(): void {
    this.imageBlocksData = this.data[0]
    this.editMode = this.data[1]
    if(this.editMode){
      this.widgetNumber = this.data[2]
    }
    this.layout=this.imageBlocksData.layout
    this.updateLayouts()

    if(this.imageBlocksData.images){
      this.imageBlocksData.images.forEach((image, index) => {
        if(image.url){
          try{
            const partsd = image.url.includes("${")
            if(partsd){
              //image.url = checkDynamicContent(image.url)
              this.dynamic = true
            }
            this.url[index]=image.url
            this.url[image.url]=image.url
            let url = supportUrlStructure(image.url, this.idToShow, this.settingsService.contextId$)
            if(url){
              this.url[index]=url
              this.url[image.url]=url
            }

            // const imgUrl = new URL(image.url)
            // let hostname = environment.urlHostName
            // if(imgUrl.protocol=="pass:" ){
            //   const parts = image.url.match(/pass:\/\/(.+)\/(.+)/)
            //   if(parts){
            //     const passTemplateID = parts[1]
            //     const path = parts[2]
            //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            //     this.url[index]=firebaseUrl
            //     this.url[image.url]=firebaseUrl
            //   }else{
            //     const newparts = image.url.match(/pass:\/\/(.+)/)
            //     if(newparts){
            //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
            //       newparts.forEach( (part,index)=> {
            //         if(index>0){
            //           firebaseUrl += '/' + part 
            //         }
            //       })
            //       this.url[index]=firebaseUrl
            //       this.url[image.url]=firebaseUrl
            //     }
            //   }
            // }

            // if(imgUrl.protocol =="tridy:"){
            //   const parts = image.url.match(/tridy:\/\/(.+)\/(.+)/)
            //   let path =""
            //   parts.forEach((parte, index) =>{
            //     if(index>0 && index<parts.length){
            //       path += "/" + parte
            //     }
            //   })
            //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
            //   this.url[index]=firebaseUrl
            //   this.url[image.url]=firebaseUrl
            // }
          }catch(erro){}
        }

        if(image.linkURL){
          try{
            //image.linkURL = checkDynamicContent(image.linkURL)
            this.linkURL[index]=image.linkURL
            let url = supportUrlStructure(image.linkURL, this.idToShow, this.settingsService.contextId$)
            if(url)
            this.linkURL[index]=url

            // const imgUrl = new URL(image.linkURL)
            // let hostname = environment.urlHostName
  
            // if(imgUrl.protocol=="pass:" ){
            //   const parts = image.linkURL.match(/pass:\/\/(.+)\/(.+)/)
            //   if(parts){
            //     const passTemplateID = parts[1]
            //     const path = parts[2]
            //     const firebaseUrl = `https://${hostname}/v1/pass/${passTemplateID}/${path}`
            //     this.linkURL[index]=firebaseUrl
            //   }else{
            //     const newparts = image.linkURL.match(/pass:\/\/(.+)/)
            //     if(newparts){
            //       let firebaseUrl = `https://${hostname}/v1/pass/${this.idToShow}`
            //       newparts.forEach( (part,index)=> {
            //         if(index>0){
            //           firebaseUrl += '/' + part 
            //         }
            //       })
            //       this.linkURL[index]=firebaseUrl
            //     }
            //   }
            // }
  
            // if(imgUrl.protocol =="tridy:"){
            //   const parts = image.linkURL.match(/tridy:\/\/(.+)\/(.+)/)
            //   let path =""
            //   parts.forEach((parte, index) =>{
            //     if(index>0 && index<parts.length){
            //       path += "/" + parte
            //     }
            //   })
            //   const firebaseUrl = `https://${hostname}/v1/pass${path}`
            //   this.linkURL[index]=firebaseUrl
            // }
          }catch(error){

          }
        }

  /*       if(image.title)
          image.title = checkDynamicContent(image.title)
  
        if(image.subtitle)
          image.subtitle = checkDynamicContent(image.subtitle) */
      });
    }

    if(this.imageBlocksData.mediaURL){
      this.dynamic = true
    }
  }

  updateLayouts(){
    this.images=[]
    switch(this.layout){
      case "twoHorizontal": {
        if(this.imageBlocksData.images[0]){
          this.images.push(this.imageBlocksData.images[0])
        }else{
          this.images.push({url:""})
        }

        if(this.imageBlocksData.images[1]){
          this.images.push(this.imageBlocksData.images[1])
        }else{
          this.images.push({url:""})
        }
        
        break;
      }

      case 'twoVertical': {
        if(this.imageBlocksData.images[0]){
          this.images.push(this.imageBlocksData.images[0])
        }else{
          this.images.push({url:""})
        }

        if(this.imageBlocksData.images[1]){
          this.images.push(this.imageBlocksData.images[1])
        }else{
          this.images.push({url:""})
        }
        
        break;
      }

      case 'threeLayout1': {
        if(this.imageBlocksData.images[0]){
          this.images.push(this.imageBlocksData.images[0])
        }else{
          this.images.push({url:""})
        }

        if(this.imageBlocksData.images[1]){
          this.images.push(this.imageBlocksData.images[1])
        }else{
          this.images.push({url:""})
        }

        if(this.imageBlocksData.images[2]){
          this.images.push(this.imageBlocksData.images[2])
        }else{
          this.images.push({url:""})
        }
        
        break;
      }

      case 'threeLayout2': {
        if(this.imageBlocksData.images[0]){
          this.images.push(this.imageBlocksData.images[0])
        }else{
          this.images.push({url:""})
        }

        if(this.imageBlocksData.images[1]){
          this.images.push(this.imageBlocksData.images[1])
        }else{
          this.images.push({url:""})
        }

        if(this.imageBlocksData.images[2]){
          this.images.push(this.imageBlocksData.images[2])
        }else{
          this.images.push({url:""})
        }
        break;
      }

      case 'fourLayout1': {
        if(this.imageBlocksData.images[0]){
          this.images.push(this.imageBlocksData.images[0])
        }else{
          this.images.push({url:""})
        }

        if(this.imageBlocksData.images[1]){
          this.images.push(this.imageBlocksData.images[1])
        }else{
          this.images.push({url:""})
        }

        if(this.imageBlocksData.images[2]){
          this.images.push(this.imageBlocksData.images[2])
        }else{
          this.images.push({url:""})
        }
        if(this.imageBlocksData.images[3]){
          this.images.push(this.imageBlocksData.images[3])
        }else{
          this.images.push({url:""})
        }
        break;
      }
      case 'fourLayout2': {
        if(this.imageBlocksData.images[0]){
          this.images.push(this.imageBlocksData.images[0])
        }else{
          this.images.push({url:""})
        }

        if(this.imageBlocksData.images[1]){
          this.images.push(this.imageBlocksData.images[1])
        }else{
          this.images.push({url:""})
        }

        if(this.imageBlocksData.images[2]){
          this.images.push(this.imageBlocksData.images[2])
        }else{
          this.images.push({url:""})
        }
        if(this.imageBlocksData.images[3]){
          this.images.push(this.imageBlocksData.images[3])
        }else{
          this.images.push({url:""})
        }
        break;
      }
    }

  }

  openDialogEdit(){
    const values= this.imageBlocksData
    this.dialogRef = this.dialog.open(ImagesEditComponent,
      {
        data: {
          values: values,
          widget: this.widgetNumber,
          passId: this.idToShow,
          url: this.url
        }
      },
    );
    
    this.dialogRef.afterClosed().subscribe((result:any) => {
      if(result[1]){
        this.imageBlocksData=result[0]
        const images = result[2]
        images.forEach( img => {
           this.imageToStorage.push(img)
        })
        this.layout=this.imageBlocksData.layout
        this.images=this.imageBlocksData.images
        this.updateLayouts()
        this.returnChanges.emit({newdata: this.imageBlocksData, widget: this.widgetNumber, widgetType: this.imageBlocksData.type,images: this.imageToStorage})
      }
    });
  }
}
