
<h2 mat-dialog-title>{{'interfaces.title' | translate}}</h2>

<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="interfaceForm">

        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'interfaces.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'interfaces.name' | translate}}" [readonly]="!interfacesUserRights_update">
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'interfaces.displayName' | translate}}</mat-label>
                    <input formControlName="displayName" type="text" matInput placeholder="{{'interfaces.displayName' | translate}}" [readonly]="!interfacesUserRights_update">
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'interfaces.info' | translate}}</mat-label>
                    <input formControlName="info" type="text" matInput placeholder="{{'interfaces.info' | translate}}" [readonly]="!interfacesUserRights_update">
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'interfaces.itemArrayPath' | translate}}</mat-label>
                    <input formControlName="itemArrayPath" type="text" matInput placeholder="{{'interfaces.itemArrayPath' | translate}}" [readonly]="!interfacesUserRights_update">
                </mat-form-field>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'interfaces.headerKey' | translate}}</mat-label>
                    <input formControlName="headerKey" type="text" matInput placeholder="{{'interfaces.headerKey' | translate}}" [readonly]="!interfacesUserRights_update">
                </mat-form-field>
        
                <div class="d-flex flex-row flex-wrap justify-content-between">
                    <mat-form-field appearance="outline" style="width:40%;" >
                        <mat-label>{{'interfaces.contentType' | translate}} </mat-label>
                        <mat-select formControlName="contentType" class="input-group">
                          <mat-option value="id" [disabled]="!interfacesUserRights_update">{{'interfaces.ID' | translate}} </mat-option>
                          <mat-option value="path" [disabled]="!interfacesUserRights_update">{{'interfaces.path' | translate}} </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field *ngIf="interfaceForm.value.contentType=='path'" appearance="outline" style="width:59%;">
                        <mat-label>{{'interfaces.contentTypePath' | translate}}</mat-label>
                        <input formControlName="contentTypePath" type="text" matInput placeholder="{{'interfaces.contentTypePath' | translate}}" [readonly]="!interfacesUserRights_update">
                    </mat-form-field>
        
                    <mat-form-field *ngIf="interfaceForm.value.contentType=='id'" appearance="outline" style="width:59%;">
                        <mat-label>{{'interfaces.contentTypeId' | translate}}</mat-label>
                        <mat-select [(value)]="interfaceForm.value.contentTypeId" formControlName="contentTypeId">
                          <mat-option *ngFor="let content of allContentTypes" [value]="content.id" [disabled]="!interfacesUserRights_update">
                            {{content.displayName}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
        
                <p>{{'interfaces.ids' | translate}}</p>
                <div>
                    <button mat-button 
                            class="secondary-gray-button my-2"
                            id="btn-mapping-id"
                            (click)="addMappingId();"
                            [disabled]="!interfacesUserRights_update"
                    >
                        <mat-icon>add_circle</mat-icon> {{'interfaces.add_mapping' | translate}}
                    </button>
                    <div class="list-group mt-2">
                        <div *ngFor="let id of interfaceForm.value.ids; let index= index; " class=" d-flex flex-row align-items-center w-100 list-group-item" >
                            <div class="d-flex flex-row justify-content-between flex-wrap  rounded w-100 " (click)="editId(id,index);" style="cursor:pointer;">
                                <span class="fw-bold">{{id.type}}</span>
                                <span class="text-muted">{{id.path}}</span>
                            </div>
        
                            <div class="d-flex flex-row flex-nowrap ps-2">
                                <div class="vr"></div>

                                <button  mat-icon-button class="button-task" style="color:var(--warmOrange);" (click)="editId(id,index);" [disabled]="!interfacesUserRights_update">
                                    <span class="material-symbols-outlined">
                                        edit
                                    </span>
                                </button>
                                <button  mat-icon-button class="button-task" style="color:red;" (click)="deleteId(id,index);" [disabled]="!interfacesUserRights_update">
                                    <span class="material-symbols-outlined">
                                        delete
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        
                <p class="mt-2">{{'interfaces.media' | translate}}</p>
                <button mat-button 
                        class="secondary-gray-button my-2"
                        id="btn-mapping-media"
                        (click)="addMappingMedia();"
                        [disabled]="!interfacesUserRights_update"
                >
                    <mat-icon>add_circle</mat-icon> {{'interfaces.add_mapping' | translate}}
                </button>
                <div class="list-group mt-2">
                    <div *ngFor="let media of interfaceForm.value.media; let index= index;" class=" d-flex flex-row align-items-center w-100 list-group-item" >
                        <div class="d-flex flex-row justify-content-between flex-wrap border-right w-100 " (click)="editMedia(media,index);" style="cursor:pointer;">
                            <span class="fw-bold">{{media.key}}</span>
                            <span class="text-muted" >{{media.path}}</span>
                        </div> 
                        <div class="d-flex flex-row flex-nowrap ps-2">
                            <div class="vr"></div>
                            <button mat-icon-button style="color:var(--warmOrange);" (click)="editMedia(media,index);" [disabled]="!interfacesUserRights_update">
                                <span class="material-symbols-outlined">
                                    edit
                                </span>
                            </button>
        
                            <button mat-icon-button style="color:red;" (click)="deleteMedia(media,index);" [disabled]="!interfacesUserRights_update">
                                <span class="material-symbols-outlined">
                                    delete
                                </span>
                            </button>
                        </div>     
                    </div>
                </div>
            </form>

        </section>
    </div>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="dialogCancel();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" (click)="dialogSave();" [disabled]="!interfacesUserRights_update" >{{'save' | translate}}</button>
</mat-dialog-actions>
