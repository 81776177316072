
<section class="container">
    <div class="row mt-4 pb-3">

        <div class="col-12">            
            <mat-card class="h-100" style="min-height: 600px;">
                <mat-card-header>
                    <div class="w-100 m-3 d-flex justify-content-between align-items-center">
                        <mat-form-field class="white-input-bg search">
                            <mat-label>{{'search_title' | translate}}</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="{{'search' | translate}}" #input>
                            <mat-icon matPrefix>search</mat-icon>
                        </mat-form-field>

                        <mat-button-toggle-group hideSingleSelectionIndicator name="gridViewOptionToggle" [(ngModel)]="gridViewOption">
                            <mat-button-toggle matTooltip="List-View" (click)="changeView('list');" value="list"><mat-icon>view_list</mat-icon></mat-button-toggle>
                            <mat-button-toggle matTooltip="Item-View" (click)="changeView('item');" value="item"><mat-icon>grid_view</mat-icon></mat-button-toggle>
                        </mat-button-toggle-group> 
                    </div>
                </mat-card-header>
                <mat-card-content class="mx-2 h-100">
                  <ng-container>
                    <section [hidden]="!gridView || emptyMedia" class="h-100">
                        <div class="containerFolders h-100 w-100">
                            <p *ngIf="folder.length==0">{{'empty' | translate}}</p>
                            <div *ngFor="let value of filteredFolder" class="grid-item mx-2 mt-2 mb-4" (click)="openFilePreview(value);"> 

                                <div *ngIf="value?.folder" class="w-100 h-100">
                                    <mat-card *ngIf="value?.folder" class="w-100 h-100">
                                        <mat-card-content class="custom-card p-3 cursor-pointer">
                                        <div class="py-2"><span class="card-icon material-symbols-outlined">folder</span></div>
                                        </mat-card-content>
                                    </mat-card>
                                    <div class="text-center pt-1 filename">{{value.folder}}</div>
                                </div>

                    
                                <div *ngIf="value?.item" class="w-100 h-100"><!--class="background-img"-->
    
                                    <img *ngIf="checkFileType(value) == 'image'" src="{{value.url}}" alt="{{value.item}}" width="200" height="130" style="border-radius:10px; object-fit: cover;">
                                    
                                    <mat-card *ngIf="checkFileType(value) == 'file'" class="w-100 h-100">
                                        <mat-card-content class="custom-card p-3 cursor-pointer">
                                        <div class="py-2">
                                            <span class="card-icon material-symbols-outlined">draft</span>
                                        </div>
                                        </mat-card-content>
                                    </mat-card>

                                    <mat-card *ngIf="checkFileType(value) == 'video'" class="w-100 h-100" >
                                        <mat-card-content class="custom-card p-3 cursor-pointer">
                                        <div class="py-2"> <span class="card-icon material-symbols-outlined">movie</span> </div>
                                        </mat-card-content>
                                    </mat-card>
                                    <div class="text-center pt-1 filename">{{value.item}}</div>
                                </div>
                            </div>
                        </div>
                      </section>
                      
                      <section [hidden]="gridView || emptyMedia" class="h-100">
                        <div class="row mt-4">
                            <div class="col-12 mb-4">
                                <div class="mat-elevation-z8 rounded shadow-sm" id="table-container">
                                    <table mat-table [dataSource]="listdata" matSort> 
                            
                                        <ng-container matColumnDef="icon" >
                                            <th mat-header-cell *matHeaderCellDef> </th>
                                            <td mat-cell *matCellDef="let row" (click)="openFilePreview(row);">
                                            <span *ngIf="checkFileType(row) == 'video'" class="material-symbols-outlined">movie</span>
                                            <span *ngIf="checkFileType(row) == 'file'" class="material-symbols-outlined">draft</span>
                                            <span *ngIf="checkFileType(row) == 'image'" class="material-symbols-outlined">image</span>
                                            <span *ngIf="checkFileType(row) == 'folder'" class="material-symbols-outlined">folder</span>
                                            </td>
                                        </ng-container>
                
                                        <!-- name Column -->
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'contentTypes.name' | translate}} </th>
                                            <td mat-cell *matCellDef="let row" (click)="openFilePreview(row);"> 
                                                <span *ngIf="row.name" class="text-wrap text-break">{{row.name}}</span>
                                            </td>
                                        </ng-container>
                
                                        <!-- type Column -->
                                        <ng-container matColumnDef="type">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'type' | translate}} </th>
                                            <td mat-cell *matCellDef="let row" (click)="openFilePreview(row);"> 
                                                <span *ngIf="row.metadata" class="text-wrap text-break">{{row.type}}</span> 
                                            </td>
                                        </ng-container>
                                    
                                        <!-- updated Column -->
                                        <ng-container matColumnDef="updated" >
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'last_update' | translate}} </th>
                                            <td mat-cell *matCellDef="let row" (click)="openFilePreview(row);" >
                                                    {{row?.updated | date: 'dd.MM.yyyy, HH:mm'}}
                                            </td>
                                        </ng-container>
                                    
                                        <!-- size Column -->
                                        <ng-container matColumnDef="size" >
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'size' | translate}} </th>
                                            <td mat-cell *matCellDef="let row" (click)="openFilePreview(row);" > 
                                                    {{ getFileSize(row?.size) }}
                                            </td>
                                        </ng-container>
                
                                        <!-- Action Column -->
                                        <ng-container matColumnDef="actions">
                                            <th mat-header-cell *matHeaderCellDef></th>
                                            <td mat-cell *matCellDef="let row"> 
                                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
    
                                                    <button mat-menu-item (click)="downloadFile(row);" ><!--[disabled]="!contentTypeUserRights_create"-->
                                                        <mat-icon class="material-symbols-outlined">download</mat-icon> {{'download' | translate}}
                                                    </button>
                                                    <button mat-menu-item (click)="deleteFile(row);">
                                                        <mat-icon class="material-symbols-outlined delete">delete</mat-icon> {{'delete' | translate}}
                                                    </button>
                                                </mat-menu>
                                            </td>
                                        </ng-container>
                
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true;" class="p-1"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-1 element-row text-wrap text-break"></tr>
                                    
                                        <!-- Row shown when there is no matching data. -->
                                        <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="4">{{'no_matching_data' | translate}} "{{input.value}}"</td>
                                        </tr>
                                    </table>
                                
                                    <mat-paginator [pageSizeOptions]="paginatorSize" aria-label="Select page of users" showFirstLastButtons class="pb-2"></mat-paginator>
                                </div>
                            </div>
                        </div>
                      </section>
                      @if(emptyMedia){

                        <section class="m-2 h-100">
                            <div appDragAndDrop (files)="filesDropped($event)" class="h-100">
                                <mat-card class="upload-section text-center justify-content-center mb-4">
                                    <div style="margin:0 auto; height:100%;" class="d-flex justify-content-end flex-column">
                                        <div class="p-2 mb-4 text-center">
                                            <mat-icon class="decoration-icon material-symbols-outlined">add_photo_alternate</mat-icon>
                            
                                            <button mat-button class="my-3 orange-button" (click)="triggerFileInput()">
                                                {{'pass.browse' | translate}}
                                            </button>
                            
                                            <input #fileInput type="file" id="imageFile" name="imageFile" style="display: none;" accept=".jpg, .jpeg, .png, .webp" (change)="readURL($event)">
                                        </div>
                                        <p class="py-2 mt-4">{{'pass.supportedFiles' | translate}}: .jpg, .jpeg, .png, .webp</p>
                                    </div>
                                </mat-card>
                            </div>
                        </section>
                    }
                    
                  </ng-container>

                  
                </mat-card-content>
                <!-- <mat-card-actions></mat-card-actions>
                <mat-card-footer></mat-card-footer> -->
              </mat-card>

        </div>
    </div>
</section>
