<div class="container-fluid login-page" fxLayout="column" fxLayoutAlign="center center">

    <div class="position-top">
        <div class="d-flex flex-row align-self-end justify-content-end flex-wrap m-lg-2">
            <button mat-button [matMenuTriggerFor]="language" style="color:#fff;margin-bottom:10px;">
                <span *ngIf="languageFlag === 'gb'">
                    <span class="fi fi-gb dd-menu-image"></span> English
                </span>
                <span *ngIf="languageFlag === 'de'">
                    <span class="fi fi-de dd-menu-image"></span> Deutsch
                </span>
                <span *ngIf="languageFlag === 'es'">
                    <span class="fi fi-es dd-menu-image"></span> Español
                </span>
                
            </button>
            <mat-menu #language="matMenu">
                <button mat-menu-item (click)="useLanguage('en')"><span class="fi fi-gb dd-menu-image"></span>  English </button>
                <button mat-menu-item (click)="useLanguage('de')"><span class="fi fi-de dd-menu-image"></span>  Deutsch </button>
                <button mat-menu-item (click)="useLanguage('es')"><span class="fi fi-es dd-menu-image"></span>  Español </button>
            </mat-menu>
        </div>
    </div>

    <div class="login-card-wrapper">
        <div class="login-card p-4 mat-elevation-z8">
            <div class="login-logo mt-3">
                <img src="./../../assets/img/narravero-logo_website.svg" id="logo" alt="Narravero Logo">
            </div>

            <!-- reinvitation handler -->
            <div *ngIf="linkOutdated && !processingReinvite" class="py-2">
                <span class="card-icon material-symbols-outlined">error</span>
              </div>
            <div *ngIf="linkOutdated" class="mb-5 registration-header">
                <h2 *ngIf="!processingReinvite">{{'register.failed' | translate}}</h2>
                <p *ngIf="!processingReinvite" class="p-3">{{'register.linkOutdated' | translate}}</p>

                <div *ngIf="resendInvitationError" class="py-3">
                    <div class="alert alert-danger" role="alert">
                        {{'register.resendLink_error' | translate}}
                      </div>
                </div>

                <div *ngIf="!processingReinvite && !resendInvitationError" class="py-2">
                    <button mat-button class="orange-button" (click)="resendInvitation();">{{'register.resendLink' | translate}}</button>
                    <div class="py-2 text-uppercase">{{'pass.or' | translate}}</div>
                </div>

                <div *ngIf="processingReinvite" class="py-2">
                    <div class="login-spinner">
                        <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <p style="margin-top: 100%;">{{'register.resendLink_processing' | translate}}</p>
                </div>

                <button *ngIf="!processingReinvite" mat-button [routerLink]="['/']" class="orange-button">{{'register.backtoLogin' | translate}}</button>
            </div>

            <div *ngIf="!showSpinner" class="d-flex flex-column px-3 align-items-center justify-content-center flex-wrap form-section">
                <div *ngIf="finishRegistrationForm && !linkOutdated">
                    <div class="mt-4 mb-5 registration-header">
                        <h2>{{'register.signIn_emailLink' | translate}}</h2>
                        <p>{{'register.info' | translate}}</p>
                    </div>
    
                    <form [formGroup]="finishRegistrationForm" *ngIf="!linkOutdated">
                        
                        <!-- IF EMAIL NOT INCLUDED IN URL -->
<!--                         <mat-form-field *ngIf="enterEmail" appearance="outline" class="w-100 mt-2">
                            <mat-label>{{'register.email' | translate }}</mat-label>
                            <input matInput placeholder="{{'register.email' | translate }}" formControlName="email" type="email" required>
                            <mat-error *ngIf="finishRegistrationForm.get('email')?.hasError('required')">
                                {{ 'register.emailRequired' | translate }}
                            </mat-error>
                            <mat-error *ngIf="finishRegistrationForm.get('email')?.hasError('email')">
                                {{ 'register.invalidEmail' | translate }}
                            </mat-error>
                        </mat-form-field> -->
    
                        <mat-form-field appearance="outline" class="w-100 mb-1">
                            <mat-label>{{'login.pass' | translate}}</mat-label>
                            <input matInput formControlName="password" new-password autocomplete placeholder="{{ 'login.pass' | translate }}" 
                            [type]="hidePasswort ? 'password' : 'text'"
                            >
                            <button mat-icon-button matSuffix (click)="hidePasswort = !hidePasswort" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswort">
                                <mat-icon>{{hidePasswort ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="finishRegistrationForm.get('password')?.hasError('required')">
                                {{ 'register.passRequired' | translate }}
                            </mat-error>
                            <mat-error *ngIf="finishRegistrationForm.get('password')?.hasError('minlength')">
                                {{ 'register.passMinLength' | translate }}
                            </mat-error>
                        </mat-form-field>
                
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{'setPassword.confirmed_password' | translate}}</mat-label>
                            <input matInput formControlName="confirmPassword" new-password autocomplete placeholder="{{ 'login.pass' | translate }}" 
                            [type]="hidePasswort ? 'password' : 'text'"
                            >
                            <button mat-icon-button matSuffix (click)="hidePasswort = !hidePasswort" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswort">
                                <mat-icon>{{hidePasswort ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error *ngIf="finishRegistrationForm.get('confirmPassword')?.hasError('passwordMismatch')">
                                {{ 'register.passwordEqualsError' | translate }}
                            </mat-error>
                        </mat-form-field>
    
                        <div class="mb-4 mt-3">
                            <button mat-button (click)="finishRegistration()" class="w-100 mb-2 orange-button" [disabled]="finishRegistrationForm.invalid">
                                {{ 'register.confirm' | translate }}
                            </button>
                        </div> 
                    </form>
                </div>
            </div>
    
            <div *ngIf="showSpinner" class="login-spinner">
                <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
            </div>
            
        </div>
        <div class="dev-note mobile-text-small"> {{buildEnvironment}} - {{version}} ( {{buildNumber}} )</div>
    </div>

    <div class="position-bottom">
        <div class="p-4 w-100">
            <div class="d-flex flex-row justify-content-between flex-wrap">
                <div class="footer-links mobile-text-small">
                    <span ><a class="text-decoration-none" target="_blank" href="https://www.narravero.com/impressum">{{'imprint' | translate}}</a></span> 
                    <span class="divider">   |  </span> 
                    <span> <a class="text-decoration-none" target="_blank" href ="https://www.narravero.com/datenschutz">{{'privacy_policy' | translate}}</a></span>
                </div>
            </div>
        </div>
    </div>

</div>