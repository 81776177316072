import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommentsService } from '../../service/comments.service';
import { SettingsService } from '../../service/settings.service';
import { TaskTemplateFieldDialogComponent } from '../worktask-template-field-dialog/worktask-template-field-dialog.component';
import { deepCopy } from '../../../../../backend/utils/object';

@Component({
  selector: 'app-work-task-template-field-rule-dialog',
  templateUrl: './work-task-template-field-rule-dialog.component.html',
  styleUrl: './work-task-template-field-rule-dialog.component.css'
})

export class WorkTaskTemplateFieldRuleDialogComponent {

  ruleForm!:FormGroup
  ruleData:any
  ruleStructure:any
  tasktemplateUserRights_update:boolean
  tasktemplateUserRights_delete:boolean
  constructor(
    public dialogRef: MatDialogRef<WorkTaskTemplateFieldRuleDialogComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commentService: CommentsService,
    private translate : TranslateService,
    private settingService: SettingsService
  ){
    dialogRef.disableClose = true;

  }

  ngOnInit(): void {
    this.tasktemplateUserRights_update = this.settingService.modulesRolesRights$.value['workTaskTemplates']['U']
    this.tasktemplateUserRights_delete = this.settingService.modulesRolesRights$.value['workTaskTemplates']['D']

    this.ruleForm = this.fb.group({
      type:[],//dateRange -- numericRange -- textRegex -- textLength
      min:[],
      max:[],
      regex:[],
      decimal:[],
      minDaysAgo:[]
    })
    
    this.ruleData = deepCopy(this.data.rule)
    this.ruleStructure = deepCopy(this.data.ruleStructure)

    const { type, min, max, regex, decimal, minDaysAgo } = this.ruleData

    this.ruleForm.patchValue({
      type:type ? type : undefined,//dateRange -- numericRange -- textRegex -- textLength
      min:min ? min: undefined,
      max:max ? max : undefined,
      regex:regex ? regex : undefined,
      decimal:decimal ? decimal : undefined,
      minDaysAgo:minDaysAgo ? minDaysAgo : undefined
    })

    this.ruleForm.valueChanges.subscribe( value=>{
      console.log(value)
    })

  }

  saveRule(){
    if(this.data.rule && this.data.rule.type){
      let data
      switch(this.data.rule.type){
        case 'textLength':{
          const { type, min, max, }= this.ruleForm.value
          data = {type, min, max}
          break;
        }

        case 'textRegex':{
          const { type, regex } = this.ruleForm.value
          data = { type, regex }
          break;
        }

        case 'numericRange':{
          const { type, min, max, decimal } = this.ruleForm.value
          data = { type, min, max, decimal }
          break;
        }

        case 'dateRange':{
          const { type, min, max, minDaysAgo } = this.ruleForm.value
          data = { type, min, max, minDaysAgo }
          break;
        }
      }
      this.dialogRef.close([true, data])
    }
  }
}
