<h2 mat-dialog-title>{{'pass.widgets.callToAction' | translate}}</h2>
<!-- <div *ngIf="defaultLanguage" style=" position: absolute;top: 25px;right: 10px;">
    <span class="hint-text">{{'defaultLanguage' | translate}} : {{languagesList[defaultLanguage]}}</span>
</div> -->
<mat-dialog-content>
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section">
            <form [formGroup]="callToActionEditform">

                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.name' | translate}}</mat-label>
                    <input formControlName="name" type="text" matInput placeholder="{{'pass.name' | translate}}">
                </mat-form-field>
                
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.title' | translate}}</mat-label>
                    <input formControlName="title" type="text" matInput placeholder="{{'pass.title' | translate}}">
                </mat-form-field>

                <mat-card class="mb-4">
                    <mat-card-content>
                        <div class="p-2">
                            <div *ngIf="urlData">
                                <div class="d-flex align-items-center mb-3">
                                    <mat-icon class="material-symbols-outlined" >image</mat-icon>
                                    <span class="px-2">{{'pass.widgets.img' | translate}}</span>
                                </div>
                                <div class="d-flex justify-content-stretch flex-column">
                                    <div *ngIf="urlData" class="custom-form-field" >
                                        <img [src]="urlData" style="width:100%; border-radius:5px;" onerror="this.src='./../../assets/img/default.jpg'" > 
                                    </div>
                                    <div *ngIf="!urlData" class="custom-form-field" [ngStyle]="{'background-image': 'url( ./../../assets/img/default.jpg)'}"></div>
                                    <div class="form-fields mt-3">
                                        <div class="d-flex align-items-center">
                                            <mat-form-field appearance="outline" class="flex-grow-1">
                                                <mat-label>{{ 'pass.url' | translate }}</mat-label>
                                                <input type="text" formControlName="imageUrl" matInput (change)="checkNewUrl()">
                                            </mat-form-field>
                                            <button mat-icon-button class="delete-button" style="margin-top:-20px;" (click)="deleteImg(url)" matTooltip="{{ 'pass.deleteIMG' | translate }}">
                                                <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!urlData">
                                <div class="d-flex align-items-center mb-3">
                                    <mat-icon class="material-symbols-outlined">image</mat-icon>
                                    <span class="px-2">{{'pass.widgets.img' | translate}}</span>
                                </div>
                                <div [app-drag-file-browse-ui]="dragFileData" (returnChanges)="returnChangesDragFileBrowseUI($event)"></div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
        
                <mat-card class="p-3">
                    <mat-card-content>
                        <label for="hasLogin">{{'pass.callToAction.networks' | translate}}</label>
                        <div class="mb-2">
                            <button mat-button 
                                    class="secondary-gray-button my-3"
                                    id="btn-add"
                                    [matMenuTriggerFor]="sm_links"
                            >
                                <mat-icon>add_circle</mat-icon> {{'add' | translate}}
                            </button>
                        </div>
                        <mat-menu #sm_links="matMenu">
                            <div  *ngFor="let sn of posibleNetworks" >
                                <button mat-menu-item [disabled]="sn.added==true" (click)="addNetwork(sn.name);">
                                    <span *ngIf="sn.name == 'facebook'"><i class="bi bi-{{sn.name}}" class="menu-sm-icon"></i> Facebook</span>
                                    <span *ngIf="sn.name == 'instagram'"><i class="bi bi-{{sn.name}}" class="menu-sm-icon"></i> Instagram</span>
                                    <span *ngIf="sn.name == 'snapchat'"><i class="bi bi-{{sn.name}}" class="menu-sm-icon"></i> Snapchat</span>
                                    <span *ngIf="sn.name == 'linkedin'"><i class="bi bi-{{sn.name}}" class="menu-sm-icon"></i> LinkedIn</span>
                                    <span *ngIf="sn.name == 'tiktok'"><i class="bi bi-{{sn.name}}" class="menu-sm-icon"></i> TikTok</span>
                                    <span *ngIf="sn.name == 'twitter'"><i class="bi bi-{{sn.name}}" class="menu-sm-icon"></i> Twitter / X</span>
                                    <span *ngIf="sn.name == 'youtube'"><i class="bi bi-{{sn.name}}" class="menu-sm-icon"></i> YouTube</span>
                                </button>
                            </div>
                        </mat-menu>
                        <div *ngFor="let s of posibleNetworks">
                            <mat-form-field *ngIf="s.added" appearance="outline" class="w-100">
                                <i matPrefix class="bi bi-{{s.name}}" style="font-size:20px; margin:15px;"></i>
                                <mat-label *ngIf="s.name == 'facebook'">Facebook</mat-label>
                                <mat-label *ngIf="s.name == 'instagram'">Instagram</mat-label>
                                <mat-label *ngIf="s.name == 'snapchat'">Snapchat</mat-label>
                                <mat-label *ngIf="s.name == 'linkedin'">LinkedIn</mat-label>
                                <mat-label *ngIf="s.name == 'tiktok'">TikTok</mat-label>
                                <mat-label *ngIf="s.name == 'twitter'">Twitter / X</mat-label>
                                <mat-label *ngIf="s.name == 'youtube'">YouTube</mat-label>
                                <input formControlName="{{s.name}}" id="{{s.name}}" type="text" matInput value="{{s.url}}" (change)="updateSocialNetwork(s.name)">
                                <button class="delete-button" style="vertical-align: middle;" mat-icon-button matTooltip="{{'pass.callToAction.remove_network' | translate}}" matSuffix (click)="removeNetwork(s.name);">
                                    <mat-icon class="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
    
                    </mat-card-content>
                </mat-card>

            </form>
        </section>
    </div>

    <div [app-widget-edit-filters] = "callToActionEditData" (returnChanges)="returnChangesFilters($event)"></div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" (click)="onSaveEdit();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>
