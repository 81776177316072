import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditValueContentDialogComponent } from '../edit-value-content-dialog/edit-value-content-dialog.component';
import { SettingsService } from '../service/settings.service';

@Component({
  selector: 'app-content-edit-dialog',
  templateUrl: './content-edit-dialog.component.html',
  styleUrls: ['./content-edit-dialog.component.css']
})

export class ContentEditDialogComponent implements OnInit {
  dialogForm!:FormGroup
  dialogData:any
  oldData:any
  dataValues:any=[]
  type:any
  currentType: string | null = null;
  contentUserRights_update:any

  constructor(public dialogRef: MatDialogRef<ContentEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[],
            private fb: FormBuilder,
    public dialog: MatDialog,
    private settingsService:SettingsService ){
      dialogRef.disableClose = true;
  }

  ngOnInit():void{
    this.contentUserRights_update = this.settingsService.modulesRolesRights$.value['content']['U']

    this.settingsService.modulesRolesRights$.subscribe( value => {
      this.contentUserRights_update = this.settingsService.modulesRolesRights$.value['content']['U']
    })
    this.dialogData = this.data
    this.oldData = this.data

    this.dialogForm = this.fb.group({
      id:[],
      type:[],
      key:[],
      value:[],
      date:[],
      time:[]
    })

    const {id, type, key, value } = this.dialogData
    this.dialogForm.patchValue({
      id: id ? id : undefined,
      type: type ? type : undefined,
      key: key ? key : undefined,
      value: value ? value : false,
      date: undefined,
      time: undefined
    })
    this.type=this.dialogData.type

    if(this.type=="string"){
      this.dialogForm.patchValue({
        value:value ? value : undefined
      })
    }
    if (this.type == "array") {
      this.dialogForm.value.value.forEach((element: any, index: any) => {
        this.dataValues.push(element);
        this.setCurrentType(element);
      });
    }

    if (this.type == "object") {
      if (this.dialogForm.value.value) {
        this.dialogForm.value.value.forEach((element: any, index: any) => {
          this.dataValues.push(element);
          this.setCurrentType(element);
        });
      }
    }

    if(this.type=="date"){
      let dat = this.dialogData.value
      if(this.dialogData.value==false || this.dialogData.value == undefined){
        dat = this.dialogData.creationDate
        
      }
      const fireBaseTime = new Date(
        dat.seconds * 1000 + dat.nanoseconds / 1000000,
      );
      this.formatDate(fireBaseTime)
      this.formatTime(fireBaseTime)
    
      this.dialogForm.patchValue({value:fireBaseTime})
    }

    this.dialogForm.valueChanges.subscribe( value=>{
      if(value.type!=this.type){
        if(value.type == "boolean" && this.type != "boolean"){
          this.type=value.type
          this.dialogForm.patchValue({value: false})
          
        }
        this.type=value.type
        if(this.type =="array" || this.type=="object"){
          this.dataValues = []
          this.dialogForm.value.value = []
        }else{
          this.dataValues = undefined
          this.dialogForm.patchValue({value: undefined})    
        }
      }

      if(this.type=="date"){
        // const alld = value.date + " " + value.time
        // const dateNew = new Date(date)
        // value.value = dateNew
        try{
          let newdate 
          if(value.date){
            newdate = new Date(value.date)
          }

          if(value.time){
            const hoursminute = value.time.split(':')
            const newtime = new Date(value.date).setHours(hoursminute[0], hoursminute[1], 0)
            newdate.setTime(newtime)
          }
          value.value = newdate
        }catch(e){}
      }

    })

  }

  setCurrentType(element: any): void {
    if (typeof element === 'string') {
      this.currentType = 'text';
    } else if (typeof element === 'number') {
      this.currentType = 'number';
    } else if (typeof element === 'boolean') {
      this.currentType = 'boolean';
    } else if (element instanceof Date || (element.nanoseconds && element.seconds)) {
      this.currentType = 'date';
    } else if (typeof element === 'object') {
      this.currentType = 'object';
    }
  }
  
  addNewValue(type: string) {
    if (this.currentType && this.currentType !== type) {
      // Prevent adding a different type if values exist
      alert('You can only add values of the same type. Please delete all values to change the type.');
      return;
    }

    let data;
    switch (type) {
      case 'text':
        data = '-';
        break;
      case 'number':
        data = 0;
        break;
      case 'boolean':
        data = true;
        break;
      case 'date':
        data = new Date();
        break;
      default:
        return;
    }

    this.dataValues.push(data);
    this.dialogForm.patchValue({ value: this.dataValues });

    if (!this.currentType) {
      this.currentType = type; // Set the current type if it's not set
    }
  }

  

  deleteListValue(value: any, index: number) {
    this.dataValues.splice(index, 1);
    const valueArray = this.dataValues;
    this.dialogForm.patchValue({ value: valueArray });
  
    // Reset the currentType if the array is empty
    if (this.dataValues.length === 0) {
      this.currentType = null;
    }
  }
  

  editListValue(string, index){
    const type = typeof string
    this.openDialogEditContentValue(index, string, type)
  }

  onCancelEdit(){
    this.dialogRef.close([this.oldData,false])
  }

  onSaveEdit(){
    const {id, key, type, value} = this.dialogForm.value
    const data = { id, key, type, value}
    this.dialogRef.close([data,true])
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    this.dialogForm.patchValue({date: [year, month, day].join('-')})
    return [year, month, day].join('-');
  }

  formatTime(date){
    var dateToinput=""
    const d = new Date(date)

    let hours = d.getHours()
    const minutes = d.getMinutes()
    const m = d.toTimeString()
    if(hours<10){
      const h = '0' + hours
      dateToinput = h + ":" + minutes
      if(minutes<10){
        const m = '0' + minutes
        dateToinput = h + ":" + m
  
      }else{
        dateToinput = h + ":" + minutes
  
      }
    }else{
      dateToinput = hours + ":" + minutes
      if(minutes<10){
        const m = '0' + minutes
        dateToinput = hours + ":" + m
  
      }else{
        dateToinput = hours + ":" + minutes
  
      }
    }

    this.dialogForm.patchValue({time:dateToinput})
   return dateToinput
  }

  openDialogEditContentValue(index:string, data:any ,type:any):void{

    let dialogRef= this.dialog.open(EditValueContentDialogComponent, {
      data: [index, data, type]
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result[1]){
        this.dataValues[index]= result[0]
        this.dialogForm.patchValue({value: this.dataValues})
      }
    });
  }

  isNumber(val): boolean { return typeof val === 'number'; }
  isString(val): boolean { return typeof val === 'string'; }
  isBoolean(val): boolean { return typeof val === 'boolean'; }
  isObject(val): boolean { 
    return typeof val === 'object'; }
  isDate(val): string { 
    let dateToShow =""
    let date = false
    if(val.nanoseconds){
      const dateHour = new Date(val)
      dateToShow = dateHour.toTimeString()
      date=true
      return dateToShow;
    }
    return dateToShow
  }

  isArray(valu): boolean { 
    return true; 
  }

  isDateTime(element){
    let dateValue
    dateValue = element

    if(element){
      if(element['_nanoseconds'] || element['_seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['_seconds'] * SEC_TO_MS_MULTIPLIER + element['_nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toUTCString()//----------
      }

      if(element['nanoseconds'] || element['seconds']){
        const NS_TO_MS_MULTIPLIER = 1/1000000
        const SEC_TO_MS_MULTIPLIER = 1000
        const timestampInMilliseconds = element['seconds'] * SEC_TO_MS_MULTIPLIER + element['nanoseconds'] * NS_TO_MS_MULTIPLIER
        const date = new Date(timestampInMilliseconds)
        element= date
        dateValue = date.toUTCString()//----------
      }

      if( typeof element === 'object'){
        const date = new Date(element)
        dateValue = date.toUTCString()//----------
      }

      if( typeof element === 'string'){
        const date = new Date(element)
        dateValue = date.toUTCString()//----------
      }
    }
   return dateValue
  }
}