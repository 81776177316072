import { Injectable } from '@angular/core';
import { ReportService } from './report.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Filter, filterObjects, filterObjectsOnFilter } from '../../../../backend/src/filter';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { deepCopy } from '../../../../backend/utils/object';

@Injectable({
  providedIn: 'root'
})
export class ReportFilterService {
  reportDataResults$: Subject<any> = new Subject<any>()
  reportDataResultsFiltered$= new BehaviorSubject<any[]>([])
  reportFilters$: Subject<any> = new Subject<any>()
  reportFiltersValues$: Subject<any> = new Subject<any>()

  filteredReportResults$ = new BehaviorSubject<any[]>(undefined)
  availableFilter$ = new BehaviorSubject<Map<string, Filter>>(new Map())
  selectedFilterValue$ = new BehaviorSubject<Filter[]>([])
  selectedFilters$ = new BehaviorSubject<Filter[]>([])
  reportDataResultsFilteredValues$ = new BehaviorSubject<any[]>([])
  lastReportQueryParams$ = new BehaviorSubject<any>({})

  operatorOptions = [
    {
      name:"(==) equal to",
      translation: 'filters.operators.equal',
      value:"="
    },
    {
      name:"(!=) not equal to",
      translation: 'filters.operators.not_equal',
      value:"!="
    },
    {
      name:"(>) greather than",
      translation: 'filters.operators.greather_than',
      value:">"
    },
    {
      name:"(<) less than",
      translation: 'filters.operators.less_than',
      value:"<"
    },
    {
      name:"(>=) greter than or equal to",
      translation: 'filters.operators.greather_than_equal_to',
      value:">="
    },
    {
      name:"(<=) less than or equal to",
      translation: 'filters.operators.less_than_equal_to',
      value:"<="
    },
    {
      name:"(in) equal to any of the following",
      translation: 'filters.operators.in',
      value:"in"
    },
    {
      name:"(!in) not equal to any of the following",
      translation: 'filters.operators.not_in',
      value:"!in"
    },
    {
      name:"(contains) an array containing",
      translation: 'filters.operators.contains',
      value:"contains"
    },
    {
      name:"(!contains) array not containing",
      translation: 'filters.operators.not_contains',
      value:"!contains"
    },
    {
      name:"(startsWith) starts with..",
      translation: 'filters.operators.startsWith',
      value:"startsWith"
    },
    {
      name:"(!startsWith) not starting with..",
      translation: 'filters.operators.not_startsWith',
      value:"!startsWith"
    },
    {
      name:"(range) range.",
      translation: 'filters.operators.inrange',
      value:"inrange"
    },
  ]

  operatorOptionsFixedValue =[
    {
      name:"(==) equal to",
      translation: 'filters.operators.equal',
      value:"="
    },
    {
      name:"(!=) not equal to",
      translation: 'filters.operators.not_equal',
      value:"!="
    },
    {
      name:"(>) greather than",
      translation: 'filters.operators.greather_than',
      value:">"
    },
    {
      name:"(<) less than",
      translation: 'filters.operators.less_than',
      value:"<"
    },
    {
      name:"(>=) greter than or equal to",
      translation: 'filters.operators.greather_than_equal_to',
      value:">="
    },
    {
      name:"(<=) less than or equal to",
      translation: 'filters.operators.less_than_equal_to',
      value:"<="
    },
    {
      name:"(in) equal to any of the following",
      translation: 'filters.operators.in',
      value:"in"
    },
    {
      name:"(!in) not equal to any of the following",
      translation: 'filters.operators.not_in',
      value:"!in"
    },
    {
      name:"(contains) an array containing",
      translation: 'filters.operators.contains',
      value:"contains"
    },
    {
      name:"(!contains) array not containing",
      translation: 'filters.operators.not_contains',
      value:"!contains"
    },
    {
      name:"(startsWith) starts with..",
      translation: 'filters.operators.startsWith',
      value:"startsWith"
    },
    {
      name:"(!startsWith) not starting with..",
      translation: 'filters.operators.not_startsWith',
      value:"!startsWith"
    },
    {
      name:"(exists) the field has a value..",
      translation: 'filters.operators.exists',
      value:"exists"
    },
    {
      name:"(!exists) the field has no value..",
      translation: 'filters.operators.not_exists',
      value:"!exists"
    },
  ]

  typeOptions = [
    {
      name:"Text",
      type:"string"
    },
    {
      name:"Number",
      type:"number"
    },
    {
      name:"Decimal",
      type:"decimal"
    },
    {
      name:"Boolean",
      type:"boolean"
    },
    {
      name:"Date",
      type:"date"
    },
    {
      name:"Time",
      type:"time"
    },
    {
      name:"Date/Time",
      type:"dateTime"
    },
    {
      name:"Media",
      type:"media"
    },
  ]


  constructor(private reportService:ReportService) { 
    this.reportService.lastReportResults$.subscribe( value => {
      this.reportDataResults$.next(value)
      this.reportDataResultsFiltered$.next(value)
      this.reportDataResultsFilteredValues$.next(value)
      if(value['filters']){
       this.reportFilters$.next(value['filters'])
      }
    })

    // this.selectedFilterValue$.subscribe(value=> {
    //   this.filter()
    // })
   }


  setQueryParams(param){
    this.lastReportQueryParams$.next(param)
  }

  addQueryParam(key,value){
    let params = deepCopy( this.lastReportQueryParams$.value)
    params[key]=value
    this.lastReportQueryParams$.next(params)
  }

  clearQueryParam(key){
    let params = deepCopy( this.lastReportQueryParams$.value)
    delete params[key]
    this.lastReportQueryParams$.next(params)
  }

  clearFilter( filter, key, value){
    let allFiltersValues = this.selectedFilterValue$.value
    // if (!allFiltersValues) allFiltersValues = []
    if (allFiltersValues && allFiltersValues.length!=0) {
      const selected = allFiltersValues.find( (filt, index) => {if(filt && filt.filterKey == key) allFiltersValues.splice(index,1)})
      this.selectedFilterValue$.next(allFiltersValues)
      this.filter()
    }

  }

  activeFilter( filter, key, value){
    let allFiltersValues = this.selectedFilterValue$.value
    if (!allFiltersValues) allFiltersValues = []

    const isFilteredKey = allFiltersValues.find( id => id.filterKey == key)
    const isFiltered = allFiltersValues.find( id => id.filterKey == key &&  id.filterValue == value)

    if(isFilteredKey){
      const isFilteredDiffValue = allFiltersValues.find( id => id.filterKey == key &&  id.filterValue != value)
      if(isFilteredDiffValue){
        this.updateFilter(filter, key, value)
      }else{

      }
    }else{
      this.addFilter(allFiltersValues, filter, key, value)
    }
    
  }

  updateFilter(filter, key, value){
      let allFiltersValues = this.selectedFilterValue$.value
      // if (!allFiltersValues) allFiltersValues = []
    if (allFiltersValues) {
        // const selected = allFiltersValues.find( (filt, index) => {if(filt.filterKey == key) allFiltersValues.splice(index,1)})
        const selected = allFiltersValues.find( (filt, index) => filt.filterKey == key)
        selected.filterValue = value
        this.selectedFilterValue$.next(allFiltersValues)
        this.filter()
    }
  }

  addFilter(allFiltersValues, filter, key, value){
    var newFilter: Filter
    const operator = this.operatorOptions.find( id => id.value== filter.operator)
    const type = this.typeOptions.find( id => id.type == filter.type)
    newFilter = new Filter(filter.type, filter.key, operator, type,filter.displayName, value, filter?.description, filter?.options)
    allFiltersValues.push(newFilter)
    this.selectedFilterValue$.next(allFiltersValues)
    this.filter()
  }

  resetFilters(){
    this.selectedFilterValue$.next([])
  }

 initializeFilters( filters){
    let allFilters = []

    if(filters){
      if (filters.length >0 ){
        filters.forEach(filter => {
          var newFilter: Filter
          const operator = this.operatorOptions.find( id => id.value== filter.operator)
          const type = this.typeOptions.find( id => id.type == filter.type)
          newFilter = new Filter(filter.type, filter.key, operator, type,filter.displayName, filter?.description, filter?.options)
          allFilters.push(allFilters)
        });

        this.reportFilters$.next(allFilters)
        this.selectedFilters$.next(allFilters)
        this.selectedFilterValue$.next([])
      }
    }
    
  }

  setFilter( filter, key){
    let allFilters = this.selectedFilters$.value
    if (!allFilters) allFilters = []

    var newFilter: Filter
        const operator = this.operatorOptions.find( id => id.value== filter.operator)
        const type = this.typeOptions.find( id => id.type == filter.type)
        newFilter = new Filter(filter.type, filter.key, operator, type,filter.displayName, undefined, filter?.options)

    allFilters.push(newFilter)

    this.reportFilters$.next(allFilters)
    this.selectedFilters$.next(allFilters)
  }


   filter() {  
    if(this.selectedFilters$.value.length> 0){
      if(this.selectedFilterValue$.value.length > 0){
        const data = filterObjectsOnFilter(this.reportDataResultsFiltered$.value, this.selectedFilterValue$.value)
        this.reportDataResultsFilteredValues$.next(data)
      }else{
        this.reportDataResultsFilteredValues$.next(this.reportDataResultsFiltered$.value)
      }
    }
  }
   
}
