import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DuplicatePassTemplateDialogComponent } from '../duplicate-pass-template-dialog/duplicate-pass-template-dialog.component';
import { CommentsService } from '../service/comments.service';
import { StorageService } from '../service/storage.service';
import { getSizeBytes } from '../../../../backend/utils/object';
import { isDateTime } from '../../../../backend/src/filter';
import { saveAs } from "file-saver";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-media-preview-dialog',
  templateUrl: './media-preview-dialog.component.html',
  styleUrls: ['./media-preview-dialog.component.css']
})
export class MediaPreviewDialogComponent {
  mediaFileData:any
  mediaFile:any
  mediaFileName:string


  imagePreview: string | ArrayBuffer | null = null;
  videoPreview: string | ArrayBuffer | null = null;
  textPreview: string = '';
  pdfPreview: string | ArrayBuffer | null = null;

  constructor(
    public dialogRef: MatDialogRef<DuplicatePassTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storageService:StorageService,
    private translate:TranslateService,
    private commentsService : CommentsService,
    private http: HttpClient
  ){
    dialogRef.disableClose = true;

  }


  ngOnInit(): void {
    if(this.data.data){
      const data = this.data.data
      this.mediaFileData = this.data
      this.mediaFile = data.folderItems   
      this.mediaFileName = data.folderItems.name 
    }else{
      this.mediaFileData = this.data
      this.mediaFile = this.data.file   
      this.mediaFileName = this.data.name 
    }
    this.loadFilePreview()  
  }

  getFileSize(size){
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    return getSizeBytes(size,units ,2)
  }
  
  downloadFile(){
    this.data.data.downloadFile(this.mediaFile)

  }
  deleteFile(){

    if(this.data.data){
      this.data.data.deleteFile(this.mediaFile)
      this.dialogRef.close([true, 'after-delete'])

    }else{ // TODO,delete this else
      this.storageService.deleteFile(this.mediaFile.reference).then( value => {
        
        this.dialogRef.close([true, 'after-delete'])
      })
    }

  }

 
  // Method to handle file selection
  loadFilePreview(){
    this.mediaFile = this.mediaFileData.file

    if (this.mediaFile.metadata.contentType ) {
      this.imagePreview = undefined;
      this.textPreview = undefined;
      this.pdfPreview = undefined;

      if (this.mediaFile.metadata.contentType.startsWith('image')) {
        this.imagePreview = this.mediaFileData.file.url

      } else if (this.mediaFile.metadata.contentType  === 'text/plain') {
          this.textPreview = this.mediaFileData.file.url
      } else if (this.mediaFile.metadata.contentType .startsWith('video')) {
          this.videoPreview =this.mediaFileData.file.url;
      }
      else if (this.mediaFile.metadata.contentType  === 'application/pdf') {
          this.pdfPreview = this.mediaFileData.file.url;
      }
    }
  }

  openFile(){
    window.open(this.mediaFileData.file.url.toString(), "_blank");
  }
}
