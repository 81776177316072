import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';

@Component({
  selector: '[app-spacer-phone]',
  templateUrl: './spacer-phone.component.html',
  styleUrls: ['./spacer-phone.component.css']
})
export class SpacerPhoneComponent {
  @Input('app-spacer-phone') data:any
  @Output() returnChanges = new EventEmitter<{newdata: any, widget: any, widgetType:any}>()
  divider=false
  spacerData:any
  darkModeActive= false

  constructor(private renderer: Renderer2,
    private element: ElementRef){}

  ngOnInit():void{

    if(this.data[0]){
      this.spacerData = this.data[0]
    }
    if(this.data[5]){
      this.darkModeActive = this.data[5]
      // this.setWidgetStyle(this.spacerData)  
    }

    if(this.spacerData.divider){
      this.divider=true
    }

    setTimeout( ()=> {
      if(this.spacerData['spacing']){
        const name = 'spacer'+this.spacerData['id']
        document.getElementById(name).style.height = this.spacerData['spacing']+'px'
  
      }
    },300)

  }

  ngOnChanges(){
    if(this.data[0]){
      this.spacerData = this.data[0]
    }
    if(this.data[5]){
      this.darkModeActive = this.data[5]
    }
    this.darkModeActive = this.data[5]
    
    setTimeout(() => {
      this.setWidgetStyle(this.spacerData)     
    }, 200);
  }

  setWidgetStyle(data){
    const elementId = 'phoneSection-'+data.id
    const element = this.element.nativeElement.querySelector('#'+elementId);
    if(data?.widgetStyle){
      if(this.darkModeActive){
        if( data.widgetStyle.dark){
          if(data.widgetStyle.dark.backgroundColor){
            document.getElementById(elementId).style.backgroundColor = data.widgetStyle.dark.backgroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          }
          if( data.widgetStyle.dark.foregroundColor){
            document.getElementById(elementId).style.color = data.widgetStyle.dark.foregroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
          }
        }else{
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
        }
      }else{
        if( data.widgetStyle.light){
          if( data.widgetStyle.light.backgroundColor){
            document.getElementById(elementId).style.backgroundColor = data.widgetStyle.light.backgroundColor
          }else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          }

          if( data.widgetStyle.light.foregroundColor)
            document.getElementById(elementId).style.color = data.widgetStyle.light.foregroundColor
          else{
            if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
          }
        }else{
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.backgroundColor)
            this.renderer.removeStyle(element, 'backgroundColor');
          if(document.getElementById(elementId).style && document.getElementById(elementId).style.color)
            this.renderer.removeStyle(element, 'color');
        }
      }

      if(data.widgetStyle.contentAlignment){
        if(data.widgetStyle.contentAlignment=='leading')
          document.getElementById(elementId).style.textAlign = 'left'

        if(data.widgetStyle.contentAlignment=='center')
          document.getElementById(elementId).style.textAlign = 'center'

        if(data.widgetStyle.contentAlignment=='trailing')
          document.getElementById(elementId).style.textAlign = 'end'

      }else{
        if(document.getElementById(elementId)?.style && document.getElementById(elementId)?.style.textAlign)
          this.renderer.removeStyle(element, 'textAlign');
      }
    }
  }
}
